<template>
  <v-card elevation="1">
    <v-dialog v-model="updateWeeklyAverageModal" max-width="400">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('titles.updateWeeklyAverages') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />
        <ValidationObserver
          ref="updateWeeklyAveragesObserver"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(updateWeeklyAverage)">
            <v-card-text>
              <v-row>
                <FormElement
                  v-for="(element, index) in formItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  :vertical="element.vertical"
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :cols="element.cols"
                  :sm="element.sm"
                  :md="element.md"
                  :lg="element.lg"
                  :light="false"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :topLabel="element.topLabel"
                  :active="element.active"
                  :disabled="element.disabled"
                  :placeholder="$t(element.placeholder)"
                />
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="$store.state.loadingStatus"
                >{{ $t('buttons.update') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="showOfferModal"
                >{{ $t('buttons.skipThisStep') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="short-content"
      v-model="priceDetailsModal"
      v-if="selectedItem.PriceDetails"
      max-width="600"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('titles.priceDetails') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <v-card-text>
          <v-list>
            <template v-for="(element, index) in selectedItem.PriceDetails">
              <v-list-item :key="`LIST_ITEM_${index}`">
                <div class="d-flex justify-space-between" style="width: 100%">
                  <h3 class="list-item-title">
                    {{ priceTypes[element.PriceType] }}
                  </h3>
                  <h2 class="price">
                    {{
                      `${parseFloat(element.Value).toFixed(2)} ${
                        selectedItem.PriceCurrency
                      }`
                    }}
                  </h2>
                </div>
              </v-list-item>
              <v-divider></v-divider>
            </template>

            <v-list-item
              class="mt-4"
              :key="`LIST_ITEM_${selectedItem.PriceDetails.length}`"
            >
              <div class="d-flex justify-space-between" style="width: 100%">
                <h3 style="font-size: 30px" class="list-item-title">
                  {{ $t('titles.total') }}
                </h3>
                <h2 class="price last">
                  {{
                    `${parseFloat(selectedItem.Price).toFixed(2)} ${
                      selectedItem.PriceCurrency
                    }`
                  }}
                </h2>
              </div>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            style="
              color: #fff;
              text-transform: unset !important;
              background-color: var(--red);
              padding: 1vh 1vh;
              border-radius: 30px !important;
              font-weight: bolder;
              font-size: medium;
            "
            elevation="0"
            class="darken-1"
            @click="priceDetailsModal = false"
            >{{ $t('buttons.cancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="confirmation.message"
      :confirm="confirmation.onConfirm"
      ref="confirmationModal"
      v-model="confirmation.visible"
      :button-settings="{
        no: {
          text: $t('buttons.ok'),
        },
      }"
    />
    <v-dialog
      @click:outside="change = false"
      v-model="showCreateOffer"
      max-width="1200px"
    >
      <CreateOfferForm
        v-if="change"
        key="CHANGE_OFFER_RESULTS"
        :searchAgain="changeTheSearch"
        :body="changeData"
        :update="!add"
        :add="add"
        :demand="add"
        :demandOnFinally="demandOnFinally"
        :params="{
          fastShippingSearchId: fastShippingSearchId || '',
          countries,
          citiesFrom,
          citiesTo,
          airports,
        }"
      />
      <CreateOfferForm
        v-else-if="showCreateOffer"
        key="DEMAND_OFFER_RESULTS"
        :body="activeItem"
        :update="!add"
        :add="add"
        :demand="add"
        :demandOnFinally="demandOnFinally"
        :params="{
          fastShippingSearchId: fastShippingSearchId || '',
          countries,
          citiesFrom,
          citiesTo,
          airports,
        }"
      />
    </v-dialog>
    <v-card-title style="color: var(--darkBlue)">
      <div class="flex">
        <v-card
          v-if="!$store.state.Token && offers.length != 0"
          elevation="4"
          class="d-flex flex-column justify-center py-4"
        >
          <h3
            style="
              color: var(--darkBlue);
              font-weight: bold;
              font-size: 1.75rem;
            "
            class="text-center"
          >
            {{ $t('description.pleaseRegister') }}
          </h3>
          <v-img
            src="https://expadoortest.s3.amazonaws.com/Media/7.09.2023%2010:35:14signuplogin.png?AWSAccessKeyId=AKIA5TWV25RSSAFBF64U&Expires=1725694514&Signature=78CqsLN8jm7bD4rrhP7%2FsYYD58Y%3D"
            style="object-fit: contain"
            max-height="200px"
            max-width="200px"
            class="align-self-center"
          >
          </v-img>
          <v-btn
            @click="goRegister"
            style="color: white; background-color: var(--softRed)"
            max-width="200px"
            class="button my-2 px-10 py-4 align-self-center"
            >{{ $t('buttons.register') }}</v-btn
          >
        </v-card>
        <h3 class="mt-4">{{ $t('titles.offerResults') }}</h3>
      </div>

      <v-btn
        v-if="!pageDisabled && $store.state.Token"
        style="border-radius: 12px !important"
        elevation="0"
        class="button gradient-button-dark-blue"
        dark
        type="button"
        @click="changeTheSearch(null, false)"
      >
        <v-icon left color="white">mdi-tooltip-edit-outline</v-icon>
        {{ $t('buttons.changeTheSearch') }}
      </v-btn>
    </v-card-title>
    <v-divider />

    <v-data-table
      :headers="offersHeaders"
      hide-default-footer
      disable-pagination
      :items="offers"
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:item.FromToWhere="{ item }">
        <div style="white-space: normal; word-wrap: break-word">
          {{ item.From }}
        </div>
        <span>-</span>
        <div style="white-space: normal; word-wrap: break-word">
          {{ item.To }}
        </div>
      </template>
      <template v-slot:footer>
        <v-pagination
          v-if="
            false /* TODO: Fiyat olan sayfalarda Pagination'ın kaldırılmasını Gökhan Bey istemişti. */
          "
          class="my-4"
          @input="onPageChange"
          :value="page"
          :length="pageLength"
          :total-visible="15"
          circle
        ></v-pagination>
      </template>
      <template v-slot:no-data>
        <div class="my-10 d-flex flex-column align-center">
          <h4>{{ $t('messages.noOffer') }}</h4>
          <h4 v-if="!$store.state.Token">
            {{ $t('messages.shouldRegister') }}
          </h4>
          <v-btn
            v-if="
              offers.length == 0 &&
              showDemandButton &&
              !pageDisabled &&
              $store.state.Token
            "
            key="DEMAND_BUTTON_NO_DATA"
            style="color: white; background-color: var(--softRed)"
            class="button my-2"
            @click="beforeWeeklyAverageModalOpen()"
          >
            {{ $t('buttons.demandPriceFromSupplier') }}
          </v-btn>
          <v-btn
            v-else
            key="REGISTER_OFFER_RESULTS"
            style="color: white; background-color: var(--blue)"
            class="button my-2"
            @click="$router.push({ name: 'register' })"
          >
            {{ $t('buttons.register') }}
          </v-btn>
        </div>
      </template>
      <!-- eslint-disable -->

      <template v-slot:item.shipmentType="{ item }">
        {{ activeItem.shipmentTypeAsString || '-' }}
      </template>
      <template v-slot:item.loadType="{ item }">
        {{ activeItem.loadingTypeAsString || '-' }}
      </template>
      <template v-slot:item.company="{ item }">
        {{ item.Supplier || '-' }}
      </template>
      <template v-slot:item.load="{ item }">
        {{ activeItem.load }}
      </template>
      <template v-slot:item.transferTime="{ item }">{{
        item.TransportTime || '-'
      }}</template>
      <template v-slot:item.price="{ item }">
        <span
          style="cursor: pointer; white-space: nowrap"
          @click="onClickOffer(item)"
          class="badge-cell"
        >
          {{ item.Price }} {{ item.PriceCurrency }}
        </span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          :disabled="!$store.state.Token"
          style="color: white"
          color="green"
          class="button my-2"
          @click="beforeCreateOrder(item)"
        >
          {{ $t('buttons.createOrder') }}
        </v-btn>
      </template>
    </v-data-table>
    <div
      style="width: 100%; padding-top: 3%"
      class="d-flex flex-column flex-lg-row align-center justify-center"
    >
      <v-alert
        style="font-size: 13px"
        outlined
        text
        class="mt-4 ml-0 ml-lg-2 p-2"
        type="info"
        >{{ $t('messages.ephWarning') }}</v-alert
      >
    </div>
    <div
      style="width: 100%; padding-bottom: 3%"
      class="d-flex flex-column flex-lg-row align-center justify-center"
      v-if="alerts.length > 0"
    >
      <v-alert
        style="font-size: 13px"
        v-if="alerts.search(1) > -1"
        outlined
        text
        class="mt-4 mr-0 p-2"
        type="warning"
        >{{ $t('messages.oversizeMessage') }}</v-alert
      >
      <v-alert
        style="font-size: 13px"
        v-if="alerts.search(0) > -1"
        outlined
        text
        class="mt-4 ml-0 ml-lg-2 p-2"
        type="warning"
        >{{ $t('messages.longDistanceMessage') }}</v-alert
      >
    </div>

    <div
      style="
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      class="pb-4"
    >
      <v-btn
        v-if="offers.length > 0 && $store.state.Token"
        key="DEMAND_BUTTON_NO_DATA"
        style="
          color: white;
          background-color: var(--softRed);
          align-self: start;
        "
        class="button my-2"
        @click="beforeWeeklyAverageModalOpen()"
      >
        {{ $t('buttons.demandPriceFromSupplier') }}
      </v-btn>
    </div>
  </v-card>
</template>
<script>
import OrderService from '../services/OrderService';
import SearchServices from '../services/SearchServices';
import ShipmentService from '../services/ShipmentService';
import {
  ITEMS_PER_PAGE_OPTIONS,
  convertVolumeToBoxes,
  loadTypes,
  shipmentTypes,
  convertBoxesToVolumes,
  getCountries,
  getCities,
  getAirports,
} from '../utils/constants';
import CreateOfferForm from './OfferScreens/CreateOfferForm.vue';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import CompanyServices from '../services/CompanyServices';
import FormElement from '../components/FormElement.vue';

export default {
  data() {
    return {
      offersHeaders: [
        {
          text: this.$t('titles.shipmentType'),
          value: 'shipmentType',
          sortable: false,
          groupable: false,
          cellClass: 'color-pink',
          align: 'center',
        },
        {
          text: this.$t('labels.loadType'),
          value: 'loadType',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.from') + '/' + this.$t('titles.to'),
          value: 'FromToWhere',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
          width: '20%',
        },
        {
          text: this.$t('titles.load'),
          value: 'load',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },

        {
          text: this.$t('labels.companyTitle'),
          value: 'company',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.transferTime'),
          value: 'transferTime',
          sortable: true,
          groupable: false,
          cellClass: ['regular-cell', 'bold-cell'],
          align: 'center',
        },
        {
          text: this.$t('titles.offer'),
          value: 'price',
          sortable: false,
          groupable: false,
          cellClass: ['regular-cell'],
          align: 'center',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          groupable: false,
        },
      ],
      offers: [],
      fastShippingSearchId: null,
      activeItem: {},
      activeBody: {},
      change: false,
      changeData: {},

      // States
      pageLoading: false,
      itemsPerPageOptions: [],
      itemsPerPage: 10,
      page: 1,
      showCreateOffer: false,
      operationStatus: 1,
      add: false,
      showDemandButton: true,
      pageDisabled: false,

      confirmation: {
        onConfirm: () => {},
        visible: false,
        message: '',
      },

      alerts: '',

      selectedItem: {},
      priceDetailsModal: false,

      priceTypes: [],

      updateWeeklyAverageModal: false,
      form: {},

      countries: [],
      citiesFrom: [],
      citiesTo: [],
      airports: [],
    };
  },
  components: { CreateOfferForm, ConfirmationModal, FormElement },
  async mounted() {
    if (this.$route.params.id) {
      this.operationStatus = 1;
      this.fastShippingSearchId = this.$route.params.id;
      await this.searchAgain();
    } else if (this.$route.params.activeBody) {
      this.operationStatus = 1;
      this.activeBody = this.$route.params.activeBody;

      this.prepare();
      this.updateActiveItem();
      await this.priceSearch();
    } else if (this.$route.params.item) {
      this.operationStatus = 2;
      this.activeItem = this.$route.params;

      this.prepare();
      this.updateActiveItem();
      await this.priceSearch();
    } else {
      this.pageDisabled = true;
      this.$router.push('quick-search');
    }

    if (this.activeItem.item) {
      this.activeItem.item.loadType === 1 ||
      this.activeItem.item.loadType === 4 ||
      this.activeItem.item.loadType === 6
        ? null
        : this.offersHeaders.splice(3, 4, {
            text: this.$t('labels.cargo'),
            value: 'ListName',
            sortable: false,
            groupable: false,
            cellClass: ['regular-cell'],
          });
    }
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.changeData = this.activeItem;

    this.priceTypes = this.$priceTypes;

    this.countries = await getCountries();

    if (this.changeData.item.country_fromWhere) {
      this.citiesFrom = await getCities(this.changeData.item.country_fromWhere);
    }
    if (this.changeData.item.country_toWhere) {
      this.citiesTo = await getCities(this.changeData.item.country_toWhere);
    }

    if (
      !!this.changeData.item.airport_fromWhere ||
      !!this.changeData.item.airport_toWhere
    ) {
      this.airports = await getAirports();
    }
  },
  watch: {
    updateWeeklyAverageModal(newValue) {
      if (!newValue) {
        this.$refs.updateWeeklyAveragesObserver.reset();
        this.form = {};
      }
    },
  },
  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'form',
            child: 'AverageWeeklyUploadCount',
          },
          validationLabel: 'labels.averageWeeklyLoadCount',
          formType: {
            name: 'text',
          },
          rules: 'numeric',
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.averageWeeklyLoadCount',
        },
        {
          key: {
            parent: 'form',
            child: 'AverageWeeklyUploadWeight',
          },
          validationLabel: 'labels.averageWeeklyLoadWeight',
          formType: {
            name: 'text',
          },
          rules: 'numeric',
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.averageWeeklyLoadWeight',
        },
      ];
    },

    pageLength() {
      return Math.ceil(this.offers.length / this.itemsPerPage);
    },
  },
  methods: {
    beforeWeeklyAverageModalOpen() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getWeeklyAverages()
        .then((response) => {
          const data = response.data.Result;
          if (data) {
            this.form = { ...data };
          }
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.updateWeeklyAverageModal = true;
        });
    },
    updateWeeklyAverage() {
      this.$store.state.loadingStatus = true;
      CompanyServices.updateWeeklyAverages(this.form)
        .then((response) => {
          this.$t('messages.succesfulUpdateWeeklyAverages');
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.showOfferModal();
        });
    },

    showOfferModal() {
      this.updateWeeklyAverageModal = false;
      this.showCreateOffer = true;
      this.add = true;
      this.change = false;
      this.activeItem.item.fastShippingSearchId = this.fastShippingSearchId;
    },

    onClickOffer(item) {
      this.selectedItem = item;
      this.priceDetailsModal = true;
    },

    demandOnFinally() {
      this.showCreateOffer = false;
      this.showDemandButton = false;
      this.$router.push({ name: 'spotOffers' });
    },

    /**
     * Change the search with create offer form.
     * @param {boolean} isChange
     * Goes to back for changing search parameters.
     */
    async changeTheSearch(request = null, add = false) {
      if (!request) {
        this.changeData = this.activeItem;
        this.showCreateOffer = true;
        this.add = add;
        this.change = true;
      } else {
        this.activeItem.transferType = request.transferType;
        this.activeItem.shipmentType = request.shipmentType;
        this.activeItem.exportImportType = request.exportImportType;

        this.activeItem.item = request.item;
        this.operationStatus = 2;
        this.prepare();
        await this.priceSearch();
      }
    },

    async priceSearch() {
      const data = this.activeItem;
      const item = data.item;

      this.activeBody.Boxes = convertVolumeToBoxes(item.volumes);

      if (!this.fastShippingSearchId) {
        this.$store.state.loadingStatus = true;
        this.activeBody.page = 1;
        this.activeBody.pageSize = 1000;

        await SearchServices.getSearches(this.activeBody)
          .then((response) => {
            if (response?.data?.Result) {
              this.fastShippingSearchId =
                response.data.Result.fastShippingSearchId;

              if (this.fastShippingSearchId) {
                this.$router.push({
                  name: 'offerResultsById',
                  params: { id: this.fastShippingSearchId },
                });
              }

              this.offers = response.data.Result.supplierLists || [];
              this.showCreateOffer = false;
            }
          })
          .catch((error) => this.onServiceError(error))
          .finally(() => (this.$store.state.loadingStatus = false));
      } else {
        this.activeBody.Id = this.fastShippingSearchId;
        await SearchServices.updateFastShippingSearch(this.activeBody)
          .then(async (response) => {
            await this.searchAgain();
            this.showCreateOffer = false;
          })
          .catch((error) => this.onServiceError(error))
          .finally(() => (this.$store.state.loadingStatus = false));
      }
    },

    prepare() {
      if (this.operationStatus === 1) {
        const data = this.activeBody;

        let updatedBoxes = [];
        if (data?.FastShippingBoxes?.length > 0) {
          updatedBoxes = convertBoxesToVolumes(
            data.FastShippingBoxes,
            data.ShipmentType || data.item.shipmentType
          );
        }

        this.activeItem = {
          transferType: data.TransportType,
          shipmentType: data.ShipmentType,
          exportImportType: data.ExportImportType,

          item: {
            transferType: data.TransportType,
            shipmentType: data.ShipmentType,
            loadType: data.LoadingType,
            selectedService: data.ServiceType,
            seaport_toWhere: data.SeaUnloadingPortId,
            seaport_fromWhere: data.SeaLoadingPortId,
            airport_toWhere: data.AirUnloadingPortId,
            airport_fromWhere: data.AirloadingPortId,
            station_toWhere: data.RailwayloadingId,
            station_fromWhere: data.RailwayUnloadingId,
            city_toWhere: data.DestinationCityId,
            postalCode_fromWhere: data.LoadingPostalCode,
            country_fromWhere: data.LoadingCountryId,
            city_fromWhere: data.LoadingCityId,
            postalCode_toWhere: data.DestinationPostalCode,
            country_toWhere: data.DestinationCountryId,
            carType: data.CarriageType,
            carCount: data.CarriageCount,
            vehicleType: data.TruckType,
            vehicleCount: data.TruckCount,
            containerType: data.ContainerType,
            containerCount: data.ContainerCount,
            doorCount: data.SumCapPieces || 0,
            kilo: parseFloat(data.SumWeight).toFixed(2).toString() || '0',
            volume: parseFloat(data.SumVolume).toFixed(2).toString() || '0',
            // SumVolume float gelmiyor. Kontrol edilecek. Zehra'nın son işlemi denenecek.
            isDangerousMaterial: data.IsDangeroousSubstance || false,
            dangerousMaterialLevel: data.HazmatCode,
            willNotBeStacked: data.IsStockUp || false,
            volumes: updatedBoxes || [],

            isTransshipment: data.IsTransshipment,
            transshipmentCount: data.TransshipmentCount,

            chargeableWeight: data.ChargeableWeight,

            taxPayer: data.TaxPayer,

            subsupplier: data.SubSupplier,
            subsupplierServiceType: data.SubSupplierService,

            expressPackageType: data.ExpressPackageType,
            expressServiceType: data.ExpressServiceType,
          },
        };

        if (data.MarketplaceOrderId) {
          this.activeItem.item.marketplaceOrderId = data.MarketplaceOrderId;
        }
      } else {
        const data = this.activeItem;
        const item = data.item;

        this.activeBody = {
          TransportType: data.transferType,
          ShipmentType: data.shipmentType,
          ExportImportType: parseInt(data.exportImportType),
          LoadingType: item.loadType,
          ServiceType: item.selectedService,
          SeaUnloadingPortId: item.seaport_toWhere,
          SeaLoadingPortId: item.seaport_fromWhere,
          AirUnloadingPortId: item.airport_toWhere,
          AirloadingPortId: item.airport_fromWhere,
          RailwayloadingId: item.station_toWhere,
          RailwayUnloadingId: item.station_fromWhere,
          DestinationCityId: item.city_toWhere,
          DestinationPostalCode: item.postalCode_toWhere,
          DestinationCountryId: item.country_toWhere,
          LoadingCityId: item.city_fromWhere,
          LoadingPostalCode: item.postalCode_fromWhere,
          LoadingCountryId: item.country_fromWhere,
          CarriageType: item.carType,
          CarriageCount: item.carCount,
          TruckType: item.vehicleType,
          TruckCount: item.vehicleCount,
          ContainerType: item.containerType,
          ContainerCount: parseInt(item.containerCount),
          SumCapPieces: parseInt(item.doorCount),
          SumWeight: parseFloat(item.kilo),
          SumVolume: parseFloat(item.volume),
          IsDangeroousSubstance: item.isDangerousMaterial || false,
          HazmatCode: item.dangerousMaterialLevel,
          IsStockUp: item.willNotBeStacked || false,
          Boxes: [],

          IsTransshipment: item.isTransshipment,
          TransshipmentCount: item.transshipmentCount,

          ChargeableWeight: parseFloat(item.chargeableWeight),

          TaxPayer: item.taxPayer,
          SubSupplier: item.subsupplier,
          SubSupplierService: item.subsupplierServiceType,

          ExpressPackageType: item.expressPackageType,
          ExpressServiceType:
            item.expressPackageType === 2 ? 5 : item.expressServiceType,
        };

        if (item.marketplaceOrderId) {
          this.activeBody.MarketplaceOrderId = item.marketplaceOrderId;
        }
      }
    },

    async searchAgain() {
      this.$store.state.loadingStatus = true;
      await SearchServices.getFastShippingSearchById(this.fastShippingSearchId)
        .then(async (response) => {
          this.operationStatus = 1;
          this.activeBody = response.data;
          this.prepare();
          await this.updateActiveItem();
        })
        .catch((error) => this.onServiceError(error));

      await SearchServices.searchAgain(
        this.page,
        this.itemsPerPage,
        this.fastShippingSearchId
      )
        .then((response) => {
          const data = response.data.Result;

          this.offers = data.supplierLists || [];
          this.alerts = data.Alerts.join();
        })
        .catch((error) => this.onServiceError(error))
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    async updateActiveItem() {
      let object = this.activeBody;

      this.activeItem.shipmentTypeAsString =
        shipmentTypes.find((item) => item.Id === object.ShipmentType)?.Name ||
        'No Transfer';
      if (object.TransportType === 2) {
        this.activeItem.loadingTypeAsString = 'Express';
      } else {
        this.activeItem.loadingTypeAsString =
          loadTypes.find((item) => item.Id === object.LoadingType)?.Name ||
          'No Load';
      }

      if (object.SumCapPieces && object.SumVolume && object.SumWeight) {
        this.activeItem.load = `${object.SumCapPieces || 0} ${this.$t(
          'labels.piece'
        )}  (${object.SumVolume || 0} m³ x ${object.SumWeight | 0} kg)  `;
      } else if (object.ContainerCount && object.ContainerType) {
        this.activeItem.load = `${object.ContainerCount} x ${object.ContainerType}`;
      } else if (object.TruckCount && object.TruckType) {
        this.activeItem.load = `${object.TruckCount} x ${object.TruckType}`;
      } else if (object.CarriageCount && object.CarriageType) {
        this.activeItem.load = `${object.CarriageCount} x ${object.CarriageType}`;
      }
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      console.log(error);
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Reset all models in View.
     */
    resetAllModels() {
      this.resetForm();
    },
    /**
     * Save the volumes.
     */
    save() {
      this.onSave(this.volumes);
      this.openModal = false;
    },
    /**
     * Deletes an item from volumes.
     */
    deleteItem(index) {
      this.volumes = this.volumes.filter((item) => item.index !== index);
    },
    /**
     * Calculate prices in the list.
     * @param {array} items
     */
    calculatePrice(items, key) {
      let total = 0;
      if (items) {
        items.map((item) => (total += parseFloat(item[key]).toFixed(2)));
      }
      return total;
    },

    beforeCreateOrder(item) {
      if (!item.IsOverWeight) {
        this.createOrder(item);
      } else {
        this.confirmation.message = this.$t('messages.maximumKg', {
          maximumKg: item.ServiceMaxKg,
        });
        this.confirmation.onConfirm = null;
        this.confirmation.visible = true;
      }
    },

    createOrder(item) {
      const body = {
        FastShippingSearchId: this.fastShippingSearchId,
        Price: item.Price,
        CarrierCompanyId: item.CarrierId,
        CurrencyCode: item.PriceCurrency,
        TransitTime: item.TransportTime,
        PriceListId: item.ListId,
        PriceDetails: item.PriceDetails || [],
      };

      this.$store.state.loadingStatus = true;
      ShipmentService.createShipment(body)
        .then((res) => {
          var shipment = res.data;
          const orderBody = {
            ShipmentId: shipment.Id,
          };
          OrderService.createOrder(orderBody)
            .then((result) => {
              var orderRes = result.data.Result;
              this.$router.push({
                name: `OrderDetail`,
                query: {
                  orderId: orderRes.Id,
                },
              });
              this.$store.state.loadingStatus = false;
            })
            .catch((err) => {
              window.handleServiceError(err);
              this.$store.state.loadingStatus = false;
            });
        })
        .catch((err) => {
          window.handleServiceError(err);
          this.$store.state.loadingStatus = false;
        });
    },
    /**
     * Add a new volume.
     */
    addVolume() {
      this.$store.state.loadingStatus = true;
      setTimeout(() => {
        this.volumeItem.index = this.volumes.length;
        this.volumes.unshift(this.volumeItem);
        this.$refs.addVolumeObserver.reset();
        this.volumeItem = {};
        window.showSuccess(
          this.$t('messages.successfulAdd', {
            value: this.$t('labels.volume').replaceAll('(m3)', ''),
          })
        );
        this.$store.state.loadingStatus = false;
      }, 1000);
    },
    goRegister() {
      this.$router.push({ name: 'register' });
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
}

.list-item-title {
  color: black;
  font-weight: 500;
  font-size: 14px;
}
.price {
  color: var(--darkerBlue);
  font-weight: bold;
  font-size: 17px;
}

.last {
  font-size: 20px;
}
</style>
