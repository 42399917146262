import { update } from 'lodash';
import axios from '../javascript/custom-axios';

const RESOURCE_NAME = '/company-package';

export default {
  addPackage(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },
  getModules(CompanyCategory) {
    return axios.get(`${RESOURCE_NAME}/modules`, {
      params: { CompanyCategory },
    });
  },
  getPackages(Page, PageSize, Id, CompanyCategory) {
    return axios.get(`${RESOURCE_NAME}/get`, {
      params: {
        Page,
        PageSize,
        Id,
        CompanyCategory,
      },
    });
  },
  updatePackage(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
  deletePackage(Id) {
    return axios.delete(`${RESOURCE_NAME}/delete`, {
      params: { Id },
    });
  },
  setPackage(body) {
    return axios.post(`${RESOURCE_NAME}/set-package`, body);
  },
  getForUsers() {
    return axios.get(`${RESOURCE_NAME}/get-for-users`, {
      params: { Page: 1, PageSize: 99999 },
    });
  },

  buyPackage(body) {
    return axios.post(`${RESOURCE_NAME}/buy`, body);
  },

  getAllUsage() {
    return axios.get(`${RESOURCE_NAME}/get-all-usage`, {});
  },
  getStatistics() {
    return axios.get(`${RESOURCE_NAME}/get-statistics`, {});
  },
  activePortals(body) {
    return axios.put(`${RESOURCE_NAME}/activate-portals`, {
      CompanyIds: body,
    });
  },
};
