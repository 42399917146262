<template>
  <div>
    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row>
            <v-col class="text-lg-left text-center" lg="5" cols="12">
              <h3>{{ $t('navigationDrawerMenu.marketplace') }}</h3>
            </v-col>
          </v-row>
          <div>
            <v-btn
              @click="addMarketplaceDialog = true"
              class="rounded-circle mr-4"
              style="
                min-width: 1px;
                min-height: 1px;
                width: 36px;
                height: 36px;
                background-color: #0177d4;
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon small color="white" style="font-size: 24px" v-on="on">
                    mdi-plus
                  </v-icon>
                </template>
                <span>{{ $t('labels.addMarketplace') }}</span>
              </v-tooltip>
            </v-btn>

            <RefreshButton
              :refreshFunction="getMarketplaceData"
            ></RefreshButton>
          </div>
        </v-card-title>
      </div>

      <v-divider />

      <v-data-table
        :headers="headers"
        :items="marketplaceData"
        :items-per-page="10"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        style="padding: 0 16px"
        hide-default-footer
        :loading="$store.state.loadingStatus"
        :no-data-text="$t('messages.noData')"
        ><!-- eslint-disable -->
        <template v-slot:item.PhotoUrl="{ item }">
          <div>
            <img
              :src="item.PhotoUrl"
              alt="Carrier Logo"
              style="width: 50px; height: auto"
            />
          </div>
        </template>
        <template v-slot:item.Status="{ item }">
          <v-chip
            v-if="item.Status == 'Aktive'"
            class="ma-2"
            small
            color="#c5f4db"
            text-color="#2b3838"
          >
            {{ $t('labels.tooltipActive') }}
          </v-chip>

          <v-chip
            v-if="item.Status == 'Passive'"
            class="ma-2 text--center"
            small
            color="#e3e2e3"
            text-color="#2b3838"
          >
            {{ $t('labels.tooltipPassive') }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-start">
            <v-tooltip v-if="item.StatusType === 2" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  middle
                  class="mr-2"
                  color="blue"
                  @click="sendMail(item.Id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-email
                </v-icon>
              </template>
              <span>{{ $t('labels.marketplaceLoginURL') }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.StatusType === 2" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  middle
                  class="mr-2"
                  color="red"
                  @click="test(item.Id)"
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-table-merge-cells
                </v-icon>
              </template>
              <span>{{ $t('labels.test') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.MarketplaceName == 'amazon'"
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="selectEditMarketplace(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipEdit') }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-center mt-8 pb-lg-0 pb-8">
            <v-row justify="center">
              <v-col md="3" class="ml-md-auto">
                <v-pagination
                  class="mr-2"
                  @input="onPageChange"
                  :value="page"
                  :length="pageLength"
                  :total-visible="15"
                  circle
                ></v-pagination>
              </v-col>
              <v-col md="2" class="ml-md-auto">
                <v-select
                  v-model="itemsPerPage"
                  :items="perPageValues"
                  :label="$t('labels.selectItemsPerPage')"
                  item-value="value"
                  item-text="name"
                  outlined
                  dense
                  @change="changeItemsPerPage()"
                  rounded
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-data-table>

      <v-dialog
        v-model="addMarketplaceDialog"
        @input="resetDialog"
        @close="resetDialog"
        max-width="600"
      >
        <v-card class="popup-card" elevation="1">
          <v-card-title
            class="popupHeadline d-flex justify-center"
            style="font-size: 16px"
          >
            {{ $t('labels.addMarketplace') }}
          </v-card-title>

          <v-divider class="mb-2" />
          <ValidationObserver
            v-slot="{ handleSubmit }"
            ref="addMarketplaceObserver"
          >
            <v-card-text>
              <form @submit.prevent="handleSubmit(addMarketplace)">
                <FormElement
                  v-model="selectedMarketplace"
                  :validationLabel="$t('labels.marketplaceLabel')"
                  :formLabel="$t('labels.marketplaceLabel')"
                  :rules="'required'"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: marketplaces,
                      value: 'Id',
                      text: 'Name',
                    },
                  }"
                  :labelShown="false"
                  :topLabel="true"
                  :onChange="getParameters"
                  :placeholder="$t('labels.marketplaceLabel')"
                />

                <v-divider class="mb-6" />

                <FormElement
                  v-for="(element, index) in parameters"
                  :key="index"
                  v-model="marketplace[element]"
                  :active="true"
                  :validationLabel="$t('labels.marketplace.' + element)"
                  rules="required"
                  :formLabel="$t('labels.marketplace.' + element)"
                  :formType="{
                    name: 'text',
                  }"
                  :topLabel="true"
                  :labelShown="false"
                  :placeholder="$t('labels.marketplace.' + element)"
                />

                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
                      padding: 2vh 3vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    color="primary"
                    >{{ $t('buttons.add') }}</v-btn
                  >
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--red);
                      padding: 2vh 3vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    @click="resetDialog"
                    >{{ $t('buttons.cancel') }}</v-btn
                  >
                </v-card-actions>
              </form>
            </v-card-text>
          </ValidationObserver>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="editMarketplaceDialog"
        @input="resetDialog"
        @close="resetDialog"
        max-width="600"
      >
        <v-card class="popup-card" elevation="1">
          <v-card-title
            class="popupHeadline d-flex justify-center"
            style="font-size: 16px"
          >
            {{ $t('labels.editMarketplace') }}
          </v-card-title>

          <v-divider class="mb-2" />
          <ValidationObserver
            v-slot="{ handleSubmit }"
            ref="updateMarketplaceObserver"
          >
            <v-card-text>
              <form @submit.prevent="handleSubmit(updateMarketplace)">
                <!-- <v-row>
                  <v-col class="mb-4 form-column d-flex" cols="12" lg="12">
                    <v-img
                      v-if="
                        marketplace.PhotoUrl ||
                        require('../assets/no-image.png')
                      "
                      :lazy-src="
                        marketplace.PhotoUrl ||
                        require('../assets/no-image.png')
                      "
                      max-height="50"
                      max-width="50"
                      :src="marketplace.PhotoUrl"
                    ></v-img>
                    <div class="ml-4 align-self-center d-flex flex-column">
                      <span class="form-label">
                        {{ marketplace.MarketplaceName }}
                      </span>
                      <div v-if="parameters[0]">
                        <span style="line-height: 2.2; color: #315a88"
                          >{{ $t('labels.marketplace.' + parameters[0]) }}:
                        </span>
                        <span style="line-height: 2.2; color: #315a88">{{
                          marketplace[parameters[0]]
                        }}</span>
                      </div>
                    </div>
                  </v-col>
                </v-row> -->
                <FormElement
                  v-for="(element, index) in parameters"
                  :key="index"
                  v-model="selectedCompanyMarket[element]"
                  :active="true"
                  :validationLabel="$t('labels.marketplace.' + element)"
                  rules="required"
                  :formLabel="$t('labels.marketplace.' + element)"
                  :formType="{
                    name: 'text',
                  }"
                  :topLabel="true"
                  :labelShown="false"
                  :placeholder="$t('labels.marketplace.' + element)"
                />
                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
                      padding: 2vh 3vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    color="primary"
                    >{{ $t('buttons.save') }}</v-btn
                  >
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--red);
                      padding: 2vh 3vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    @click="resetDialog"
                    >{{ $t('buttons.cancel') }}</v-btn
                  >
                </v-card-actions>
              </form>
            </v-card-text>
          </ValidationObserver>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import MarketplaceServices from '../services/MarketplaceServices';
import CompanyMarketplaceServices from '../services/CompanyMarketplaceServices';

export default {
  data: () => ({
    marketplaces: [],
    marketplaceData: [],
    selectedMarketplace: null,
    headers: [],
    addMarketplaceDialog: false,
    editMarketplaceDialog: false,
    itemsPerPageOptions: [],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,
    parameters: [],
    marketplace: {},
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],

    selectedCompanyMarket: {},
  }),
  mounted() {
    this.headers = [
      {
        text: this.$t('labels.logo'),
        value: 'PhotoUrl',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.marketplace.Name'),
        value: 'Name',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.marketplace.title'),
        value: 'MarketplaceName',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.creator'),
        value: 'CreateBy',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.status'),
        value: 'Status',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.actions'),
        value: 'actions',
        sortable: false,
      },
    ];
    this.getMarketplaceData();
    this.getMarketplaces();
  },
  components: {
    RefreshButton,
    FormElement,
  },
  computed: {
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    /**
     * Send mail for company marketplace.
     * @param {string} companyMarketId
     */
    sendMail(companyMarketId) {
      this.$store.state.loadingStatus = true;
      CompanyMarketplaceServices.sendMail(companyMarketId)
        .then((response) => {
          window.showSuccess(this.$t('messages.mailHasBeenSentSuccessfully'));
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * Test Company Market which one you have added into Company Market List.
     * @param {string} companyMarketId
     */
    test(companyMarketId) {
      this.$store.state.loadingStatus = true;
      CompanyMarketplaceServices.test(companyMarketId)
        .then((response) => {
          console.log(response.data.Successed);
          if (response.data.Successed) {
            window.open(response.data.Result, 'Popup', 'width=600,height=400');
          } else {
            window.showError(null, this.$t('messages.errorTest'));
          }
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    async selectEditMarketplace(item) {
      this.selectedCompanyMarket = Object.assign({}, item);
      this.selectedMarketplace = item.MarketplaceId;
      await this.getParameters();
      this.editMarketplaceDialog = true;
    },
    getMarketplaceData() {
      this.$store.state.loadingStatus = true;
      CompanyMarketplaceServices.get(
        this.$store.state.CompanyId,
        this.page,
        this.itemsPerPage
      )
        .then((response) => {
          this.marketplaceData = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    updateMarketplace() {
      const body = {
        CompanyMarketId: this.selectedCompanyMarket.Id
      };
      this.marketplace.CompanyMarketId = this.selectedCompanyMarket.Id;
      this.$store.state.loadingStatus = true;

      this.parameters.forEach(
        (item) => (body[item] = this.selectedCompanyMarket[item])
      );

      CompanyMarketplaceServices.update(body)
        .then(() => {
          this.getMarketplaceData();
          this.resetDialog();

          window.showSuccess(
            this.$t('messages.successfulUpdate', {
              value: this.$t('labels.marketplaceLabel'),
            })
          );
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    addMarketplace() {
      this.marketplace.MarketplaceId = this.selectedMarketplace;
      this.$store.state.loadingStatus = true;
      CompanyMarketplaceServices.add(this.marketplace)
        .then(() => {
          this.getMarketplaceData();
          this.resetDialog();

          window.showSuccess(
            this.$t('messages.successfulAdd', {
              value: this.$t('labels.marketplaceLabel'),
            })
          );
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getMarketplaces() {
      this.$store.state.loadingStatus = true;
      MarketplaceServices.get()
        .then((response) => {
          this.marketplaces = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getParameters() {
      if (this.selectedMarketplace == null) {
        return;
      }
      this.$store.state.loadingStatus = true;
      MarketplaceServices.getParameters(this.selectedMarketplace)
        .then((response) => {
          this.parameters = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    changeItemsPerPage() {
      this.page = 1;
    },
    onPageChange(newPage) {
      this.$store.state.loadingStatus = true;
      this.page = newPage;
    },

    resetDialog() {
      this.addMarketplaceDialog = false;
      this.editMarketplaceDialog = false;
      this.selectedMarketplace = null;
      this.parameters = [];
      this.marketplace = {};

      if (this.$refs.addMarketplaceObserver)
        this.$refs.addMarketplaceObserver.reset();
      if (this.$refs.updateMarketplaceObserver)
        this.$refs.updateMarketplaceObserver.reset();
    },
  },
};
</script>
