// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/courierInformation';

export default {
  addCarrier(body) {
    return axios.post(`${RESOURCE_NAME}/add`, body);
  },
  editCarrier(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
};
