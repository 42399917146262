// Örnek servis codiagno login
import { get } from 'lodash';
import axios from '../javascript/custom-axios';
const RESOURCE_NAME = '/reports';

export default {
  getReportsList(startDate, endDate, reportType, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/summary-reports`, {
      params: {
        PageSize: pageSize,
        Page: page,
        StartDate: startDate,
        EndDate: endDate,
        ReportType: reportType,
      },
    });
  },
  OrderReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-order-report`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  MarketplaceProfitReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-profit-report`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  OrderProfitReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-order-report`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  MarketplaceOrderReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-order-pie-graph`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  MarketplaceSalesReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-sales-bar-graph`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  GiroExpenseReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-giro-and-expense-line-graph`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
      },
    });
  },
  MarketplaceExpenseReport(from, to, marketplaceId, page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-expense-pie-graph`, {
      params: {
        PageSize: pageSize,
        Page: page,
        From: from,
        To: to,
        MarketplaceId: marketplaceId,
        IsShipmentCost: true,
      },
    });
  },
  GeneralSummaryReport() {
    return axios.get(`${RESOURCE_NAME}/general-summary-for-admin`);
  },

  // Dynamic Reports

  /**
   * Create dynamic reports action for user.
   * @param {*} body 
   * @param {{ type: string, isUpdate: boolean }} params Example: supplier-update
   * @returns {Promise}
   */
  actionsOnDynamicReports(body, { type = "supplier", isUpdate = false }){
    return axios[isUpdate ? 'put' : 'post'](`${RESOURCE_NAME}/${type}-${isUpdate ? 'update' : 'create'}-dynamic`, body);
  },

  /**
   * Get Data Of Dynamic Reports
   * @param {*} parameters
   * @returns {Promise}
   */
  getDataDynamicReports(params) {
    return axios.get(`${RESOURCE_NAME}/supplier-dynamic-getdata`, {
      params,
    });
  },

  /**
   * Get Details Of Dynamic Reports By Id
   * @param {string} type Example: admin
   * @param {string} Id
   * @returns {Promise}
   */
  getDetailDynamicReports(type, Id){
    return axios.get(`${RESOURCE_NAME}/${type}-dynamic-get-detail`, {
      params: {
        Id,
      },
    });
  },

  /**
   * Get List Of Dynamic Reports
   * @param {*} params
   * @returns {Promise}
   */
  getListDynamicReports(params) {
    return axios.get(`${RESOURCE_NAME}/dynamic-reports-get-list`, {
      params: params,
    });
  },

  /**
   * Get Sample Of Dynamic Reports
   * @param {*} params
   * @returns {Promise}
   */
  getSampleDynamicReports(params) {
    return axios.get(`${RESOURCE_NAME}/dynamic-reports-get-sample`, {
      params: params,
    });
  },

  /**
   * Download A Dynamic Reports
   * @param {{ ReportId: string, StartDate?: string, EndDate?: string }} params
   * @returns {Promise}
   */
  downloadDynamicReports(params) {
    return axios.get(`${RESOURCE_NAME}/download-dynamic-excels`, {
      params: params,
      responseType: 'blob',
    });
  },

  /**
  * Get SQL Query Report.
  * @param {*} body
  * @param {{ type: string }} params Example: admin
  * @returns {Promise}
  */
  getSQLQuery(body, { type = 'supplier' }){
    return axios.post(`${RESOURCE_NAME}/${type}-dynamic-getsql`, body);
  },

  /**
  * Get SQL Query Report For Supplier
  * @param {*} body
  * @returns {Promise}
  */
  getSQLQuerySupplier(body){
    return axios.post(`${RESOURCE_NAME}/supplier-dynamic-getsql`, body);
  },

  /**
   * Get SQL Query Report For Customer
   * @param {*} body
   * @returns {Promise}
   */
  getSQLQueryCustomer(body) {
    return axios.post(`${RESOURCE_NAME}/customer-dynamic-getsql`, body);
  },
  adminGetDynamicCompanies(id) {
    return axios.get(`${RESOURCE_NAME}/admin-dynamic-get-companies`, {
      params: {
        CompanyType: id,
      }
    })
  },

  /**
  * Get Admin Companies By Company Type Filter.
  * @param {number} CompanyType
  * @returns {Promise}
  */
  getAdminCompanies(CompanyType){
    return axios.get(`${RESOURCE_NAME}/admin-dynamic-get-companies`, {
      params: {
        CompanyType
      }
    });
  },

  /**
   * Get all reports. 
   * @returns {Promise}
   */
  getAllReports(){
    return axios.get(`${RESOURCE_NAME}/get-all-reports`);
  },

  /**
   * Get Report By Key.
   * @param {{ Key: string, StartDate: string, EndDate: string, Page: number, PageSize: number, IsExcel: boolean, Role: 0 | 1 | 2 }} params
   * @returns {Promise}
   */
  getReportByKey(params){
    return axios.get(`${RESOURCE_NAME}/get-report`, {
      params
    });
  },
};
