//import fields from '../attributes/tr' // validasyon için tanımlı fieldları genel dil dosyasına ekleme
export default {
  buttons: {
    callCarrier: 'Kurye Çağır',
    carrierInfo: 'Kurye Bilgileri',
    showCarrierInfo: 'Kurye Bilgilerini Göster',
    block: 'Engelle',
    unBlock: 'Engeli Kaldır',
    showStateCodes: 'Eyalet Kodlarını Göster',
    samplePeriodicCostExcelDownload: 'Örnek Dönemsel Ek Maliyet Listesi',
    resetFilters: 'FİLTRELERİ SIFIRLA',
    merge: 'DAVET ET',
    clearFilter: 'FİLTRELERİ TEMİZLE',
    planSelecteds: 'SEÇİLENLERİ DÜZENLE',
    reset: 'Sıfırla',
    back: 'GERİ',
    backNavigate: 'Geri Dön',
    next: 'İleri',
    complete: '{value} Tamamla',
    getAnOffer: 'Hemen Teklif Al',
    addPackage: 'Paket Ekle',
    addVolume: 'Boyutları Gir',
    addBox: 'Paket Ekle',
    changeTheSearch: 'Aramayı Değiştir',
    createOrder: 'Sipariş Oluştur',
    demandPriceFromSupplier: 'Özel Fiyat Talep Et',
    demandPrice: 'Fiyat Al',
    searchAgain: 'Tekrar Ara',
    viewTheResults: 'Sonuçları Gör',
    offerPrice: 'Fiyat Ver',
    updateOffer: 'Revize Et',
    lost: 'Kaybedildi',
    expired: 'Teklif Süresi Doldu',
    search: 'Ara',
    rate: 'Değerlendirmeyi Gönder',
    giveCustomerAPrice: 'Fiyat Ver',
    lumpSum: 'Seçilenleri({value} Adet) Öde',
    lumpSumMobile: 'Öde',
    lumpSumSupplier: 'Seçilen Ödemeleri ({value} Adet) Ödendi Olarak Güncelle',
    lumpSumSupplierMobile: 'Ödendi Olarak Güncelle',
    lumpActivate: 'Seçilenleri({value} Adet) Aktif Et',
    lumpActivateMobile: 'Aktif Et',
    completePayment: 'Ödemeyi Tamamla',
    resetDates: 'Tarihleri Sıfırla',
    deleteSelected: 'SEÇİLENLERİ SİL',
    changeExcelFile: 'Dosyayı Değiştir',
    goToDetails: 'Detayları Gör',
    samplePriceExcelDownload: 'Örnek Fiyat Listesi',
    sampleRegionExcelDownload: 'Örnek Bölge Listesi',
    sampleOutOfAreaExcelDownload: 'Örnek Bölge Dışı Listesi',
    blockUser: 'Pasife Al',
    activateUser: 'Aktif Et',
    viewCompanyDetail: 'Şirket Detayını Görüntüle',
    refreshOrders: 'Siparişleri Yenile',
    remainingRefreshCount: 'Kalan Yenileme Sayısı',
    viewPackageDetails: 'Paket Detayları',
    removeUserFromBlacklist: 'Kullanıcının Engelini Kaldır',
    unbanUser: 'Engeli Kaldır',
    banUser: 'Kullanıcıyı Engelle',
    approve: 'ONAYLA',
    cancelOrder: 'Siparişi İptal Et',
    sendAMessage: 'Mesaj gönder',
    saveDraft: 'TASLAK OLARAK KAYDET',
    selectOnMap: 'Haritadan Seç',
    add: 'EKLE',
    update: 'GÜNCELLE',
    login: 'Giriş',
    smsRefresh: 'YENİDEN GÖNDER',
    register: 'Kayıt Ol',
    registerNow: 'Hemen Kayıt Olun',
    forgotPass: 'Şifremi Unuttum',
    close: 'KAPAT',
    select: 'SEÇ',
    open: 'Aç',
    orderDetail: 'Sipariş Detay',
    offerDetail: 'Teklif Detayı',
    sendMessage: 'Mesajı Gönder',
    resume: 'DEVAM',
    assign: 'ATAMA YAP',
    send: 'GÖNDER',
    sendCode: 'KOD GÖNDER',
    sendCodeAgain: 'KODU TEKRAR GÖNDER',
    updatePhone: 'TELEFON GÜNCELLE',
    cancel: 'İPTAL',
    profile: 'Profil',
    logout: 'Çıkış Yap',
    yes: 'EVET',
    no: 'HAYIR',
    ok: 'TAMAM',
    submit: 'Gönder',
    excelExport: 'EXCEL OLARAK İNDİR',
    download: 'İNDİR',
    clear: 'TEMİZLE',
    save: 'KAYDET',
    saveLower: 'Kaydet',
    isAlreadySended: 'Gönderildi',
    createFile: 'Dosya Oluştur',
    inReviewOrder: 'İncelemeye Al',
    publish: 'Yayımla',
    saveAsDraft: 'Taslak Olarak Kaydet',
    uploadNew: 'YENİ YÜKLE',
    uploadfromLibrary: 'KÜTÜPHANEDEN EKLE',
    addNew: 'YENİ EKLE',
    addNewGroup: 'YENİ GRUP EKLE',
    makeAdmin: 'ADMİN YAP',
    removeAdminPermissions: 'Adminden Çıkar',
    userPermissions: 'YETKİLENDİR',
    userDetails: 'DETAY',
    setArchive: 'Arşive Al',
    goPayment: 'Ödemeye Git',
    unarchive: 'Arşivden Çıkart',
    seeMessages: 'Mesajları Gör',
    deleteSelectedCategory: 'SİL',
    sampleFile: 'ÖRNEK DOSYAYI İNDİR',
    surveyAddAnswer: 'YENİ ŞIK EKLE',
    allPermissionSelect: 'TÜMÜNÜ SEÇ',
    allPermissionDeselect: 'TÜMÜNÜ KALDIR',
    dowloadTempFile: 'Örnek Dosyayı İndir',
    downloadFile: 'Dosyayı İndir',
    deleteFile: 'Dosyayı Sil',
    addStock: 'Stok Ekle',
    change: 'DEĞİŞTİR',
    addProduct: 'Ürün Ekle',
    transfer: 'Aktar',
    reload: 'YENİLE',
    labels: {
      airway: 'Havayolu',
    },
    unban: 'Kullanıcı Blokesini kaldır',
    createShipment: 'Gönderi Oluştur',

    skipThisStep: 'Bu Adımı Geç',
    next: 'İleri',
    back: 'Geri',

    searchPrice: 'Fiyat Ara',
    getReportData: 'RAPOR AL',

    cloneOrder: 'Sipariş Kopyala',
    reject: 'Reddet',
    activate: 'Aktif Et',
    approve: 'Onayla',
  },
  labels: {
    filterCountry: 'Ülke Filtrele',
    tooltipBlacklist: 'Kara Listede',
    tooltipNotBlacklist: 'Kara Listede Değil',
    blacklist: 'Kara Liste',
    eph: 'EPH',
    sumWeightEPH: 'Toplam Ağırlık Sınırı (kg)',
    sumVolumeEPH: 'Toplam Hacim Sınırı (m³)',
    basePricePercentage: 'Navlun Fiyatı Yüzdesi',
    integrationType: 'Entegrasyon Türü',
    cancelOrder: 'Siparişi İptal Et',
    label: 'Etiket',
    carriageContract: 'Sipariş Sözleşmesi',
    damageFile: 'Hasar Dosyası',
    warehouseLabel: 'Etiket',
    blockStatus: 'Blok Durumu',
    willReturn:
      'Ürün tanımı yeterince olmadığı takdirde yükleme yurtdışı gümrükten geri dönecek',
    maxPortalWarning: 'Paketinizde tanımlı maksimum portal sayısına ulaştınız',
    free: 'Ücretsiz',
    limitless: 'Limitsiz',
    queryCount: 'Sorgu Adedi',
    comissionCurrency: 'Komisyon Para Birimi',
    freePackage: 'Ücretsiz Paket',
    currentSubscription: 'Mevcut Abonelik',
    upgrade: 'Yükselt',
    subscribe: 'Abone Ol',
    annualPrice: 'Yıllık Fiyat',
    subscriptionManagement: 'Abonelik Yönetimi',
    portalMonthlyPrice: 'Portal Müşteri Aylık Fiyatı',
    portalCommissionDiscountRate: 'Portal Müşteri Komisyon İndirim Oranı',
    limitlessMonthlyQuery: 'Limitsiz Aylık Sorgu',
    limitlessOrderCount: 'Limitsiz Sipariş Adedi',
    specialCompanies: 'Şirket Seç',
    stateCodes: 'Eyalet Kodlarını görüntülemek için tıklayınız',
    source: 'Kaynak',
    agency: 'Acente',
    periodicPrice: 'Dönemsel Express Navlun Farkı',
    periodicLabel: 'Dönemsel',
    periodic: 'Dönemsel Ek Maaliyet',
    labelUrl: 'Etiket Alma URL',
    maxPortalCount: 'Maksimum Portal Sayısı',
    monthlyPrice: 'Aylık Fiyat',
    yearlyPrice: 'Yıllık Fiyat',
    accounts: 'Alt Tedarikçi Hesabı',
    domesticCommissionType: 'Yurtiçi Komisyon Tipi',
    domesticCommission: 'Yurtiçi Navlun Komisyon Oranı',
    airlineCommissionType: 'Havayolu Komisyon Tipi',
    airlineCommission: 'Havayolu Navlun Komisyon Oranı',
    seawayCommissionType: 'Denizyolu Komisyon Tipi',
    seawayCommission: 'Denizyolu Navlun Komisyon Oranı',
    expressCommissionType: 'Ekspres Komisyon Tipi',
    expressCommission: 'Ekspres Navlun Komisyon Oranı',
    roadwayCommissionType: 'Kara Yolu Komisyon Tipi',
    roadwayCommission: 'Kara Yolu Navlun Komisyon Oranı',
    railwayCommissionType: 'Demiryolu Komisyon Tipi',
    railwayCommission: 'Demiryolu Navlun Komisyon Oranı',
    integrationUnitPrice: 'Entegrasyon Birim Fiyatı',
    percentage: 'Yüzdesel',
    orderCount: 'Aylık Sipariş Adedi',
    monthlyQueryCount: 'Aylık Sorgu Adedi',
    companyPackage: 'Şirket Paketi',
    moduleList: 'Modül Listesi',
    companyCategory: 'Şirket Kategorisi',
    reportType: 'Rapor Türü',
    etgbBankInfo: 'İhracatçı Banka Bilgisi',
    etgbDocuments: 'Mal Beraberi Gidecek Evraklar',
    onlyWire: 'Sadece havale ödemeleri onaylanabilir.',
    cannotSelectExitDate:
      'Ödeme onaylanmadığı için depo çıkış tarihi seçilememektedir',
    showLogoInLabel: 'Etikette Logom Görünsün',
    downloadSuccessfully: 'İndirme Başarılı',
    downloadData: 'Veriyi İndir',
    circumference: 'Çevresel Ölçü',
    handling: 'Ek Elleçleme',
    additionalCostType: 'Ek Maliyet Türü',
    notAvailable: 'Mevcut Değil',
    fileStatus: 'Dosya Durumu',
    widthLengthHeight: 'En / Boy / Yükseklik',
    sender: 'Gönderen',
    senderName: 'Gönderen Adı',
    priceListName: 'Fiyat Liste Adı',
    customerList: 'Müşteri Liste Adı',
    showLabel: 'Etiket Görüntüleme',
    invoiceNo: 'Fatura Numarası',
    purposeOfShippingLabel: 'Gönderi Amacı',
    purposeOfShippingGift: 'Hediye',
    purposeOfShippingSample: 'Örnek',
    purposeOfShippingNotSold: 'Ticari Değil',
    purposeOfShippingPersonalEffects: 'Kişisel Eşyalar',
    purposeOfShippingRepairReturn: 'Onarım ve İade',
    purposeOfShippingSold: 'Ticari',
    IOSS: 'IOSS',
    saveSuccess: 'Kayıt Başarılı',
    fileNavDescription:
      'Etiketleri indirmek ve belgeleri yüklemek için yönlendiriliyorsunuz.',
    fileNavDescription2:
      'Ödemeniz onaylandıktan sonra etiketiniz ve diğer belgeleriniz oluşturulacaktır.',
    closeWithoutSave:
      'Değişiklikleri kaydetmeden çıkmak istediğinize emin misiniz? Yapılan değişiklikler kaybolacak.',
    atLeastOneDate: 'En az bir tarih girmelisiniz',
    damageNotification: 'Hasar Bildirimi',
    otherNotification: 'Diğer',
    promotionalText:
      "Algönder'den promosyon amaçlı e-postalar, mesajlar veya çağrılar almayı ve iletişim bilgilerimin bu amaçla saklanmasını kabul ediyorum.",
    contactPermissionText:
      "Algönder'den tanıtım e-postaları, mesajları veya aramaları almayı ve iletişim bilgilerimin bu amaçla saklanmasını kabul ediyorum. İstediğim zaman aboneliğimi iptal edebilir ve onayımı geri alabilirim.",
    customerName: 'Müşteri Adı',
    customerInsurance: 'Müşteri Sigortası',
    supplierInsurance: 'Tedarikçi Sigortası',
    serviceProviderInsurance: 'Servis Sağlayıcı Sigortası',
    insurance: 'Sigorta',
    otherServices: 'Diğer Hizmetler',
    orderFileSubSupplierUploaded: 'Gönderildi',
    orderFileSubSupplierNotUploaded: 'Gönderilmedi',
    damageFileType: 'Hasar Dosyası',
    loadingInstruction: 'Yükleme Talimatı',
    proformaFileType: 'Proforma',
    eArchiveInvoice: 'E-Arşiv Fatura',
    etgbForm: 'ETGB Form',
    etgbFormPlus: 'ETGB Formunu yükleyiniz veya {value} bilgilerini doldurunuz',
    eInvoice: 'E-Fatura',
    otherFileType: 'Diğer Dosyalar',
    tooltipDateTime: 'Tarih Saat Ekle',
    Term: 'Terim',
    Meaning: 'Anlam',
    AddTerm: 'Terim Ekle',
    AddFAQ: 'SSS Ekle',
    EditFAQ: 'SSS Düzenle',
    EditTerm: 'Terim Düzenle',
    Trace: 'Trace',
    None: 'Diğer',
    All: 'Tümü',
    Emergency: 'Acil',
    Alert: 'Uyarı',
    Alerts: 'Uyarılar',
    Critical: 'Kritik',
    Error: 'Hata',
    Warning: 'Uyarı',
    Notice: 'Uyari',
    Informational: 'Bilgi',
    Debug: 'Hata Ayıkla',
    error: 'Hata',
    informational: 'Bilgi',
    warning: 'Uyarı',
    dateRange: 'Tarih Aralığı',
    emptyCart: 'Sepeti Temizle',
    accepted: 'Onaylandı',
    canceled: 'Reddedildi',
    level: '{value} Seviyesi',
    waiting: 'Onay Aşamasında',
    required: '{value} alanı gereklidir.',
    type: 'Anahtar Kelime',
    date: '{value} Tarihi',
    dateAsNoun: 'Tarih',
    offerCount: 'Teklif Sayısı',
    code: 'Kodu',
    number: '{number} Numaralı {field}',
    name: 'Ad',
    address: 'Adres',
    appTitle: 'AlGönder Üyelik',
    authorizationFailed: 'Doğrulama Başarısız',
    test: 'Test Et',
    SecretKey: 'Secret Key',
    postalCode: 'P. Kodu',
    postalCode_from: 'Başlangıç Şehri Posta Kodu',
    postalCode_to: 'Varış Şehri Posta Kodu',
    country: 'Ülke',
    countryCode: 'Ülke Kodu',
    role: 'İmza Yetkisi',
    warehouse: 'Depo',
    creditCard: {
      number: 'Kredi Kartı Numarası',
      ccv: 'Güvenlik Kodu',
      month: 'Ay',
      year: 'Yıl',
    },
    marketplace: {
      MerchantSecret: 'Merchant Secret',
      RefreshToken: 'Refresh Token',
      RoleArn: 'Role Arn',
      title: 'Pazaryeri',
      SecretKey: 'Secret Key',
      MerchantId: 'Merchant ID',
      ApiUserName: 'API Kullanıcı Adı',
      ApiKey: 'API Key',
      MerchantKey: 'Merchant Key',
      ApiURL: 'API URL',
      ClientId: 'Client ID',
      ClientSecret: 'Client Secret Kod',
      ShopId: 'Shop ID',
      Name: 'Adı',
      MarketplaceName: 'Entegrasyon Adı',
    },
    Integration: {
      title: 'Entegrasyon',
    },
    type: '{value} Türü',
    logo: 'Logo',
    creator: 'Ekleyen',
    status: 'Durum',
    company: {
      officerName: 'Kullanıcı Adı',
      officerSurname: 'Kullanıcı Soyadı',
      taxOffice: 'Vergi Dairesi',
      taxNo: 'Vergi Numarası / TC Kimlik Numarası',
      tradeRegisterNumber: 'Ticari Sicil Numarası',
      industryRegisterNumber: 'Endüstri Sicil Numarası',
    },
    user: {
      singular: 'Kullanıcı',
      plural: 'Kullanıcılar',
      firstName: 'Adı',
      lastName: 'Soyadı',
    },
    serviceType: 'Servis Tipi',
    loadType: 'Yükleme Tipi',
    loadAddress: 'Yükleme Adresi',
    howDidYouHearAboutUs: 'Bizi Nereden Duydunuz?',
    fromWhere: 'Nereden?',
    fromWhereCity: 'Nereden şehir',
    toWhere: 'Nereye?',
    containerType: 'Konteyner Tipi',
    containerCount: 'Konteyner Sayısı',
    isDangerousMaterial: 'Tehlikeli madde',
    dangerousMaterialLevel: 'Tehlikeli Madde Seviyesi',
    toAirport: 'Havalimanına',
    fromAirport: 'Havalimanından',
    doorCount: 'Kap Adedi',
    kilo: 'Ağırlık/Paket (kg)',
    volume: 'Hacim (m3)',
    totalKilo: 'Toplam Ağırlık (kg)',
    totalVolume: 'Toplam Hacim (m3)',
    cargoDefinition: 'Kargo Tanımı',
    myLoadWillNotBeStacked: 'Yüküm İstiflenemez',
    vehicleType: 'Araç Tipi',
    vehicleCount: 'Araç Adedi',
    toSeaport: 'Limana',
    fromSeaport: 'Limandan',
    toStation: 'İstasyona',
    fromStation: 'İstasyondan',
    carType: 'Vagon Türü',
    carCount: 'Vagon Sayısı',
    width: 'En (cm)',
    height: 'Yükseklik (cm)',
    length: 'Boy (cm)',
    gTypeNumber: 'GTIP Numarası',
    productType: 'Mal Cinsi',
    rowsPerPage: 'Sayfa Başına Sütun Sayısı',
    targetPrice: 'Hedef Fiyat',
    currency: 'Birim',
    note: 'Not',
    offerType: 'Teklif Türü',
    filter: 'Filtrele',
    offeredPrice: 'Verilen Fiyat',
    day: {
      singular: 'Gün',
      plural: 'Günler',
    },
    expiryDate: 'Geçerlilik Tarihi',
    expiryDateStart: 'Geçerlilik Tarihi Başlangıç',
    expiryDateEnd: 'Geçerlilik Tarihi Bitiş',
    buyer: 'Alıcı',
    referenceNumber: 'Ref. No',
    rating: 'Değerlendirme Puanı',
    including: '{value} Dahil',
    excluding: '{value} Hariç',
    tax: {
      singular: 'Vergi',
      plural: 'Vergiler',
    },
    cargo: 'Kargo',
    express: 'Ekspres',
    chatStatus: 'Sohbet Durumu',
    downloaded: 'İndirildi',
    notDownloaded: 'İndirilmedi',
    labelsOrderStatuses: {
      detail: 'Taslak Halinde',
      supplier: 'Tedarikçi Onayında',
      payment: 'Ödeme Bekleniyor',
      approved: 'Yüklenmeye Hazır',
      loaded: 'Yüklendi',
      shipping: 'Yolda',
      willBeComingToWarehouse: 'Depoya Gelecek',
      inWarehouse: 'Depoda',
      outWarehouse: 'Depodan Çıktı',
      inDestination: 'Varış Yerinde',
      delivered: 'Teslim Edildi',
      archived: 'Arşivlendi',
      other: 'Belirlenemedi',
      canceled: 'İptal Edildi',
    },
    fileStatuses: {
      notPlanned: 'Planlanmadı',
      pickupPlanned: 'Pickup planlandı',
      inWarehouse: 'Depoda',
      loading: 'Yükleme Aşamasında',
      invoiced: 'Faturası Kesildi',
      atDestination: 'Varış Yerinde',
      delivered: 'Teslim Edildi',
    },
    selectReport: 'Rapor Seçiniz',
    companyType: 'Şirket Tipi',
    accountType: 'Hesap Türü',
    customer: 'Müşteri',
    sender: 'Gönderen',
    has: 'Var',
    hasNot: 'Yok',
    authority: 'Yetki',
    authorized: 'Yetkili',
    unauthorized: 'Yetkisiz',
    hasSignAuthority: 'İmza Yetkili',
    hasNotSignAuthority: 'İmza Yetkisiz',
    myTickets: 'Destek Taleplerim',
    ticketStatus: 'Destek Talebi Durumu',
    secondAddress: 'İkincil Adres',
    stationToStation: 'İstasyondan İstasyona',
    paymentStatus: 'Ödeme Durumu',
    paymentCompleted: 'Ödendi',
    paymentIncomplete: 'Ödeme Bekleniyor',
    yourOffer: 'Teklifiniz',
    yourOfferCurrency: 'Teklif Para Birimi',
    region: 'Bölge',
    regions: 'Bölgeler',
    priceList: 'Fiyat Listesi',
    regionList: 'Bölge Listesi',
    isActive: 'Aktif',
    default: 'Varsayılan',
    pricing: 'Fiyatlandırma',
    operation: 'Operasyon',
    noPrice: 'Fiyat Belirlenmedi',
    isTransshipment: 'Aktarmalı',
    transshipmentCount: 'Aktarma Sayısı',
    waitingForApproval: 'Onay Bekliyor',
    currentExpiryDate: 'Mevcut Geçerlilik Tarihi',
    currentTransportTime: 'Mevcut Transit Süre',
    newExpiryDate: 'Yeni Geçerlilik Tarihi',
    newTransportTime: 'Yeni Transit Süre',
    expired: 'Geçerlilik Süresi Doldu',
    website: 'Website',
    approved: 'Onaylandı',
    approvedAccount: 'Onaylı',
    readyForShipping: 'Kargo Bekleniyor',
    shipped: 'Kargolandı',
    paid: 'Ödendi',
    paymentProgressing: 'Ödeme Devam Ediyor',
    fullyRefunded: 'Geri Ödeme Yapıldı',
    partiallyRefunded: 'Parçalı Geri Ödeme',
    receiptDetail: 'Makbuz Detayları',
    volumeWeight: 'Hacim Ağırlığı',
    chargeableWeight: 'Fatura Ağırlığı',
    lademeter: 'Lademetre',
    rejectApprovement: 'Ödeme Onayını Geri Al',
    requestDate: 'Talep Tarihi',
    paymentTarget: 'Ödeme Hedefi',
    returnToUserList: 'Kullanıcına Dön',
    loginAs: 'Müşteri olarak giriş yap',
    addPortalUser: 'Portal Kullanıcısı Ekle',
    addAdditionalCost: 'Ek Masraf Ekle',
    updateAdditionalCost: 'Ek Masraf Düzenle',
    editPortalUser: 'Portal Kullanıcısını Düzenle',
    listName: 'Liste Adı',
    cancelInformation: 'İptal Bilgileri',
    sendToSupplier: 'Tedarikçiye Gönder',
    piece: 'Adet',
    direction: 'Gönderim Yönü',
    createComplaint: 'Şikayet Oluştur',
    yourComplaint: 'Şikayetiniz',
    dimensionSummary: 'Ebat Özeti',
    isHome: 'Bu adres ev adresi mi?',
    expressInformation: 'Express Taşıma Bilgileri',
    goodsPrice: 'Mal bedeli',
    taxPayer: 'Vergi Ödeme',
    dap: 'DAP (Alıcı)',
    ddp: 'DDP (Gönderen)',
    payingType: 'Ödeme Türü',
    upFront: 'Peşin',
    cashOnDelivery: 'Karşı Ödemeli',
    intermediateAgency: 'Ara Acente',
    packageType: 'Paket Tipi',
    document: 'Döküman',
    package: 'Koli',
    ETGBExpress: 'ETGB Ekspres',
    ETGBEconomy: 'ETGB Ekonomi',
    ExitWithDeclaration: 'Ekspress Beyannameli Çıkış',
    Economic: 'Beyannameli Ekonomi Ekspress',
    ExpressSample: 'ETGB Numune',
    sample: 'Numune',
    outOfArea: 'Bölge Dışı',
    import: 'İthalat',
    export: 'İhracat',
    supplierBankInfo: 'Tedarikçi Banka Bilgileri',
    addBankInfo: 'Banka Bilgisi Ekle',
    editBankInfo: 'Banka Bilgisi Düzenle',
    accountOwner: 'Hesap Sahibi',
    surchargeInformation: 'Ek Ücret Bilgileri',
    bankInformation: 'Banka Bilgileri',
    timeOut0: 'İlk Uyarı Süresi',
    timeOut1: 'İkinci Uyarı Süresi',
    timeOut2: 'Üçüncü Uyarı Süresi',
    timeOut3: 'İptal Süresi',
    approvedByAdmin: 'Admin Tarafından Onaylandı',
    notApprovedByAdmin: 'Admin Tarafından Onaylanmadı',
    adminApproval: 'Admin Onayı',
    estimateGreaterSelected:
      'Seçtiğiniz tahmini teslim tarihi, transit süresini aşmaktadır',
    estimateGreaterAuto:
      'Seçili tahmini teslim tarihi, transit süresini aşmaktadır',
    cancelDescription: 'İptal Açıklaması',
    cancelReason: 'İptal Nedeni',
    Other: 'Diğer',
    ConfirmationNotRecived: 'Onay Alınamadı',
    DocumentsMissing: 'Belgeler Eksik',
    InformationChanged: 'Bilgi Değişti',
    PaymentNotReceived: 'Ödeme Alınmadı',
    DeliveryProblems: 'Teslimat Problemleri',
    contract: 'Sözleşme',
    addAddress: 'Adres Ekle',
    maxZipCode: 'Bitiş posta kodu',
    minZipCode: 'Başlangıç posta kodu',
    minPrice: 'Minimum Fiyat',
    pricePerKg: 'Kg Başı Fiyat',
    selectOnMap: 'Harita üzerine tıklayarak lokasyon seçebilirsiniz',
    generalOrderInformation: 'Genel Sipariş Bilgileri',
    carrierCompanyInfo: 'Gönderen / Alıcı',
    packageInformation: 'Koli Bilgileri',
    orderDates: 'Tarihler',
    noNewMessage: 'Yeni mesajınız bulunmamaktadır',
    noNewOffer: 'Yeni teklifinizi bulunmamaktadır',
    noNewPending: 'Ödeme yapılmayı bekleyen siparişiniz bulunmamaktadır',
    noNewApproval: 'Ödeme onayı bekleyen siparişiniz bulunmamaktadır',
    noNewSpotOrder: 'Yeni fiyat talebiniz bulunmamaktadır',
    stateCode: 'Eyalet Kodu',
    ClientId: 'Client ID',
    ClientSecretKey: 'Client Secret',
    ChildKey: 'Child Key',
    ChildSecret: 'Child Secret',
    AccountNumber: 'Hesap Numarası',
    GrantType: 'Yetki Tipi',
    LogisticId: ' Logistic ID',
    minLength: 'En az 6 karakter olmalıdır.',
    maxLength: 'En fazla 24 karakter olmalıdır.',
    uppercase: 'En az 1 büyük harf içermelidir.',
    lowercase: 'En az 1 küçük harf içermelidir.',
    digit: 'En az 1 rakam içermelidir.',
    special: 'En az 1 özel karakter içermelidir.',
    noAccount: 'Hesabınız yok mu?',
    loadingFile: 'Yükleme talimatı',
    newMessage: '{value} adet yeni mesajınız var',
    ordersWaitingPayment: 'Ödeme bekleyen siparişler',
    ordersWaitingPaymentMessage:
      '{value} adet siparişiniz, ödeme yapılmayı bekliyor',
    PendingApprovalCompanyCount: 'Onay Bekleyen Şirket Sayısı',
    PendingApprovalCompanyCountMessage: '{value} adet şirket onay bekliyor',
    noNewPendingApprovalCompanyCount: 'Onay Bekleyen Şirket Bulunmamaktadır',
    UnSolvedDemandCount: 'Çözümlenmemiş Talep Sayısı',
    UnSolvedDemandCountMessage: '{value} adet çözümlenmemiş talebiniz var',
    noNewUnSolvedDemandCount: 'Çözümlenmemiş talep bulunmamaktadır',
    ordersWaitingApproval: 'Ödeme onayı',
    ordersWaitingApprovalMessage:
      '{value} adet siparişiniz, ödeme onayı bekliyor',
    newShippings: 'Yeni Sevkiyat Talepleri',
    newShippingsMessage: '{value} adet yeni sevkiyat talebiniz var',
    spotOrderMessage: '{value} adet yeni talebiniz var',
    newOffer: 'Teklifler',
    newOfferMessage: '{value} adet yeni teklifiniz var',
    noFileTitle: 'Dosya ismi bulunamadı.',
    transferDate: 'Transfer Tarihi',
    amount: 'Miktar',
    accountNumber: 'Hesap Numarası',
    fullName: 'Ad Soyad',
    bankName: 'Banka Adı',
    addInvoice: 'Makbuz Ekle',
    showInvoice: 'Makbuzları Göster',
    showInvoiceDetail: 'Makbuz Detayını Göster',
    invoice: 'Makbuzlar',
    editInvoice: 'Makbuzu Düzenle',
    deleteInvoice: 'Makbuzu Sil',
    equipmentDetails: 'Ekipman Detayları',
    editEquipment: 'Ekipmanı Düzenle',
    softwareSupport: 'Yazılım Destek',
    selectFile: 'Dosya Seçiniz',
    fileNote: 'Dosya Notu',
    fileType: 'Dosya Türü',
    fileUploadDate: 'Dosya Yükleme Tarihi',
    fileSubSupplierUploadStatus: 'Alt Tedarikçi Statüsü',
    noFileNote: 'Dosya Notu Girilmemiştir',
    fileName: 'Dosya Adı',
    tableActions: 'İşlemler',
    showNotes: 'Notları Göster',
    mediaFiles: 'Medya Dosyaları',
    endTicket: 'Destek Talebini Kapat',
    ticket: 'Destek Talebi',
    myAddresses: 'Adreslerim',
    supplierRating: 'Tedarikçi Değerlendirmesi',
    noDate: 'Tarih Atanmadı',
    orders: 'Siparişler',
    spotOffers: 'Talepler',
    smsNotification: 'SMS ile bilgilendirilmek istiyorum',
    emailNotification: 'E-posta ile bilgilendirilmek istiyorum',
    newSupport: 'Yeni Destek Talebi Oluştur',
    demandTitle: 'Talep Başlığı',
    subject: 'Konu',
    orderId: 'Sipariş Numarası',
    companyUpdateString: 'Şirket bilgilerini',
    companyFileTypeTaxBoard: 'Vergi Levhası',
    companyFileTypeCircularOfSignature: 'İmza Sirküleri',
    companyFileTypeCertificateOfRegistry: 'Tescil Belgesi',
    companyFileTypeArticlesOfIncorporation: 'Şirket Sözleşmesi',
    termsAndConditions: 'Kullanım Koşullarını',
    privacyPolicy: 'Gizlilik Politikası',
    consent: 'Açık Rıza Metni',
    read: 'okudum ve kabul ediyorum.',
    termsOfUse: 'Kullanım Koşulları',
    transfer: 'Havale',
    plannedLabel: 'Planlandı',
    notPlannedLabel: 'Planlanmadı',
    creditCardOrder: 'Kredi Kartı',
    currencyWarning: 'Ödeme yapmak için lütfen bir para birimi seçiniz.',
    fillIn: 'Lütfen zorunlu tüm ayarları doldurduğunuzdan emin olunuz.',
    customerNote: 'Müşteri Notu',
    supplierNote: 'Tedarikçi Notu',
    signature: 'Sözleşmeyi imzalamadan önce imzanızı sisteme yüklemelisiniz.',
    research: 'YENİDEN ARA',
    readAccept: 'Sözleşmeyi Okudum ve Siparişi Onaylıyorum',
    preparingAgreement: 'Sözleşme Hazırlanıyor',
    dangerSubstance: 'Tehlikeli Madde İçeriyor',
    cap: 'Kap',
    addNewFile: 'Yeni Dosya Ekle',
    createETGBform: 'ETGB Formu Oluştur',
    noNote: 'Not Girilmedi',
    addNewEquipment: 'Yeni Ekipman Ekle',
    addLocation: 'Lokasyon Ekle',
    addStatus: 'Durum Ekle',
    equipmentNo: 'Ekipman Numarası',
    equipmentType: 'Ekipman Tipi',
    goodsType: 'Mal Cinsi',
    lastLogin: 'Son Giriş',
    lastLocation: 'Son Konum',
    lastLatitude: 'Son Enlem',
    lastLongitude: 'Son Boylam',
    loadedKg: 'Yüklenen Kilo',
    loadedVolume: 'Yüklenen Hacim',
    sealNo: 'Mühür Numarası',
    airline: 'Hava Yolu',
    airway: 'Hava Yolu',
    roadway: 'Kara Yolu',
    seaway: 'Deniz Yolu',
    railway: 'Demir Yolu',
    partial: 'Parsiyel',
    complete: 'Komple',
    FCL: 'FCL',
    LCL: 'LCL',
    FTL: 'FTL',
    LTL: 'LTL',
    wait: 'Beklemede',
    onTheWay: 'Yolda',
    delivered: 'Teslim Edildi',
    carrierInfo: 'Taşıyıcı Bilgileri',
    orderActionStatusLog: 'Sipariş Aksiyon Hareket Logu',
    orderStatusLogs: 'Sipariş Durum Logları',
    actions: 'İşlem',
    locationHistory: 'Lokasyon Kayıtları',
    locationDesc: 'Lokasyon Açıklaması',
    addAction: 'Hareket Ekle',
    addActionFromExcel: "Excel'den Hareket Ekle",
    latitude: 'Enlem',
    longitude: 'Boylam',
    location: 'Konum İsmi',
    locationDate: 'Konum Tarihi',
    doorToDoor: 'Kapıdan Kapıya',
    portToPort: 'Limandan Limana',
    doorToPort: 'Kapıdan Limana',
    airportToAirport: 'Havalimanından Havalimanına',
    addressToAddress: 'Adresten Adrese',
    addSubCompany: 'Alt Şirket Ekleyin',
    editSubCompany: 'Alt Şirket Düzenleyin',
    address1: 'Adres Satırı',
    address2: 'Ek Adres Satırı',
    authorizedPerson: 'Yetkili Kişi',
    EORICode: 'EORI Kodu',
    IBAN: 'IBAN',
    alGonderReferenceNumber: 'AlGönder Referans Numarası',
    loadStatus: 'Yük Durumu',
    supplier: 'Tedarikçi',
    unknown: 'Bilinmiyor',
    shipmentType: 'Sevkiyat Türü',
    sourceCountry: 'Yükleme Ülkesi',
    sourceCity: 'Yükleme Şehri',
    sourcePostalCode: 'Yükleme Posta Kodu',
    destinationCountry: 'Boşaltma Ülkesi',
    destinationCity: 'Boşaltma Şehri',
    destinationPostalCode: 'Boşaltma Posta Kodu',
    loadingPort: 'Yükleme Limanı',
    unloadingPort: 'Boşaltma Limanı',
    loadingStation: 'Yükleme İstasyonu',
    unloadingStation: 'Boşaltma İstasyonu',
    loaderCompany: 'Yükleyen Firma',
    buyerCompany: 'Alıcı Firma',
    loaderAddress: 'Yükleyen Adresi',
    buyerAddress: 'Alıcı Adresi',
    loaderAddress2: 'Yükleyen Adresi 2',
    buyerAddress2: 'Alıcı Adresi 2',
    customerRefNo: 'Müşteri Referans Numarası',
    subSupplierTrackingNo: 'Alt Tedarikçi Takip Numarası',
    paymentType: 'Ödeme Tipi',
    numOfContainers: 'Kap Adedi',
    kg: 'Kilo (kg)',
    m3: 'Hacim (m3)',
    kgShorten: 'kg',
    m3Shorten: 'm3',
    estimationDate: 'Tahmini Teslim Tarih',
    desiredDate: 'İstenilen Yükleme Tarih',
    loadingDate: 'Yükleme Tarihi',
    departureDate: 'Çıkış Tarihi',
    arrivalDate: 'Varış Tarihi',
    deliveryDate: 'Teslim Tarihi',
    paymentDate: 'Ödeme Tarihi',
    hazmatDescription: 'Tehlikeli Madde Açıklaması',
    price: 'Fiyat',
    payOptions: 'Ödeme Seçenekleri',
    pastResearchSingular: 'Geçmiş Arama',
    pastResearchPlural: 'Geçmiş Aramalar',
    orderDate: 'Sipariş Tarihi',
    orderTime: 'Sipariş Saati',
    cutOffHour: 'Cut Off Hour - Saat',
    loadingDeadline: 'Son Yükleme Süresi - İş Günü',
    latestNotifyTime: 'Son Bildirim Süresi - İş Saati',
    termDay: 'Termin Süresi - Gün',
    penaltyPerDay: 'Günlük Gecikme Ücreti',
    email: 'E-Mail',
    brand: 'Marka',
    Creator: 'Ekleyen',
    sicilNumber: 'Sicil Numarası',
    password: 'Şifre',
    search: 'Aramak için metin giriniz',
    companyTitle: 'Şirket',
    companyName: 'Şirket Adı',
    productGroup: 'Ürün Grubu',
    companyAddress: 'Şirket Adresi',
    receiptAddress: 'Fatura Adresi',
    receiptAddressInfo: 'Fatura adresim şirket adresim ile aynı.',
    isRate: 'Müşterilerden değerlendirme almak istiyorum',
    isLabelShown: 'Logom etikette görünsün',
    yourMessage: 'Mesajınız',
    companyLogo: 'Şirket Logosu',
    city: 'Şehir',
    district: 'İlçe',
    neighbourhood: 'Mahalle',
    phone: 'Telefon Numarası',
    groups: 'Gruplar',
    nameSurname: 'Ad Soyad',
    yourName: 'Adınız',
    lastname: 'Soyad',
    yourLastname: 'Soyadınız',
    userName: 'Kullanıcı Adı',
    sellerCode: 'Satıcı Kodu',
    bayiEmail: 'Bayi E-mail',
    modulName: 'Modül İsmi',
    modulRoutue: 'Modül Yol Dizini',
    modulIsStatic: 'Statik bir modül yarat.',
    modulKey: 'Modül Key',
    modulDescription: 'Modül Açıklaması',
    groupType: 'Grup Tipi',
    groupName: 'Grup İsmi',
    allGroupTypes: 'Tümü',
    userBlocked: 'Kullanıcınız Bloklanmıştır.',
    userRemainingLogin: 'Hakkınız Kaldı.',
    userUsername: 'Kullanıcı Adı (Sicil)',
    userPassword: 'Şifre',
    newPassword: 'Yeni Şifre',
    userPasswordAgain: 'Tekrar Şifre',
    newPasswordAgain: 'Yeni Şifre Tekrar',
    userFirstName: 'İsim',
    userLastname: 'Soyisim',
    userEmail: 'E-posta',
    userPhone: 'Telefon',
    formUserPhone: 'Kayıtlı Telefon',
    userPhonePlaceHolder: '(5__) ___ __ __ şeklinde giriniz',
    userStatus: 'Kullanıcı Durumu',
    userType: 'Kullanıcı Tipi',
    userAbout: 'Kullanıcı Hakkında',
    header: 'Başlık',
    chooseKeyFirst: 'Birincil Yap',
    deleteKey: 'Sil',
    category: 'Kategori',
    tags: 'Etiketler',
    categoryNameTR: 'Kategori Türkçe İsmi',
    categoryNameEN: 'Kategori İngilizce İsmi',
    mainPicture: 'Ana Resim',
    max5: 'Maksimum 5 Adet',
    max15: 'Maksimum 15 Adet',
    userSearch: 'İsim',
    tooltipNotifyDamage: 'Hasar Bildir',
    tooltipShowSupport: 'Destek Talebini Gör',
    tooltipDeleteSupport: 'Destek Talebini Sil',
    tooltipCustomFiels: 'Özel Alana Git',
    tooltipEdit: 'Düzenle',
    tooltipInspectDocument: 'Dosyayı İncele',
    tooltipInspectDocumentNote: 'Dosya Notunu İncele',
    tooltipDocumentAssign: 'Döküman Ata',
    tooltipDocumentAssignUpdate: 'Döküman Atamasını Güncelle',
    tooltipDeny: 'Reddet',
    tooltipAccept: 'Onayla',
    tooltipAnswer: 'Cevapla',
    tooltipAnalyse: 'İncele',
    tooltipAnswered: 'Cevaplandı',
    tooltipDelete: 'Sil',
    tooltipDownloadLabel: 'Etiketleri İndir',
    tooltipDeleteAssignment: 'Atamayı Sil',
    surveyType: 'Anket Tipi',
    documentType: 'Döküman Tipi',
    assignmentType: 'Atama Tipi',
    documentTypeAssigned: 'Atanmış Dökümanlar',
    documentTypeUnassigned: 'Atanmamış Dökümanlar',
    documentTypePrivate: 'Gizli Dökümanlar',
    documentTypePublic: 'Görünür Dökümanlar',
    contentType: 'İçerik Tipi',
    contentStatus: 'İçerik Durumu',
    questionType: 'Soru Tipi',
    questionStatus: 'Soru Durumu',
    makeAdmin: 'Admin Yap',
    authorizeUser: 'Yetkilendir',
    unblockUser: 'Bloğu Kaldır',
    fileUpload: 'Dosya veya Medya Ekle',
    fileUploadCompulsory: 'Dosya veya Medya Ekle (Zorunlu)',
    storyFileUpload: 'Hikaye Görseli Ekle',
    storyFileUploadDescription:
      'En fazla 50 MB, dikey formatta, önerilen çözünürlük 900x1200',
    fileUploadDescription:
      'En fazla 20 MB, 4:3 oranında, önerilen çözünürlük 1200x900 olmalıdır.',
    photoUpload: 'Fotoğraf Ekle (Zorunlu)',
    storyPhotoUpload: 'İkon Fotoğrafı Ekle (Zorunlu)',
    storyphotoUploadDescription:
      'En fazla 20 MB, kare formatta, önerilen çözünürlük 900x900 olmalıdır.',
    photoUploadDescription:
      'En fazla 1 MB, 4:3 oranında, önerilen çözünürlük 1200x900 olmalıdır.',
    photoUploadNotifications: 'Fotoğraf Yükle',
    description: 'Açıklama',
    contact: 'İletişim',
    image: 'Görsel',
    sortOrder: 'Sıralama',
    postType: 'İçerik Tipi',
    orderInfo: 'Şipariş Bilgileri',
    content: 'İçerik',
    postCategory: 'Kategori',
    postCategoryRequired: ' (Her ana kategoriden bir tane seçilmesi zorunlu)',
    postTag: 'Etiket',
    postGroup: 'Grup',
    postDivision: 'Bölge',
    postCustomField: 'Özel Alan',
    selectExcelFile: 'Yemek menüsü seçiniz',
    tooltipActive: 'Aktif',
    tooltipPassive: 'Pasif',
    tooltipResolved: 'Çözüldü',
    tooltipUnresolved: 'Çözülmedi',
    foodMenuCalory: 'Kalori',
    selectCorp: 'Kuruluş seçiniz',
    selectLoc: 'Lokasyon seçiniz',
    selectEmpType: 'Çalışan Tipi seçiniz',
    selectItemsPerPage: 'Sayfa başına veri adedi seçiniz',
    marketplaceLabel: 'Pazar Yeri',
    addMarketplace: 'Pazar Yeri Ekle',
    editMarketplace: 'Pazar Yeri Düzenle',
    addIntegration: 'Entegrasyon ekle',
    editIntegration: 'Entegrasyon düzenle',
    ShipmentCompany: 'Kargo Firması',
    selectDate: 'Tarih Seçiniz',
    selectTime: 'Saat Seçiniz',
    selectDateRange: 'Tarih Aralığı Seçin',
    tooltipPublished: 'Yayında',
    tooltipDraft: 'Beklemede',
    tooltipReview: 'İncelemede',
    tooltipUnpublished: 'Kaldırıldı',
    tooltipReject: 'Reddedildi',
    answerQuestion: 'Soruyu Cevapla',
    inspectQuestion: 'Soruyu İncele',
    questionAnswer: 'Soru Cevabı',
    questionHr: 'Soru',
    titleHr: 'Başlık',
    filesHr: 'Dosyalar',
    dateHr: 'Cevaplanma Tarihi',
    noFileHr: 'Dosya Yok',
    statusHr: 'Soru Durumu',
    spotOffer: 'Teklif',
    order: 'Sipariş',
    tooltipNoAnswered: 'Cevaplanmadı',
    tooltipInReview: 'İncelemede',
    selectboxType: 'Lütfen veri tipini seçiniz',
    inputName: 'Name (İsim alanı özgün olmalı)',
    parentCategory: 'Ebeveyn Kategori',
    surveyName: 'Anket İsmi',
    prohibitedWord: 'Yasaklı Kelime',
    surveyStatus: 'Anket Durumu',
    surveyPublishDate: 'Yayınlanma Tarihi',
    surveyEndDate: 'Yayından Kaldırılma Tarihi',
    surveyQuestion: 'Anket Sorusu',
    surveyAnswer: 'Şık',
    surveyLink: 'Link',
    selectPage: 'Sayfa Seçiniz',
    selectStatus: 'Durum',
    selectWareHouse: 'Depo',
    selectCountPostType: 'Gönderi Tipi Seçiniz',
    inputTagId: 'Tag Id',
    selectInnerPage: 'Yönlendirilen sayfayı seçiniz',
    modulChoose: 'Yönlendirilecek Sayfayı Seç',
    dateAndLinks: 'Tarih ve Link',
    dateAndPin: 'Tarih ve Sabitle',
    link: 'Link',
    notificationUsers: 'Kullanıcılar',
    documentName: 'Döküman İsmi',
    documentContent: 'Döküman İçeriği',
    notificationTargetTypes: 'İletilecek Kişi(ler)',
    testNotification: 'Test Bildirimi',
    notificationDateTime: 'Gönderim Tarihi ve Saati',
    notificationAllTargets: 'Tüm kişilere ve gruplara gönder.',
    notificationTestMessage: 'Test bildirimi olarak gönder.',
    notificationSendMessage: 'Seçilenlere bildirim gönder.',
    notificationUserSelect: 'Kullanıcıları seçiniz',
    notificationLink: 'Uygulama içinde açılacak bağlantıyı giriniz.',
    notificationExternalLink: 'Uygulama dışında açılacak bağlantıyı giriniz.',
    notificationMessage: 'Mesaj',
    notificationTitleDescription: 'İçerik ve Mesaj',
    storyTitle: 'Hikaye Başlığı',
    surveyDownload: 'Anketi Sonuçlarını İndir',
    currentPassword: 'Mevcut Şifre',
    reNewPassword: 'Yeni Şifre (Tekrar)',
    changePassword: 'Şifre Değiştir',
    changeNumber: 'Telefon Numarası Değiştir',
    hardReload: 'Sayfayı Yenile',
    useGuide: 'Kullanım Kılavuzu',
    searchInTitle: 'Başlıkta Ara',
    noCategory: 'Kategori Yok',
    searchCategory: 'Görüntülemek İstediğiniz Kategoriyi Arayın',
    noCategorySelected: 'Kategori seçimi yapılmadı',
    unPublish: 'Yayından Kaldır',
    publishItem: 'Yayına Al',
    batnews: 'Battan Haberler',
    bspPlus: 'BSP+',
    brandName: 'Marka Adı',
    orderNo: 'Sipariş No',
    orderAmount: 'Sipariş Tutarı',
    customerFullName: 'Müşteri',
    customerInformation: 'Müşteri Bilgileri',
    dangerSubstanceLabel: 'Tehlikeli Madde Ücret Bilgileri',
    customerCompanyName: 'Müşteri Firma Adı',
    customerEmail: 'Müşteri E-Posta',
    customerPhone: 'Müşteri Telefon',
    receiverAddress: 'Teslimat Adresi',
    shippingCompany: 'Kargo Firması',
    shippingNo: 'Kargo Numarası',
    shippingDate: 'Kargo Tarihi',
    shippingPrice: 'Kargo Maliyeti',
    appeal: 'Hitap',
    appLoginTitle: 'Üye Girişi',
    smsVerificationCode: 'SMS Doğrulama Kodu',
    searchProduct: 'Ürün Ara',
    notifications: 'Bildirimler',
    readAll: 'TÜMÜNÜ OKU',
    bottomNavTitle: 'Powered by — ',
    applicationName: 'AlGönder',
    addNew: 'Yeni Ekle',
    welcomeText: 'Hoşgeldiniz ',
    goToOrder: 'Siparişe Git',
    messageLanguagePreference: 'SMS ve Mail Dil Tercihi',
    languagePreference: 'Dil Tercihi',
    waitingCustomer: 'Müşteri Onayı Bekliyor',
    waitingSupplier: 'Tedarikçi Onayı Bekliyor',
    waitingContract: 'Sözleşme Bekleniyor',
    completed: 'Teslim Edildi',
    loaded: 'Yükleme Yapıldı',
    onRoad: 'Yolda',
    arrival: 'Varış Yerinde',
    archived: 'Arşivlendi',
    rate: 'Değerlendir',
    exhibition: 'Fuar',
    reference: 'Referans',
    personal: 'Kişisel',
    corporate: 'Kurumsal',
    currentPhoneNumber: 'Mevcut Telefon Numarası',
    newPhoneNumber: 'Yeni Telefon Numarası',
    verificationCode: 'Onay Kodu',
    profileInformation: 'Profil Bilgileriniz',
    userGuide: 'Site Kullanımı',
    startDate: 'Başlangıç Tarihi',
    invoiceDate: 'Fatura Tarihi',
    endDate: 'Bitiş Tarihi',
    orderStatus: 'Yükleme Durumu',
    orderAndFileStatus: 'Yükleme / Dosya Durumu',
    orderShipmentType: 'Taşıma Tipi',
    orderFrom: 'Nereden',
    orderTo: 'Nereye',
    productName: 'Ürün Adı',
    productDescripton: 'Açıklama',
    Barcode: 'Barkod',
    SKUNo: 'SKU No',
    productDimensions: 'Ebatlar',
    productTimes: 'Süreler',
    productMaxOrderCount: 'Maksimum Sipariş Sayısı',
    productCriticalStockCount: 'Kritik Stok Sayısı',
    productGTIN: 'Global Ticari Öğe Numarası',
    productCost: 'Ürün Maliyeti',
    warehouseManagement: 'Depo Yönetimi',
    stockCount: 'Stok Adedi',
    productIntroduction: 'Ürün Tanıtımı',
    ready: 'Hazır',
    file: 'Dosya',
    preparing: 'Hazırlanıyor',
    getError: 'Hata Alındı',
    Width: 'Genişlik (cm)',
    Length: 'Uzunluk (cm)',
    Height: 'Yükseklik (cm)',
    Weight: 'Ağırlık (kg)',
    preparingTime: 'Hazırlanma Süresi (gün)',
    warrantyTime: 'Garanti Süresi (ay)',
    selectPhoto: 'Fotoğraf Seçiniz',
    live: 'Güncel',
    notLive: 'Güncel Değil',
    onSale: 'Satışta',
    notOnSale: 'Satışta Değil',
    priceChange: 'Fiyat Değişimi',
    criticalStock: 'Kritik Stok Geçildi',
    hasStock: 'Stok Var',
    hasNotStock: 'Stok Yok',
    changePercentage: 'Değişim Yüzdesi',
    cost: 'Maliyet',
    checkboxIsUpdateOrder: 'Değişikleri tamamladım',
    chatSupport: 'Destek Birimi',
    addBox: 'Paket ekle',
    editBox: 'Paketi Düzenle',
    from: 'Nereden',
    to: 'Nereye',
    fromWhereDashboard: 'Nereden/Nereye',
    newSpotOffersHeader: 'Yeni Teklif',
    landway: 'Karayolu',
    hourAsNoun: 'Saat',
    codeAsNoun: 'Kod',
    locationAsNoun: 'Lokasyon',
    recordDate: 'Kayıt Açılma Tarihi',
    requestedPurchaseDate: 'İstenen Alım Tarihi',
    pickupType: 'Pickup Türü',
    productionCountry: 'Malın Üretim Ülkesi',
    packagingType: 'Paketleme Türü',
    portalSellerCode: 'Satışçı Kodu',
    deferredPayment: 'Vadeli Ödeme',
    envelope: 'Zarf',
    origin: 'Menşei',
    maxKg: 'Maksimum Brüt Kg.',
    marketplaceLoginURL: 'Pazar Yeri Giriş Linki',
    receiveFromDoor: 'Adresten Alınsın',
    productPackageType: 'Kap Tipi',
    expenseType: 'Masraf',
    proformaOption: 'Proforma Seçeneği',
    customerProforma: 'Müşteri Proforması',
    supplierProforma: 'Tedarikçi Proforması',
    pickupTypes: {
      contactWithFedEx: 'FedEx ile İletişime Geçeceğim',
      leavingPackageToFedEx: 'FedEx Şubesine Bırakacağım',
      scheduledPickup: 'Planlanmış Alım Kapsamında',
    },
    fsc: 'FSC',
    cargoCompanyName: 'Kargo Firması',
    cargoTrackingNumber: 'Kargo Takip Numarası',
    transportCompanyName: 'Nakliye Firması',
    transportPlate: 'Nakliye Plakası',
    transportDriverName: 'Nakliye Şoförü',
    selfSendCargo: 'Kargo ile göndereceğim',
    selfSendHaul: 'Nakliye ile göndereceğim',
    orderSender: 'Siparişi gönderen',
    pickupOrganisation: 'Pickup Yönetimi',
    deliveryInfo: 'Teslim Bilgileri',
    customerType: 'Müşteri Tipi',
    memberType: 'Üye Tipi',
    tooltipFileOperations: 'Dosya İşlemleri',
    tooltipDownloadLabelSingular: 'Etiketi İndir',
    tooltipCorporate: 'Kurumsal Müşteri',
    tooltipMarketPlace: 'Entegrasyon Müşterisi',
    portalCustomerCode: 'Tedarikçi Müşteri Kodu',
    generatePassword: 'Şifreyi Otomatik Ver',
    portalUsers: 'Portal Kullanıcıları',
    accountantOrganisation: 'Muhasebe Yönetimi',
    warehouseOrganisation: 'Depo Yönetimi',

    entranceToWarehouseDate: 'Depo Giriş Tarihi',
    exitFromWarehouseDate: 'Depo Çıkış Tarihi',

    countInCap: 'Kap İçi Adet',

    additionalCostType: 'Ek Maliyet Türü',
    costParameter: 'Maliyet Parametresi',
    value: 'Değer',
    minValue: 'Minimum Değer',

    environmentalMeasure: 'Çevresel Ölçü',
    ddp: 'DDP',
    additionalHandling: 'Ek Elleçleme',
    personalDelivery: 'Kişisel Teslimat',
    oversize: 'Oversize',
    insurance: 'Sigorta',
    signedDelivery: 'İmzalı Teslimat',

    fixedPrice: 'Sabit Fiyat',
    perOrder: 'Sipariş Başına',
    perPackage: 'Paket Başına',
    perWeight: 'Kilogram Başına',
    perProductPrice: 'Ürün Fiyatı Yüzdesi',

    issueInvoice: 'Faturayı Kes',

    invoiceIssued: 'Fatura Kesildi',
    invoiceNotIssued: 'Fatura Kesilmedi',

    planned: 'Planla',
    notPlanned: 'Planı İptal Et',

    wantSignedDelivery: 'İmzalı teslimat istiyorum.',
    referenceId: 'AlGönder Referans ID',

    mailLogo: 'E-Posta Logosu',

    customerListName: 'Müşteri Liste Adı',

    exportImportType: 'Gönderim Türü',

    optionalReferenceID: 'Müşteri Referans ID',

    securityType: 'Security Type',
    webhookToken: 'Webhook Token',
    shipmentUpdateUrl: 'Gönderi Güncelleme URL',
    trackingUrl: 'Takip URL',

    eTGBExpress: 'ETG Express',
    eTGBEconomy: 'ETGB Ekonomi',
    exitWithDeclaration: 'Beyanname İle Çıkış',
    economic: 'Ekonomik',
    sampleShipping: 'Örnek Gönderi',

    basePrice: 'Temel Fiyat',

    averageWeeklyLoadCount: 'Haftalık Yükleme Sayısı',
    averageWeeklyLoadWeight: 'Haftalık Yükleme Miktarı(kg)',

    priceListName: 'Fiyat Liste Adı',

    results: 'Sonuçlar',

    profit: 'Kâr',
    additionalExpense: 'Ek Harcama',
    additionalExpenseCurrency: 'Ek Harcama Birim',

    showColumns: 'Gösterilecek Alanlar',
    isMailSent: 'E-Posta Gönderilecek Mi?',
    mailFrequency: 'E-Posta Gönderme Sıklığı',
    willBeFilteredColumns: 'Filtrelenecek Alanlar',

    daily: 'Günlük',
    weekly: 'Haftalık',
    monthly: 'Aylık',

    invoiceStatus: 'Fatura Durumu',

    roles: {
      Accountant: 'Muhasebeci',
      Operation: 'Operasyon',
      Planner: 'Planlayıcı',
      Supplier: 'Tedarikçi',
      PendingSupplier: 'Onay Bekleyen Kullanıcı(Tedarikçi)',
      Customer: 'Müşteri',
      PendingCustomer: 'Onay Bekleyen Kullanıcı(Müşteri)',
      Warehouse: 'Depocu',
    },

    packageDescription: 'Mal Kısa Adı',

    sourceCompany: 'Kaynak Şirket',

    dynamicForm: {
      ulasim_tipi: 'Ulaşım Tipi',
      faturasi_kesildi: 'Faturası Kesildi',
      planlandi: 'Planlandı',
      dosya_durumu: 'Dosya Durumu',
      transfer_tipi: 'Transfer Tipi',
      odeme_durumu: 'Ödeme Durumu',
      yukleme_durumu: 'Yükleme Durumu',
      siparis_tipi: 'Sipariş Tipi',
      portal_musteri_tipi: 'Portal Müşteri Tipi',
      tehlikeli_madde_iceriyor: 'Tehlikeli Madde İçeriyor',
      gonderici_ulke: 'Gönderici Ülke',
      alici_ulke: 'Alıcı Ülke',
      eve_teslimat: 'Eve Teslimat',
      vergi_odeme: 'Vergi Ödeme',
      odeme_tipi: 'Ödeme Tipi',
      sigorta: 'Sigorta',
      alt_tedarikci: 'Alt Tedarikçi',
      servis_adi: 'Servis Adı',
      imzali_teslimat: 'İmzalı Teslimat',
      CompanyType: 'Şirket Tipi',
      musteri_adi: 'Müşteri Adı',
      kap_adedi: 'Kap Adedi',
      toplam_kilo: 'Toplam Kilo',
      toplam_hacim: 'Toplam Hacim',
      fatura_agirligi: 'Fatura Ağırlığı',
      referans_kodu: 'Referans Kodu',
      gonderici_adi: 'Gönderici Adı',
      gonderici_adres: 'Gönderici Adresi',
      gonderici_sehir: 'Gönderici Şehir',
      alici_adi: 'Alıcı Adı',
      alici_adres: 'Alıcı Adresi',
      alici_sehir: 'Alıcı Şehir',
      odeme_tarihi: 'Ödeme Tarihi',
      yukleme_tarihi: 'Yükleme Tarihi',
      cikis_tarihi: 'Çıkış Tarihi',
      varis_tarihi: 'Varış Tarihi',
      teslim_tarihi: 'Teslim Tarihi',
      siparis_bedeli: 'Sipariş Bedeli',
      fiyat_birimi: 'Fiyat Birimi',
      ek_maliyetler: 'Ek Maliyetler',
      takip_kodu: 'Takip Kodu',
      tedarikci_adi: 'Tedarikçi Adı',
      tedarikci_fatura_agirligi: 'Tedarikçi Fatura Ağırlığı',
      depo_giris_tarihi: 'Depo Giriş Tarihi',
      depo_cikis_tarihi: 'Depo Çıkış Tarihi',
      CompanyIds: 'Filtrelenecek Şirketler',
      siparis_tarihi: 'Sipariş Tarihi',
    },

    oldPassword: 'Eski Şifre',

    state: 'Eyalet',
    selectId: 'Select ID',
    isoCode2: 'ISO Code 2',
    isoCode3: 'ISO Code 3',
    phoneCode: 'Telefon Kodu',
    withoutProforma: 'Proformasız',

    ETGB: 'ETGB',
    portalOwner: 'Portal Sahibi',

    invoiceCompany: 'Fatura Firması',
    referenceNumber: 'Referans Numarası',
    expressPackageType: 'Gönder Tipi',

    netWeight: 'Net Kg',
    rejected: 'Reddedildi',

    operatorName: 'Operasyoncu Adı Soyadı',
    authorizedPerson: 'Şirket Yetkilisi',
    portal: 'Portal',

    isLabelShownForETGB: 'ETGB Formunda Etiketim Görünsün',

    weightPerCap: 'Koli Ağırlık Sınırı',
    maxSideLength: 'En Uzun Kenar Sınırı',
    circumferenceLimit: 'Çevresel Ölçü Sınırı',
  },
  menu: {
    groups: 'Gruplar',
    users: 'Kullanıcılar',
    modulManagement: 'Modül Yönetimi',
  },
  company: {
    title: {
      users: 'Şirket Yetkilileri',
    },
    roles: 'Firma Rolleri',
  },
  titles: {
    orderFileTypeWarning: 'Dosya Tipi Uyarısı',
    user: 'Kullanıcı',
    line: 'Satır',
    output: 'Çıktı',
    tags: 'Etiketler',
    cart: 'Sepet',
    number: '{value} Numarası',
    discount: 'İndirim',
    requests: '{value} İstekler',
    creditCard: 'Kredi Kartı Bilgileri',
    summary: 'Özet',
    choose: '{value} Seç',
    buy: '{value} Satın Al',
    warehouse: 'Depo Yönetimi',
    management: '{value} Yönetimi',
    boughtItems: 'Satın Aldığınız Paketler',
    total: 'Toplam',
    offerResults: 'Fiyat Arama Sonuç Ekranı',
    shipmentType: 'Sevkiyat Tipi',
    from: 'Nereden',
    to: 'Nereye',
    load: 'Yük',
    transferTime: 'Transit Süre',
    offer: 'Teklif',
    new: 'Yeni',
    searchHistory: 'Geçmiş Aramalarım',
    spotPriceRequests: 'Fiyat Talepleri',
    offerDate: 'Teklif Tarihi',
    quickSearch: 'Hızlı Fiyat Arama',
    rateSupplier: 'Tedarikçi Değerlendir',
    alreadyRateSupplier: 'Tedarikçi Daha Önce Değerlendirildi',
    payments: 'Ödemeler',
    paymentSummary: 'Ödeme Özeti',
    details: 'Detaylar',
    spotOfferMessages: 'Teklif Mesajları',
    orderMessages: 'Sipariş Mesajları',
    messageDate: 'Mesaj Tarihi',
    messageCompanyName: 'Şirket Adı',
    referenceCode: 'Referans Kodu',
    loadDetail: 'Yük Detayları',
    attachments: 'Ekli Dosyalar',
    totalKilo: 'Toplam Kilo',
    totalKiloShorten: 'Kilo',
    totalVolume: 'Toplam Hacim',
    totalVolumeShorten: 'Hacim',
    theOffer: 'Bu teklif',
    expiryStatus: 'Geçerlilik Durumu',
    activationSuccessful: 'Aktivasyon Başarılı',
    activationFailed: 'Aktivasyon Başarısız',
    contractDetails: 'Sözleşme Detayları',
    totalVolumeWeight: 'Toplam Fatura Ağırlığı',
    blockUser: 'Kullanıcı Engelle',
    blockUserNameOf: '{value} Adlı Kullanıcıyı Engelle',
    blacklist: 'Engellediğiniz Kullanıcılar',
    reason: 'Neden',
    blacklistedByUser: 'Engellenen Kullanıcılar',
    blockedBy: 'Engelleyen Kullanıcı',
    orderOperations: 'Sipariş İşlemleri',
    subsupplier: 'Alt Tedarikçi',
    integrationService: 'Hizmet',
    integrationPackaging: 'Alt Tedarikçi Paketleme Tipi',
    integration: {
      title: 'Alt Tedarikçi Detayları',
      trackingNumber: 'Takip Numarası',
      serviceName: 'Hizmet Adı',
      netRateAmount: 'Net Oran Tutarı',
      label: 'Etiket',
      logisticsCompany: 'Alt Tedarikçi',
    },
    expenseOptions: 'Masraf Bilgileri',
    dateAsNoun: '',
    hourAsNoun: '',
    codeAsNoun: '',
    locationAsNoun: '',
    company: {
      roles: '',
    },

    dateTimeSettings: 'Tarih Saat Ayarları',

    invoiceIssued: 'Fatura Kesildi',
    invoiceNotIssued: 'Fatura Kesilmedi',

    accountingSettings: 'Fatura Ayarları',
    orderStatus: 'Sipariş Durumu',

    orderType: 'Sipariş Türü',

    expressServiceType: 'Express Hizmet Türü',
    myExchangeRateList: 'Benim Kurlarım',
    centralBankExchangeRateList: 'Merkez Bankası Kurları',

    priceDetails: 'Fiyat Detayları',
    updateWeeklyAverages: 'Haftalık Ortalamalar',
    preview: 'Önizleme',

    financialInformation: 'Mali Bilgiler',

    updatePassword: 'Şifrenizi Güncelleyin',
  },
  title: {
    supportDetail: 'Destek Talebi Detayı',
    add: '{value} Ekle',
    update: '{value} Düzenle',
    photo: 'Resim',
    modulManagement: 'Modül Yönetimi',
    stockManagement: 'Stok Yönetimi',
    orderProducts: 'Sipariş Ürünleri',
    groups: 'Gruplar',
    users: 'Kullanıcılar',
    endorsement: 'Takdir Teşekkür',
    moduleModalCreate: 'Yeni Modül Ekle',
    moduleModalUpdate: 'Modülü Güncelle',
    groupModalCreate: 'Yeni Grup Ekle',
    groupModalUpdate: 'Grubu Güncelle',
    userModalUpdate: 'Kullanıcı Güncelle',
    userModalCreate: 'Yeni Kullanıcı Ekle',
    logout: 'Çıkış Yap',
    userDetails: 'Kullanıcı Detay',
    category: 'Kategori',
    status: 'Durum',
    name: 'İsim',
    categoryModalUpdate: 'Kategori Güncelle',
    categoryCreate: 'Yeni Kategori Ekle',
    makeAdmin: 'Admin Yap',
    removeAdmin: 'Adminden Çıkar',
    addContent: 'İçerik Ekle',
    updateContent: 'İçerik Güncelle',
    tableHeaderTrName: 'Türkçe İsim',
    tableHeaderEnName: 'İngilizce İsim',
    orders: 'Siparişler',
    orderDetails: 'Sipariş Detayları',
    products: 'Ürünler',
    prohibitedWords: 'Yasaklı Kelimeler',
    askHr: "IK'ya Sorum Var",
    createSurvey: 'Anket Ekle',
    createBrand: 'Marka Ekle',
    surveyDetails: 'Anket Detay',
    brandDetails: 'Marka Detay',
    tableHeaderIcon: 'İkon',
    tableHeaderName: 'İsmi',
    tableHeaderUsername: 'Kullanıcı Adı',
    tableHeaderProfilePic: 'Profil Resmi',
    tableHeaderSignature: 'İmza',
    tableHeaderLastName: 'Soyismi',
    tableHeaderEmail: 'Eposta',
    tableHeaderKey: 'Modül Anahtarı',
    tableHeaderCreateDate: 'Oluşturulma Tarihi',
    tableHeaderEndDate: 'Bitiş Tarihi',
    tableHeaderActions: 'İşlemler',
    tableHeaderSurveyQuestion: 'Anket Sorusu',
    tableHeaderSurveyType: 'Anket Tipi',
    tableHeaderUserType: 'Kullanıcı Tipi',
    tableHeaderStatus: 'Durum',
    tableHeaderSurveyAnswerCount: 'Cevaplanma Sayısı',
    tableHeaderModules: 'Modül İsmi',
    tableHeaderWrite: 'Yazma',
    tableHeaderRead: 'Okuma',
    tableHeaderApprove: 'Yayına Alma',
    deleteContentTitle: 'İçeriği Sil',
    titleCustomFields: 'Özel Alanlar',
    customFieldCreate: 'Özel Alan Ekle',
    customFieldEdit: 'Özel Alan Düzenle',
    tableHeaderDisplayName: 'Görüntülenecek Ad',
    tableHeaderDataType: 'Veri Tipi',
    tableHeaderCustomFieldName: 'Özel Alan İsmi',
    tableHeaderCustomFieldModuleName: 'Ait Olduğu Modül İsmi',
    foodMenuModalCreate: 'Dosya Yükle',
    titleFoodMenu: 'Yemek Menüsü',
    titleReport: 'Raporlar',
    tableHeaderFoodMenuDate: 'Sisteme Ekleniş Tarihi',
    tableHeaderLikeCount: 'Beğeni Sayısı',
    tableHeaderFoodMenuName: 'Yemek Menü Adı',
    tableHeaderFoodMenuCreator: 'Menüyü Oluşturan Kişi',
    foodMenuModalDetail: 'Yemek Menüsü Detayı',
    parentName: 'Ebeveyn İsmi',
    documentAssign: 'Döküman Atama',
    documentUpdate: 'Döküman Atama Güncelle',
    ProfileInfo: 'Profil Bilgileri',
    categoryDetails: 'Detayı',
    subCategoryCreate: 'Alt Kategori Ekle',
    tableHeaderFullNameSurvey: 'Anketi Oluşturan',
    tableHeaderFullNameAskHR: 'Soruyu Oluşturan',
    tableHeaderContentOwner: 'İçerik Sahibi',
    tableHeaderProhibitedWord: 'Yasaklı Kelime',
    tableHeaderProhibitedWordCreator: 'Yasaklı Kelimeyi Oluşturan',
    forceUpdate: 'Güncelleme JSON',
    forceUpdateAdd: 'Güncelleme JSON Ekleme',
    forceUpdateLabel: 'Güncelleme JSON Dosyası',
    tableHeaderContentType: 'İçerik Tipi',
    tableHeaderContentReason: 'İçerik Veri Türü',
    tableHeaderSuspensefulContent: 'İçerik',
    stories: 'Hikayeler',
    tableHeaderTitle: 'Başlık',
    tableHeaderThumbNail: 'Görsel',
    addStory: 'Hikaye Ekle',
    brandName: 'Marka',
    notificationType: 'Bildirim Hedefi',
    Creator: 'Ekleyen',
    storyData: 'Hikaye İçeriği',
    storyLinkType: 'Hikaye Link Türü',
    notificationRedirectPage: 'Yönlendirilecek Sayfa',
    contentPageHeaderAdd: 'Ekle',
    contentPageHeaderUpdate: 'Güncelle',
    moduleDescription: 'Modül Açıklaması',
    blockedUsers: 'Bloklanmış Kullanıcılar',
    advertModalUpdate: 'Advert Güncelle',
    advertModalCreate: 'Advert Ekle',
    endorsementTopicModalCreate: 'Takdir Teşekkür Ekle',
    endorsementTopicModalUpdate: 'Takdir Teşekkür Güncelle',
    tableHeaderEndorsementTopicImage: 'Görsel',
    tableHeaderEndorsementTopicDescription: 'Açıklama',
    tableHeaderEndorsementTopicSortOrder: 'Sıralama',
    tableHeaderEndorsementTopicType: 'Anahtar Tipi',
    tableHeaderEndorsementTopicHeader: 'Başlık',
    brands: 'Markalar',
    brandDetail: 'Marka Detayı',
    categories: 'Kategoriler',
    categoryDetail: 'Kategori Detay',
    deleteCategory: 'Sildiğiniz Kategoride Ürünler Var',
    newCategory: 'Yeni Kategori',
    importTitle: 'Ürün Ekleme',
    addTag: 'Etiket Ekle',
    priceChange: 'Fiyat Değişimi',
    exportTitle: 'Ürün Listesi Çıkarma',
    messages: 'Mesajlar',
    bulkAddTag: 'Toplu Etiket Ekleme',
    bulkUpdateProduct: 'Toplu Fiyat Değişimi',
    importFileInstructions: 'Dosya Yükleme Talimatları',
    stockTransfer: 'Depolar Arası Stok Aktarımı',
    productInformation: 'Ürün Bilgisi',
    stockInformation: 'Stok Durumu',
    companyInformationDetail: 'Şirket Detayı',
  },
  description: {
    carrierUpdated: 'Kurye bilgileri başarıyla güncellendi',
    carrierAdded: 'Kurye bilgileri başarıyla eklendi',
    404: '404 - Sayfa Bulunamadı',
    changePasswordSuccessfully: 'Şifreniz başarıyla değiştirildi',
    updateExpiryDateSuccess: 'Son kullanma tarihi başarıyla güncellendi',
    smsVerificationCheckboxLabel: 'İki adımlı koruma istiyorum.',
    updateExpiryDateError: 'Son kullanma tarihi güncellenirken bir hata oluştu',
    packageDetailsUpdated: 'Paket detayları güncellendi',
    bankInformationAddSuccess: 'Banka bilgisi başarıyla eklendi',
    bankInformationEditSuccess: 'Banka bilgisi başarıyla güncellendi',
    bankInformationDeleteSuccess: 'Banka bilgisi başarıyla silindi',
    makeSurePayment:
      'Lütfen yükleme, çıkış, varış ve teslimat gibi tarihleri belirtmeden önce ödemenin alındığından emin olun',
    orderCanceled: 'Sipariş iptal edildi',
    pleaseRegister: 'İndirimli fiyatları görmek için kayıt olunuz',
    marketplaceNotSelected: 'Lütfen pazaryeri seçiniz.',
    titleInformation: 'Bilgilendirme',
    logoutInformationText:
      'Oturum süreniz dolduğu için tekrar giriş yapmanız gerekmektedir',
    passwordLength: {
      min: 'Şifreniz en az altı karakter uzunluğunda olmalıdır.',
      max: 'Şifreniz en fazla yirmi dört karakter uzunluğunda olmalıdır.',
    },
    passwordRequirements: {
      uppercase: 'Şifrenizde en az bir büyük harf bulunmalıdır.',
      lowercase: 'Şifrenizde en az bir küçük harf bulunmalıdır.',
      digit: 'Şifrenizde en az bir rakam bulunmalıdır.',
      specialChar: 'Şifrenizde en az bir özel karakter bulunmalıdır.',
    },
    addCompanyInformationText:
      'Uygulamayı kullanmadan önce şirket bilgilerinizi doldurmanız gerekmektedir.',
    signatureInformationText:
      'Uygulama içinde müşterilere fiyat vermek ve sipariş oluşturmak için imzanızı yüklemeniz gerekmektedir.',
    forgotPassword: 'Şifremi Unuttum',
    verifyResetToken: 'Şifremi Değiştir',
    invoiceSuccess: 'Fatura başarıyla onaylandı',
    invoiceError: 'Fatura onaylanırken bir hata oluştu',
    orderSuccess: 'Sipariş başarıyla onaylandı',
    orderError: 'Sipariş onaylanırken bir hata oluştu',
    invoiceLoading: 'Fatura onaylanıyor. Lütfen bekleyiniz',
    orderLoading: 'Sipariş onaylanıyor. Lütfen bekleyiniz',
    noList: 'Liste boş.',
    deleteModalTitle: 'Silme İşlemi',
    statusToActiveModalTitle: 'Yayına Alma İşlemi',
    statusToInactiveModalTitle: 'Yayından Kaldırma İşlemi',
    contentDenyTitle: 'İçerik Engelleme İşlemi',
    contentApproveTitle: 'İçerik Onaylama İşlemi',
    deleteModalDescription: 'Seçilen veriyi silmek istediğinize emin misiniz?',
    StatusToActiveDescription:
      'Bu ürünü yayına almak istediğinize emin misiniz?',
    StatusToInactiveDescription:
      'Bu ürünü yayından kaldırmak istediğinize emin misiniz?',
    deletedCategory: 'Sildiğiniz Kategori',
    contentModalDescription:
      'Seçilen içeriği onaylamak istediğinize emin misiniz?',
    invalidKey:
      'Lütfen anahtar alanında tükçe karakter ve _ dışında karakter kullanmayınız.',
    logout: 'Çıkış yapmak istediğinize emin misiniz?',
    groupLengthMessage: 'Not : En fazla 4 satır ekleyebilirsiniz.',
    tableLoadingError:
      'Tablo yüklenirken bir hata oluştu, lütfen tekrar deneyiniz.',
    makeAdminDescription: 'kişisini admin yapmak istediğinize emin misiniz?',
    removeAdminDescription:
      'kişisini admin yetkisinden çıkarmak istediğinize emin misiniz?',
    authorizationUserText1:
      'kullanıcısının yetkilerini tanımlayınız, daha sonra aşağıda yer alan',
    authorizationUserText2: 'butonuna tıklayınız.',
    deleteContent: 'Seçilen içeriği silmek istediğinize emin misiniz?',
    invalidDatePick: 'Lütfen geçerli bir tarih aralığı girin.',
    invalidCategoryMarketPlace:
      'Lütfen tüm pazaryerleri için kategori seçiniz.',
    invalidUserSelection: 'Lütfen atamak için kullanıcı veya bölge seçin.',
    excelDataError: 'Lütfen dışarı aktaracak verinin olduğu tablo seçiniz.',
    excelColumnError:
      'Lütfen dışarı aktaracak verinin başlığı olan tablo seçiniz.',
    invalidMenuSelect: 'Lütfen en az bir adet menü seçin.',
    selectModule: 'Lütfen bir modül seçiniz',
    fileSizeError: 'Lütfen dosya boyut limitine uygun dosya seçiniz.',
    notificationTargetError:
      'Lütfen bildirim göndermeden önce en az bir adet hedef seçiniz.',
    unsupportedMediaType:
      'Desteklenmeyen dosya tipi, lütfen farklı bir dosya tipi seçiniz.',
    surveyAnswerBoundReached: 'Cevap ekleme sınırına ulaşılmıştır.',
    surveyEmptyAnswer: 'Boş cevap eklenemez',
    wrongDateInput: 'Başlangıç tarihi Bitiş tarihinden ileride olamaz.',
    selectPage: 'Lütfen Bir Sayfa Seçiniz',
    selectInnerPage: 'Lütfen Bir  İç Sayfa Seçiniz',
    notificationSendError: 'Bildirim Gönderilemedi, Lütfen Tekrar Deneyiniz.',
    storyPhotoError: 'Lütfen en az bir adet fotoğraf yükleyiniz.',
    tagCountReached:
      'Maksimum 15 adet etiket ekleyebilirsiniz, sıfırlamak için sayfayı yenileyiniz.',
    propertyEmptySicil:
      'Boş sicil numarası eklenemez, lütfen sicil numarası ekleyiniz.',
    propertyEmptyPublishDate:
      'Özel alanlar bölümündeki bilgiler zorunludur, lütfen eksiksiz doldurunuz',
    contentPhotoUpload: 'Lütfen zorunlu fotoğraf alanını doldurunuz.',
    groupRowDelete:
      'Bu satırı silemezsiniz. En az bir grup satırı girilmelidir.',
    emptyCategorySelect:
      'Kategori seçimi zorunludur, lütfen en az bir adet kategori seçiniz.',
    draftNotificationInfo:
      'Taslak olarak yayınlanıyor, bildirim gönderilmeyecektir.',
    contentFileUpload: 'Lütfen zorunlu medya alanını doldurunuz.',
    contentFileUploadOnlyPdf:
      'Lütfen medya alanına sadece pdf uzantılı dosyaları ekleyiniz.',
    errorOnCreate: 'Lütfen zorunlu alanları doldurup tekrar deneyin.',
    errorOnCreateContent: 'Lütfen içerik kurallarına uygun yayınlama yapınız.',
    communityModule:
      'Bu modül içerikleri tüm topluluk tarafınca görüntülenebilir.',
    invalidFileType:
      'Lütfen içeriği yayınlamak için geçerli bir dosya türü seçin.',
    authorizationError:
      'Yetkilendirme problemi yaşanıyor, lütfen tekrar giriş yapınız.',
    serverError:
      'Sunucularda bir problem yaşanıyor, lütfen daha sonra tekrar deneyiniz.',
    deleteCategory: 'Lütfen ürünler için yeni kategori seçiniz',
    noOrderId:
      'Sipariş numarası tespit edilemedi, siparişlere yönlendiriliyorsunuz.',
    bulkUpdateProduct:
      'Pazaryeri ve yüzdesel değişimi seçerek seçili ürünleri seçtiğiniz pazarlerindeki fiyatlarını güncelleyebilirsiniz.',
    bulkAddTag:
      'Etiket eklemek istediğiniz pazaryerlerini seçiniz ve eklemek istediğiniz etiketleri giriniz.',
    addStock: 'Ürünü satışa çıkarmadan önce stok eklemelisiniz.',
    addStockDesc: "Stok adedini ve ürünün maliyetini 'TL' cinsinden giriniz.",
    stockTransferDesc:
      'Hangi depodan aktarım yapacağınızı ve aktaracağınız stok sayısını giriniz.',
    saveOrderAsDraft:
      'Sipariş taslak olarak kaydedilecektir, tedarikçiye sipariş olarak gitmesi için `Tedarikçiye gönderilmesini istiyorum` kutucuğunu işaretleyiniz.',
    orderFileTypeWarningDesc:
      'Siparişiniz tedarikçiye sipariş olarak gitmesi için zorunlu dosya türünü veya türlerini yüklemeniz gerekmektedir. ',
    planInfoDesription:
      'Planlama işleminde bir hata oluştu, lütfen daha sonra tekrar deneyiniz.',
  },
  messages: {
    ephWarning:
      "0-30 kilogram ağırlığa veya 0.3 m³'ün altında hacime sahip gönderilerin tümü, taşıma bedeli üzerinden %2.35 oranında Evrensel Posta Hizmeti Ücreti'ne tabidir.",
    updateExpiryDate: 'Geçerlilik tarihi güncellendi',
    periodicUpdateSuccessful: 'Dönemsel fiyat güncellemesi başarılı.',
    updatePriceExcel: 'Fiyatlar başarıyla güncellendi.',
    updateRegionExcel: 'Bölgeler başarıyla güncellendi.',
    updateTransportTimeExcel: 'Taşıma süreleri başarıyla güncellendi.',
    updateIntegrationOrders: 'Entegrasyon siparişleri başarıyla güncellendi.',
    paymentSuccessful: 'Ödeme başarılı bir şekilde gerçekleştirildi.',
    paymentFailed: 'Ödeme sırasında bir hata oluştu.',
    paymentRedirect: 'Ödemeler sayfasına yönlendiriliyorsunuz.',
    updateListNameSuccess: 'Liste adı başarıyla güncellendi.',
    updateCompanyNotificationsSuccessful:
      'Şirket bildirimleri başarılı bir şekilde güncellendi.',
    updateCompanyNotificationsError:
      'Bildirimler güncellenirken bir hata oluştu.',
    updateCompanyNotificationLanguageSuccessful:
      'Dil seçimi başarıyla güncellendi.',
    updateCompanyNotificationLanguageError:
      'Dil seçimi güncellenirken bir hata oluştu.',
    updateHazmatPricesSuccessfully:
      'Ek ücret bilgileriniz başarıyla güncellendi.',
    paymentApproved: 'Ödeme onaylandı',
    dateSelectionOrder:
      'Tarihleri sıra ile seçmeniz gerekmektedir. Tarih girme sırası aşağıdaki gibidir:',
    dateOrder: {
      estimatedDeliveryDate: 'Tahmini Teslim Tarihi',
      loadingDate: 'Yükleme Tarihi',
      departureDate: 'Çıkış Tarihi',
      arrivalDate: 'Varış Tarihi',
      deliveryDate: 'Teslim Tarihi',
    },
    shipmentBoxUpdateSuccess: 'Koli başarılı bir şekilde güncellendi',
    shipmentBoxUpdateError: 'Koli güncellenirken bir hata oluştu',
    complaintSuccess: 'Şikayetiniz başarıyla alındı.',
    complaintError: 'Şikayetiniz alınırken bir hata oluştu.',
    contractSignSuccess: 'Sözleşme başarılı bir şekilde imzalandı.',
    contractSignError: 'Sözleşme imzalanırken bir hata oluştu.',
    contractNotSigned: 'Devam etmek için sözlemeyi imzalamalısınız.',
    deleteReceiptSuccess: 'Fatura başarılı bir şekilde silindi.',
    deleteReceiptError: 'Fatura silinirken bir hata oluştu.',
    updateReceiptSuccess: 'Fatura başarılı bir şekilde güncellendi.',
    updateReceiptError: 'Fatura güncellenirken bir hata oluştu.',
    addReceiptSuccess: 'Fatura başarılı bir şekilde eklendi.',
    fileAddSuccess: 'Dosya başarılı bir şekilde eklendi.',
    fileDeleteSuccess: 'Dosya başarılı bir şekilde silindi.',
    addReceiptError: 'Fatura eklenirken bir hata oluştu.',
    addAddressSuccess: 'Adres başarılı bir şekilde eklendi.',
    addAddressError: 'Adres eklenirken bir hata oluştu.',
    editAddressSuccess: 'Adres başarılı bir şekilde güncellendi.',
    editAddressError: 'Adres güncellenirken bir hata oluştu.',
    deleteAddressSuccess: 'Adres başarılı bir şekilde silindi.',
    deleteAddressError: 'Adres silinirken bir hata oluştu.',
    createOfferFormInfoTextTitle: 'Sorunsuz ve Hızlı Gönderilerin Adı:',
    integrationText:
      'Algönder sistemi içindeki pazaryerleri entegrasyonu ile Etsy, Amazon, Ebay, Shopify, Wallmart ve daha bir çok pazaryerinde gerçekleştirdiğiniz satışlar için anında en iyi gönderi fiyatlarını en hızlı şekilde bulabilirsiniz.',
    etsyText:
      "The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.",
    createOfferFormInfoTextDescription:
      'Güvenilir ve Hızlı Gönderilerin Adı: AlGönder\nAlGönder, firmaların yurtiçi ve yurtdışı paketlerini hızlı, güvenilir ve kişiselleştirilmiş bir deneyimle ulaştırmanın yeni adresi.',
    profileUpdateSuccess:
      'Profil bilgileriniz başarılı bir şekilde güncellendi.',
    profileUpdateError: 'Profil bilgileriniz güncellenirken bir hata oluştu.',
    ticketCreated: 'Destek talebiniz başarılı bir şekilde oluşturuldu.',
    ticketCreatedError: 'Destek talebi oluşturulurken bir hata oluştu.',
    ticketDeleteSuccess: 'Destek talebi başarılı bir şekilde kaldırıldı.',
    ticketDeleteError: 'Destek talebi kaldırılırken bir hata oluştu.',
    notifictionUpdateSuccess: 'Bildirim başarılı bir şekilde güncellendi.',
    notifictionUpdateError: 'Bildirim güncellenirken bir hata oluştu.',
    completePackageOrder: 'Paket siparişini başarılı bir şekilde onayladınız.',
    completePackageOrderError: 'Paket siparişi reddedildi.',
    defaultSuccess: 'İşleminiz başarılı bir şekilde tamamlandı.',
    forgotPasswordSuccess:
      'Şifre sıfırlama linki başarılı bir şekide gönderildi.',
    successfulUpdate: '{value} başarılı bir şekilde güncellediniz.',
    successfulAdd: '{value} başarılı bir şekilde eklendi.',
    successfulDelete: '{value} başarılı bir şekilde kaldırıldı.',
    successfulArchive: '{value} başarılı bir şekilde arşivlendi.',
    successfulUnarchive: '{value} başarılı bir şekilde arşivden çıkarıldı.',
    successfulSave: '{value} başarılı bir şekilde kaydedildi.',
    successfulTest: 'Başarılı bir şekilde test edildi.',
    errorTest: 'Test işlemi başarısız oldu. Lütfen bilgileri kontrol ediniz.',
    alreadyBought: 'Bu ürünü/ürünleri zaten satın aldınız.',
    noData: 'Herhangi bir veri yok.',
    notFoundSearch: 'Herhangi bir sonuca ulaşılamadı.',
    listEmpty: 'Liste boş görünüyor.',
    noOffer:
      'Aradığınız kriterlere göre bir fiyat bulunamadı. İsterseniz tedarikçilerden fiyat talep edebilirsiniz.',
    noOfferSpotShipping: 'Henüz bir teklif bulunmamaktadır.',
    demandPriceSuccessfull: 'Fiyat isteğiniz başarılı bir şekilde ulaştırıldı.',
    approvedSuccessfully: 'Onaylama işlemi başarılı bir şekilde gerçekleşti.',
    areYouSureToApprove: 'Onaylamak istediğinize emin misiniz?',
    noSearch:
      'Arama geçmişinize boş görünüyor. İsterseniz yeni bir arama oluşturabilirsiniz.',
    rateSupplier: 'Tedarikçiniziden aldığınız hizmeti değerlendiriniz',
    ratedSuccessfully: 'Tedarikçiyi başarılı bir şekilde değerlendirdiniz.',
    noRequestSupplier: 'Henüz bir istek oluşturulmamış.',
    unexpectedError: 'Beklenmeyen bir hata oluştu.',
    noOrders: 'Sipariş listeniz boş görünüyor.',
    waitingForPayment: 'Ödeme Bekleniyor',
    paid: 'Ödendi',
    noPayments: 'Ödeme listeniz boş görünüyor.',
    verificationCodeSent:
      '*********{phone} nolu telefonunuza doğrulama kodu sms gönderildi.',
    needsToSignedByCustomer: 'Ödeme bekleniyor.',
    needsApprovementByCustomer: 'Siparişiniz müşteri onayı beklemektedir.',
    pleaseDoNotCloseThePage: 'Lütfen pencereyi kapatmayın veya yenilemeyin.',
    loadingText: 'İşleminiz yapılıyor',
    shouldRegister:
      'Detaylı arama yapabilmek ve fiyatları görebilmek için kayıt olabilirsiniz.',
    successfulAddExpress: 'Listeniz başarılı bir şekilde eklendi.',
    updateActiveExpress: 'Listenizin durumu başarılı bir şekilde güncellendi.',
    updateFSC: 'FSC oranı başarılı bir şekilde güncellendi.',
    changeFileSuccessful: 'Dosya başarılı bir şekilde değiştirildi.',
    changeTheExcelFile: 'Dosyayı değiştirmek istediğinize emin misiniz?',
    deleteCompanyUser: 'Yetkili kullanıcı başarılı bir şekilde kaldırıldı.',
    activationSuccessful:
      'Hesabınız başarılı bir şekilde aktif edildi. Aşağıdaki butona basarak giriş yapabilirsiniz.',
    activationFailed: 'Hesabınızın aktivasyon işlemi başarısız oldu.',
    noTransshipment: 'direkt aktarmasızdır.',
    withTransshipment: '{value} aktarmalıdır.',
    noUser: 'Henüz kayıt olan bir kullanıcı yok.',
    updateUserStatusSuccessful:
      'Kullanıcı durumu başarılı bir şekilde güncellendi.',
    updateUserStatus:
      'Kullanıcı durumunu güncellemek istediğinize emin misiniz?',
    updateCompanyStatus:
      'Şirket durumunu güncellemek istediğinize emin misiniz?',
    noUserInCompany: 'Henüz bu şirkete kayıtlı bir kullanıcı yok.',
    companyInPending:
      'Şirket hesabınız onay aşamasında. Şirketiniz onaylanınca size e-posta ile bildirilecektir.',
    waitingForApproval:
      'Hesabınız onay aşamasındadır. Onaylama işlemi tamamlandıktan sonra uygulamanın bütün özelliklerine erişim sağlayabileceksiniz.',
    noRemainingRefreshCount: 'Siparişleri Yenileme Hakkınız Bitmiştir',
    extraPayment: 'Ek ödeme',
    returnPayment: 'İade Ödemesi',
    waitingForPaymentApproval: 'Ödeme Onayı Bekleniyor',
    approvedPayments: 'Onaylananlar',
    closeTicket: 'Bu destek talebini kapatmak istediğinize emin misiniz?',
    addVolumeWarning:
      'İlk eklediğiniz hacim değerleri ile koli değerleri farklıdır. Kaydetmek istediğinize emin misiniz?',
    updateBoxesSuccessfully:
      'Eklediğiniz koli değerleri başarılı bir şekilde güncellenmiştir.',
    addBlacklistSuccessfully:
      'Kullanıcıyı başarılı bir şekilde kara listeye aldınız.',
    addPlanMessageSuccessfully:
      'Seçilen veriler başarılı bir şekilde güncellenmiştir.',
    removeBlacklistSuccessfully:
      'Kullanıcının engelini başarılı bir şekilde kaldırdınız.',
    sureToBlockUser: 'Bu kullanıcıyı engellemek istediğinize emin misiniz?',
    sureToApprovePayment: 'Bu ödemeyi onaylamak istediğinize emin misiniz?',
    noBlacklistedUser: 'Engellenmiş bir kullanıcı yok.',
    noWaitingForApprovalPayment: 'Onay bekleyen bir ödeme bulunmamaktadır.',
    noApprovedPayment: 'Onaylı bir ödeme bulunmamaktadır.',
    banUserSuccessfully: 'Kullanıcıyı başarılı bir şekilde engellediniz.',
    unbanUserSuccessfully:
      'Kullanıcının engelini başarılı bir şekilde kaldırdınız.',
    rejectPaymentApprovement:
      'Ödemeyi onayını geri almak istediğinize emin misiniz?',
    paymentRejected: 'Ödeme onayı geri alındı.',
    sendSupplier: 'Tedarikçiye gönderilmesini istiyorum.',
    noDepartureDate:
      'Çıkış tarihiniz boş görünüyor. Lütfen bir çıkış tarihi belirleyiniz.',
    createdShipmentSuccessfully: 'Gönderiniz başarılı bir şekilde oluşturuldu.',
    successfullLogin: 'Başarılı bir şekilde giriş yaptınız.',
    failedLogin: 'Yetkilendirme işlemi başarısız oldu.',
    maximumKg:
      'Bu fiyat girişinin paket başına maksimum brüt ağırlık {maximumKg} kg. olarak belirlenmiştir. Bu nedenle sipariş oluramazsınız.',
    mailHasBeenSentSuccessfully: 'İlgili mail başarılı bir şekilde iletildi.',
    noArrivalDate: '',

    dateTimeOfWarehouse:
      'Depo giriş ve çıkış tarihleri başarılı bir şekilde düzenlendi.',
    successfulUpdateGeneral: 'Başarılı bir şekilde güncellendi.',

    paymentProcessing: 'Ödemeniz işleniyor.',

    multipleEmailInformation:
      'Birden fazla e-posta adresi eklemek istiyorsanız noktalı virgül ile ayırarak yazabilirsiniz.',

    successfulUpdateUserRole:
      'Kullanıcıya ait yetki başarılı bir şekilde güncellendi.',

    oversizeMessage:
      'Kap boyutları girilmediği için oversize kontrolü yapılamadı. Sipariş detayında fiyatınız girilen paket detaylarına göre değişiklik gösterebilir.',
    longDistanceMessage:
      'Posta kodu girilmediği için uzak bölge kontrolü yapılamadı. Sipariş detayında fiyatınız girilen posta koduna göre değişiklik gösterebilir.',
    noPackageDetails:
      'Paket detayları girilmeden siparişi tedarikçiye gönderemezsiniz. Lütfen kontrol sağlayınız.',

    addedDevAPISuccessfully: 'API yapılandırması başarılı bir şekilde eklendi.',

    successfulAddGeneral: 'Başarılı bir şekilde eklendi.',

    succesfulUpdateWeeklyAverages:
      'Haftalık yükleme değerleriniz başarılı bir şekilde güncellendi.',
    listedAlready: 'Bu talebi zaten {price} olarak listelemiştiniz.',

    requiredFields: '{index}. paket için sağlamanız gereken veriler: {fields}',
    requiredFieldsWarning:
      'Siparişi kaydetmeden önce aşağı alanları doldurmanız gerekmektedir.',
    noGoodsTypeWarning:
      'Sipariş Açıklaması alanını doldurmanız gerekmektedir. Paket detaylarında birinci sırada yer alan paketin mal cinsi değeri otomatik Sipariş Açıklaması olarak doldurulsun mu?',
    envelopeWeightWarning:
      'Toplam ağırlığınız 0.5 kg olduğu için, paket tipini zarf olarak seçemezsiniz.',

    warningSelectReportKey: 'Lütfen bir adet rapor türü seçiniz.',
    successfulDelete: 'Başarılı bir şekilde kaldırıldı.',
    expressPolicyWarning:
      'Fiyat ararken seçeceğiniz taşıyıcının minimum fiyat ve kilogram politikasına göre fatura ağırlığınızda değişiklik olabilir.',
    expressWarning:
      'Tehlikeli ve istiflenemeyen ürünler express olarak taşınamaz.',

    warningOnDeleteIntegration:
      'Bu entegrasyona bağlı mevcut siparişler bulunmaktadır. Yine de silmek istediğinizden emin misiniz?',
    cloneOrderWarning: 'Bu siparişi çoğaltmak istediğinize emin misiniz?',
  },

  navigationDrawerMenu: {
    blockManagement: 'Engelleme Yönetimi',
    subscriptionManagement: 'Abonelik Yönetimi',
    transferEntry: 'EX. Navlun Farkı',
    adminPackageManagment: 'Paket Yönetimi',
    home: 'Ana Sayfa',
    priceChartEntry: 'Express Fiyat Girişi',
    expressPriceList: 'Express Fiyat Listesi',
    partialTransportation: 'Parsiyel Fiyat Girişi',
    outOfAreaEntry: 'Uzak Bölge Girişi',
    periodicCostEntry: 'Express Navlun Farkı Girişi',
    quickSearch: 'Hızlı Fiyat Arama',
    spotOffersSupplier: 'Fiyat Talepleri',
    offers: 'Tarifeler',
    spotOffersCustomer: 'Spot Tarifeler',
    searchHistory: 'Geçmiş Aramalarım',
    orders: 'Siparişlerim',
    ordersAdmin: 'Siparişler',
    marketplace: 'Pazar Yerleri',
    portalManagement: 'Portal Yönetimi',
    plannerManagement: 'Planlama Yönetimi',
    integrationManagement: 'Entegrasyon Yönetimi',
    countryManagement: 'Ülke Yönetimi',
    publicApi: 'Dev API',
    messages: 'Mesajlar',
    payments: 'Ödemeler',
    reports: 'Raporlar',
    company: 'Şirket Bilgileri',
    address: 'Adreslerim',
    profile: 'Profil',
    support: 'Yardım',
    userGuide: 'Site Kullanımı',
    faq: 'Sıkça Sorulan Sorular',
    settings: 'Ayarlar',
    help: 'Yardım',
    addCompany: 'Şirket Ekle',
    devSpecial: 'Developer Menüsü',
    logging: 'Loglama',
    companyManagement: 'Şirket Yönetimi',
    users: 'Kullanıcılar',
    admin: 'Admin',
    companies: 'Şirketler',
    timeOut: 'Zaman aşımı ayarları',
    marketplaceOrders: 'Entegrasyon Siparişleri',
    blacklist: 'Engellenen Kullanıcılar',
    approvePayment: 'Ödeme Onayı',
    transactionApproval: 'Havale Onayı',
    warehouse: 'Depo Yönetimi',
    dictionary: 'Sözlük',
    pricing: 'Fiyat Girişi',
    management: 'Yönetim',
    other: 'Diğer',
    library: 'Kütüphane',
    additionalCosts: 'Ek Maliyetler',
    accounting: 'Muhasebe Yönetimi',
    listOffers: 'Liste Tarifeler',
    currencyManagement: 'Kur Yönetimi',
    dynamicReports: 'Dinamik Raporlar',
    fixedReports: 'Sabit Raporlar',
    graphicalReports: 'Grafiksel Raporlar',
    generalReports: 'Genel Raporlar',
    locationManagement: 'Lokasyon Yönetimi',
  },
  hazmatCodes: {
    11: 'Parsiyel - Hava Yolu',
    15: 'Parsiyel - Demiryolu',
    25: 'Komple - Demiryolu',
    33: 'FCL - Denizyolu',
    43: 'LCL - Denizyolu',
    52: 'FTL - Karayolu',
    62: 'LTL - Karayolu',
  },
  adminFilters: {
    supplier: 'Tedarikçi Özeti',
    supplierDetail: 'Tedarikçi Detay Özeti',
    customer: 'Müşteri Özeti',
    customerDetail: 'Müşteri Detay Özeti',
    carriageType: 'Taşıma Türü Özeti',
  },
  adminHeaders: {
    SupplierName: 'Tedarikçi Adı',
    SumWeight: 'Toplam Ağırlık',
    SumPrice: 'Toplam Fiyat',
    SumCapPieces: 'Toplam Adet',
    ShipmentType: 'Gönderi Tipi',
    PosPayment: 'POS Ödemesi',
    PortalRate: 'Portal Oranı',
    PoolRate: 'Havuz Oranı',
    OrderCount: 'Sipariş Sayısı',
    MembershipType: 'Üyelik Türü',
    ExportImportType: 'İhracat/İthalat Türü',
    CustomerCount: 'Müşteri Sayısı',
    Commission: 'Komisyon',
    CustomerName: 'Müşteri Adı',
    CustomerType: 'Müşteri Türü',
    SearchCount: 'Arama Sayısı',
    SupplierCount: 'Tedarikçi Sayısı',
  },
  packageModal: {
    airlineCommission: 'Havayolu Navlun Komisyonu',
    seawayCommission: 'Denizyolu Navlun Komisyonu',
    roadCommission: 'Karayolu Navlun Komisyonu',
    railwayCommission: 'Demiryolu Navlun Komisyonu',
    expressCommission: 'Express Navlun Komisyonu',
    domesticCommission: 'Yurtiçi Navlun Komisyonu',
    portalCommission: 'Portal Komiyonu',
    integrationCommission: 'Entegrasyon Komisyonu',
    portalCommision: 'Portal Komisyonu',
    shipmentCommision: 'Gönderi Komisyonu',
  },
};
