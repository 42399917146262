// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'admin';

export default {
  getWarnings() {
    return axios.get(`${RESOURCE_NAME}/order-warnings-get`);
  },
  updateWarnings(body) {
    return axios.put(`${RESOURCE_NAME}/order-warnings-update`, body);
  },
  updateApproveOrderPayment (body) {
    return axios.put(`${RESOURCE_NAME}/update-approve-order-payment`, body);
  },

  /**
   * Block a company from admin account.
   * @param {{ CompanyId: string, CompanyLoginPermission: boolean }} body 
   * @returns {Promise}
   */
  updateCompanyLoginPermission (body) {
    return axios.put(`${RESOURCE_NAME}/updateCompanyLoginPermission`, body);
  },
};
