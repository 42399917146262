<template>
  <v-card elevation="1">
    <v-card-title class="d-flex flex-row" style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="12" cols="12">
          <h3>{{ $t(`navigationDrawerMenu.${$attrs.key}`) }}</h3>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider />
    <v-card-text></v-card-text>
    
  </v-card>
</template>
<script>
export default {
  data() {
    return {
    }
  },
};
</script>
<style></style>
