<template>
  <div>
    <v-dialog
      v-model="showCarrierModal"
      max-width="600"
      @input="resetCarrierModal"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
        >
          <h3>{{ $t('buttons.callCarrier') }}</h3>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver
          v-slot="{ handleSubmit }"
          ref="carrierModalObserver"
        >
          <v-card-text>
            <form @submit.prevent="handleSubmit(submitCarrier)">
              <v-row>
                <FormElement
                  v-for="(element, index) in carrierFormItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :topLabel="element.topLabel"
                  :active="element.active"
                  :placeholder="$t(element.placeholder)"
                />
              </v-row>
              <v-card-actions class="text-center justify-center">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;
                    padding: 1vh 1vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  type="submit"
                  class="darken-1"
                >
                  {{
                    isCarrierEdit ? $t('buttons.update') : $t('buttons.save')
                  }}
                </v-btn>
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: var(--red);
                    padding: 1vh 1vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-2"
                  @click="resetCarrierModal"
                >
                  {{ $t('buttons.close') }}
                </v-btn>
              </v-card-actions>
            </form>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- SUBMIT ADDRESS FORM MODAL -->
    <v-dialog
      v-model="cancelOrderModal"
      max-width="600"
      @input="resetCancelModal"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
        >
          <h3>{{ $t('buttons.cancelOrder') }}</h3>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver v-slot="{ handleSubmit }" ref="cancelModalObserver">
          <v-card-text>
            <form @submit.prevent="handleSubmit(cancelOrder)">
              <v-row>
                <FormElement
                  :vertical="true"
                  v-model="selectedReason"
                  :validationLabel="$t('labels.cancelReason')"
                  rules="required"
                  :topLabel="true"
                  :labelShown="false"
                  :lg="12"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: cancelReasons,
                      value: 'value',
                      text: 'name',
                    },
                  }"
                  :placeholder="$t('labels.cancelReason')"
                />
                <FormElement
                  v-model="cancelDescription"
                  :lg="12"
                  rules="required"
                  :validationLabel="$t('labels.cancelDescription')"
                  :labelShown="false"
                  :placeholder="$t('labels.cancelDescription')"
                  :formType="{ name: 'textarea' }"
                />
              </v-row>
              <v-card-actions class="text-center justify-center">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;
                    padding: 1vh 1vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  type="submit"
                  class="darken-1"
                >
                  {{ $t('buttons.approve') }}
                </v-btn>
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: var(--red);
                    padding: 1vh 1vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-2"
                  @click="resetCancelModal"
                >
                  {{ $t('buttons.close') }}
                </v-btn>
              </v-card-actions>
            </form>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-dialog v-model="submitAddressFormModal" max-width="600">
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
        >
          <h3>
            {{
              $t(
                `labels.${editAddressMode ? 'editSubCompany' : 'addSubCompany'}`
              )
            }}
          </h3>
        </v-card-title>
        <v-divider class="mb-4" />
        <ValidationObserver v-slot="{ handleSubmit }" ref="addressFormObserver">
          <v-card-text class="">
            <form @submit.prevent="handleSubmit(submitAddressForm)">
              <v-row>
                <FormElement
                  v-for="(element, index) in addressFormItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :lg="element.lg"
                  :cols="element.cols"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :topLabel="element.topLabel"
                  :active="element.active"
                  :placeholder="$t(element.placeholder)"
                  @openURL="handleOpenURL"
                />
              </v-row>
              <v-row class="mb-4">
                <v-col
                  cols="12"
                  class="d-flex flex-row px-2"
                  style="padding: 0"
                >
                  <v-checkbox
                    style="padding: 0; margin: 0"
                    hide-details
                    v-model="addressForm.IsHomeAddress"
                  ></v-checkbox>
                  <label> {{ $t('labels.isHome') }} </label>
                </v-col>
              </v-row>
              <v-card-actions class="text-center justify-center">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;
                    padding: 1vh 1vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  type="submit"
                >
                  {{ $t('buttons.save') }}
                </v-btn>
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: var(--red);
                    padding: 1vh 1vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-2"
                  @click="submitAddressFormModal = false"
                >
                  {{ $t('buttons.close') }}
                </v-btn>
              </v-card-actions>
            </form>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- SUBMIT ADDRESS FORM MODAL END -->

    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('messages.addVolumeWarning')"
      :confirm="confirmation.onConfirm"
      v-model="confirmation.visible"
    />
    <v-dialog v-model="addOrderModal" max-width="1200">
      <v-stepper v-model="stepper" alt-labels>
        <div
          class="d-flex justify-space-between"
          style="padding: 10px; background-color: #fff !important"
        >
          <div class="popupHeadline" style="font-weight: bold">
            {{ $t('buttons.createOrder') }}
          </div>
          <v-btn
            elevation="0"
            type="button"
            @click="addOrderModal = false"
            fab
            style="width: 20px; height: 20px; padding: 14px"
            dark
            color="red"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </div>
        <v-stepper-header>
          <v-stepper-step step="1">
            {{ $t('titles.quickSearch') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="2">
            {{ $t('labels.results') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3">
            {{ $t('buttons.createOrder') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <ValidationObserver
              v-if="searching"
              ref="addOrderObserver"
              v-slot="{ handleSubmit }"
            >
              <form @submit.prevent="handleSubmit(searchOffer)">
                <v-card class="popup-card" elevation="1">
                  <v-card-title
                    v-if="false"
                    class="popupHeadline"
                    style="font-size: 24px"
                  >
                    <div class="text-center popupHeadline" style="width: 100%">
                      {{ $t('buttons.add') }}
                    </div>
                  </v-card-title>
                  <v-divider class="mb-4" />
                  <v-card-text>
                    <v-row class="mb-4">
                      <FormElement
                        v-for="(element, index) in formItems"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :active="element.active"
                        :topLabel="element.topLabel"
                        :placeholder="$t(element.placeholder)"
                      />
                      <v-col cols="12">
                        <v-row>
                          <FormElement
                            v-for="(element, index) in form_expressTypes"
                            :key="
                              element.key.parent +
                              '_' +
                              element.key.child +
                              '_' +
                              index
                            "
                            v-model="
                              $data[element.key.parent][element.key.child]
                            "
                            :validationLabel="$t(element.validationLabel)"
                            :rules="element.rules"
                            :formLabel="$t(element.formLabel)"
                            :onChange="element.onChange"
                            :formType="element.formType"
                            :lg="element.lg"
                            :cols="element.cols"
                            :disabled="element.disabled"
                            :hidden="element.hidden"
                            :labelShown="element.labelShown"
                            :active="element.active"
                            :topLabel="element.topLabel"
                            :placeholder="$t(element.placeholder)"
                          />
                        </v-row>
                      </v-col>
                      <fragment
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-for="(element, index) in form_company"
                      >
                        <FormElement
                          v-if="!element.onClick"
                          v-model="$data[element.key.parent][element.key.child]"
                          :validationLabel="$t(element.validationLabel)"
                          :rules="element.rules"
                          :formLabel="$t(element.formLabel)"
                          :onChange="element.onChange"
                          :formType="element.formType"
                          :lg="element.lg"
                          :cols="element.cols"
                          :sm="element.sm"
                          :disabled="element.disabled"
                          :hidden="element.hidden"
                          :labelShown="element.labelShown"
                          :active="element.active"
                          :topLabel="element.topLabel"
                          :placeholder="$t(element.placeholder)"
                        />
                        <v-col
                          v-else
                          :cols="2"
                          :sm="2"
                          :md="1"
                          :lg="1"
                          :xl="1"
                          style="padding: 0"
                        >
                          <v-btn
                            :disabled="element.disabled"
                            class="rounded-lg"
                            @click="element.onClick"
                            style="
                              width: 95%;
                              height: 65%;
                              min-width: 0px;
                              min-height: 0px;
                              background-color: #235c9e;
                              color: #ffffff;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            <v-icon> {{ element.icon }} </v-icon>
                          </v-btn>
                        </v-col>
                      </fragment>

                      <FormElement
                        v-for="(element, index) in formContainerDetails"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :active="element.active"
                        :topLabel="element.topLabel"
                        :placeholder="$t(element.placeholder)"
                      />
                    </v-row>
                    <AddVolume
                      isFirstSearch
                      :onSave="onSaveVolumes"
                      :calculateParameter="calculateParameter"
                      :calculateChargeableWeight="calculateChargeableWeight"
                      :isCard="true"
                      :volumesByUser="form.volumes"
                      :read-only="form.ExpressPackageType === 2"
                      :key="`ADD_ORDER_VOLUME_CARD_${form.volumes.length}`"
                    />
                  </v-card-text>
                  <v-card-actions v-if="searching" class="justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: green;

                        padding: 1vh 2.5vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                    >
                      <v-icon dark> mdi-magnify </v-icon>
                      {{ $t('buttons.searchPrice') }}</v-btn
                    >
                  </v-card-actions>
                  <v-card-actions v-else class="justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;

                        padding: 1vh 1vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                      >{{ $t('buttons.save') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 1vh 1vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="addOrderModal = false"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </form>
            </ValidationObserver>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card class="popup-card" elevation="1">
              <v-card-text>
                <v-row class="mt-5" v-if="searchResults.length > 0">
                  <FormElement
                    key="FORM_ELEMENT_SEARCH_RESULTS"
                    v-model="selectedSearchResult"
                    :validationLabel="$t('labels.results')"
                    rules="required"
                    :formLabel="$t('labels.results')"
                    :formType="{
                      name: 'multipleSelect',
                      items: {
                        data: searchResults,
                        text: (item) =>
                          `${item.ListName} - ${
                            item.Supplier
                          } - ${$getFormattedFloatValue(item.Price)} ${
                            item.PriceCurrency
                          }`,
                        value: 'ListId',
                        disableAutoSelection: true,
                      },
                      returnObject: true,
                    }"
                    :lg="6"
                    :cols="6"
                    :labelShown="true"
                    :topLabel="true"
                    :placeholder="$t('labels.results')"
                  />
                </v-row>
              </v-card-text>
              <v-card-actions v-if="searching" class="justify-center">
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;

                    padding: 1vh 2.5vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  type="button"
                  @click="stepper = 1"
                >
                  <v-icon dark> mdi-arrow-left </v-icon>
                  {{ $t('buttons.back') }}
                </v-btn>
                <v-btn
                  style="
                    color: #fff;
                    text-transform: unset !important;
                    background-color: #0077d5;

                    padding: 1vh 2.5vh;
                    border-radius: 30px !important;
                    font-weight: bolder;
                    font-size: medium;
                  "
                  elevation="0"
                  class="darken-1"
                  type="button"
                  @click="stepper = 3"
                  :disabled="!selectedSearchResult"
                  :loading="$store.state.loadingStatus"
                >
                  {{ $t('buttons.next') }}
                  <v-icon dark> mdi-arrow-right </v-icon></v-btn
                >
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3">
            <ValidationObserver
              ref="createOrderFormObserver"
              v-slot="{ handleSubmit }"
            >
              <form @submit.prevent="handleSubmit(createOrder)">
                <v-card class="popup-card" elevation="1">
                  <v-card-title class="popupHeadline" style="font-size: 24px">
                    <div class="text-center popupHeadline" style="width: 100%">
                      {{ $t('buttons.createOrder') }}
                    </div>
                  </v-card-title>
                  <v-divider class="mb-4" />
                  <v-card-text>
                    <v-row>
                      <FormElement
                        v-for="(element, index) in createOrderFormItems"
                        :key="
                          element.key.parent +
                          '_' +
                          element.key.child +
                          '_' +
                          index
                        "
                        v-model="$data[element.key.parent][element.key.child]"
                        :validationLabel="$t(element.validationLabel)"
                        :rules="element.rules"
                        :formLabel="$t(element.formLabel)"
                        :onChange="element.onChange"
                        :formType="element.formType"
                        :lg="element.lg"
                        :cols="element.cols"
                        :disabled="element.disabled"
                        :hidden="element.hidden"
                        :labelShown="element.labelShown"
                        :active="element.active"
                        :topLabel="element.topLabel"
                        :placeholder="$t(element.placeholder)"
                        :classes="element.classes"
                      />
                    </v-row>
                  </v-card-text>
                  <v-card-actions v-if="searching" class="justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;

                        padding: 1vh 2.5vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="button"
                      @click="stepper = 2"
                    >
                      <v-icon dark> mdi-arrow-left </v-icon>
                      {{ $t('buttons.back') }}
                    </v-btn>
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: green;

                        padding: 1vh 2.5vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                    >
                      {{ $t('buttons.createOrder') }}</v-btn
                    >
                  </v-card-actions>
                  <v-card-actions v-else class="justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;

                        padding: 1vh 1vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      :loading="$store.state.loadingStatus"
                      >{{ $t('buttons.save') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 1vh 1vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="addOrderModal = false"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </form>
            </ValidationObserver>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>
    <!-- Page content -->
    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row align="center">
            <!-- Trick -->
            <v-col
              lg="5"
              xl="5"
              v-if="$vuetify.breakpoint.lg || $vuetify.breakpoint.xl"
            ></v-col>
            <v-col class="text-center" cols="12" lg="2" xl="2">
              <h3>{{ $t('labels.orders') }}</h3>
            </v-col>
            <v-col
              cols="12"
              lg="5"
              xl="5"
              style="gap: 10px"
              class="d-flex flex-sm-row flex-column justify-center align-center justify-lg-end"
            >
              <v-btn
                v-if="Object.values(filters).some((item) => !!item)"
                variant="tonal"
                color="#0177d4"
                class="button"
                style="color: white"
                @click="resetFilter"
              >
                <v-icon>mdi-trash-can</v-icon>
                {{ $t('buttons.clearFilter') }}
              </v-btn>
              <v-tooltip bottom v-if="!isSupplier">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="color: white; background-color: var(--pink)"
                    class="button"
                    v-bind="attrs"
                    v-on="on"
                    @click="addOrderModal = true"
                  >
                    <v-icon>mdi-plus</v-icon>
                    {{ $t('buttons.createOrder') }}
                  </v-btn>
                </template>
                <span>{{ $t('buttons.createOrder') }}</span>
              </v-tooltip>
              <RefreshButton :refreshFunction="getTableList"></RefreshButton>
            </v-col>
          </v-row>
        </v-card-title>
      </div>

      <v-divider />

      <v-card-text fill-height fluid>
        <v-row align="center" class="d-flex">
          <v-col cols="12" class="align-center">
            <v-row class="align-center pt-3">
              <FormElement
                v-for="(element, index) in filters_formItems"
                :key="
                  element.key.parent + '_' + element.key.child + '_' + index
                "
                :willBeEliminated="false"
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :rules="element.rules"
                :formLabel="$t(element.formLabel)"
                :onChange="element.onChange"
                :formType="element.formType"
                :lg="element.lg"
                :md="element.md"
                :sm="element.sm"
                :xl="element.xl"
                :cols="element.cols"
                :hidden="element.hidden"
                :labelShown="false"
                :topLabel="true"
                :active="element.active"
                :placeholder="$t(element.placeholder)"
                @input="getSearchDebounced"
              />
            </v-row>
          </v-col>

          <v-row class="align-center"> </v-row>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="tableData"
          :loading="loadings.table"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          ref="orderList"
        >
          <!-- eslint-disable -->

          <template v-slot:item.Customer="{ item }">
            <div
              class="pt-2 d-flex flex-column justify-center items-center align-center"
            >
              <img
                :src="item.CustomerLogo"
                alt="Logo"
                style="width: 60px; height: auto"
              />
              {{ item.Customer }}
            </div>
          </template>

          <template v-slot:item.Carrier="{ item }">
            <div
              class="pt-2 d-flex flex-column justify-center items-center align-center"
            >
              <img
                :src="item.CarrierLogo"
                alt="Logo"
                style="width: 60px; height: auto"
              />
              {{ item.Carrier }}
            </div>
          </template>
          <template v-slot:item.Source="{ item }">
            <div
              class="pt-2 d-flex flex-column justify-center items-center align-center"
            >
              <img
                :src="item.SourceCompanyLogo"
                alt="Logo"
                style="width: 60px; height: auto"
              />
              {{ item.SourceCompany }}
            </div>
          </template>
          <template class="w-min" v-slot:item.FromToWhere="{ item }">
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.From }}
            </div>
            <span>-</span>
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.To }}
            </div>
          </template>

          <template class="w-min" v-slot:item.CarrierLogo="{ item }">
            <div>
              <img
                :src="item.CarrierLogo"
                alt="Logo"
                style="width: 50px; height: auto"
              />
            </div>
          </template>
          <template v-slot:item.ShipmentType="{ item }">
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.ShipmentType }}
            </div>
            <span v-if="item.ShipmentType !== 'Express'">
              <span>-</span>
              <div style="white-space: normal; word-wrap: break-word">
                {{ item.LoadingType }}
              </div>
            </span>
          </template>
          <template v-slot:item.CreatedOnUtc="{ item }">
            {{ convertToLocal(item.CreatedOnUtc) }}
          </template>
          <template v-slot:item.OrderType="{ item }">
            {{ orderTypes[item.OrderType] }}
          </template>
          <template v-slot:item.OrderStatus="{ item }">
            <v-chip
              v-if="item.OrderStatus == 0"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.detail') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 1"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.supplier') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 2"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.payment') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 3"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.approved') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 4"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.loaded') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 5"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.shipping') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 6"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.inDestination') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 7"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.delivered') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 8"
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.labelsOrderStatuses.archived') }}
            </v-chip>
            <v-chip
              v-if="item.OrderStatus == 9"
              class="ma-2"
              small
              color="#E72929"
              text-color="white"
              style="font-weight: 600"
            >
              {{ $t('labels.labelsOrderStatuses.canceled') }}
            </v-chip>
          </template>
          <template v-slot:item.SupplierOrderStatus="{ item }">
            <div v-if="item.OrderStatus == 9">
              <v-chip
                class="ma-2"
                small
                color="#E72929"
                text-color="white"
                style="font-weight: 600"
              >
                {{ $t('labels.labelsOrderStatuses.canceled') }}
              </v-chip>
            </div>
            <div v-else>
              <v-chip
                v-if="item.SupplierOrderStatus == 0"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.notPlanned') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 1"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.pickupPlanned') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 2"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.inWarehouse') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 3"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.loading') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 4"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.invoiced') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 5"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.atDestination') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus == 6"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.fileStatuses.delivered') }}
              </v-chip>
              <v-chip
                v-if="item.SupplierOrderStatus === null"
                style="font-weight: 600"
                class="ma-1"
                small
                color="#689fd5"
                text-color="#FFFFFF"
              >
                {{ $t('labels.notAvailable') }}
              </v-chip>
            </div>
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="d-flex text-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="goOrderDetail(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipEdit') }}</span>
              </v-tooltip>
              <v-tooltip top v-if="item.OrderStatus !== 9">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="var(--red)"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="triggerCancelOrder(item)"
                  >
                    mdi-cancel
                  </v-icon>
                </template>
                <span>{{ $t('labels.cancelOrder') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-if="item.LabelStatus && item.OrderStatus != 9"
                    color="#29B7B7"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="downloadTag(item)"
                    style="font-size: 20px"
                  >
                    mdi-barcode
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipDownloadLabel') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.OrderStatus == 2 && item.OrderStatus != 9"
                    small
                    color="#29B7B7"
                    @click="goToPayments()"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    style="font-size: 20px"
                  >
                    mdi-credit-card
                  </v-icon>
                </template>
                <span>{{ $t('buttons.goPayment') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.OrderStatus == 7 && !item.IsArchive"
                    small
                    color="#29B7B7"
                    @click="toggleArchive(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    style="font-size: 20px"
                  >
                    mdi-archive-arrow-down
                  </v-icon>
                </template>
                <span>{{ $t('buttons.setArchive') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.IsArchive"
                    small
                    color="#29B7B7"
                    @click="toggleArchive(item)"
                    v-bind="attrs"
                    v-on="on"
                    class="mr-1"
                    style="font-size: 20px"
                  >
                    mdi-archive-arrow-up
                  </v-icon>
                </template>
                <span>{{ $t('buttons.unarchive') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="
                      item.OrderStatus == 7 &&
                      (userRole == 'Customer' ||
                        userRole == 'PendingCustomer') &&
                      item.IsRate &&
                      item.OrderStatus != 9
                    "
                    small
                    color="#29B7B7"
                    @click="goRateScreen(item)"
                    v-bind="attrs"
                    v-on="on"
                    style="font-size: 20px"
                  >
                    mdi-star
                  </v-icon>
                </template>
                <span>{{ $t('labels.rate') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="messageToUser(item.Id)"
                  >
                    mdi-email
                  </v-icon>
                </template>
                <span>{{ $t('buttons.sendAMessage') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="
                      (userRole == 'Customer' ||
                        userRole == 'PendingCustomer') &&
                      item.OrderStatus == 3
                    "
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="callCarrier(item)"
                  >
                    mdi-account-tie-hat
                  </v-icon>
                </template>
                <span>{{ $t('buttons.callCarrier') }}</span>
              </v-tooltip>

              <BlockUser
                v-if="isSupplier"
                :customer="{
                  customerId: item.CustomerCompanyId,
                  fullName: item.Customer,
                }"
                :dataTable="true"
              />
            </div>
          </template>
        </v-data-table>

        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="rowsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage()"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import OrderService from '../services/OrderService';
import RefreshButton from '../components/RefreshButton.vue';
import MessageServices from '../services/MessageServices';
import BlockUser from '../components/BlockUser.vue';
import FormElement from '../components/FormElement.vue';
import {
  getCountries,
  convertVolumeToBoxes,
  getAirports,
  getCurrencyTypes,
  getCities,
  extractDateFromObject,
} from '../utils/constants';
import rules, { authorizedUser, company } from '../utils/validationRules';
import CompanyServices from '../services/CompanyServices';
import AddVolume from '../views/OfferScreens/AddVolume.vue';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import SearchServices from '../services/SearchServices';
import ShipmentService from '../services/ShipmentService';
import AddressService from '../services/AddressService';

import { openRouteInNewTab } from '../utils/common';
import CarrierService from '../services/CarrierService';

const USA_ID = 'ccf580f9-25e0-4aac-9216-6741eeece89e';
const CANADA_ID = '8e1060f5-9433-4b64-9e8f-3da864f7cac6';

const stateRequiredCountries = [USA_ID, CANADA_ID];
const { ...INITIAL_FILTERS } = {
  StartDate: null,
  EndDate: null,
  OrderStatus: null,
  PaymentStatus: null,
  TransportType: null,
  HasBoxes: null,
  CompanyName: null,
  RefNo: null,
  TrackingNo: null,
  From: null,
  To: null,
  Id: null,
  SupplierOrderStatus: null,
};

export default {
  data: () => ({
    options: {
      itemsPerPage: 15,
    },
    tableData: [],
    headers: [],
    userRole: '',
    top: 15,
    search: '',
    loadings: {
      table: false,
      select: false,
      send: false,
    },
    countryItems: [],
    orderStatuses: [],
    supplierOrderStatuses: [],
    shipmentTypes: [],
    totalItems: 0,
    debounceTimeout: null,
    cancelOrderModal: false,
    selectedReason: null,
    selectedOrder: null,
    cancelDescription: '',
    rowsPerPage: 15,
    page: 1,
    perPageValues: [
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
    cancelReasons: [],
    isSupplier: false,
    isAdmin: false,

    orderTypes: ['Manuel', 'Etsy', 'Amazon'],

    // Order
    addOrderModal: false,
    form: {
      ServiceType: 1,
      ExportImportType: 1,
      volumes: [],
    },
    orderForm: {},
    taxPayers: [],
    subCompanies: [],
    calculateParameter: 166.67,
    transferType: 2,
    searching: true,
    searchResults: [],
    selectedSearchResult: null,
    confirmation: {
      onConfirm: () => {},
      visible: false,
    },
    airports: [],
    fastShippingSearchId: null,

    stepper: 1,
    purposeOfShippingItems: [],
    currencies: [],

    // Subcompany
    submitAddressFormModal: false,
    addressForm: {},
    cities: [],
    phonePrefix: null,
    editAddressMode: false,
    addressKey: '',
    addresses: {
      LoadingCompany: '',
      BuyerCompany: '',
    },

    sourceCompanies: [],
    carrierInfo: {
      id: '',
      name: '',
      address: '',
      phone: '',
      email: '',
      date: '',
    },
    showCarrierModal: false,
    isCarrierEdit: false,

    filters: {
      StartDate: null,
      EndDate: null,
      OrderStatus: null,
      PaymentStatus: null,
      TransportType: null,
      HasBoxes: null,
      CompanyName: null,
      RefNo: null,
      TrackingNo: null,
      From: null,
      To: null,
      Id: null,
    },
  }),
  components: {
    RefreshButton,
    BlockUser,
    FormElement,
    AddVolume,
    ConfirmationModal,
  },
  async mounted() {
    this.getSubCompanies();

    this.purposeOfShippingItems = this.$root.purposeOfShippingList;

    this.taxPayers = [
      {
        name: this.$t('labels.dap'),
        value: 0,
      },
      {
        name: this.$t('labels.ddp'),
        value: 1,
      },
    ];

    this.cancelReasons = [
      {
        name: this.$t('labels.Other'),
        value: 0,
      },
      {
        name: this.$t('labels.ConfirmationNotRecived'),
        value: 1,
      },
      {
        name: this.$t('labels.DocumentsMissing'),
        value: 2,
      },
      {
        name: this.$t('labels.InformationChanged'),
        value: 3,
      },
      {
        name: this.$t('labels.PaymentNotReceived'),
        value: 4,
      },
      {
        name: this.$t('labels.DeliveryProblems'),
        value: 5,
      },
    ];

    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';

    this.isAdmin = this.$store.state.Type === 'Admin';

    this.shipmentTypes = [
      {
        name: this.$t('labels.All'),
        value: null,
      },
      {
        name: this.$t('labels.cargo'),
        value: 1,
      },
      {
        name: this.$t('labels.express'),
        value: 2,
      },
    ];

    this.orderStatuses = [
      {
        name: this.$t('labels.All'),
        value: null,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.detail'),
        value: 0,
      },
      // {
      //   name: this.$t('labels.labelsOrderStatuses.supplier'),
      //   value: 1,
      // },
      // {
      //   name: this.$t('labels.labelsOrderStatuses.payment'),
      //   value: 2,
      // },
      {
        name: this.$t('labels.labelsOrderStatuses.approved'),
        value: 3,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.loaded'),
        value: 4,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.shipping'),
        value: 5,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.inDestination'),
        value: 6,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.delivered'),
        value: 7,
      },
      {
        name: this.$t('labels.labelsOrderStatuses.archived'),
        value: 8,
      },
    ];
    this.supplierOrderStatuses = [
      {
        name: this.$t('labels.fileStatuses.notPlanned'),
        value: 0,
      },
      {
        name: this.$t('labels.fileStatuses.pickupPlanned'),
        value: 1,
      },
      {
        name: this.$t('labels.fileStatuses.inWarehouse'),
        value: 2,
      },
      {
        name: this.$t('labels.fileStatuses.loading'),
        value: 3,
      },
      {
        name: this.$t('labels.fileStatuses.invoiced'),
        value: 4,
      },
      {
        name: this.$t('labels.fileStatuses.atDestination'),
        value: 5,
      },
      {
        name: this.$t('labels.fileStatuses.delivered'),
        value: 6,
      },
    ];
    this.perPageValues.push({
      name: this.$t('labels.All'),
      value: 9999,
    });

    this.userRole = this.$store.getters.getUserType;
    this.setup();
    this.getCountries();
    this.getTableList();

    this.currencies = await getCurrencyTypes();

    this.getSourceCompanies();
  },
  watch: {
    submitAddressFormModal(newValue) {
      if (!newValue) {
        this.addressForm = {};
        this.editAddressMode = false;
        this.$refs.addressFormObserver.reset();

        this.addressKey = '';
      }
    },
    addOrderModal(newValue) {
      if (!newValue) {
        this.stepper = 1;
        this.form = {
          ServiceType: 1,
          ExportImportType: 1,
          volumes: [],
        };
        this.selectedSearchResult = null;
        this.orderForm = {};
        this.$refs.addOrderObserver.reset();
        this.$refs.createOrderFormObserver.reset();
        this.searchResults = [];
      }
    },
  },
  computed: {
    // Filter Form Items
    filters_formItems() {
      return [
        {
          key: {
            parent: 'filters',
            child: 'TrackingNo',
          },
          validationLabel: 'titles.integration.trackingNumber',
          formLabel: 'titles.integration.trackingNumber',
          formType: {
            name: 'text',
            dense: true,
            appendIcon: 'mdi-magnify',
            appendClick: this.getSearchDebounced,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'titles.integration.trackingNumber',
          classes: 'mb-1 mb-sm-0',
        },
        {
          key: {
            parent: 'filters',
            child: 'RefNo',
          },
          validationLabel: 'labels.referenceNumber',
          formLabel: 'labels.referenceNumber',
          formType: {
            name: 'text',
            dense: true,
            appendIcon: 'mdi-magnify',
            appendClick: this.getSearchDebounced,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'labels.referenceNumber',
          classes: 'mb-1 mb-sm-0',
        },
        {
          key: {
            parent: 'filters',
            child: 'CompanyName',
          },
          validationLabel: 'labels.companyName',
          formLabel: 'labels.companyName',
          formType: {
            name: 'text',
            dense: true,
            appendIcon: 'mdi-magnify',
            appendClick: this.getSearchDebounced,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'labels.companyName',
          classes: 'mb-1 mb-sm-0',
        },
        {
          active: !this.isSupplier,
          key: {
            parent: 'filters',
            child: 'Source',
          },
          validationLabel: 'labels.sourceCompany',
          formLabel: 'labels.sourceCompany',
          formType: {
            name: 'multipleSelect',
            dense: true,
            items: {
              data: this.sourceCompanies,
              text: 'Name',
              value: 'Id',
              disableAutoSelection: true,
            },
            appendIcon: 'mdi-magnify',
            appendClick: this.getSearchDebounced,
          },
          onChange: this.getSearchDebounced,
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'labels.sourceCompany',
          classes: 'mb-1 mb-sm-0',
        },
        {
          key: {
            parent: 'filters',
            child: 'TransportType',
          },
          validationLabel: 'labels.shipmentType',
          formLabel: 'labels.shipmentType',
          formType: {
            name: 'select',
            dense: true,
            items: {
              data: this.shipmentTypes,
              text: 'name',
              value: 'value',
            },
            onChange: this.filterContent,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'labels.shipmentType',
          classes: 'mb-1 mb-sm-0',
        },
        {
          key: {
            parent: 'filters',
            child: 'From',
          },
          validationLabel: 'titles.from',
          formLabel: 'titles.from',
          formType: {
            name: 'multipleSelect',
            dense: true,
            items: {
              data: this.countryItems,
              text: 'Name',
              value: 'Id',
            },
            onChange: this.filterContent,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'titles.from',
          classes: 'custom-border',
        },
        {
          key: {
            parent: 'filters',
            child: 'To',
          },
          validationLabel: 'titles.to',
          formLabel: 'titles.to',
          formType: {
            name: 'multipleSelect',
            dense: true,
            items: {
              data: this.countryItems,
              text: 'Name',
              value: 'Id',
            },
            onChange: this.filterContent,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'titles.to',
          classes: 'custom-border',
        },
        {
          key: {
            parent: 'filters',
            child: 'OrderStatus',
          },
          validationLabel: 'labels.orderStatus',
          formLabel: 'labels.orderStatus',
          formType: {
            name: 'select',
            dense: true,
            items: {
              data: this.orderStatuses,
              text: 'name',
              value: 'value',
            },
            onChange: this.filterContent,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'labels.orderStatus',
          classes: 'custom-border',
          active: !this.isSupplier,
        },
        {
          key: {
            parent: 'filters',
            child: 'SupplierOrderStatus',
          },
          validationLabel: 'labels.fileStatus',
          formLabel: 'labels.fileStatus',
          formType: {
            name: 'select',
            dense: true,
            items: {
              data: this.supplierOrderStatuses,
              text: 'name',
              value: 'value',
            },
            onChange: this.filterContent,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 2,
          xl: 2,
          placeholder: 'labels.fileStatus',
          classes: 'custom-border',
          active: this.isSupplier,
        },
        {
          key: {
            parent: 'filters',
            child: 'StartDate',
          },
          validationLabel: 'labels.startDate',
          formLabel: 'labels.startDate',
          formType: {
            name: 'date',
            dateMode: 'date',
            dense: true,
            onChange: this.filterContent,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 3,
          xl: 2,
          placeholder: 'labels.startDate',
          classes: 'custom-border',
        },
        {
          key: {
            parent: 'filters',
            child: 'EndDate',
          },
          validationLabel: 'labels.endDate',
          formLabel: 'labels.endDate',
          formType: {
            name: 'date',
            dateMode: 'date',
            dense: true,
            onChange: this.filterContent,
          },
          cols: 6,
          sm: 4,
          md: 3,
          lg: 3,
          xl: 2,
          placeholder: 'labels.endDate',
          classes: 'custom-border',
        },
      ];
    },

    isStateCodeRequired() {
      if (!this.addressForm.CountryId) return false;

      return (
        stateRequiredCountries.findIndex(
          (element) => element === this.addressForm.CountryId
        ) > -1
      );
    },
    carrierFormItems() {
      return [
        {
          key: {
            parent: 'carrierInfo',
            child: 'name',
          },
          rules: company,
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'carrierInfo',
            child: 'address',
          },
          rules: 'required',
          validationLabel: 'labels.address1',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.address1',
        },
        {
          key: {
            parent: 'carrierInfo',
            child: 'phone',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prefix: this.phonePrefix,
            autocomplete: 'new-password',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'carrierInfo',
            child: 'email',
          },
          validationLabel: 'labels.email',
          rules: 'required|email|max:50',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.email',
        },
        {
          key: {
            parent: 'carrierInfo',
            child: 'date',
          },
          validationLabel: 'labels.dateAsNoun',
          rules: 'required',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.dateAsNoun',
        },
      ];
    },
    addressFormItems() {
      return [
        {
          key: {
            parent: 'addressForm',
            child: 'Name',
          },
          rules: 'required',
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'Address',
          },
          rules: 'required',
          validationLabel: 'labels.address1',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.address1',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'CountryId',
          },
          rules: 'required',
          validationLabel: 'labels.country',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countryItems,
              value: 'Id',
              text: 'Name',
            },
          },
          onChange: () => {
            this.onChangeCountry();
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.country',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'CityId',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'AuthorizedPerson',
          },
          rules: authorizedUser,
          validationLabel: 'labels.authorizedPerson',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.authorizedPerson',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'EoriCode',
          },
          validationLabel: 'labels.EORICode',
          rules: 'numeric',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.EORICode',
        },

        {
          key: {
            parent: 'addressForm',
            child: 'PostalCode',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.postalCode',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'StateCode',
          },
          rules: this.isStateCodeRequired ? 'required|max:2' : '',
          validationLabel: 'labels.stateCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            autocomplete: 'new-password',
            isMagnify: true,
            magnifyURL:
              'https://www.ups.com/worldshiphelp/WSA/ENU/AppHelp/mergedProjects/CORE/Codes/State_Province_Codes.htm',
            magnifyLabel: 'buttons.showStateCodes',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.stateCode',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'ContactPhone',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prefix: this.phonePrefix,
            autocomplete: 'new-password',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'addressForm',
            child: 'Mail',
          },
          validationLabel: 'labels.email',
          rules: 'required|email|max:50',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.email',
        },
      ];
    },

    insuranceOptions() {
      return [
        {
          Value: 0,
          Name: this.$t('labels.customerInsurance'),
        },
        {
          Value: 1,
          Name: this.$t('labels.supplierInsurance'),
        },
        {
          Value: 2,
          Name: this.$t('labels.serviceProviderInsurance'),
        },
      ];
    },
    proformaOptions() {
      return [
        {
          Value: 0,
          Name: this.$t('labels.customerProforma'),
        },
        {
          Value: 1,
          Name: this.$t('labels.supplierProforma'),
        },
      ];
    },
    serviceTypes() {
      let serviceTypes = [];
      if (this.transferType !== 2) {
        serviceTypes.push({
          Id: 3,
          Name: this.$t('labels.portToPort'),
        });
      }

      serviceTypes.push({
        Id: 1,
        Name: this.$t('labels.doorToDoor'),
      });

      if (this.transferType == 2 && this.form.ExportImportType == 0) {
        serviceTypes.push({
          Id: 5,
          Name: this.$t('labels.doorToPort'),
        });
      }

      return serviceTypes;
    },
    formContainerDetails() {
      return [
        {
          key: {
            parent: 'form',
            child: 'SumCapPieces',
          },
          rules: 'required|numeric|min_value:1',
          validationLabel: 'labels.doorCount',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-package-variant',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          disabled: this.demand || this.transferType == 2,
          placeholder: 'labels.doorCount',
        },
        {
          key: {
            parent: 'form',
            child: 'SumWeight',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.kilo',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-weight-kilogram',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          disabled: this.demand || this.transferType == 2,
          placeholder: 'labels.kilo',
        },
        {
          key: {
            parent: 'form',
            child: 'SumVolume',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.volume',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prepend: 'mdi-package',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          disabled: this.demand || this.transferType == 2,
          placeholder: 'labels.volume',
        },
        {
          key: {
            parent: 'form',
            child: 'ChargeableWeight',
          },
          rules: 'required|double|min_value:0',
          validationLabel: 'labels.chargeableWeight',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 2,
          disabled: true,
          placeholder: 'labels.chargeableWeight',
        },
      ];
    },
    createOrderFormItems() {
      return [
        {
          key: {
            parent: 'orderForm',
            child: 'PurposeOfShipment',
          },
          validationLabel: 'labels.purposeOfShippingLabel',
          rules: 'required',
          formType: {
            name: 'select',
            items: {
              data: this.purposeOfShippingItems,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 6,
          placeholder: 'labels.purposeOfShippingLabel',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'ProformaOption',
          },
          rules: 'required',
          validationLabel: 'labels.proformaOption',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.proformaOptions,
              value: 'Value',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'labels.proformaOption',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'GoodsPrice',
          },
          validationLabel: 'labels.goodsPrice',
          topLabel: true,
          rules: 'required|double',
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 4,
          xl: 2,
          placeholder: 'labels.goodsPrice',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'GoodsPriceCurrency',
          },
          rules: 'required',
          validationLabel: 'labels.currency',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.currencies,
              value: 'Id',
              text: 'Code',
            },
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 4,
          xl: 2,
          placeholder: 'labels.currency',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'CountryOfManufacture',
          },
          validationLabel: 'labels.origin',
          topLabel: true,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countryItems,
              value: 'ISOCode2',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 6,
          md: 2,
          lg: 4,
          xl: 2,
          placeholder: 'labels.origin',
        },

        {
          key: {
            parent: 'orderForm',
            child: 'InsuranceType',
          },
          validationLabel: 'labels.insurance',
          topLabel: true,
          rules: 'required',
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.insuranceOptions,
              value: 'Value',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 4,
          placeholder: 'labels.insurance',
          classes: 'mr-sm-16',
        },
        {
          key: {
            parent: 'orderForm',
            child: 'SignedDelivery',
          },
          validationLabel: 'labels.wantSignedDelivery',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'checkbox',
          },
          cols: 12,
          sm: 6,
          md: 4,
          lg: 4,
          xl: 4,
          placeholder: 'labels.wantSignedDelivery',
          classes: 'ml-lg-16 mt-4 mt-sm-2',
        },
      ];
    },
    form_expressTypes() {
      return [
        {
          key: {
            parent: 'form',
            child: 'ExpressPackageType',
          },
          rules: 'required',
          validationLabel: 'labels.expressPackageType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.$expressPackageTypes,
              value: 'value',
              text: 'name',
            },
            prepend: 'mdi-plus',
          },
          lg: 6,
          placeholder: 'labels.expressPackageType',
          onChange: () => {
            if (this.form.ExpressPackageType === 2) {
              this.form.ExpressServiceType = 5;
              this.onSaveVolumes(
                [
                  {
                    volume: '0.00',
                    volumeWeight: 0,
                    kilo: '0.40',
                    height: '1',
                    length: '1',
                    width: '1',
                    countInCap: 1,
                    packageType: 'Envelope',
                    productType: 'Envelope',
                    containerCount: '1',
                    index: 0,
                    totalWeight: '0.40',
                  },
                ],
                {
                  canStack: true,
                  totalContainerCount: 1,
                  totalKilo: '0.400',
                  totalVolume: '0.000',
                  totalVolumeWeight: 0.4,
                  willNotBeStacked: false,
                  chargeableWeight: '0.40',
                }
              );
            } else {
              this.form.volumes = [];
              this.form.SumCapPieces = 0;
              this.form.SumWeight = 0;
              this.form.SumVolume = 0;
              this.form.ChargeableWeight = 0;
              this.form.ShipmentBoxes = [];
            }
          },
        },
        {
          key: {
            parent: 'form',
            child: 'ExpressServiceType',
          },
          rules: 'required',
          validationLabel: 'titles.expressServiceType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.$expressServiceTypes,
              value: 'value',
              text: 'name',
            },
            prepend: 'mdi-plus',
          },
          lg: 6,
          placeholder: 'titles.expressServiceType',
          active: this.form.ExpressPackageType !== 2,
        },
      ];
    },
    formItems() {
      return [
        {
          key: {
            parent: 'form',
            child: 'ExportImportType',
          },
          validationLabel: 'labels.exportImportType',
          rules: 'required',
          formType: {
            name: 'select',
            items: {
              data: [
                {
                  value: 0,
                  name: this.$t('labels.import'),
                },
                {
                  value: 1,
                  name: this.$t('labels.export'),
                },
              ],
              text: 'name',
              value: 'value',
            },
          },
          lg: 4,
          labelShown: false,
          topLabel: true,
          cols: 4,
          placeholder: 'labels.exportImportType',
        },
        {
          key: {
            parent: 'form',
            child: 'ServiceType',
          },
          rules: 'required',
          validationLabel: 'labels.serviceType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.serviceTypes,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-airplane-cog',
          },
          lg: 4,
          placeholder: 'labels.serviceType',
        },
        {
          key: {
            parent: 'form',
            child: 'TaxPayer',
          },
          rules: 'required',
          validationLabel: 'labels.taxPayer',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.taxPayers,
              value: 'value',
              text: 'name',
            },
            prepend: 'mdi-currency-usd',
          },
          lg: 4,
          placeholder: 'labels.taxPayer',
        },
      ];
    },

    form_company() {
      return [
        {
          key: {
            parent: 'form',
            child: 'LoadingCompany',
          },
          rules: 'required',
          validationLabel: 'labels.loaderCompany',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.subCompanies,
              value: 'Id',
              text: 'Name',
            },
            returnObject: true,
          },
          lg: 4,
          cols: 4,
          sm: 8,
          placeholder: 'labels.loaderCompany',
          onChange: () => {
            this.addresses.LoadingCompany = this.convertToAddressFormat(
              this.form.LoadingCompany
            );
          },
        },
        {
          key: {
            parent: 'Button',
            child: 'LoadingCompanyAdd',
          },
          icon: 'mdi-plus',
          onClick: () => {
            this.addressKey = 'LoadingCompany';
            this.submitAddressFormModal = true;
          },
        },
        {
          disabled: !this.form.LoadingCompany,
          key: {
            parent: 'Button',
            child: 'LoadingCompanyEdit',
          },
          icon: 'mdi-pencil',
          onClick: () => {
            this.editAddressMode = true;
            this.submitAddressFormModal = true;
            this.addressForm = Object.assign({}, this.form.LoadingCompany);
          },
        },
        {
          key: {
            parent: 'form',
            child: 'BuyerCompany',
          },
          rules: 'required',
          validationLabel: 'labels.buyerCompany',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.subCompanies,
              value: 'Id',
              text: 'Name',
            },
            returnObject: true,
          },
          cols: 4,
          lg: 4,
          sm: 8,
          placeholder: 'labels.buyerCompany',
          onChange: async () => {
            this.addresses.BuyerCompany = this.convertToAddressFormat(
              this.form.BuyerCompany
            );

            this.airports = await getAirports(
              '',
              this.form.BuyerCompany.CountryId,
              this.form.BuyerCompany.CityId,
              1,
              9999
            );
          },
        },
        {
          key: {
            parent: 'Button',
            child: 'BuyerCompanyAdd',
          },
          icon: 'mdi-plus',
          onClick: () => {
            this.addressKey = 'BuyerCompany';
            this.submitAddressFormModal = true;
          },
        },
        {
          disabled: !this.form.BuyerCompany,
          key: {
            parent: 'Button',
            child: 'BuyerCompanyEdit',
          },
          icon: 'mdi-pencil',
          onClick: () => {
            this.editAddressMode = true;
            this.submitAddressFormModal = true;
            this.addressForm = Object.assign({}, this.form.BuyerCompany);
          },
        },
        {
          key: {
            parent: 'addresses',
            child: 'LoadingCompany',
          },
          disabled: true,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },

          lg: 6,
          placeholder: 'labels.loaderCompany',
        },
        {
          key: {
            parent: 'addresses',
            child: 'BuyerCompany',
          },
          disabled: true,
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },

          lg: 6,
          placeholder: 'labels.buyerCompany',
        },
        {
          key: {
            parent: 'form',
            child: 'AirUnloadingPortId',
          },
          rules: this.form.ServiceType === 3 ? 'required' : '',
          validationLabel: 'labels.fromAirport',
          topLabel: true,
          labelShown: false,
          hidden: !(
            this.transferType == 2 &&
            this.form.ExportImportType == 0 &&
            this.form.ServiceType == 5
          ),
          active:
            !!this.form.ServiceType &&
            this.transferType == 2 &&
            this.form.ExportImportType == 0 &&
            this.form.ServiceType == 5,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.airports,
              value: 'Id',
              text: 'Name',
            },
            prepend: 'mdi-airplane-takeoff',
          },

          lg: this.form.ServiceType === 5 ? 4 : 12,
          placeholder: 'labels.fromAirport',
        },
      ];
    },
    pages() {
      if (this.rowsPerPage == null || this.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
  },
  methods: {
    submitCarrier() {
      if (this.isCarrierEdit) {
        this.editCarrier();
      } else {
        this.addCarrier();
      }
    },
    addCarrier() {
      this.$store.state.loadingStatus = true;
      const body = {
        ShipmentId: this.carrierInfo.id,
        CompanyName: this.carrierInfo.name,
        PickupAddress: this.carrierInfo.address,
        Phone: this.carrierInfo.phone,
        Email: this.carrierInfo.email,
        CourierDate: this.carrierInfo.date,
      };

      CarrierService.addCarrier(body)
        .then((res) => {
          this.resetCarrierModal();
          window.showSuccess(this.$t('description.carrierAdded'));
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.getTableList();
        });
    },

    editCarrier() {
      this.$store.state.loadingStatus = true;
      const body = {
        Id: this.carrierInfo.id,
        CompanyName: this.carrierInfo.name,
        PickupAddress: this.carrierInfo.address,
        Phone: this.carrierInfo.phone,
        Email: this.carrierInfo.email,
        CourierDate: this.carrierInfo.date,
      };

      CarrierService.editCarrier(body)
        .then((res) => {
          this.resetCarrierModal();
          window.showSuccess(this.$t('description.carrierUpdated'));
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.getTableList();
        });
    },

    resetCarrierModal() {
      this.carrierInfo = {
        id: null,
        name: null,
        address: null,
        phone: null,
        email: null,
        date: null,
      };
      this.isCarrierEdit = false;
      this.showCarrierModal = false;
      this.$refs.carrierModalObserver.reset();
    },
    callCarrier(item) {
      console.log(item);
      if (item.CourierInformation) {
        this.carrierInfo.id = item.CourierInformation.Id;
        this.carrierInfo.name = item.CourierInformation.CompanyName;
        this.carrierInfo.address = item.CourierInformation.PickupAddress;
        this.carrierInfo.phone = item.CourierInformation.Phone;
        this.carrierInfo.email = item.CourierInformation.Email;
        this.carrierInfo.date = item.CourierInformation.CourierDate;
        this.isCarrierEdit = true;
      } else {
        this.carrierInfo.id = item.ShipmentId;
      }

      this.showCarrierModal = true;
    },
    triggerCancelOrder(order) {
      this.selectedOrder = order;
      this.cancelOrderModal = true;
    },
    cancelOrder() {
      this.$store.state.loadingStatus = true;
      const body = {
        OrderId: this.selectedOrder.Id,
        CancelReason: this.selectedReason,
        CancelDescription: this.cancelDescription,
      };

      OrderService.cancelOrder(body)
        .then((res) => {
          this.resetCancelModal();
          this.getOrderDetails(this.orderId);
          window.showSuccess(this.$t('description.orderCanceled'));
        })
        .catch((err) => {
          window.handleServiceError(err);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.getTableList();
        });
    },
    resetCancelModal() {
      this.cancelOrderModal = false;
      this.selectedReason = null;
      this.cancelDescription = '';
      this.$refs.cancelModalObserver.reset();
    },
    async getSourceCompanies() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getSourceCompanies()
        .then((response) => {
          this.sourceCompanies = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    handleOpenURL() {
      window.open(
        'https://www.ups.com/worldshiphelp/WSA/ENU/AppHelp/mergedProjects/CORE/Codes/State_Province_Codes.htm',
        '_blank'
      );
    },
    convertToAddressFormat(addressObject) {
      if (addressObject) {
        let formattedAddress = '';
        formattedAddress = addressObject.Address;
        formattedAddress +=
          ' ' + addressObject.City + '/' + addressObject.CountryCode;
        if (addressObject.StateCode) {
          formattedAddress += ' ' + addressObject.StateCode;
        }

        formattedAddress += ' ' + addressObject.PostalCode;
        return formattedAddress.replaceAll('null', '');
      }
    },
    submitAddressForm() {
      this.$store.state.loadingStatus = true;
      const submitFunction = this.editAddressMode
        ? CompanyServices.updateCompany
        : CompanyServices.addSubCompany;

      submitFunction(this.addressForm)
        .then((response) => {
          this.submitAddressFormModal = false;
          this.getSubCompanies();

          const data = response.data.Result;
          if (data && data.Id && !this.editAddressMode && this.addressKey) {
            this.form[this.addressKey] = data;
            this.addresses[this.addressKey] = this.convertToAddressFormat(data);
          }
        })
        .catch(window.showError)
        .finally(() => (this.$store.state.loadingStatus = false));
    },
    async onChangeCountry() {
      AddressService.getCounties(this.addressForm.CountryId)
        .then((response) => {
          this.phonePrefix = response.data.Result[0].PhoneCode;
        })
        .catch((error) => {});

      this.cities = await getCities(this.addressForm.CountryId);
    },
    // Add Order
    createOrder() {
      // Create Shipment
      this.$store.state.loadingStatus = true;

      const body = {
        FastShippingSearchId: this.fastShippingSearchId,
        Price: this.selectedSearchResult.Price,
        CarrierCompanyId: this.selectedSearchResult.CarrierId,
        CurrencyCode: this.selectedSearchResult.PriceCurrency,
        TransitTime: this.selectedSearchResult.TransportTime,
        PriceListId: this.selectedSearchResult.ListId,
        PriceDetails: this.selectedSearchResult.PriceDetails || [],
      };

      ShipmentService.createShipment(body)
        .then(async (res) => {
          var shipment = res.data;
          const orderBody = {
            ShipmentId: shipment.Id,
          };
          await OrderService.createOrder(orderBody).then(async (response) => {
            const orderId = response.data.Result.Id;
            const {
              SumCapPieces,
              SumWeight,
              SumVolume,
              LoadingCompany,
              BuyerCompany,
              AirUnloadingPortId,

              ExportImportType,
              ServiceType,
              TaxPayer,
            } = this.form;

            const updateShipmentBody = {
              Id: shipment.Id,
              ...this.orderForm,

              SumCapPieces,
              SumWeight,
              SumVolume,

              ExportImportType,
              ServiceType,
              TaxPayer,

              DestinationSubCompanyId: BuyerCompany.Id,
              LoadingSubCompanyId: LoadingCompany.Id,
            };

            if (AirUnloadingPortId && AirUnloadingPortId.search('_NA') === -1) {
              updateShipmentBody.AirUnloadingPortId = AirUnloadingPortId;
            }
            await ShipmentService.updateShipment(updateShipmentBody)
              .then((response) => {
                var order = response.data;
                this.$router.push({
                  name: `OrderDetail`,
                  query: {
                    orderId,
                  },
                });
              })
              .catch(window.showError);
          });
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    async onSaveVolumes(volumes, parameters) {
      this.searchResults = [];

      const {
        totalContainerCount,
        totalVolume,
        totalKilo,
        totalInvoiceWeight,
        chargeableWeight,
      } = parameters;

      const updateVolumes = () => {
        this.form.SumCapPieces = totalContainerCount;
        this.form.SumWeight = totalKilo;
        this.form.SumVolume = totalVolume;
        this.form.ChargeableWeight = chargeableWeight;

        this.form.ShipmentBoxes = convertVolumeToBoxes(volumes);

        this.form.volumes = volumes;
        this.form = Object.assign({}, this.form);
      };

      this.confirmation.onConfirm = () => {
        updateVolumes();
      };

      if (
        totalVolume > 0 &&
        (parseInt(this.form.SumCapPieces) !== totalContainerCount ||
          parseFloat(this.form.SumWeight).toFixed(2) !== totalKilo ||
          parseFloat(this.form.SumVolume).toFixed(2) !== totalVolume) &&
        this.form.SumCapPieces &&
        this.form.SumWeight &&
        this.form.SumVolume &&
        this.form.TransportType !== 2
      ) {
        this.confirmation.visible = true;
      } else {
        this.confirmation.onConfirm();
      }
    },
    calculateChargeableWeight(width, length, height, containerCount) {
      return (width * length * height * containerCount) / 5000;
    },
    searchOffer() {
      const onResponse = async (response) => {
        let data = response.data.Result;
        if (data) {
          this.fastShippingSearchId = data.fastShippingSearchId;
          await SearchServices.searchAgain(1, 15, data.fastShippingSearchId)
            .then((response) => {
              const dataSearches = response.data.Result;
              this.searchResults = dataSearches.supplierLists || [];
            })
            .catch((error) => this.onServiceError(error));

          if (this.searchResults.length === 0) {
            window.showInfo(this.$t('messages.noOffer'));
          } else {
            this.stepper = 2;
          }
        }
      };

      this.$store.state.loadingStatus = true;
      const body = {
        TransportType: 2,
        ShipmentType: 1,
        LoadingType: 1,
        ServiceType: this.form.ServiceType,
        LoadingCountryId: this.form.LoadingCompany.CountryId,
        DestinationCountryId: this.form.BuyerCompany.CountryId,
        DestinationCityId: this.form.BuyerCompany.CityId,
        DestinationPostalCode: this.form.BuyerCompany.PostalCode,
        LoadingCityId: this.form.LoadingCompany.CityId,
        LoadingPostalCode: this.form.LoadingCompany.PostalCode,
        SumCapPieces: this.form.SumCapPieces,
        SumWeight: this.form.SumWeight,
        SumVolume: this.form.SumVolume,
        ChargeableWeight: this.form.ChargeableWeight,
        ExportImportType: this.form.ExportImportType,
        Boxes: this.form.ShipmentBoxes,
        TaxPayer: this.form.TaxPayer,

        ExpressPackageType: this.form.ExpressPackageType,
        ExpressServiceType:
          this.form.ExpressPackageType === 2 ? 5 : this.form.ExpressServiceType,
      };

      if (
        this.form.AirUnloadingPortId &&
        this.form.AirUnloadingPortId.search('_NA') === -1
      ) {
        body.AirUnloadingPortId = this.form.AirUnloadingPortId;
      }

      SearchServices.getSearches(body)
        .then(onResponse)
        .catch(window.showError)
        .finally(() => (this.$store.state.loadingStatus = false));
    },
    getSubCompanies() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getSubCompanies()
        .then((response) => {
          this.subCompanies = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.itemsPerPage = 15;
        this.page = 1;
        this.getTableList();
      }, 300);
    },
    resetFilter() {
      this.filters = { ...INITIAL_FILTERS };
      this.filterContent();
    },
    downloadTag(item) {
      this.$store.state.loadingStatus = true;
      let string = item.RefNo + '_Labels';
      OrderService.downloadTag(item.Id, string).then(() => {
        this.$store.state.loadingStatus = false;
      });
    },
    toggleArchive(item) {
      const body = {
        OrderId: item.Id,
      };
      OrderService.archiveOrder(body).then((res) => {
        this.getTableList();
      });
    },
    goToPayments() {
      this.$router.push('/home/payments');
    },
    changeItemsPerPage() {
      this.page = 1;
      this.options.itemsPerPage = this.rowsPerPage;
      this.getTableList();
    },
    paginationChangeHandler(pageNumber) {
      this.page = pageNumber;
      this.getTableList();
    },
    async getCountries() {
      this.countryItems = await getCountries();
    },
    setup() {
      this.headers = [
        {
          text: this.$t('titles.integration.trackingNumber'),
          value: 'TrackingNo',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.customer'),
          value: 'Customer',
          sortable: true,
          groupable: false,
          align: 'center',
        },

        {
          text: this.$t('labels.supplier'),
          value: 'Carrier',
          sortable: true,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.source'),
          value: 'Source',
          sortable: true,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.sender'),
          value: 'Sender',
          sortable: true,
          groupable: false,
          align: 'center',
        },

        {
          text: this.$t('labels.shipmentType'),
          value: 'ShipmentType',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('titles.from') + '/' + this.$t('titles.to'),
          value: 'FromToWhere',
          sortable: false,
          align: 'center',
        },
        // {
        //   text: 'Yük',
        //   value: 'Shipment.SumWeight',
        //   sortable: false,
        //   align: 'center',
        // },

        {
          text: this.$t('labels.referenceNumber'),
          value: 'RefNo',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.dateAsNoun'),
          value: 'CreatedOnUtc',
          sortable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.orderStatus'),
          value: 'OrderStatus',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.fileStatus'),
          value: 'SupplierOrderStatus',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('titles.orderType'),
          value: 'OrderType',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.chargeableWeight'),
          value: 'ChargeableWeight',
          sortable: true,
          align: 'center',
        },
        {
          text: this.$t('labels.price'),
          value: 'Price',
          sortable: true,
          align: 'end',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        },
      ];

      if (this.userRole === 'Customer' || this.userRole === 'PendingCustomer') {
        this.headers = this.headers.filter(
          (header) =>
            header.value !== 'Customer' &&
            header.value !== 'SupplierOrderStatus'
        );
      } else if (
        this.userRole === 'Supplier' ||
        this.userRole === 'PendingSupplier'
      ) {
        this.headers = this.headers.filter(
          (header) =>
            header.value !== 'Carrier' &&
            header.value !== 'OrderStatus' &&
            header.value !== 'Source'
        );
      } else if (this.isAdmin) {
        this.headers = this.headers.filter(
          (header) => header.value !== 'actions' && header.value !== 'actions'
        );
      }
    },
    goOrderDetail(item) {
      openRouteInNewTab({
        name: `OrderDetail`,
        query: {
          orderId: item.Id,
        },
        params: {
          transportType: item.TransportType,
        },
      });
    },
    goRateScreen(item) {
      this.$router.push({
        name: `rateOrder`,
        params: {
          order: item,
        },
      });
    },
    filterContent() {
      this.tableData = [];
      this.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.page = 1;
      this.getTableList();
    },

    getTableList() {
      this.$store.state.loadingStatus = true;
      const { StartDate, EndDate, ...others } = this.filters;
      OrderService[this.isAdmin ? 'getListForAdmin' : 'getOrders']({
        Page: this.page,
        PageSize: this.rowsPerPage,
        StartDate: StartDate ? extractDateFromObject(StartDate) : null,
        EndDate: EndDate ? extractDateFromObject(EndDate) : null,
        ...others,
      })
        .then((res) => {
          this.tableData = res.data.Result == null ? [] : res.data.Result;
          this.totalItems = res.data.TotalItems;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    async messageToUser(id) {
      this.$store.state.loadingStatus = true;
      await MessageServices.getById(id)
        .then(async (response) => {
          const data = response.data.Result;
          let chatId = data.Id;
          if (chatId) {
            this.$router.push({
              name: 'messageDetail',
              query: {
                id: chatId,
              },
            });
          }
        })
        .catch((error) => this.onServiceError(error))
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    getShipmentType(item) {
      let type = item.ShipmentType;
      if (item.TransportType == 2) {
        type = item.ShipmentType + ' (Express)';
      }
      return type;
    },
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style>
.v-stepper__label {
  text-align: center !important;
}
</style>
