import axios from "../javascript/custom-axios";
let COUNTRY_RESOURCE = "country", CITY_RESOURCE = "city";

export default {
  /**
   * Add A Country.
   * @param {{ SelectId?: number, PhoneCode: string, NameTr: string, NameEn: string, ISOCode2: string, ISOCode3: string }} body
   * @returns {Promise<any>}
   */
  addCountry(body) {
    return axios.post(`${COUNTRY_RESOURCE}/addCountry`, body);
  },

  /**
   * Get country by ISO codes.
   * @param {{ ISOCode2: string, ISOCode3: string }} params
   * @returns {Promise<any>}
   */
  getCountryByCode(params) {
    return axios.get(`${COUNTRY_RESOURCE}/getByCountryCode`, {
      params
    });
  },

  /**
   * Add A City.
   * @param {{   SelectId: number, Name: string, CountryId: string, State: string, StateCode: string }} body
   * @returns {Promise<any>}
   */
  addCity(body) {
    return axios.post(`${CITY_RESOURCE}/addCity`, body);
  },

  /**
   * Get countries by filter. 
   * @param {{ Name?: string, ISOCode2?: string, ISOCode3?: string, SelectId?: number, PhoneCode?: string, Id?: string, page?: number, pageSize?: number, AcceptLanguage?: string }} params 
   * @returns {Promise}
   */
  async getCountries(params) {
    return axios.get(`${COUNTRY_RESOURCE}/getCountries`, {
      params,
      responseType: params?.IsExcel ? 'blob' : 'json'
    });
  },

  /**
   * Get cities by filter.
   * @param {{ Name?: string, Id?: string, CountryId?: string, SelectId?: number, page?: number, pageSize?: number }} params 
   * @returns {Promise}
   */
  async getCities(params) {
    return axios.get(`${CITY_RESOURCE}/getCities`, {
      params
    });
  },

  /**
   * Get cities by filter for admin.
   * @param {{ Name?: string, Id?: string, CountryId?: string, SelectId?: number, page?: number, pageSize?: number }} params 
   * @returns {Promise}
   */
    async getCitiesForAdmin(params) {
      return axios.get(`${CITY_RESOURCE}/getCitiesForAdmin`, {
        params,
        responseType: params?.IsExcel ? 'blob' : 'json'
      });
    },

  /**
   * Update A Country.
  * @param {{ SelectId?: number, PhoneCode: string, NameTr: string, NameEn: string, ISOCode2: string, ISOCode3: string }} body
   * @returns {Promise<any>}
   */
  updateCountry(body) {
    return axios.put(`${COUNTRY_RESOURCE}/updateCountry`, body)
  },

  /**
   * Update A City.
   * @param {{  SelectId: number, Name: string, CountryId: string, State: string, StateCode: string }} body
   * @returns {Promise<any>}
   */
  updateCity(body) {
    return axios.put(`${CITY_RESOURCE}/updateCity`, body)
  },

  /**
   * Delete A Country.
   * @param {string} Id 
   * @returns {Promise}
   */
  deleteCountry(Id){
    return axios.delete(`${COUNTRY_RESOURCE}/deleteCountry`, {
      params: {Id}
    })
  },

  /**
   * Delete A City.
   * @param {string} Id 
   * @returns {Promise}
   */
  deleteCity(Id){
    return axios.delete(`${CITY_RESOURCE}/deleteCity`, {
      params: {Id}
    })
  },

  /**
   * Get a country by Id.
   * @param {string} Id 
   * @returns {Promise}
   */
  getCountryById(Id){
    return axios.get(`${COUNTRY_RESOURCE}/getByCountryId`, {params: {Id}})
  },

  /**
   * Get a city by Id.
   * @param {string} Id 
   * @returns {Promise}
   */
  getCityById(Id){
    return axios.get(`${CITY_RESOURCE}/getCityById`, {params: {Id}})
  }
};
