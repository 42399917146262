import store from '../store/store';
import axios from '../javascript/custom-axios';
import VueI18n from 'vue-i18n';

const storedLanguage = localStorage.getItem('language') || 'tr';
const language = storedLanguage == 'tr' ? 'tr-TR' : 'en-US';

export default {
  ifAuthenticated(to, from, next) {
    const userType = store.getters.getUserType;
    const userBlacklist = store.getters.getUserBlacklist;
    const isRouteBlacklisted = userBlacklist?.some(
      (module) => to.name === module
    );

    let isUserTypeAllowed =
      to.meta.userType && to.meta.userType.indexOf(userType) >= 0;

    if (store.getters.isAuthenticated && isUserTypeAllowed) {
      if (!isRouteBlacklisted) {
        axios.defaults.headers = {
          Authorization: 'Bearer ' + store.state.Token,
          'Accept-Language': language,
        };
        next();
      } else {
        next('/home/dashboard');
      }
    } else {
      if (isUserTypeAllowed) {
        next('/home/dashboard');
      } else {
        next('/login');
      }
    }
  },

  ifNotAuthenticated(to, from, next) {
    if (!store.getters.isAuthenticated) {
      axios.defaults.headers = {
        Authorization: '',
        'Accept-Language': language,
      };
      next();
      return;
    }
    next('/home');
  },
};
