<template>
  <div>
    <v-dialog v-model="showCarrierModal" max-width="600">
      <v-card class="popup-card" elevation="1">
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 16px"
        >
          <h3>{{ $t('buttons.callCarrier') }}</h3>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver ref="carrierModalObserver">
          <v-card-text>
            <v-row>
              <FormElement
                v-for="(element, index) in carrierFormItems"
                :key="
                  element.key.parent + '_' + element.key.child + '_' + index
                "
                v-model="$data[element.key.parent][element.key.child]"
                :validationLabel="$t(element.validationLabel)"
                :formLabel="$t(element.formLabel)"
                :onChange="element.onChange"
                :formType="element.formType"
                :lg="element.lg"
                :cols="element.cols"
                :hidden="element.hidden"
                :labelShown="element.labelShown"
                :topLabel="element.topLabel"
                :active="element.active"
                :placeholder="$t(element.placeholder)"
                :disabled="true"
              />
            </v-row>
          </v-card-text>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card elevation="1">
      <v-card-title style="color: var(--darkBlue)">
        <v-row justify="space-between">
          <v-col
            class="text-lg-left text-center pb-0 pb-sm-2"
            cols="12"
            sm="12"
            md="9"
            lg="9"
            xl="9"
          >
            <h3>{{ $t('navigationDrawerMenu.plannerManagement') }}</h3>
          </v-col>
          <v-col
            class="d-flex align-center justify-end pb-0 pb-sm-2"
            cols="12"
            sm="12"
            md="3"
            lg="3"
            xl="3"
          >
            <v-btn
              v-if="searchCustomer || searchTrackingNo || searchRefNo"
              variant="tonal"
              color="#0177d4"
              class="button mr-1"
              style="color: white; border-radius: 14px !important"
              @click="resetFilter"
            >
              {{ $t('buttons.clearFilter') }}
            </v-btn>
            <RefreshButton :refreshFunction="getPlannerList"></RefreshButton>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />
      <v-card-text fill-height fluid>
        <v-row align="center" justify="start" class="d-flex mb-5">
          <v-col cols="12" class="align-center">
            <v-row class="align-center pt-3 justify-space-between">
              <v-col>
                <v-row class="align-center pt-3">
                  <FormElement
                    v-model="searchTrackingNo"
                    :validationLabel="$t('labels.cargoTrackingNumber')"
                    :formLabel="$t('labels.cargoTrackingNumber')"
                    @input="getSearchDebounced"
                    :formType="{
                      name: 'text',
                      dense: true,
                      appendIcon: 'mdi-magnify',
                      appendClick: () => {
                        getSearchDebounced();
                      },
                    }"
                    :labelShown="false"
                    :cols="6"
                    :sm="6"
                    :md="3"
                    :lg="3"
                    :xl="3"
                    :topLabel="true"
                    :hideDetails="true"
                    :placeholder="$t('labels.cargoTrackingNumber')"
                  />
                  <FormElement
                    v-model="searchRefNo"
                    :validationLabel="$t('labels.referenceNumber')"
                    :formLabel="$t('labels.referenceNumber')"
                    @input="getSearchDebounced"
                    :formType="{
                      name: 'text',
                      dense: true,
                      appendIcon: 'mdi-magnify',
                      appendClick: () => {
                        getSearchDebounced();
                      },
                    }"
                    :labelShown="false"
                    :cols="6"
                    :sm="6"
                    :md="3"
                    :lg="3"
                    :xl="3"
                    :topLabel="true"
                    :hideDetails="true"
                    :placeholder="$t('labels.referenceNumber')"
                  />
                  <FormElement
                    v-model="searchCustomer"
                    :validationLabel="$t('labels.customer')"
                    :formLabel="$t('labels.customer')"
                    @input="getSearchDebounced"
                    :formType="{
                      name: 'text',
                      dense: true,
                      appendIcon: 'mdi-magnify',
                      appendClick: () => {
                        getSearchDebounced();
                      },
                    }"
                    :labelShown="false"
                    :cols="6"
                    :sm="6"
                    :md="2"
                    :lg="2"
                    :xl="2"
                    :topLabel="true"
                    :hideDetails="true"
                    :placeholder="$t('labels.customer')"
                  />

                  <v-col
                    :cols="6"
                    :sm="6"
                    :md="2"
                    :lg="2"
                    :xl="2"
                    style="padding: 4px"
                  >
                    <v-autocomplete
                      class="custom-border"
                      v-model="planFlag"
                      :value="planFlag"
                      :items="
                        planFilterList.concat({
                          Text: $t('labels.All'),
                          Value: null,
                        })
                      "
                      :label="$t('labels.status')"
                      item-text="Text"
                      item-value="Value"
                      dense
                      hide-details
                      variant="solo"
                      @change="getPlannerList()"
                      outlined
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    :cols="6"
                    :sm="6"
                    :md="2"
                    :lg="2"
                    :xl="2"
                    style="padding: 4px"
                  >
                    <v-autocomplete
                      class="custom-border"
                      v-model="orderStatus"
                      :value="orderStatus"
                      :items="
                        orderStatuses.concat({
                          Text: $t('labels.All'),
                          Value: null,
                        })
                      "
                      :label="$t('titles.orderStatus')"
                      item-text="Text"
                      item-value="Value"
                      dense
                      hide-details
                      variant="solo"
                      @change="getPlannerList()"
                      outlined
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="selectedPlans.length > 0"
                cols="12"
                sm="12"
                md="4"
                lg="4"
                xl="3"
                class="d-flex justify-center justify-lg-end"
              >
                <v-row class="align-center pt-0 pt-md-3">
                  <FormElement
                    v-model="planStatus"
                    :validationLabel="$t('labels.status')"
                    :formLabel="$t('labels.status')"
                    :formType="{
                      name: 'select',
                      dense: true,
                      items: {
                        data: [
                          {
                            Name: $t('labels.planned'),
                            Value: true,
                          },
                          {
                            Name: $t('labels.notPlanned'),
                            Value: false,
                          },
                        ],
                        value: 'Value',
                        text: 'Name',
                      },
                    }"
                    :labelShown="false"
                    :cols="6"
                    :sm="6"
                    :md="6"
                    :lg="6"
                    :xl="6"
                    :topLabel="true"
                    :hideDetails="true"
                    :placeholder="$t('labels.status')"
                  />
                  <v-col
                    :cols="6"
                    :sm="6"
                    :md="6"
                    :lg="6"
                    :xl="6"
                    style="padding: 4px"
                  >
                    <v-btn
                      variant="tonal"
                      color="#0177d4"
                      class="button mr-1"
                      style="
                        color: white;
                        border-radius: 14px !important;
                        width: 100%;
                      "
                      @click="planSelecteds"
                    >
                      {{ $t('buttons.planSelecteds') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="planners"
          :options.sync="options"
          :hide-default-footer="true"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :loading="$store.state.loadingStatus"
          :no-data-text="$t('messages.noData')"
          v-model="selectedPlans"
          :item-key="'Id'"
          ><!-- eslint-disable -->
          <template v-slot:item.Checkbox="{ item, select, isSelected }">
            <!--  -->
            <v-checkbox
              @change="updateSelectedPlans(item)"
              large
              color="green"
              :off-icon="'mdi-checkbox-blank-outline'"
              :on-icon="'mdi-checkbox-marked-outline'"
            ></v-checkbox>
          </template>
          <template v-slot:item.PhotoUrl="{ item }">
            <img
              :src="item.PhotoUrl"
              :alt="item.Title"
              style="width: 50px; height: 50px; object-fit: contain"
            />
          </template>
          <template v-slot:item.CreatedOnUtc="{ item }">
            {{ convertToLocal(item.CreatedOnUtc) }}
          </template>
          <template v-slot:item.WatedLoadingDate="{ item }">
            {{ convertToLocal(item.WatedLoadingDate) }}
          </template>
          <template v-slot:item.LoadingAddress="{ item }">
            {{ item.LoadingAddress + ' ' + item.Country + '/' + item.City }}
          </template>
          <template v-slot:item.Planned="{ item }">
            <v-chip
              v-if="
                !item.Planned && new Date(item.WatedLoadingDate) < new Date()
              "
              style="font-weight: 600"
              class="ma-2"
              small
              color="red"
              text-color="white"
            >
              {{ $t('labels.notPlannedLabel') }}
            </v-chip>
            <v-chip
              v-if="
                !item.Planned && new Date(item.WatedLoadingDate) >= new Date()
              "
              style="font-weight: 600"
              class="ma-2"
              small
              color="#FA6400"
              text-color="#2b3838"
            >
              {{ $t('labels.notPlannedLabel') }}
            </v-chip>
            <v-chip
              v-if="
                item.Planned &&
                (item.EntranceToWarehouseDate == null ||
                  new Date(item.EntranceToWarehouseDate) > new Date())
              "
              style="font-weight: 600"
              class="ma-2"
              small
              color="#0177d4"
              text-color="white"
            >
              {{ $t('labels.plannedLabel') }}
            </v-chip>
            <v-chip
              v-if="
                item.EntranceToWarehouseDate &&
                item.Planned &&
                new Date(item.EntranceToWarehouseDate) <= new Date()
              "
              style="font-weight: 600"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.plannedLabel') }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex text-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.CourierInformation"
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="showCarrierInfo(item)"
                  >
                    mdi-account-tie-hat
                  </v-icon>
                </template>
                <span>{{ $t('buttons.carrierInfo') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="rowsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage()"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import PlannerService from '../services/PlannerService';
import AddressService from '../services/AddressService';
import AccountService from '../services/AccountService';
import { mapActions } from 'vuex';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import rules from '../utils/validationRules';

export default {
  data: () => ({
    selectedPlans: [],
    planFilterList: [],
    btnLoading: false,
    showPassword: true,
    showConfirmPassword: false,
    planners: [],
    form: {
      PaymetTime: 0,
    },
    countries: [],
    cities: [],
    customerTypes: [],
    addPortalUserDialog: false,
    headers: [],
    itemsPerPageOptions: [],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,
    rowsPerPage: 15,
    options: {
      itemsPerPage: 15,
    },
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],
    searchCustomer: '',
    searchRefNo: '',
    searchTrackingNo: '',
    paymentTargets: [],
    paymentTypes: [],
    selectedCompany: '',
    payingTypes: [],
    planFlag: false,
    planStatus: false,
    orderStatus: 0,
    orderStatuses: [],
    showCarrierModal: false,
    carrierInfo: {
      id: '',
      name: '',
      address: '',
      phone: '',
      email: '',
      date: '',
    },
  }),
  mounted() {
    console.log(new Date('2024-07-17T09:00:48.269'));
    console.log(new Date('2024-07-17T09:00:48.269') < new Date());
    this.planFilterList = [
      {
        Value: false,
        Text: this.$t('labels.notPlannedLabel'),
      },
      {
        Value: true,
        Text: this.$t('labels.plannedLabel'),
      },
    ];
    this.payingTypes = [
      {
        name: this.$t('labels.transfer'),
        value: 1,
      },
      {
        name: this.$t('labels.creditCardOrder'),
        value: 2,
      },
    ];
    this.paymentTypes = [
      {
        name: this.$t('labels.upFront'),
        value: 0,
      },
      {
        name: this.$t('labels.deferredPayment'),
        value: 1,
      },
    ];

    this.headers = [
      {
        text: '',
        value: 'Checkbox',
        sortable: false,
        groupable: false,
        align: 'center',
      },
      {
        text: this.$t('titles.integration.trackingNumber'),
        value: 'TrackingNo',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.referenceNumber'),
        value: 'RefNo',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.customer'),
        value: 'Customer',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.sender'),
        value: 'Sender',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.userPhone'),
        value: 'Phone',
        sortable: false,
        align: 'center',
      },

      {
        text: this.$t('labels.loadAddress'),
        value: 'LoadingAddress',
        sortable: false,
        align: 'center',
        width: '200px',
      },
      {
        text: this.$t('labels.doorCount'),
        value: 'SumCapPieces',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.totalKilo'),
        value: 'SumWeight',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.recordDate'),
        value: 'CreatedOnUtc',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.requestedPurchaseDate'),
        value: 'WatedLoadingDate',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.status'),
        value: 'Planned',
        sortable: true,
        align: 'center',
      },
      {
        text: this.$t('buttons.carrierInfo'),
        value: 'actions',
        sortable: false,
      },
    ];
    this.paymentTargets = [
      {
        Id: 0,
        Name: this.$t('labels.applicationName'),
      },
      {
        Id: 1,
        Name: this.$t('labels.supplier'),
      },
    ];
    this.customerTypes = [
      {
        Id: 0,
        Name: this.$t('labels.tooltipCorporate'),
      },
      {
        Id: 1,
        Name: this.$t('labels.tooltipMarketPlace'),
      },
    ];

    this.orderStatuses = [
      {
        Value: 0,
        Text: this.$t('labels.isActive'),
      },
      {
        Value: 1,
        Text: this.$t('labels.labelsOrderStatuses.canceled'),
      },
    ];

    this.getCountries();
    this.getPlannerList();
  },
  components: {
    RefreshButton,
    FormElement,
    ConfirmationModal,
  },
  computed: {
    pages() {
      if (this.rowsPerPage == null || this.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
    carrierFormItems() {
      return [
        {
          key: {
            parent: 'carrierInfo',
            child: 'name',
          },
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'carrierInfo',
            child: 'address',
          },

          validationLabel: 'labels.address1',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.address1',
        },
        {
          key: {
            parent: 'carrierInfo',
            child: 'phone',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
            prefix: this.phonePrefix,
            autocomplete: 'new-password',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'carrierInfo',
            child: 'email',
          },
          validationLabel: 'labels.email',
          rules: 'required|email|max:50',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.email',
        },
        {
          key: {
            parent: 'carrierInfo',
            child: 'date',
          },
          validationLabel: 'labels.dateAsNoun',

          topLabel: true,
          labelShown: false,
          formType: {
            name: 'date',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.dateAsNoun',
        },
      ];
    },
    formElement() {
      return [
        {
          key: {
            parent: 'form',
            child: 'Name',
          },
          rules: 'required',
          validationLabel: 'labels.name',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.name',
        },
        {
          key: {
            parent: 'form',
            child: 'Surname',
          },
          rules: 'required',
          validationLabel: 'labels.user.lastName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.user.lastName',
        },
        {
          key: {
            parent: 'form',
            child: 'Email',
          },
          validationLabel: 'labels.userEmail',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.userEmail',
        },
        {
          key: {
            parent: 'form',
            child: 'PhoneNumber',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'form',
            child: 'CompanyName',
          },
          rules: 'required',
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'form',
            child: 'Password',
          },
          rules: 'required|max:24',
          rules: {
            required: true,
            max: 24,
            min: 6,
            regex:
              /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
          },
          validationLabel: 'labels.password',
          topLabel: true,
          labelShown: false,
          formLabel: 'labels.password',
          formType: {
            name: this.showPassword ? 'seePassword' : 'password',
            counter: '24',
            appendIcon: this.showPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendOuterIcon: 'mdi-key-plus',
            appendClick: () => {
              this.showPassword = !this.showPassword;
            },
            appendOuterClick: () => {
              this.generatePassword();
            },
            appendOuterText: this.$t('labels.generatePassword'),
            autocomplete: 'new-password',
          },
          lg: 12,
          hideDetails: true,
          placeholder: 'labels.password',
          disabled: true,
        },
        {
          key: {
            parent: 'form',
            child: 'PortalCustomerCode',
          },
          rules: 'required',
          validationLabel: 'labels.portalCustomerCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.portalCustomerCode',
        },
        {
          key: {
            parent: 'form',
            child: 'PortalSellerCode',
          },
          rules: 'required',
          validationLabel: 'labels.portalSellerCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.portalSellerCode',
        },
        {
          key: {
            parent: 'form',
            child: 'TaxOffice',
          },
          rules: 'required',
          validationLabel: 'labels.company.taxOffice',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.company.taxOffice',
        },
        {
          key: {
            parent: 'form',
            child: 'TaxNo',
          },
          rules: 'required',
          validationLabel: 'labels.company.taxNo',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.company.taxNo',
        },
        {
          key: {
            parent: 'form',
            child: 'PortalCustomerType',
          },
          rules: 'required',
          validationLabel: 'labels.customerType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.customerTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'labels.customerType',
        },
        {
          key: {
            parent: 'form',
            child: 'Country',
          },
          rules: 'required',
          validationLabel: 'labels.country',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'Id',
              text: 'Name',
            },
          },
          onChange: async () => {
            this.onChangeCountry();
          },
          lg: 6,
          placeholder: 'labels.country',
        },
        {
          key: {
            parent: 'form',
            child: 'City',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'form',
            child: 'District',
          },
          rules: 'required',
          validationLabel: 'labels.district',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.district',
        },
        {
          key: {
            parent: 'form',
            child: 'Postcode',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.postalCode',
        },
        {
          key: {
            parent: 'form',
            child: 'Address1',
          },
          rules: 'required',
          validationLabel: 'labels.address',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.address',
        },
        {
          key: {
            parent: 'form',
            child: 'PaymentTarget',
          },
          rules: 'required',
          validationLabel: 'labels.paymentTarget',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.paymentTargets,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 4,
          cols: 4,
          placeholder: 'labels.paymentTarget',
        },
        {
          key: {
            parent: 'form',
            child: 'PaymentType',
          },
          validationLabel: 'labels.paymentType',
          formLabel: 'labels.paymentType',
          formType: {
            name: 'select',
            items: {
              data: this.payingTypes,
              value: 'value',
              text: 'name',
            },
          },
          topLabel: true,
          labelShown: false,
          lg: 4,
          cols: 4,
          placeholder: 'labels.paymentType',
        },
        {
          key: {
            parent: 'form',
            child: 'PaymetTime',
          },
          rules: 'required',
          validationLabel: 'labels.payingType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.paymentTypes,
              value: 'value',
              text: 'name',
            },
          },
          lg: 4,
          cols: 4,
          placeholder: 'labels.payingType',
        },
      ];
    },
  },
  methods: {
    showCarrierInfo(item) {
      this.carrierInfo.id = item.CourierInformation?.Id
        ? item.CourierInformation?.Id
        : '';
      this.carrierInfo.name = item.CourierInformation?.CompanyName
        ? item.CourierInformation?.CompanyName
        : '';
      this.carrierInfo.address = item.CourierInformation?.PickupAddress
        ? item.CourierInformation?.PickupAddress
        : '';
      this.carrierInfo.phone = item.CourierInformation?.Phone
        ? item.CourierInformation?.Phone
        : '';
      this.carrierInfo.email = item.CourierInformation?.Email
        ? item.CourierInformation?.Email
        : '';
      this.carrierInfo.date = item.CourierInformation?.CourierDate
        ? item.CourierInformation?.CourierDate
        : '';

      this.showCarrierModal = true;
    },
    updateSelectedPlans(item) {
      if (!this.selectedPlans.includes(item)) {
        this.selectedPlans.push(item);
      } else {
        const index = this.selectedPlans.indexOf(item);
        if (index > -1) {
          this.selectedPlans.splice(index, 1);
        }
      }
    },
    generatePassword() {
      console.log('Generating password');
      this.btnLoading = true;
      AccountService.generatePassword()
        .then((response) => {
          this.form.Password = response.data.Result.Password;
          this.btnLoading = false;
        })
        .catch((error) => {
          window.handleServiceError(error);
          this.btnLoading = false;
        });
    },
    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.page = 1;
        this.getPlannerList();
      }, 300);
    },

    getPlannerList() {
      this.planners = [];
      this.selectedPlans = [];
      this.$store.state.loadingStatus = true;
      PlannerService.getPlannerList(
        this.rowsPerPage,
        this.page,
        this.searchCustomer,
        this.searchTrackingNo,
        this.searchRefNo,
        this.planFlag,
        this.orderStatus
      )
        .then((response) => {
          this.planners = response.data.Result;
          this.totalItems = response.data.TotalItems;
          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          window.handleServiceError(error);
          this.$store.state.loadingStatus = false;
        });
    },
    changeItemsPerPage() {
      this.page = 1;
      this.options.itemsPerPage = this.rowsPerPage;
      this.getPlannerList();
    },
    paginationChangeHandler(pageNumber) {
      this.page = pageNumber;
      this.getPlannerList();
    },
    filterContent() {
      this.tableData = [];
      this.rowsPerPage = 15;
      this.itemsPerPage = 15;
      this.page = 1;
      this.getTableList();
    },
    async getCountries() {
      await AddressService.getCounties()
        .then((response) => {
          this.countries = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    onChangeCountry() {
      this.$store.state.loadingStatus = true;
      AddressService.getCities(this.form.Country)
        .then((response) => {
          this.cities = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    resetDialog() {
      this.$refs.observer.reset();
      this.addPortalUserDialog = false;
      this.form = {
        PaymetTime: 0,
      };
    },

    planSelecteds() {
      this.$store.state.loadingStatus = true;
      let body = {
        OrderIds: [],
        Planned: this.planStatus,
      };
      if (this.selectedPlans.length > 0) {
        this.selectedPlans.forEach((element) => {
          body.OrderIds.push(element.Id);
        });

        PlannerService.plannedSelected(body)
          .then((response) => {
            console.log('Plan request response >> ', response);
            window.showSuccess(this.$t('messages.addPlanMessageSuccessfully'));

            this.getPlannerList();
          })
          .catch((err) => {
            window.showError(err);
          })
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      } else {
        window.showInfo(
          this.$t('description.planInfoDesription'),
          'standart',
          'info'
        );
      }
    },

    resetFilter() {
      this.searchCustomer = '';
      this.searchTrackingNo = '';
      this.searchRefNo = '';
      this.getPlannerList();
    },

    ...mapActions(['portalLogin', 'updatePortalUserId']),
  },
};
</script>
<style>
.style-1 {
  background-color: #bfecd4;
}
</style>
