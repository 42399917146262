<template>
  <div>
    <v-dialog
      v-model="$store.state.IsPackagePopupVisible"
      @input="handleCloseModal"
      max-width="1500"
    >
      <SubscriptionModal @close-modal="handleCloseModal" />
    </v-dialog>

    <!-- Change password dialog start -->
    <v-dialog max-width="500" v-model="changePasswordModal">
      <ChangePassword />
      <v-card v-if="false">
        <v-card-title class="text-h4 text-center justify-center mb-5">
          {{ $t('labels.changePassword') }}
        </v-card-title>
        <ValidationObserver
          v-if="false"
          v-slot="{ handleSubmit }"
          ref="observer"
        >
          <form @submit.prevent="handleSubmit(changePassword)">
            <v-card-text
              class="font-weight-bold pl-8 body-1"
              style="line-height: 1.8; color: #7443a0"
            >
              <v-text-field
                :label="$t('labels.currentPassword')"
                v-model="currentPassword"
                :rules="exampleRules"
                :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showCurrentPassword ? 'text' : 'password'"
                @click:append="showCurrentPassword = !showCurrentPassword"
              ></v-text-field>

              <v-text-field
                :label="$t('labels.newPassword')"
                v-model="newPassword"
                :rules="[
                  newPassword != '' || 'Field required',
                  newPassword.length >= 8 ||
                    'Bu alan en az 8 karakter olmalıdır',
                  newPassword.length <= 16 ||
                    'Bu alan izin verilen maksimum 16 karakteri aşıyor',
                  reNewPassword == newPassword ||
                    'Şifre yeni şifre ile eşleşmiyor',
                ]"
                :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showNewPassword ? 'text' : 'password'"
                @click:append="showNewPassword = !showNewPassword"
                ref="newPassword"
              ></v-text-field>

              <v-text-field
                :label="$t('labels.reNewPassword')"
                v-model="reNewPassword"
                :rules="[
                  reNewPassword != '' || 'Bu alan zorunludur',
                  reNewPassword.length >= 8 ||
                    'Bu alan en az 8 karakter olmalıdır',
                  reNewPassword.length <= 16 ||
                    'Bu alan izin verilen maksimum 16 karakteri aşıyor',
                  reNewPassword == newPassword ||
                    'Şifre yeni şifre ile eşleşmiyor',
                ]"
                :append-icon="showReNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showReNewPassword ? 'text' : 'password'"
                @click:append="showReNewPassword = !showReNewPassword"
              ></v-text-field>
              <!-- </ValidationProvider> -->
            </v-card-text>
            <v-card-actions
              style="background-color: white; position: sticky; bottom: 0px"
            >
              <v-spacer></v-spacer>

              <v-btn
                :disabled="!updateButtonControl"
                color="deep-purple lighten-2"
                text
                @click="changePassword()"
              >
                {{ $t('buttons.save') }}
              </v-btn>

              <v-btn
                color="deep-purple lighten-2"
                text
                v-if="!changePasswordFlag"
                @click="
                  changePasswordModal = false;
                  clearModal();
                "
              >
                {{ $t('buttons.close') }}
              </v-btn>
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <!-- Change password  dialog end -->
    <!-- Logout dialog start -->
    <v-dialog v-model="dialogLogout" persistent max-width="400">
      <v-card class="popup-card" elevation="1">
        <!-- Title Section -->
        <v-card-title
          class="popupHeadline d-flex justify-center"
          style="font-size: 24px"
        >
          {{ $t('title.logout') }}
        </v-card-title>

        <v-divider />

        <v-card-text class="text-center">
          <img
            src="../images/error.png"
            alt="Image Description"
            style="width: 200px; max-height: 200px"
          />
          <p style="font-size: 16px">
            {{ $t('description.logout') }}
          </p>
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn
            variant="tonal"
            class="rounded-lg"
            style="
              color: white;
              text-transform: none;
              font-size: large;
              font-weight: bold;
              padding: 1em;
            "
            color="var(--red)"
            @click="LogOut"
          >
            {{ $t('buttons.yes') }}
          </v-btn>
          <v-btn
            variant="tonal"
            class="rounded-lg"
            style="
              color: white;
              text-transform: none;
              font-size: large;
              font-weight: bold;
              padding: 1em;
            "
            color="#0077d5"
            @click="dialogLogout = false"
          >
            {{ $t('buttons.no') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- <v-card>
        <v-card-title class="headline">
          {{ $t('title.logout') }}
        </v-card-title>
        <v-card-text>
          {{ $t('description.logout') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="LogOut">
            {{ $t('buttons.yes') }}
          </v-btn>
          <v-btn color="green darken-1" text @click="dialogLogout = false">
            {{ $t('buttons.no') }}
          </v-btn>
        </v-card-actions>
      </v-card> -->
    </v-dialog>
    <!-- Logout dialog end -->

    <v-app-bar
      :class="fromLogin ? 'login-style' : 'app-bar-style'"
      :elevation="fromLogin ? 0 : undefined"
      :shadow="fromLogin ? false : undefined"
      :floating="fromLogin ? false : true"
      :app="fromLogin ? false : true"
      :dense="fromLogin ? false : true"
      :fixed="fromLogin ? false : true"
      style="z-index: 99"
    >
      <v-toolbar-title class="d-flex align-center mr-8">
        <button
          v-if="
            !($vuetify.breakpoint.lg || $vuetify.breakpoint.xl) && !fromLogin
          "
          @click="$emit('toggle-drawer')"
        >
          <v-icon style="font-size: 32px" color="white">mdi-menu</v-icon>
        </button>
        <v-img
          :width="fromLogin ? '150px' : '100px'"
          contain
          :src="require('@/../public/img/logo.png')"
          @click="navigateHome()"
        ></v-img>
        <div>
          <span
            :class="
              $i18n.locale === 'tr' ? 'activeLanguage' : 'passiveLanguage'
            "
            @click="changeLanguage('tr')"
            color="red"
            dark
            >TR</span
          >
          <span
            :class="
              $i18n.locale === 'en' ? 'activeLanguage' : 'passiveLanguage'
            "
            @click="changeLanguage('en')"
            color="blue"
            dark
            >EN</span
          >
        </div>
      </v-toolbar-title>
      <div class="d-flex justify-center align-center" v-if="$store.state.Token">
        <v-btn
          v-if="$store.state.PortalUserId && !$store.state.isPrevAdmin"
          @click="returnUser"
          style="
            color: #fff;
            text-transform: unset !important;
            background-color: var(--red);
            padding: 1vh 1vh;
            border-radius: 30px !important;
            font-weight: bolder;
            font-size: small;
            margin-right: 10px;
          "
          elevation="0"
          class="darken-1"
        >
          {{ $t('labels.returnToUserList') }}
        </v-btn>
        <v-btn
          v-if="
            $store.getters.getUserType !== 'Unassigned' &&
            $store.getters.getUserType !== 'Admin'
          "
          @click="
            $route.name === 'messages'
              ? null
              : $router.push({ name: 'messages' })
          "
          icon
          large
          color="white"
          id="menu-activator"
        >
          <v-badge
            style="cursor: pointer"
            overlap
            :content="unSeenCount"
            left
            offset-x="16"
            offset-y="16"
            color="red"
            :value="unSeenCount > 0"
            ><v-icon>mdi-bell-outline</v-icon></v-badge
          >
        </v-btn>
        <v-btn @click="dialogLogout = true" icon large color="white">
          <v-icon>mdi-logout</v-icon>
        </v-btn>
      </div>
      <div
        v-if="fromLogin"
        class="flex w-full h-full mt-14 mx-2 mx-xs-2 mx-sm-4 mx-md-8 mx-lg-12 mx-xl-14 mx-xxl-16"
        style="
          border-bottom-width: 2px;
          border-bottom-color: #e0e0e0;
          border-bottom-style: solid;
          opacity: 0.75;
        "
      ></div>
      <div class="d-flex flex-row" v-if="!$store.state.Token">
        <v-btn
          variant="flat"
          dark
          color="var(--pink)"
          style="margin-right: 10px"
          class="button"
          @click="$router.push({ name: 'register' })"
        >
          {{ $t('buttons.register') }}
        </v-btn>

        <v-btn
          @click="$router.push({ name: 'login' })"
          variant="flat"
          dark
          color="var(--turquoise)"
          class="button"
        >
          {{ $t('buttons.login') }}
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import store from '../store/store';
import { mapActions } from 'vuex';
import UserService from '../services/UserService';
import NotificationService from '../services/NotificationService';
import MessageServices from '../services/MessageServices';
import ChangePassword from './ChangePassword.vue';
import OrderService from '../services/OrderService';
import { localize } from 'vee-validate';
import AccountService from '../services/AccountService';
import CompanyPackageService from '../services/CompanyPackageService';
import FormElement from '../components/FormElement.vue';
import CompanyServices from '../services/CompanyServices';
import SubscriptionModal from './SubscriptionModal.vue';

export default {
  name: 'NavMenu',
  props: {
    fromLogin: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descriptionLimit: 60,
      entries: [],
      notifications: [],
      isLoading: false,
      unSeenCount: 0,
      model: null,
      search: null,
      url: '',
      profileData: {},
      dialogLogout: false,
      profileDialog: false,
      name: store.getters.getUserFullName,
      userType: store.getters.getUserType,
      changePasswordModal: false,
      Id: store.getters.getId,
      currentPassword: '',
      newPassword: '',
      reNewPassword: '',
      showCurrentPassword: false,
      showNewPassword: false,
      showReNewPassword: false,
      updateButtonControl: false,
      changePasswordFlag: store.getters.userPasswordControl,

      //#region Validation Rules
      exampleRules: [
        (v) => !!v || 'Bu Alan zorunludur',
        (v) => (v && v.length >= 8) || 'Bu alan  en az 8 karakter olmalıdır',
        (v) =>
          (v && v.length <= 16) ||
          'Bu alan izin verilen maksimum 16 karakteri aşıyor',
      ],
      exampleRules2: [
        (v) => (v && v.length >= 8) || 'Bu alan  en az 8 karakter olmalıdır',
        (v) =>
          (v && v.length <= 16) ||
          'Bu alan izin verilen maksimum 16 karakteri aşıyor',
        (v) =>
          (v && v == this.newPassword) || 'Bu alan yeni şifre ile uyuşmuyor',
      ],
      exampleRules3: [
        (v) => (v && v.length >= 8) || 'Bu alan  en az 8 karakter olmalıdır',
        (v) =>
          (v && v.length <= 16) ||
          'Bu alan izin verilen maksimum 16 karakteri aşıyor',
        (v) =>
          this.reNewPassword === '' ||
          v == this.reNewPassword ||
          'Bu alan yeni şifre ile uyuşmuyor',
      ],
      //#endregion
    };
  },
  components: { ChangePassword, FormElement, SubscriptionModal },
  computed: {
    fields() {
      if (!this.model) return [];

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        };
      });
    },
    items() {
      return this.entries.map((entry) => {
        const Name =
          entry.Name.length > this.descriptionLimit
            ? entry.Name.slice(0, this.descriptionLimit) + '...'
            : entry.Name;

        return Object.assign({}, entry, { Name });
      });
    },
  },
  mounted() {
    this.$store.state.Token ? this.getProfileInfo() : null;
    if (this.$store.getters.getId) this.getUserDetail();
    //this.$store.state.Token ? this.getUnSeenCount() : null;
  },
  beforeMount() {
    this.url = process.env.VUE_APP_API_URL;
  },
  watch: {
    search(val) {
      // Items have already been loaded
      if (this.items.length > 0) return;

      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;

      // Lazily load input items
      OrderService.globalSearch(val)
        .then((res) => {
          this.count = res.data.TotalItems;
          this.entries = res.data.Result;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    },
    reNewPassword: function (val) {
      if (
        val == this.newPassword &&
        val.length >= 8 &&
        this.currentPassword != ''
      ) {
        this.updateButtonControl = true;
      } else {
        this.updateButtonControl = false;
      }
    },
    currentPassword: function (val) {
      if (
        this.reNewPassword == this.newPassword &&
        this.reNewPassword.length >= 8 &&
        val != '' &&
        val.length >= 8
      ) {
        this.updateButtonControl = true;
      } else {
        this.updateButtonControl = false;
      }
    },
    newPassword: function (val) {
      if (
        this.reNewPassword == val &&
        this.reNewPassword.length >= 8 &&
        val != '' &&
        val.length >= 8
      ) {
        this.updateButtonControl = true;
      } else {
        this.updateButtonControl = false;
      }
    },
  },
  methods: {
    getUserDetail() {
      UserService.getUserDetail(this.$store.getters.getId)
        .then((res) => {
          if (res.data.Result.PendingStatus == 2) {
            this.logout();
          }

          this.pendingStatus = res.data.Result.PendingStatus;
          this.$store.state.PhotoUrl = res.data.Result.PhotoUrl;
          this.$store.state.CompanyName = res.data.Result.CompanyName;
          this.$store.state.PhoneNumber = res.data.Result.PhoneNumber;
          this.$store.state.Email = res.data.Result.Email;
          this.$store.state.RequestCount = res.data.Result.RequestCount || 0;

          this.$store.commit(
            'updateIsPackagePopupVisible',
            res.data.Result.IsPackagePopupVisible
          );
          let roleName = res.data.Result.Roles[0]
            ? res.data.Result.Roles[0]
            : 'Atanmadı';

          this.$store.state.Type = roleName;
        })
        .catch((err) => {
          window.handleServiceError(err);
        });
    },
    handleCloseModal() {
      this.$store.state.loadingStatus = true;
      CompanyServices.updatePackagePopup()
        .then((res) => {
          this.$store.state.IsPackagePopupVisible = false;
        })
        .catch((error) => {
          console.error('Error updating package popup:', error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    changeLanguage(language = 'tr') {
      window.location.reload();
      this.$i18n.locale = language;
      localStorage.setItem('language', language);
      localize(language);
    },

    navigateHome() {
      if (this.$route.name !== 'dashboard')
        this.$router.push({ name: 'createOffer' });
    },
    clearModal() {
      this.changePasswordModal = false;
      this.currentPassword = '';
      this.newPassword = '';
      this.reNewPassword = '';
      this.$refs.observer.reset();
    },
    goProduct(id) {
      this.$router.push({
        name: `productDetail`,
        query: { id },
      });
    },
    returnUser() {
      this.$store.state.loadingStatus = true;
      let body = {
        UserId: this.$store.state.PortalUserId,
      };
      AccountService.portalLogin(body)
        .then((res) => {
          this.portalLogin(res);
          this.updatePortalUserId(null);
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
          if (this.$route.name !== 'dashboard') {
            this.$router.push({ name: 'home' });
          } else {
            window.location.reload();
          }
        });
    },
    LogOut() {
      this.dialogLogout = false;
      this.logout();
    },
    readAll() {
      NotificationService.readAllNotifications()
        .then((res) => {
          this.getUnSeenCount();
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },

    changePassword() {
      this.$store.state.loadingStatus = true;

      let body = {
        OldPassword: this.currentPassword,
        NewPassword: this.newPassword,
      };
      UserService.updatePassword(body)
        .then((res) => {})
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {
          this.$store.state.loadingStatus = false;
          this.changePasswordModal = false;
          this.LogOut();
        });

      this.clearModal();
    },
    openProfile() {
      this.profileDialog = true;
    },
    readNotification(item) {
      if (item.isSeen == false) {
        let body = {
          Id: item.Id,
        };
        NotificationService.readNotification(body)
          .then((res) => {
            this.$router.push(item.ReleatedPage);
          })
          .catch((err) => {
            window.showError(err);
          })
          .then(() => {});
      } else {
        this.$router.push(item.ReleatedPage);
      }
    },
    getUnSeenCount() {
      if (this.$store.getters.getUserType == 'Unassigned') return;
      MessageServices.getUnSeenCount()
        .then((res) => {
          this.unSeenCount = res.data.Result.Total;
        })
        .catch((err) => {
          window.showError(err);
        })
        .then(() => {});
    },
    getProfileInfo() {
      this.profileData = this.$store.state;
    },

    ...mapActions(['logout', 'portalLogin', 'updatePortalUserId']),
  },
};
</script>
<style scoped>
::v-deep .v-toolbar__content {
  width: 100% !important;
  justify-content: space-between;
  padding: 0 !important;
}

.menu-button {
  border-radius: 60px !important;
  text-transform: none;
}

.app-bar-style {
  display: flex !important;
  align-items: center;
  padding: 30px;
  background-color: var(--darkGray) !important;
}

.activeLanguage {
  color: white;
  font-weight: bold;
  font-size: 14px !important;
  margin: 0 2px;
  cursor: pointer;
}

.passiveLanguage {
  color: rgb(187, 181, 181);
  font-weight: bold;
  font-size: 12px !important;
  margin: 0 2px;
  cursor: pointer;
}

.login-style {
  position: absolute;
  display: flex !important;
  align-items: center;
  padding: 40px;

  @media only screen and (min-width: 1000px) {
    padding-right: 3%;
    padding-left: 3%;
  }
  @media only screen and (min-width: 1281px) {
    padding-right: 10%;
    padding-left: 10%;
  }

  background-color: transparent !important;
}
</style>
