<template>
  <v-card elevation="1">
    <v-card-title class="d-flex flex-row justify-center" style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="12" cols="12">
          <h3>{{ $t('navigationDrawerMenu.fixedReports') }}</h3>
        </v-col>
      </v-row>
      <div  v-if="reportKey">
        <v-btn
          @click="downloadExcel"
          :style="'color: white; background-color:var(--primary-color)'"
          class="rounded-circle mr-2"
          style="min-width: 1px; min-height: 1px; width: 36px; height: 36px"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon small color="white" style="font-size: 24px" v-on="on">
                mdi-arrow-down
              </v-icon>
            </template>
            <span>{{ $t('buttons.excelExport') }}</span>
          </v-tooltip>
        </v-btn>
        <vue-excel-xlsx
          style="display: none;"
          :data="excelData"
          :columns="excelHeaders"
          :file-name="`${reportKey}-${Date.now().toString()}`"
          :file-type="'xlsx'"
          :sheet-name="Date.now().toString()"
        >
          <v-btn
            ref="excelButton"
            small
            class="ma-2 mr-4"
            elevation="0"
            outlined
            color="info"
          >
            {{ $t('buttons.excelExport') }}
        </v-btn>
      </vue-excel-xlsx>
        <RefreshButton :refreshFunction="getItems"></RefreshButton>
      </div>
    </v-card-title>
    <v-alert v-if="!reportKey" style="margin-bottom: 0" dense type="warning">{{$t('messages.warningSelectReportKey')}}</v-alert>
    <v-divider />
      <v-row class="my-4 px-4 d-flex justify-start">
        <FormElement
          key="REPORT_KEY_FILTER"
          v-model="reportKey"
          :vertical="true"
          hide-details
          :validationLabel="$t('labels.reportType')"
          :topLabel="true"
          :labelShown="false"
          :cols="6"
          :sm="4"
          :lg="3"
          :onChange="getItems"
          :formType="{
            dense: true,
            name: 'multipleSelect',
            items: {
              data: keys,
              text: 'Value',
              value: 'Key',
              disableAutoSelection: true
            },
          }"
          :placeholder="$t('labels.reportType')"
        />
        <FormElement
          v-if="reportKey"
          key="START_DATE_FILTER"
          :vertical="true"
          v-model="startDate"
          hide-details
          :validationLabel="$t('labels.startDate')"
          :topLabel="true"
          :labelShown="false"
          :cols="6"
          :sm="4"
          :lg="3"
          @input="getItems"
          :formType="{
            dense: true,
            name: 'date',
            dateMode: 'date',
            maximum: dateFilter.maximum
          }"
          :placeholder="$t('labels.startDate')"
        />
        <FormElement
          v-if="reportKey"
          key="End_DATE_FILTER"
          :vertical="true"
          v-model="endDate"
          hide-details
          :validationLabel="$t('labels.endDate')"
          :topLabel="true"
          :labelShown="false"
          :cols="6"
          :sm="4"
          :lg="3"
          @input="getItems"
          :formType="{
            dense: true,
            name: 'date',
            dateMode: 'date',
            minimum: dateFilter.minimum
          }"
          :placeholder="$t('labels.endDate')"
        />
      </v-row>
      <v-divider />
  
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        :loading="$store.state.loadingStatus"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        :item-key="'Id'"
        :no-data-text="$t('messages.noData')"
      >
        <template v-slot:footer>
          <div class="d-flex justify-center mt-8">
            <v-row align="center" justify="center">
              <v-col
                v-if="
                  $vuetify.breakpoint.md ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.xl
                "
                md="2"
              ></v-col>
              <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                <v-pagination
                  v-if="pageLength > 0"
                  class="my-4"
                  @input="onPageChange"
                  v-model="page"
                  :length="pageLength"
                  :total-visible="10"
                  circle
                ></v-pagination>
              </v-col>
              <v-col
                cols="11"
                sm="11"
                md="3"
                lg="3"
                xl="3"
                class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
              >
                <v-select
                  v-model="itemsPerPage"
                  :items="perPageValues"
                  :label="$t('labels.selectItemsPerPage')"
                  item-value="value"
                  item-text="name"
                  outlined
                  dense
                  @change="changeItemsPerPage"
                  rounded
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-slot:no-data>
          <div class="my-10 d-flex flex-column align-center">
            <h4>{{ $t('messages.noData') }}</h4>
          </div>
        </template>
        <template v-slot:item.Rate="{ item }">
          {{ $getFormattedFloatValue(item.Rate) }}
        </template>
        <!-- eslint-disable -->
        <template v-slot:item.Date="{ item }">
          {{ convertToLocal(item.ValidityDate ? item.ValidityDate : item.CreatedOnUtc) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-start">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="actionsModal = true
                  isEditMode = true
                  form = Object.assign({}, item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('buttons.search') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
  </v-card>
</template>
<script>
import CurrencyManagementServices from '../services/CurrencyManagementServices';
import {
  ITEMS_PER_PAGE_OPTIONS,
  getCurrencyTypes,
removeNullValues,
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import RefreshButton from '../components/RefreshButton.vue';
import TabScreen from '../components/TabScreen.vue';
import ReportService from '../services/ReportService';

export default {
  data() {
    return {
      
      excelHeaders: [],
      excelData: [],

      keys: [],
      headers: [],
      items: [],

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],

      reportKey: "",
      startDate: "",
      endDate: "",
      isSupplier: false
    };
  },
  components: { FormElement, RefreshButton, TabScreen },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();

    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';
  },
  watch: {},
  computed: {
    dateFilter(){
      let filter = {
        minimum: null,
        maximum: null
      }

      if(this.startDate) {
        const startDate = new Date(this.startDate);
        startDate.setDate(startDate.getDate() + 1);

        filter.minimum = startDate;
      }

      if(this.endDate){
        const endDate = new Date(this.endDate);
        endDate.setDate(endDate.getDate() - 1);

        filter.maximum = endDate;
      }
      
      return filter;
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    async downloadExcel(){
      await this.getItems(true)
      this.$refs.excelButton.$refs.link.click()
    },
    getKeys(){
      ReportService.getAllReports()
      .then(response => {
        this.keys = response.data;
      })
      .catch(window.showError)
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(date) {
      return formatDate(date, '/');
    },

    changeItemsPerPage() {
      this.page = 1;
      this.getItems();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange() {
      this.getItems();
    },

    async setup() {
      this.getKeys();
      this.getItems();
    },

    /**
     * Get search history from service.
     */
    async getItems(IsExcel = false) {
      IsExcel = typeof IsExcel !== "boolean" ? false : IsExcel;

      if(this.reportKey){
        this.$store.state.loadingStatus = true;
        let parameters = {};
        
        if(!IsExcel){
          parameters = { 
            Page: this.page,
            PageSize: this.itemsPerPage
          };
        }

        await ReportService.getReportByKey({
          Key: this.reportKey,
          StartDate: this.startDate.split("T")[0],
          EndDate: this.endDate.split("T")[0],
          Role: this.isSupplier ? 0 : 1,
          IsExcel,
          ...parameters
        })
        .then((response) => {
          const data = response.data;

          if(!IsExcel){
            this.items = data.Result.PageResult;
            this.totalItems = data.TotalItems;
            
            this.headers = data.Result.Headers.map(item => {
              return {
                text: item.Text,
                value: item.Value,
                sortable: false,
                groupable: false,
                cellClass: 'regular-cell',
                align: 'center',
              }
            })
          }
          else{
            this.excelHeaders = data.Result.ExcelHeaders.map(item => ({
              label: item.Label,
              field: item.Field
            }));
            this.excelData =  data.Result.ExcelResult;
          }
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
      }
      else{
        this.items = [];
        this.totalItems = 0;
        this.headers = [];
      }
    },
  },
};
</script>
<style></style>
