<template>
  <fragment>
    <v-col
      :class="formType.name === 'textarea' ? 'align-self-start' : null"
      v-if="!vertical && active"
      cols="12"
      lg="2"
      xl="2"
      md="2"
      :style="hidden ? 'visibility: hidden;' : ''"
    >
      <div
        :style="
          !labelShown ? 'display:none' : !formLabel ? 'visibility: hidden;' : ''
        "
        :class="
          'mb-lg-9 text-field-label' +
          (light ? ' form-label-light' : ' form-label')
        "
      >
        <label>{{ formLabel }}</label>
      </div>
    </v-col>

    <v-col
      v-if="active"
      :style="hidden ? 'visibility: hidden;' : ''"
      :class="'form-column ' + classes"
      :cols="cols"
      :lg="!vertical ? 10 : lg ? lg : 12"
      :xl="xl"
      :md="md"
      :sm="sm"
    >
      <!-- DATE PICKER START -->
      <vc-date-picker
        ref="dateTimePicker"
        v-if="formType.name === 'date'"
        :min-date="formType.minimum"
        :max-date="formType.maximum"
        :popover="{
          placement: 'bottom-start',
          strategy: 'fixed',
          visibility: 'click',
        }"
        @input="(v) => $emit('input', v ? getFormattedDate(v, false) : '')"
        v-model="selectedDate"
        :mode="formType.dateMode ? formType.dateMode : 'datetime'"
        is24hr
        :timezone="'Europe/Moscow'"
        :attributes="formType.attributes ? [...formType.attributes] : null"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <ValidationProvider
            :name="validationLabel"
            :rules="rules"
            v-slot="{ errors, validate }"
          >
            <v-text-field
              readonly
              :placeholder="placeholder"
              :class="getFormClass"
              outlined
              :dense="formType.dense"
              :value="
                !value
                  ? ''
                  : inputValue ||
                    (value
                      ? formType.dateMode === 'date'
                        ? new Date(value).toLocaleDateString()
                        : new Date(value).toLocaleString()
                      : '')
              "
              v-on="disabled ? null : inputEvents"
              :label="topLabel ? placeholder : null"
              :hide-details="hideDetails"
              :background-color="
                formType.backgroundColor
                  ? formType.backgroundColor
                  : disabled
                  ? '#E0E0E0'
                  : light
                  ? 'white'
                  : ''
              "
              :error-messages="errors[0]"
              type="text"
              :disabled="disabled"
              :counter="formType.counter"
              :append-icon="`${value ? 'mdi-close' : null}`"
              @click:append="() => $emit('input', null)"
            >
              <template v-if="topLabel" v-slot:label>
                <span
                  :class="
                    disabled
                      ? 'custom-label-form-element-disabled'
                      : 'custom-label-form-element'
                  "
                  >{{ placeholder }}
                  <span v-if="isRequired" style="color: #e52d49">*</span></span
                >
              </template>
              <template v-slot:prepend-inner>
                <v-icon color="black">{{ formType.prepend }}</v-icon>
              </template>
            </v-text-field>
          </ValidationProvider>
        </template>
      </vc-date-picker>

      <!-- DATE PICKER END -->
      <ValidationProvider
        v-if="formType.name !== 'date'"
        :name="validationLabel"
        :rules="rules"
        v-slot="{ errors }"
        :ref="$vnode.data.ref"
      >
        <div
          v-if="vertical"
          :style="
            !labelShown
              ? 'display:none'
              : !formLabel
              ? 'visibility: hidden;'
              : ''
          "
          :class="
            'text-field-label ' + (light ? ' form-label-light' : 'form-label')
          "
        >
          <label>{{ formLabel || 'NO_LABEL' }}</label>
        </div>
        <v-autocomplete
          v-if="formType.name === 'multipleSelect'"
          :persistent-placeholder="formType.persistentPlaceholder"
          :style="{ fontSize: '12px' }"
          @input="(v) => $emit('input', v)"
          :value="value"
          :disabled="disabled"
          @change="onChange"
          :items="formType.items.data"
          :item-value="formType.items.value"
          :item-text="formType.items.text"
          :label="topLabel ? placeholder : null"
          :class="getFormClass"
          :multiple="formType.multiple"
          outlined
          :placeholder="placeholder"
          color="black"
          :background-color="
            formType.backgroundColor
              ? formType.backgroundColor
              : disabled
              ? '#E0E0E0'
              : light
              ? 'white'
              : ''
          "
          clearable
          autocomplete="new-password"
          :append-icon="formType.items.appendIcon || 'mdi-magnify'"
          :error-messages="errors[0]"
          :return-object="formType.returnObject"
          :search-input="searchInput"
          @update:search-input="updateSearchInput"
          :dense="formType.dense"
          :hide-details="hideDetails"
          :filter="filter"
        >
          <template v-slot:no-data>
            <div class="no-data d-flex align-center">
              <v-icon color="red">mdi-alert-circle</v-icon>
              <span class="ml-2">{{ $t('messages.noData') }}</span>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <v-chip v-if="formType.multiple" class="custom-select-item">
              <span class="chip-span-content">{{
                autocompleteFormTypeText(item)
              }}</span>
            </v-chip>
            <span v-if="!formType.multiple" class="custom-select-item">{{
              autocompleteFormTypeText(item)
            }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span class="custom-select-item">{{
              autocompleteFormTypeText(item)
            }}</span>
          </template>
          <template v-if="topLabel" v-slot:label>
            <span
              :class="
                disabled
                  ? 'custom-label-form-element-disabled'
                  : 'custom-label-form-element'
              "
              >{{ placeholder }}
              <span v-if="isRequired" style="color: #e52d49">*</span></span
            >
          </template>
          <template v-slot:prepend-inner>
            <v-icon color="black">{{ formType.prepend }}</v-icon>
          </template>
          <template
            v-if="formType.items.specialized || false"
            v-slot:selection1="data"
          >
            <v-chip>
              <v-avatar left>
                <v-img
                  :src="
                    data.item[formType.items.avatar] ||
                    require('../assets/no-image.png')
                  "
                ></v-img>
              </v-avatar>
              {{ data.item.Name }}
            </v-chip>
          </template>
          <template
            v-if="formType.items.specialized || false"
            v-slot:item1="data"
          >
            <v-list-item-avatar>
              <img
                :src="
                  data.item[formType.items.avatar] ||
                  require('../assets/no-image.png')
                "
              />
            </v-list-item-avatar>
            <!-- eslint-disable -->
            <v-list-item-content>
              <v-list-item-title
                v-html="data.item[formType.items.text]"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item[formType.items.subtitle] + '$'"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <v-text-field
          :dense="formType.dense"
          :disabled="disabled"
          :persistent-placeholder="formType.persistentPlaceholder"
          :background-color="
            formType.backgroundColor
              ? formType.backgroundColor
              : disabled
              ? '#E0E0E0'
              : light
              ? 'white'
              : ''
          "
          v-if="
            formType.name === 'text' ||
            formType.name === 'password' ||
            formType.name === 'passwordOnly' ||
            formType.name === 're-password' ||
            formType.name === 'seePassword'
          "
          v-cleave-text-field="maskType"
          :style="{
            fontWeight: 'light',
            color: '#525252',
            marginBottom:
              formType.name === 'password' || formType.name === 'seePassword'
                ? '10px'
                : '0px',
          }"
          :placeholder="placeholder"
          :class="getFormClass"
          :clearable="formType.clearable"
          :autocomplete="formType.autocomplete ? formType.autocomplete : ''"
          outlined
          :prefix="formType.prefix"
          :value="transformedValue"
          :hide-details="hideDetails"
          :label="
            topLabel ? (formType.label ? formType.label : placeholder) : null
          "
          @input="(v) => $emit('input', textTransformFunction(v))"
          @focus="focused = true"
          @blur="focused = false"
          @change="onChange"
          :error-messages="errors[0]"
          :type="
            formType.name === 're-password' || formType.name === 'passwordOnly'
              ? 'password'
              : formType.name === 'seePassword'
              ? 'text'
              : formType.name
          "
          :counter="formType.counter"
          :prepend-icon="formType.prependIcon"
          @click:prepend="formType.prependClick()"
          :persistent-hint="formType.persistentHint"
          :hint="formType.hint"
        >
          <template v-slot:message="item">
            <span v-if="!errors.length" class="custom-hint">
              {{ formType.hint }}
            </span>
            <span v-else>{{ item.message }}</span>
            <span v-if="formType.isMagnify" style="margin-left: 8px">
              <v-btn icon @click="openURL" small>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="blue"
                      style="font-size: 20px"
                      v-on="on"
                    >
                      mdi-magnify
                    </v-icon>
                  </template>
                  <span>{{ $t(formType.magnifyLabel) }}</span>
                </v-tooltip>
              </v-btn>
            </span>
          </template>

          <template v-if="topLabel" v-slot:label>
            <span
              :class="
                disabled
                  ? 'custom-label-form-element-disabled'
                  : 'custom-label-form-element'
              "
              >{{ placeholder }}
              <span v-if="isRequired" style="color: #e52d49">*</span></span
            >
          </template>
          <template v-slot:prepend-inner>
            <v-icon color="black">{{ formType.prepend }}</v-icon>
          </template>
          <template v-if="formType.appendIcon" v-slot:append>
            <v-icon @click="formType.appendClick()">
              {{ formType.appendIcon }}
            </v-icon>
          </template>
          <template v-if="formType.appendOuterIcon" v-slot:append-outer>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="formType.appendOuterClick()"
                >
                  {{ formType.appendOuterIcon }}
                </v-icon>
              </template>
              <span>{{ formType.appendOuterText }}</span>
            </v-tooltip>
          </template>
        </v-text-field>

        <div
          v-if="
            (formType.name === 'password' && focused) ||
            (formType.name === 'seePassword' && focused)
          "
          class="p-3 mb-4 text-caption text-sm-caption text-md-subtitle-2 text-lg-subtitle-2 text-xl-body-1 text-xxl-body-1 text-truncate"
          style="
            padding: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            border-radius: 15px;
            border: 1px solid #efefef;
            background-color: #ffffff;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          "
        >
          <div class="d-flex flex-col py-1 align-center">
            <v-icon
              small
              :color="minLength ? 'var(--green)' : 'var(--red)'"
              class="mr-2"
              style="font-size: 32px"
            >
              {{ minLengthIcon }}
            </v-icon>
            <p :style="{ color: minLengthColor }" class="my-auto">
              {{ $t('labels.minLength') }}
            </p>
          </div>
          <div class="d-flex flex-col py-1 align-center">
            <v-icon
              small
              :color="maxLength ? 'var(--green)' : 'var(--red)'"
              class="mr-2"
              style="font-size: 32px"
            >
              {{ maxLengthIcon }}
            </v-icon>
            <p :style="{ color: maxLengthColor }" class="my-auto">
              {{ $t('labels.maxLength') }}
            </p>
          </div>
          <div class="d-flex flex-col py-1 align-center">
            <v-icon
              small
              :color="uppercase ? 'var(--green)' : 'var(--red)'"
              class="mr-2"
              style="font-size: 32px"
            >
              {{ uppercaseIcon }}
            </v-icon>
            <p :style="{ color: uppercaseColor }" class="my-auto">
              {{ $t('labels.uppercase') }}
            </p>
          </div>
          <div class="d-flex flex-col py-1 align-center">
            <v-icon
              small
              :color="lowercase ? 'var(--green)' : 'var(--red)'"
              class="mr-2"
              style="font-size: 32px"
            >
              {{ lowercaseIcon }}
            </v-icon>
            <p :style="{ color: lowercaseColor }" class="my-auto">
              {{ $t('labels.lowercase') }}
            </p>
          </div>
          <div class="d-flex flex-col py-1 align-center">
            <v-icon
              small
              :color="digit ? 'var(--green)' : 'var(--red)'"
              class="mr-2"
              style="font-size: 32px"
            >
              {{ digitIcon }}
            </v-icon>
            <p :style="{ color: digitColor }" class="my-auto">
              {{ $t('labels.digit') }}
            </p>
          </div>
          <div class="d-flex flex-col py-1 align-center">
            <v-icon
              small
              :color="specialCharacter ? 'var(--green)' : 'var(--red)'"
              class="mr-2"
              style="font-size: 32px"
            >
              {{ specialCharacterIcon }}
            </v-icon>
            <p :style="{ color: specialCharacterColor }" class="my-auto">
              {{ $t('labels.special') }}
            </p>
          </div>
        </div>
        <v-select
          :background-color="
            formType.backgroundColor
              ? formType.backgroundColor
              : disabled
              ? '#E0E0E0'
              : light
              ? 'white'
              : ''
          "
          :disabled="disabled"
          v-if="formType.name === 'select'"
          :items="formType.items.data"
          :placeholder="placeholder"
          :class="getFormClass"
          :item-value="formType.items.value"
          :item-text="formType.items.text"
          outlined
          :label="topLabel ? placeholder : null"
          @input="(v) => $emit('input', v)"
          :value="value"
          @change="onChange"
          variant="underlined"
          append-icon="keyboard_arrow_down"
          :hide-details="hideDetails"
          :error-messages="errors[0]"
          :no-data-text="$t('messages.listEmpty')"
          :dense="formType.dense"
        >
          <template v-slot:no-data>
            <div class="no-data d-flex align-center">
              <v-icon color="red">mdi-alert-circle</v-icon>
              <span class="ml-2">{{ $t('messages.noData') }}</span>
            </div>
          </template>
          <template v-slot:selection="{ item }">
            <span class="custom-select-item custom-selected-item">{{
              autocompleteFormTypeText(item)
            }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span class="custom-select-item">{{
              autocompleteFormTypeText(item)
            }}</span>
          </template>
          <template v-if="topLabel" v-slot:label>
            <span
              :class="
                disabled
                  ? 'custom-label-form-element-disabled'
                  : 'custom-label-form-element'
              "
              >{{ placeholder }}
              <span v-if="isRequired" style="color: #e52d49">*</span></span
            >
          </template>
          <template v-slot:prepend-inner>
            <v-icon color="black">{{ formType.prepend }}</v-icon>
          </template>
        </v-select>
        <v-textarea
          v-if="formType.name === 'textarea'"
          :style="{ fontSize: '12px' }"
          :placeholder="placeholder"
          :background-color="
            formType.backgroundColor
              ? formType.backgroundColor
              : disabled
              ? '#E0E0E0'
              : light
              ? 'white'
              : ''
          "
          :class="getFormClass"
          outlined
          :label="topLabel ? placeholder : null"
          :value="transformedValue"
          :counter="formType.counter"
          @input="(v) => $emit('input', textTransformFunction(v))"
          :error-messages="errors[0]"
          :disabled="disabled"
        >
          <template v-slot:selection="{ item }">
            <span class="custom-select-item custom-selected-item">{{
              item[formType.items.text]
            }}</span>
          </template>
          <template v-slot:item="{ item }">
            <span class="custom-select-item">{{
              item[formType.items.text]
            }}</span>
          </template>
          <template v-if="topLabel" v-slot:label>
            <span
              :class="
                disabled
                  ? 'custom-label-form-element-disabled'
                  : 'custom-label-form-element'
              "
              >{{ placeholder }}
              <span v-if="isRequired" style="color: #e52d49">*</span></span
            >
          </template>
          <template v-slot:prepend-inner>
            <v-icon color="black">{{ formType.prepend }}</v-icon>
          </template>
        </v-textarea>
        <div v-if="false">
          <v-checkbox
            :placeholder="placeholder"
            color="white"
            :label="placeholder"
            style="background-color: aquamarine"
            :value="value"
            @input="(v) => $emit('input', v.target.checked)"
            :error-messages="errors[0]"
            hide-details
          ></v-checkbox>
        </div>
        <div
          v-if="formType.name === 'checkbox'"
          class="d-flex flex-row align-center"
        >
          <input
            @input="(v) => $emit('input', v.target.checked)"
            type="checkbox"
            @change="onChange"
            :checked="value"
            :disabled="disabled"
            :class="'my-2 mr-2 checkbox custom-checkbox'"
            style="
              min-width: 30px;
              max-width: 30px;
              min-height: 30px;
              max-height: 30px;
              background-color: #f2f5f9 !important;
            "
          />
          <label
            style="
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            "
            :class="light ? 'checkbox-light' : 'checkbox-dark'"
            >{{ placeholder }}</label
          >
        </div>
        <v-row
          v-if="formType.name === 'file'"
          :class="fileStyling ? 'd-lg-flex my-6' : ''"
          :style="fileStyling ? 'height: 100%; ' : ''"
        >
          <v-col
            v-if="formType.type === 'image'"
            class="d-flex justify-center align-center"
            cols="12"
            lg="4"
          >
            <v-img
              :lazy-src="base64 || formType.data.lazy"
              cover
              max-width="150px"
              height="200"
              :src="
                base64 ||
                formType.data.source ||
                require('../../public/img/no-image.png')
              "
            ></v-img>
          </v-col>
          <v-col
            cols="12"
            :lg="formType.type === 'image' ? 8 : 12"
            class="align-self-center"
          >
            <v-file-input
              :disabled="disabled"
              :show-size="formType.showSize"
              :placeholder="placeholder"
              :accept="formType.data.accept"
              :multiple="formType.data.multiple"
              :hide-details="hideDetails"
              prepend-icon=""
              :append-icon="
                formType.type === 'image' ? 'mdi-camera' : 'mdi-file'
              "
              ref="fileInput"
              :label="topLabel ? placeholder : null"
              :class="getFormClass"
              outlined
              :value="value"
              @change="
                (v) => {
                  $emit('input', v);
                  onChange(v);
                  onChangeImage(v);
                }
              "
              :error-messages="errors[0]"
            >
              <template v-if="topLabel" v-slot:label>
                <span
                  :class="
                    disabled
                      ? 'custom-label-form-element-disabled'
                      : 'custom-label-form-element'
                  "
                  >{{ placeholder }}
                  <span v-if="isRequired" style="color: #e52d49">*</span></span
                >
              </template>
              <template v-slot:prepend-inner>
                <v-icon color="black">{{ formType.prepend }}</v-icon>
              </template>
              <template v-slot:append>
                <v-icon @click="$refs.fileInput?.$refs?.input?.click()">{{
                  formType.type === 'image' ? 'mdi-camera' : 'mdi-file'
                }}</v-icon>
              </template>
            </v-file-input>
          </v-col>
        </v-row>
      </ValidationProvider>
    </v-col>
  </fragment>
</template>
<script>
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import formatDate from '../utils/formatDate';
import { convertToEnglishLetters } from '../utils/constants';

export default {
  constructor: () => {},
  name: 'FormElement',
  props: {
    textTransform: {
      type: String,
      default: 'none',
    },
    classes: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: String | Number | Boolean | File | Array,
      default() {
        return;
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    vertical: {
      type: Boolean,
      default: true,
    },
    value: {
      type: String | Number | Boolean | File | Array,
      default() {
        return;
      },
    },
    validationLabel: {
      type: String,
      default: '',
    },
    formLabel: {
      type: String,
      default: '',
    },
    formType: {
      type: Object,
      default(rawProps) {
        return {
          name: 'text',
          items: {
            data: [],
            text: 'text',
            value: 'id',
          },
          data: {
            lazy: null,
            source: null,
            accept: 'image/png, image/jpeg, image/bmp',
          },
          showSize: false,
        };
      },
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    topLabel: {
      type: Boolean,
      default: false,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    placeholder: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: String | Object | Array,
      default() {
        return;
      },
    },
    lg: {
      type: Number,
      default: 12,
    },
    xl: {
      type: Number,
      default: null,
    },
    md: {
      type: Number,
      default: null,
    },
    sm: {
      type: Number,
      default: null,
    },
    cols: {
      type: Number,
      default: 12,
    },
    light: {
      type: Boolean,
      default: false,
    },
    labelShown: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
    isQuickSearch: {
      type: Boolean,
      default: false,
    },
    willBeEliminated: {
      type: Boolean,
      default: true,
    },
    mask: {
      type: String,
      default: '',
    },
    fileStyling: {
      type: Boolean,
      default: true,
    },
  },
  mounted() {
    this.refreshSelectboxes();

    if (this.formType.name === 'date') {
      this.selectedDate = this.value;
      this.temporaryDate = this.value;

      /*if (
        this.selectedDate &&
        this.selectedDate.search('T') > -1 &&
        this.selectedDate.search('Z') === -1
      ) {
        this.selectedDate += 'Z';
      }*/
    }

    //this.rawValue = this.value;
  },
  updated() {
    if (this.temporaryDate !== this.value && this.formType.name === 'date') {
      this.selectedDate = this.value;
      this.temporaryDate = this.value;

      /*if (
        this.selectedDate &&
        this.selectedDate.search('T') > -1 &&
        this.selectedDate.search('Z') === -1
      ) {
        this.selectedDate += 'Z';
      }*/
    }

    this.refreshSelectboxes();

    if (this.value === null || this.value === undefined) {
      this.$emit('input', this.defaultValue);
    }

    // if(this.rawValue !== this.value){
    //   this.rawValue = this.value;
    // }
  },
  data() {
    return {
      temporaryRules: [],
      nowDate: new Date().toISOString().slice(0, 10),
      refreshed: false,

      focused: false,
      minLength: false,
      maxLength: false,
      uppercase: false,
      lowercase: false,
      digit: false,
      specialCharacter: false,
      minLengthColor: 'red',
      maxLengthColor: 'red',
      uppercaseColor: 'red',
      lowercaseColor: 'red',
      digitColor: 'red',
      specialCharacterColor: 'red',

      selectedDate: null,
      temporaryDate: '',

      // Autocomplete
      searchInput: '',

      // Image
      base64: '',

      //rawValue: ""
    };
  },
  beforeDestroy() {
    this.base64 = '';
  },
  methods: {
    /**
     * Filter Items
     * @param {any} item
     * @param {string} queryText
     * @param {string} itemText
     */
    filter(item, queryText, itemText) {
      const isOnlyEnglish = this.$vnode.key
        ? this.$vnode.key.search(
            /(COUNTRY|CITY|country|city|Country|City|From|To|from|to|TO|FROM)/
          ) > -1
        : false;
      const value = isOnlyEnglish
        ? convertToEnglishLetters(queryText?.toLocaleUpperCase())
        : queryText;
      const regex = new RegExp(`${value}`, 'i');
      return regex.test(itemText);
    },
    openURL() {
      this.$emit('openURL');
    },
    autocompleteFormTypeText(item) {
      return typeof this.formType.items.text === 'function'
        ? this.formType.items.text(item)
        : item[this.formType.items.text];
    },

    /**
     * onChangeImage trigger.
     */
    onChangeImage(file) {
      if (file) {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.base64 = reader.result;
        };
      }
    },

    // Methods For Autocompletes
    updateSearchInput(value) {
      let userValue = value;
      if (userValue) {
        this.searchInput = this.formType.textTransform
          ? this.textTransformFunction(value)
          : userValue;
      }
    },
    // Methods For Autocompletes END

    getKeys(k) {
      const onFunctions = Object.assign({}, k);
      delete onFunctions.input;
      delete onFunctions.change;
      delete onFunctions.keyup;

      return onFunctions;
    },
    /**
     * @param {string} value
     */
    textTransformFunction(value) {
      // this.rawValue = value;
      // value = value?.trim();

      const isOnlyEnglish = this.$vnode.key
        ? this.$vnode.key.search(/(COUNTRY|CITY|country|city|Country|City)/) >
          -1
        : false;

      switch (this.formType.textTransform) {
        case 'lowercase':
          return value.toLocaleLowerCase();
        case 'uppercase':
          return isOnlyEnglish
            ? convertToEnglishLetters(value?.toLocaleUpperCase())
            : value?.toLocaleUpperCase();
        default:
          return value;
      }
    },
    showSubtext(type) {
      if (!this.value || this.value === '') {
        this.minLength = false;
        this.minLengthColor = 'red';
        this.maxLength = false;
        this.maxLengthColor = 'red';
        this.uppercase = false;
        this.uppercaseColor = 'red';
        this.lowercase = false;
        this.lowercaseColor = 'red';
        this.digit = false;
        this.digitColor = 'red';
        this.specialCharacter = false;
        this.specialCharacterColor = 'red';
        return;
      }

      const value = this.value || '';
      const minLength = value.length >= 6;
      const maxLength = value.length <= 24;
      const hasUppercase = /[A-Z]/.test(value);
      const hasLowercase = /[a-z]/.test(value);
      const hasDigit = /\d/.test(value);
      const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

      switch (type) {
        case 'minLength':
          this.minLength = minLength;
          this.minLengthIcon = this.minLength
            ? 'mdi-check-circle-outline'
            : 'mdi-close-circle-outline';
          this.minLengthColor = this.minLength ? 'green' : 'red';
        case 'maxLength':
          this.maxLength = maxLength;
          this.maxLengthIcon = this.maxLength
            ? 'mdi-check-circle-outline'
            : 'mdi-close-circle-outline';
          this.maxLengthColor = this.maxLength ? 'green' : 'red';
        case 'uppercase':
          this.uppercase = hasUppercase;
          this.uppercaseIcon = this.uppercase
            ? 'mdi-check-circle-outline'
            : 'mdi-close-circle-outline';
          this.uppercaseColor = this.uppercase ? 'green' : 'red';
        case 'lowercase':
          this.lowercase = hasLowercase;
          this.lowercaseIcon = this.lowercase
            ? 'mdi-check-circle-outline'
            : 'mdi-close-circle-outline';
          this.lowercaseColor = this.lowercase ? 'green' : 'red';
        case 'digit':
          this.digit = hasDigit;
          this.digitIcon = this.digit
            ? 'mdi-check-circle-outline'
            : 'mdi-close-circle-outline';
          this.digitColor = this.digit ? 'green' : 'red';
        case 'specialCharacter':
          this.specialCharacter = hasSpecialCharacter;
          this.specialCharacterIcon = this.specialCharacter
            ? 'mdi-check-circle-outline'
            : 'mdi-close-circle-outline';
          this.specialCharacterColor = this.specialCharacter ? 'green' : 'red';
      }
    },
    refreshSelectboxes() {
      if (!this.refreshed && this.formType.items && this.formType.items.data) {
        if (
          this.formType.name === 'select' ||
          this.formType.name === 'multipleSelect'
        ) {
          if (
            this.formType.items.data.length === 1 &&
            this.formType.items.value &&
            !this.formType.items.disableAutoSelection
          ) {
            this.$emit(
              'input',
              this.formType.items.data[0][this.formType.items.value]
            );
            this.refreshed = true;
            this.onChange();
          }
        }
      }
    },
    getFormattedDate(date, isInput = true) {
      if (isInput)
        return convertToLocal(
          typeof date === 'string' ? new Date(date) : date,
          '.',
          this.$i18n.locale
        );
      else {
        if (date === null || date === undefined) return null;
        const pad = (num) => String(num).padStart(2, '0');

        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
      }

      return date.toISOString();
    },
  },
  watch: {
    formType() {
      this.refreshSelectboxes();
    },
    active(newValue) {
      if (!newValue && this.willBeEliminated) {
        this.$emit('input', this.value ? this.value + '_NA' : '_NA');
      } else {
        this.refreshSelectboxes();
        if (this.value) {
          const assigned = this.value.toString().replace('_NA', '');
          this.$emit(
            'input',
            typeof this.value === 'number' ? parseInt(assigned) : assigned
          );
        }
      }
    },
  },
  computed: {
    dateTimeModel() {
      if (!this.timeComparing) return {};

      return {
        timeAdjust: this.formType.minimum.replace('Z', '').split('T')[1],
      };
    },
    timeComparing() {
      if (!this.formType.minimum) return false;
      const minimumDate = new Date(this.formType.minimum.replace('Z', ''));

      let selectedDate = this.selectedDate;

      if (typeof this.selectedDate === 'string')
        selectedDate = new Date(this.selectedDate.replace('Z', ''));

      return selectedDate < minimumDate;
    },
    transformedValue() {
      //if (!this.rawValue) return '';

      const transformValue = this.formType.textTransform;
      return transformValue === 'uppercase'
        ? this.value?.toLocaleUpperCase()
        : transformValue === 'lowercase'
        ? this.value?.toLocaleLowerCase()
        : this.value;
    },
    isRequired() {
      const type = typeof this.rules;
      if (type === 'object') return !!this.rules.required;
      else if (type === 'string') return !!this.rules.match('required');
    },
    maskType() {
      if (this.formType.maskType) {
        if (this.formType.maskType === 'phone') {
          return {
            enabled: true,
            options: {
              delimiters: ['(', ') ', '-', '-'],
              blocks: [0, 3, 3, 4, 5],
              numericOnly: true,
              delimiterLazyShow: true,
            },
          };
        } else if (this.formType.maskType === 'money') {
          return {
            enabled: true,
            options: {
              numeral: true,
              numeralIntegerScale: 15,
              numericOnly: true,
              numeralPositiveOnly: true,
            },
          };
        }
      }

      if (this.formType.mask) return this.formType.mask;

      return {
        enabled: false,
      };
    },
    getFormClass() {
      let classes = '';
      if (this.isQuickSearch) {
        console.log('isQuickSearch');
        classes = 'custom-border-quick-search';
        return classes;
      }
      classes += 'custom-border';
      if (this.$props.light) {
        classes += ' custom-border-light';
      }
      if (this.formType.persistentHint) {
        classes += ' custom-border-hint';
      }

      return classes;
    },
    minLengthIcon: {
      get() {
        this.showSubtext('minLength');
        return this.minLength
          ? 'mdi-check-circle-outline'
          : 'mdi-close-circle-outline';
      },
      set(value) {},
    },
    maxLengthIcon: {
      get() {
        return this.maxLength
          ? 'mdi-check-circle-outline'
          : 'mdi-close-circle-outline';
      },
      set(value) {},
    },
    uppercaseIcon: {
      get() {
        return this.uppercase
          ? 'mdi-check-circle-outline'
          : 'mdi-close-circle-outline';
      },
      set(value) {},
    },
    lowercaseIcon: {
      get() {
        return this.lowercase
          ? 'mdi-check-circle-outline'
          : 'mdi-close-circle-outline';
      },
      set(value) {},
    },
    digitIcon: {
      get() {
        return this.digit
          ? 'mdi-check-circle-outline'
          : 'mdi-close-circle-outline';
      },
      set(value) {},
    },
    specialCharacterIcon: {
      get() {
        return this.specialCharacter
          ? 'mdi-check-circle-outline'
          : 'mdi-close-circle-outline';
      },
      set(value) {},
    },
  },
};
</script>

<style>
.no-border .v-field--variant-underlined .v-field__outline::before {
  border-width: 0;
}
.custom-hint {
  color: #0078d4;
}
</style>
