<template>
  <v-card style="border-radius: 10px; padding: 16px">
    <v-dialog
      v-model="subscriptionModal"
      @input="handleCloseModal"
      max-width="1500"
    >
      <SubscriptionModal
        :dialogOpen="subscriptionModal"
        @close-modal="handleCloseModal"
      />
    </v-dialog>

    <div class="flex w-full rounded-tl-sm rounded-tr-sm">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>
              {{ $t('labels.subscriptionManagement') }}
            </h3>
          </v-col>
        </v-row>
      </v-card-title>
    </div>
    <div
      class="d-flex border w-full"
      style="
        width: 100%;
        border-width: 1px;
        border-color: #f0f0f4;
        border-style: solid;
        border-radius: 20px;
        margin-right: 5px;
        margin-bottom: 5px;
        padding: 16px;
      "
    >
      <!-- Subscription Info -->
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="1"
          class="d-flex justify-center align-center"
        >
          <v-img
            v-if="currentSubscription.PhotoUrl"
            :src="currentSubscription.PhotoUrl"
            alt="Package Image"
            class="rounded"
            min-width="100"
            max-width="100"
            min-height="100"
            max-height="100"
          ></v-img>
          <div
            v-else
            style="background-color: #e3fbff; border-radius: 100%"
            class="px-4 py-4"
          >
            <v-icon style="font-size: 42px" color="#44AFC1">
              mdi-timer-refresh-outline
            </v-icon>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="9">
          <v-row :class="smBreakpointClass">
            <v-col
              cols="12"
              :class="
                $vuetify.breakpoint.sm
                  ? 'd-flex justify-center align-center'
                  : ''
              "
            >
              <h3>{{ currentSubscription.PackageName }}</h3>
            </v-col>
            <v-col cols="12" sm="6" style="padding-top: 0; margin-top: 0">
              <p v-if="currentSubscription.PackageDescription">
                <strong>{{ $t('labels.description') }}:</strong>

                {{ currentSubscription.PackageDescription }}
              </p>
            </v-col>
            <v-col cols="12" sm="6" style="padding-top: 0; margin-top: 0">
              <p v-if="currentSubscription.PackageEndDate">
                <strong>{{ $t('labels.endDate') }}:</strong>

                {{
                  getFormattedDate(new Date(currentSubscription.PackageEndDate))
                }}
              </p>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              v-if="currentSubscription.SumSearchCount"
              style="padding-top: 0; margin-top: 0"
            >
              <p>
                <strong>{{ $t('labels.monthlyQueryCount') }}:</strong>
                <v-chip
                  class="ma-2 text--center"
                  small
                  color="#c5f4db"
                  text-color="#2b3838"
                  style="font-size: 16px"
                >
                  {{ currentSubscription.SearchCount }}&nbsp;/{{
                    currentSubscription.SumSearchCount
                  }}
                </v-chip>
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="currentSubscription.SumOrderCount"
              style="padding-top: 0; margin-top: 0"
            >
              <p>
                <strong>{{ $t('labels.orderCount') }}:</strong>
                <v-chip
                  class="ma-2 text--center"
                  small
                  color="#c5f4db"
                  text-color="#2b3838"
                  style="font-size: 16px"
                >
                  {{ currentSubscription.OrderCount }}&nbsp;/
                  {{
                    currentSubscription.SumOrderCount == -1
                      ? $t('labels.limitless')
                      : currentSubscription.SumOrderCount
                  }}
                </v-chip>
              </p>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              v-if="currentSubscription.SumPortalCount"
              style="padding-top: 0; margin-top: 0"
            >
              <p>
                <strong>{{ $t('labels.maxPortalCount') }}:</strong>
                <v-chip
                  class="ma-2 text--center"
                  small
                  color="#c5f4db"
                  text-color="#2b3838"
                  style="font-size: 16px"
                >
                  {{ currentSubscription.PortalCount }}&nbsp;/
                  {{
                    currentSubscription.SumPortalCount == -1
                      ? $t('labels.limitless')
                      : currentSubscription.SumPortalCount
                  }}
                </v-chip>
              </p>
            </v-col>
          </v-row></v-col
        >
        <v-col
          cols="12"
          sm="12"
          md="12"
          lg="2"
          class="d-flex justify-center justify-lg-end align-center"
        >
          <v-btn
            color="primary"
            style="
              color: #fff;
              text-transform: unset !important;
              background-color: #0077d5;
              padding: 1vh 1vh;
              border-radius: 30px !important;
              font-weight: bolder;
              font-size: medium;
              align-self: center;
            "
            elevation="0"
            class="darken-1"
            type="submit"
            @click="upgradeSubscription"
          >
            <v-icon
              style="
                font-size: 24px;
                color: #ffffff !important;
                margin-right: 8px;
              "
              >mdi-arrow-up-circle-outline</v-icon
            >
            {{ $t('labels.upgrade') }}
          </v-btn></v-col
        >
      </v-row>
    </div>

    <v-data-table
      :headers="headers"
      :items="statistics"
      :hide-default-footer="true"
      :footer-props="{
        disablePagination: true,
        disableItemsPerPage: true,
      }"
      :no-data-text="$t('description.noList')"
      ref="orderList"
    >
      <template v-slot:item.StartDate="{ item }">
        <span>{{ getFormattedDate(new Date(item.StartDate)) }}</span>
      </template>
      <template v-slot:item.EndDate="{ item }">
        <span>{{ getFormattedDate(new Date(item.EndDate)) }}</span>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CompanyPackageService from '../services/CompanyPackageService';
import SubscriptionModal from '../components/SubscriptionModal.vue';
import formatDate from '../utils/formatDate';
import { h } from 'vue';

export default {
  data() {
    return {
      currentSubscription: {},
      statistics: [],
      headers: [
        {
          text: this.$t('labels.name'),
          align: 'start',
          value: 'PackageName',
        },
        {
          text: this.$t('labels.queryCount'),
          value: 'SearchCount',
        },
        {
          text: this.$t('adminHeaders.OrderCount'),
          value: 'OrderCount',
        },
        {
          text: this.$t('packageModal.integrationCommission'),
          value: 'IntegrationCommisions',
        },

        {
          text: this.$t('packageModal.portalCommision'),
          value: 'PortalCommisions',
        },
        {
          text: this.$t('packageModal.shipmentCommision'),
          value: 'ShipmentCommisions',
        },
        {
          text: this.$t('labels.startDate'),
          value: 'StartDate',
        },
        {
          text: this.$t('labels.endDate'),
          value: 'EndDate',
        },
      ],
      subscriptionModal: false,
      userRole: null,
    };
  },

  components: {
    SubscriptionModal,
  },

  mounted() {
    this.getSubscriptionInfo();
    this.getStatistics();
    this.userRole = this.$store.getters.getUserType;
    if (this.userRole === 'Customer') {
      this.headers = this.headers.filter(
        (header) =>
          ![
            'IntegrationCommisions',
            'PortalCommisions',
            'ShipmentCommisions',
          ].includes(header.value)
      );
    } else {
      this.headers = this.headers.filter(
        (header) => header.value !== 'SearchCount'
      );
    }
  },

  computed: {
    smBreakpointClass() {
      return this.$vuetify.breakpoint.sm
        ? 'd-flex justify-center align-center'
        : '';
    },
  },

  methods: {
    getFormattedDate(
      date,
      splitter = '/',
      format = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }]
    ) {
      return formatDate(date, splitter, 'tr', format);
    },
    handleCloseModal() {
      this.subscriptionModal = false;
    },
    getStatistics() {
      this.$store.state.loadingStatus = true;

      CompanyPackageService.getStatistics()
        .then((response) => {
          this.statistics = response.data.Result;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getSubscriptionInfo() {
      this.$store.state.loadingStatus = true;

      CompanyPackageService.getAllUsage()
        .then((response) => {
          this.currentSubscription = response.data.Result;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    upgradeSubscription() {
      this.subscriptionModal = true;
    },
  },
};
</script>

<style scoped>
.subscription-info h3 {
  margin-bottom: 8px;
}

.subscription-info p {
  margin-bottom: 4px;
}
</style>
