// Örnek servis codiagno login
import axios from '../javascript/custom-axios';
let RESOURCE_NAME = 'patial-price';

export default {
  add(body) {
    const formData = new FormData();
    formData.append('ListName', body.ListName);
    formData.append('OriginCountryId', body.CountryId);
    formData.append('CurrencyCode', body.CurrencyCode);
    formData.append('Regions', body.Regions);
    formData.append('Prices', body.Prices);
    formData.append('ShipmentType', body.ShipmentType);
    formData.append('ValidityStartDate', body.ValidityStartDate);
    formData.append('ValidityEndDate', body.ValidityEndDate);

    return axios.post(`${RESOURCE_NAME}/add`, formData);
  },

  getPriceList(page, pageSize) {
    return axios.get(`${RESOURCE_NAME}/get-price-list`, {
      params: {
        page,
        pageSize,
      },
    });
  },

  getPriceDetail(id) {
    return axios.get(`${RESOURCE_NAME}/get-price-detail`, {
      params: {
        Id: id,
      },
    });
  },

  updatePriceExcel(body) {
    const formData = new FormData();
    formData.append('PartialPriceId', body.PartialPriceId);
    formData.append('PriceExcel', body.PriceExcel);

    return axios.put(`${RESOURCE_NAME}/update-price-excel`, formData);
  },

  updateRegionExcel(body) {
    const formData = new FormData();
    formData.append('RegionExcelId', body.RegionExcelId);
    formData.append('RegionExcel', body.RegionExcel);

    return axios.put(`${RESOURCE_NAME}/update-region-excel`, formData);
  },

  updateActivityStatus(body) {
    return axios.put(`${RESOURCE_NAME}/update-activity-status`, body);
  },

  updateExpiryDate(expiryDateData) {
    return axios.put(`${RESOURCE_NAME}/update-expiry-date`, expiryDateData);
  },

  updateName(body) {
    return axios.put(`${RESOURCE_NAME}/update-list-name`, body);
  },
};
