<template>
  <div>
    <v-dialog v-model="addAPIModal" max-width="600">
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('buttons.add') }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver ref="addAPIObserver" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submitAPIForm)">
            <v-card-text>
              <v-row>
                <FormElement
                  v-for="(element, index) in formItems"
                  :key="
                    element.key.parent + '_' + element.key.child + '_' + index
                  "
                  :vertical="element.vertical"
                  v-model="$data[element.key.parent][element.key.child]"
                  :validationLabel="$t(element.validationLabel)"
                  :rules="element.rules"
                  :formLabel="$t(element.formLabel)"
                  :onChange="element.onChange"
                  :formType="element.formType"
                  :cols="element.cols"
                  :sm="element.sm"
                  :md="element.md"
                  :lg="element.lg"
                  :light="false"
                  :hidden="element.hidden"
                  :labelShown="element.labelShown"
                  :topLabel="element.topLabel"
                  :active="element.active"
                  :disabled="element.disabled"
                  :placeholder="$t(element.placeholder)"
                />
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="$store.state.loadingStatus"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="addAPIModal = false"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row justify="space-between">
            <v-col
              class="justify-start justify-lg-end d-flex align-center"
              cols="6"
              sm="4"
              md="5"
              lg="6"
              xl="6"
            >
              <h3>{{ $t('navigationDrawerMenu.publicApi') }}</h3>
            </v-col>

            <v-col
              cols="6"
              class="py-1 py-lg-0 px-1 d-flex justify-end items-center align-center"
            >
              <h5 class="mr-4">
                <v-icon color="var(--primary-color)">mdi-text-box-search-outline</v-icon>
                <a target="_blank" class="ml-2" href="http://devapi.com.s3-website-eu-west-1.amazonaws.com/">API Dokümantasyonu</a>
              </h5>
              <v-btn
                v-if="apis.length === 0"
                @click="addAPIModal = true"
                :style="'color: white; background-color:#0177d4'"
                class="rounded-circle mr-2"
                style="
                  min-width: 1px;
                  min-height: 1px;
                  width: 36px;
                  height: 36px;
                "
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="white"
                      style="font-size: 24px"
                      v-on="on"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>{{ $t('buttons.add') }}</span>
                </v-tooltip>
              </v-btn>

              <RefreshButton :refreshFunction="getAPIs"></RefreshButton>
            </v-col>
          </v-row>
        </v-card-title>
      </div>

      <v-divider />
      <v-card-text fill-height fluid>
        <v-data-table
          :headers="headers"
          :items="apis"
          :options.sync="options"
          :footer-props="{
            disablePagination: true,
            disableItemsPerPage: true,
          }"
          :no-data-text="$t('description.noList')"
          style="padding: 0 16px"
          hide-default-footer
          :loading="$store.state.loadingStatus"
          ><!-- eslint-disable -->

          <template v-slot:item.ClientId="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ item.ClientId }}
            </div>
          </template>
          <template v-slot:item.SecretKey="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ item.SecretKey }}
            </div>
          </template>
          <template v-slot:item.UserName="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ item.UserName }}
            </div>
          </template>
          <template v-slot:item.WebhookToken="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ item.WebhookToken }}
            </div>
          </template>
          <template v-slot:item.ShipmentUpdateUrl="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ item.ShipmentUpdateUrl }}
            </div>
          </template>
          <template v-slot:item.TrackingUrl="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ item.TrackingUrl }}
            </div>
          </template>
          <template v-slot:item.GetOrderFileUrl="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ item.GetOrderFileUrl }}
            </div>
          </template>

          <template v-slot:item.CreatedAt="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ getFormattedDate(new Date(item.CreatedAt)) }}
            </div>
          </template>
          <template v-slot:item.SecurityType="{ item }">
            <div class="text-center" style="max-width: 108px">
              {{ item.SecurityType === 0 ? 'Basic Auth' : 'Token' }}
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex align-center">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    class="mr-2"
                    color="var(--darkBlue)"
                    @click="
                      isEdit = true;
                      form = Object.assign({}, item);
                      addAPIModal = true;
                    "
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipEdit') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import APIServices from '../services/APIServices';
import formatDate from '../utils/formatDate';

import FormElement from '../components/FormElement.vue';

export default {
  data: () => ({
    apis: [],
    headers: [],
    totalItems: 0,
    rowsPerPage: 15,
    page: 1,
    options: {
      itemsPerPage: 15,
    },
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],

    addAPIModal: false,
    form: {},
    isEdit: false,
  }),
  mounted() {
    this.headers = [
      {
        text: this.$t('labels.ClientId'),
        value: 'ClientId',
        sortable: true,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('labels.SecretKey'),
        value: 'SecretKey',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('labels.securityType'),
        value: 'SecurityType',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('labels.marketplace.ApiUserName'),
        value: 'UserName',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('labels.webhookToken'),
        value: 'WebhookToken',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('labels.shipmentUpdateUrl'),
        value: 'ShipmentUpdateUrl',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('labels.trackingUrl'),
        value: 'TrackingUrl',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('title.tableHeaderCreateDate'),
        value: 'CreatedAt',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('labels.labelUrl'),
        value: 'GetOrderFileUrl',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
      {
        text: this.$t('labels.actions'),
        value: 'actions',
        sortable: false,
        groupable: false,
        align: 'center',
        width: '10%',
      },
    ];
    this.getAPIs();
  },
  components: {
    RefreshButton,
    FormElement,
  },

  watch: {
    addAPIModal(newValue) {
      if (!newValue) {
        this.$refs.addAPIObserver.reset();
        this.form = {};
        this.isEdit = false;
      }
    },
  },

  computed: {
    formItems() {
      return [
        {
          key: {
            parent: 'form',
            child: 'SecurityType',
          },
          validationLabel: 'labels.securityType',
          rules: 'required',
          formType: {
            name: 'select',
            items: {
              data: [
                {
                  text: 'Basic Auth',
                  value: 0,
                },
                {
                  text: 'Token',
                  value: 1,
                },
              ],
              value: 'value',
              text: 'text',
            },
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.securityType',
        },
        {
          key: {
            parent: 'form',
            child: 'UserName',
          },
          rules: {
            required: this.form.SecurityType === 0,
          },
          validationLabel: 'labels.marketplace.ApiUserName',
          formType: {
            name: 'text',
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.marketplace.ApiUserName',
        },
        {
          key: {
            parent: 'form',
            child: 'Password',
          },
          rules: {
            required: this.form.SecurityType === 0,
          },
          validationLabel: 'labels.password',
          formType: {
            name: 'passwordOnly',
          },
          lg: 6,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.password',
        },
        {
          key: {
            parent: 'form',
            child: 'WebhookToken',
          },
          rules: {
            required: this.form.SecurityType === 1,
          },
          validationLabel: 'labels.webhookToken',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.webhookToken',
        },
        {
          key: {
            parent: 'form',
            child: 'ShipmentUpdateUrl',
          },
          validationLabel: 'labels.shipmentUpdateUrl',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.shipmentUpdateUrl',
        },
        {
          key: {
            parent: 'form',
            child: 'TrackingUrl',
          },
          validationLabel: 'labels.trackingUrl',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.trackingUrl',
        },
        {
          key: {
            parent: 'form',
            child: 'GetOrderFileUrl',
          },
          validationLabel: 'labels.labelUrl',
          formType: {
            name: 'text',
          },
          lg: 12,
          labelShown: false,
          topLabel: true,
          cols: 12,
          placeholder: 'labels.labelUrl',
        },
      ];
    },
    pages() {
      if (this.rowsPerPage == null || this.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
  },

  methods: {
    submitAPIForm() {
      this.$store.state.loadingStatus = true;

      let requestFunction = APIServices.addAPI;
      if (this.isEdit) requestFunction = APIServices.updateAPI;

      if (requestFunction) {
        console.log(this.form);
        requestFunction(this.form)
          .then((response) => {
            window.showSuccess(this.$t('messages.addedDevAPISuccessfully'));
            this.addAPIModal = false;
            this.getAPIs();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },
    getFormattedDate(
      date,
      splitter = '/',
      format = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }]
    ) {
      return formatDate(date, splitter, 'tr', format);
    },
    paginationChangeHandler(pageNumber) {
      this.page = pageNumber;
      this.getAPIs();
    },
    changeItemsPerPage() {
      this.page = 1;
      this.options.itemsPerPage = this.rowsPerPage;
      this.getAPIs();
    },
    getAPIs() {
      this.$store.state.loadingStatus = true;
      APIServices.getAPIs()
        .then((response) => {
          const data = response.data.Result || [];
          if(data){
            const isArray = Array.isArray(data);
            this.apis = isArray ? data : [ data ];
          }
          this.totalItems = response.data.TotalItems;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
