import router from '../router'

/**
 * Resolves any route options.
 * @param {*} routeOptions 
 * @returns {string}
 */
export const openRouteInNewTab = (routeOptions) => {
    if(!routeOptions) throw new Error("Please, provide route options.");

    const href = router.resolve(routeOptions).href;

    if(href){
        window.open(href, '_blank');
    }

    return href;
}