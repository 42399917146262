<template>
  <v-dialog :value="value" @input="emitComponent" :max-width="maxWidth">
    <v-card class="popup-card" elevation="1">
      <v-card-title class="popupHeadline" style="font-size: 24px">
        <div class="text-center popupHeadline" style="width: 100%">
          {{ title }}
        </div>
      </v-card-title>

      <v-divider />

      <v-card-text class="py-3">
        <div v-if="$slots.content === undefined" class="text-center" style="width: 100%">
          {{ message }}
        </div>

        <slot name="content"></slot>

      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          v-if="confirm"
          style="
            color: #fff;
            text-transform: unset !important;
            background-color: #0077d5;
            padding: 1vh 1vh;
            border-radius: 30px !important;
            font-weight: bolder;
            font-size: medium;
          "
          elevation="0"
          class="darken-1"
          type="submit"
          @click="confirmTheOperation"
        >
        {{ buttonSettings?.yes?.text || $t('buttons.yes') }}
        </v-btn>
        <v-btn
          style="
            color: #fff;
            text-transform: unset !important;
            background-color: var(--red);
            padding: 1vh 1vh;
            border-radius: 30px !important;
            font-weight: bolder;
            font-size: medium;
          "
          elevation="0"
          class="darken-1"
          @click="closeModal"
        >
          {{ buttonSettings?.no?.text || $t('buttons.no') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    buttonSettings: {
      type: Object,
      default: () => {
        return {
          yes: {
            text:  ""
          },
          no: {
            text:  ""
          }
        }
      }
    },
    title: {
      type: String,
      default: 'Are you sure?',
    },
    message: {
      type: String,
      default: 'This is a test message.',
    },
    confirm: {
      type: Function,
      default: () => {},
    },
    value: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: Number,
      default: 300
    }
  },
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {
    emitComponent(value) {
      this.$emit('input', value);
    },
    confirmTheOperation() {
      this.confirm();
      this.closeModal();
    },
    closeModal() {
      this.$emit('input', false);
    },
  },
};
</script>
<style>
.buttonStyle {
  color: var(--red) !important;
  font-weight: bold;
  text-transform: none;
}
</style>
