import Vue from 'vue';
import Vuex from 'vuex';

import state from './state';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state) => ({
    Token: state.Token,
    RefreshToken: state.Token,
    Id: state.Id,
    Type: state.Type,
    FullName: state.FullName,
    GroupPermessionIds: state.GroupPermessionIds,
    Companies: state.Companies,
    CompanyId: state.CompanyId,
    IsChangePassword: state.IsChangePassword,
    IsPackagePopupVisible: state.IsPackagePopupVisible,
    perPageValues: state.perPageValues,
    PortalUserId: state.PortalUserId,
    isPrevAdmin: state.isPrevAdmin,
    userBlacklist: state.userBlacklist,
    PhotoUrl: state.PhotoUrl,
    CompanyName: state.CompanyName,
  }),
});

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [vuexLocal.plugin],
});
