<template>
  <v-card elevation="1">
    <div class="flex w-full rounded-tl-sm rounded-tr-sm">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>{{ $t('titles.blacklist') }}</h3>
          </v-col>
        </v-row>
        <RefreshButton :refreshFunction="getBlacklistedUsers"></RefreshButton>
      </v-card-title>
    </div>

    <v-data-table
      style="padding: 0 16px"
      :headers="blacklistHeaders"
      :items="blacklistedUsers"
      hide-default-footer
      :no-data-text="$t('messages.noData')"
    >
      <template v-slot:item.FileUrl="{ item }">
        <v-btn
          v-if="item.FileUrl"
          elevation="0"
          color="primary"
          class="button"
          dark
          :href="item.FileUrl"
          depressed
          target="_blank"
          icon
          download
          ><v-icon> mdi-eye </v-icon></v-btn
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-start">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                color="red"
                class="mr-1"
                style="font-size: 20px"
                v-bind="attrs"
                v-on="on"
                @click="removeUserFromBlacklist(item.CustomerCompany.Id)"
              >
                mdi-close
              </v-icon>
            </template>
            <span>{{ $t('buttons.removeUserFromBlacklist') }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RefreshButton from '@/components/RefreshButton.vue';
import BlacklistServices from '../services/BlacklistServices';

export default {
  data: () => ({
    blacklistedUsers: [],
    blacklistHeaders: [],
  }),
  mounted() {
    this.getBlacklistedUsers();
    this.blacklistHeaders = [
      {
        text: this.$t('labels.companyTitle'),
        value: 'CustomerCompany.CompanyName',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('titles.reason'),
        value: 'Reason',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.file'),
        value: 'FileUrl',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('title.tableHeaderActions'),
        value: 'actions',
        sortable: false,
      },
    ];
  },
  components: {
    RefreshButton,
  },
  methods: {
    removeUserFromBlacklist(id) {
      this.$store.state.loadingStatus = true;
      BlacklistServices.delete({
        CustomerId: id,
      })
        .then((response) => {
          window.showSuccess(this.$t('messages.removeBlacklistSuccessfully'));
          this.getBlacklistedUsers();
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    getBlacklistedUsers() {
      this.$store.state.loadingStatus = true;
      BlacklistServices.getForSuppliers()
        .then((response) => {
          this.blacklistedUsers = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
  },
};
</script>
