<template>
  <div>
    <!-- Page content -->
    <v-card elevation="1">
      <div class="flex w-full rounded-tl-sm rounded-tr-sm">
        <v-card-title style="color: var(--darkBlue)">
          <v-row justify="space-between">
            <v-col
              class="justify-start d-flex align-center"
              cols="6"
              sm="4"
              md="5"
              lg="12"
              xl="12"
            >
              <h3>{{ $t('titles.payments') }}</h3>
            </v-col>
            <v-col
              v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
              cols="6"
              sm="8"
              md="7"
              lg="6"
              xl="6"
              class="d-flex flex-row align-center justify-end"
            >
              <RefreshButton :refreshFunction="getPayments"></RefreshButton>
            </v-col>

            <v-col
              cols="12"
              lg="12"
              xl="12"
              class="d-flex flex-row align-center justify-end"
            >
              <v-select
                v-if="!isSupplier"
                class="custom-border mr-4"
                v-model="selectedPaymentStatus"
                :value="selectedPaymentStatus"
                :items="paymentStatuses"
                hide-details
                :label="$t('labels.paymentStatus')"
                item-text="name"
                item-value="value"
                outlined
                dense
                @change="getPayments()"
                style="max-width: 300px; min-width: 150px"
              >
              </v-select>

              <div v-if="!isSupplier" class="mr-3">
                <v-btn
                  @click="pay"
                  :disabled="selectedPayments.length === 0"
                  class="gradient-button button px-5"
                  dark
                >
                  <v-icon color="white" left>mdi-credit-card</v-icon>
                  {{
                    $vuetify.breakpoint.xs
                      ? $t('buttons.lumpSumMobile')
                      : $t('buttons.lumpSum', {
                          value: selectedPayments.length,
                        })
                  }}
                </v-btn>
              </div>

              <v-btn
                v-if="isSupplier"
                @click="payed"
                :disabled="selectedPayments.length === 0"
                class="gradient-button button mr-sm-4"
                dark
              >
                <v-icon color="white" left>mdi-credit-card</v-icon>
                {{
                  $vuetify.breakpoint.xs
                    ? $t('buttons.lumpSumSupplierMobile')
                    : $t('buttons.lumpSumSupplier', {
                        value: selectedPayments.length,
                      })
                }}
              </v-btn>

              <RefreshButton
                v-if="!($vuetify.breakpoint.xs || $vuetify.breakpoint.sm)"
                :refreshFunction="getPayments"
              ></RefreshButton>
            </v-col>
          </v-row>

          <delete-modal :yesFunc="deleteReceipt" ref="deleteModalRef" />
        </v-card-title>
      </div>

      <v-divider />

      <v-card-subtitle>
        <!-- <div v-if="!isSupplier" class="d-flex justify-end my-4">
        <div cols="4" style="padding: 0; max-width: 300px">
          <v-select
            class="custom-border mr-4"
            v-model="selectedPaymentStatus"
            :value="selectedPaymentStatus"
            :items="paymentStatuses"
            hide-details
            :label="$t('labels.paymentStatus')"
            item-text="name"
            item-value="value"
            outlined
            dense
            @change="getPayments()"
          >
          </v-select>
        </div>
        <div cols="3" class="d-flex justify-end" style="padding: 0">
          <v-btn
            @click="pay"
            :disabled="selectedPayments.length === 0"
            class="gradient-button button"
            dark
          >
            <v-icon color="white" left>mdi-credit-card</v-icon>
            {{ $t('buttons.lumpSum', { value: selectedPayments.length }) }}
          </v-btn>
        </div>
      </div>
      <v-row class="d-flex justify-end my-4">
        <v-col
          v-if="isSupplier"
          cols="3"
          class="d-flex justify-end"
          style="padding: 0"
        >
          <v-btn
            @click="payed"
            :disabled="selectedPayments.length === 0"
            class="gradient-button button mt-3"
            dark
          >
            <v-icon color="white" left>mdi-credit-card</v-icon>
            {{
              $t('buttons.lumpSumSupplier', {
                value: selectedPayments.length,
              })
            }}
          </v-btn>
        </v-col>
      </v-row> -->
      </v-card-subtitle>

      <v-dialog
        v-model="addInvoiceModal"
        max-width="850"
        @input="handleDialogInput"
        @close="resetInvoiceModal"
      >
        <v-card class="popup-card" elevation="1">
          <v-card-title
            class="popupHeadline d-flex justify-center"
            style="font-size: 16px"
            >{{
              $t(
                'labels.' +
                  (isSupplier || isPaid
                    ? 'receiptDetail'
                    : isEdit
                    ? 'editInvoice'
                    : 'addInvoice')
              )
            }}</v-card-title
          >
          <v-divider class="mb-4" />

          <div v-if="selectedOrder">
            <v-row v-if="selectedOrder?.BankInformations.length > 0">
              <v-col cols="12">
                <p class="px-3 mb-2" style="font-size: 16px; font-weight: bold">
                  {{ $t('labels.supplierBankInfo') }}
                </p>
              </v-col>
            </v-row>
            <v-row class="mb-4 px-2" no-gutters>
              <v-col
                cols="4"
                v-for="bank in selectedOrder?.BankInformations"
                :key="bank.Id"
                style="padding: 5px"
              >
                <v-card class="rounded-card" outlined style="padding: 10px">
                  <p class="bank-name" style="padding: 0px; margin: 0px">
                    {{ bank.BankName }} - {{ bank.FullName }}
                  </p>
                  <p class="account-number" style="padding: 0px; margin: 0px">
                    {{ bank.IBAN }} - {{ bank.CurrencyType.Code }}
                  </p>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <v-divider
            v-if="selectedOrder?.BankInformations.length > 0"
            class="mb-4"
          />

          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <v-card-text>
              <form @submit.prevent="handleSubmit(fileUploadOperation)">
                <v-row>
                  <FormElement
                    :disabled="isSupplier || isPaid"
                    v-model="fullName"
                    :validationLabel="$t('labels.fullName')"
                    :formLabel="$t('labels.fullName')"
                    :rules="'required|min:3|onlyStringText'"
                    :formType="{
                      name: 'text',
                    }"
                    :labelShown="false"
                    :topLabel="true"
                    :lg="6"
                    :placeholder="$t('labels.fullName')"
                  />
                  <FormElement
                    v-model="transferDate"
                    :validationLabel="$t('labels.transferDate')"
                    :formLabel="$t('labels.transferDate')"
                    :rules="'required'"
                    :formType="{
                      name: 'date',
                      dateMode: 'date',
                    }"
                    :disabled="isSupplier || isPaid"
                    :lg="6"
                    :labelShown="false"
                    :topLabel="true"
                    :placeholder="$t('labels.transferDate')"
                  />
                </v-row>
                <v-row>
                  <FormElement
                    :disabled="isSupplier || isPaid"
                    v-model="bankName"
                    :validationLabel="$t('labels.bankName')"
                    :formLabel="$t('labels.bankName')"
                    :rules="'required'"
                    :formType="{
                      name: 'text',
                    }"
                    :labelShown="false"
                    :topLabel="true"
                    :lg="6"
                    :placeholder="$t('labels.bankName')"
                  />

                  <FormElement
                    :disabled="isSupplier || isPaid"
                    v-model="accountNumber"
                    :validationLabel="$t('labels.IBAN')"
                    :formLabel="$t('labels.IBAN')"
                    :rules="'required|numeric'"
                    :formType="{
                      name: 'text',
                    }"
                    :labelShown="false"
                    :topLabel="true"
                    :lg="6"
                    :placeholder="$t('labels.IBAN')"
                  />
                </v-row>
                <v-row>
                  <FormElement
                    :disabled="isSupplier || isPaid"
                    v-model="amount"
                    :validationLabel="$t('labels.amount')"
                    :formLabel="$t('labels.amount')"
                    :rules="'required|min_value:1'"
                    :formType="{
                      name: 'text',
                    }"
                    :lg="6"
                    :labelShown="false"
                    :topLabel="true"
                    :placeholder="$t('labels.amount')"
                  />
                  <FormElement
                    v-model="selectedCurrency"
                    :validationLabel="$t('labels.currency')"
                    :formLabel="$t('labels.currency')"
                    :rules="'required'"
                    :formType="{
                      name: 'multipleSelect',
                      items: {
                        data: currencyOptions,
                        value: 'Id',
                        text: 'Code',
                      },
                    }"
                    :disabled="isSupplier || isPaid"
                    :lg="6"
                    :labelShown="false"
                    :topLabel="true"
                    :placeholder="$t('labels.currency')"
                  />
                </v-row>

                <v-row v-if="!isSupplier && !isPaid">
                  <FormElement
                    :disabled="isSupplier"
                    v-model="selectedDocument"
                    :validationLabel="$t('labels.file')"
                    :formLabel="$t('labels.selectFile')"
                    :rules="computedRules"
                    :formType="{
                      name: 'file',
                      showSize: true,
                      data: {
                        accept: 'application/pdf',
                        multiple: true,
                      },
                    }"
                    :labelShown="false"
                    :topLabel="true"
                    :placeholder="$t('labels.selectFile')"
                  />
                </v-row>
                <v-row v-if="isEdit">
                  <v-col
                    v-for="(file, index) in oldDocuments"
                    :key="index"
                    cols="3"
                  >
                    <v-img
                      src="../../public/img/zemin-login.png"
                      @click="openImagePreview(file.Url)"
                      contain
                      style="cursor: pointer"
                    ></v-img>
                    <v-btn
                      v-if="!isSupplier && !isPaid && oldDocuments.length > 1"
                      style="width: 100%; background-color: var(--red)"
                      @click="deleteFile(file.ReceiptFileId)"
                      ><v-icon
                        small
                        color="white"
                        class="mr-1"
                        style="font-size: 20px"
                      >
                        mdi-trash-can
                      </v-icon></v-btn
                    >
                  </v-col>
                </v-row>
                <v-card-actions style="justify-content: center">
                  <v-btn
                    v-if="!isSupplier && !isPaid"
                    class="add mt-6"
                    size="x-large"
                    style="
                      text-transform: uppercase !important;
                      border-radius: 30px !important;
                      padding: 2vh 3vh !important;
                      font-weight: bold;
                      font-size: 16px !important;
                    "
                    dark
                    type="submit"
                    >{{
                      isEdit ? $t('buttons.save') : $t('buttons.add')
                    }}</v-btn
                  >
                  <v-btn
                    class="add mt-6"
                    size="x-large"
                    style="
                      text-transform: uppercase !important;
                      border-radius: 30px !important;
                      background-color: var(--red) !important;
                      padding: 2vh 3vh !important;
                      font-weight: bold;
                      font-size: 16px !important;
                    "
                    dark
                    @click="resetInvoiceModal"
                  >
                    {{ $t('buttons.close') }}
                  </v-btn>
                </v-card-actions>
              </form>
            </v-card-text>
          </ValidationObserver>
        </v-card>
      </v-dialog>

      <v-dialog
        v-if="showInvoiceModal"
        v-model="showInvoiceModal"
        max-width="850"
        @input="handleDialogInput"
        @close="resetInvoiceModal"
      >
        <v-card class="popup-card" elevation="1">
          <v-row>
            <v-col class="d-flex justify-center items-center">
              <v-card-title
                class="popupHeadline d-flex"
                style="font-size: 16px"
              >
                <div style="font-size: 16px">
                  {{ $t('labels.invoice') }}
                </div>
              </v-card-title></v-col
            >
          </v-row>

          <v-divider />

          <v-data-table
            :headers="receiptItemsHeaders"
            :items="receiptItems"
            hide-default-footer
            :no-data-text="$t('messages.noData')"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.Amount="{ item }">
              <span v-if="item.Amount && item.CurrencyType">{{
                $getFormattedFloatValue(item.Amount) +
                ' ' +
                item.CurrencyType.Code
              }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <div
                v-if="item.PaymentStatus !== 0 || isSupplier"
                class="text-center"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!isPaid && !isSupplier"
                      small
                      color="#29B7B7"
                      @click="getReceiptDetail(item.Id)"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      style="font-size: 20px"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      v-if="isSupplier || isPaid"
                      small
                      color="#29B7B7"
                      @click="getReceiptDetail(item.Id)"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      style="font-size: 20px"
                    >
                      mdi-eye
                    </v-icon>
                  </template>
                  <span v-if="!isSupplier && !isPaid">{{
                    $t('labels.editInvoice')
                  }}</span>
                  <span v-if="isSupplier || isPaid">{{
                    $t('labels.showInvoiceDetail')
                  }}</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="!isSupplier && !isPaid"
                      small
                      color="#29B7B7"
                      @click="openDeleteModal(item)"
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      style="font-size: 20px"
                    >
                      mdi-trash-can
                    </v-icon>
                  </template>
                  <span>{{ $t('labels.deleteInvoice') }}</span>
                </v-tooltip>
              </div>
              <span v-else>-</span>
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>

      <v-data-table
        :headers="spotOfferPricesHeaders"
        :items="items"
        :loading="$store.state.loadingStatus"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        hide-default-footer
        :item-key="'Id'"
        v-model="selectedPayments"
        :no-data-text="$t('messages.noData')"
      >
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.ShipmentType="{ item }">
          <div style="white-space: normal; word-wrap: break-word">
            {{ item.ShipmentType }}
          </div>
          <span v-if="item.ShipmentType !== 'Express'">
            <span>-</span>
            <div style="white-space: normal; word-wrap: break-word">
              {{ item.LoadingType }}
            </div>
          </span>
        </template>

        <template v-slot:item.Customer="{ item }">
          <span>{{ item.Customer }}</span>
        </template>
        <template v-slot:item.Carrier="{ item }">
          <span>{{ item.Carrier }}</span>
        </template>
        <template v-slot:item.FromToWhere="{ item }">
          <div style="white-space: normal; word-wrap: break-word">
            {{ item.From }}
          </div>
          <span>-</span>
          <div style="white-space: normal; word-wrap: break-word">
            {{ item.To }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <div v-if="item.PaymentStatus !== 4" class="text-start">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="
                    item.PaymentStatus != 0 &&
                    item.PaymentType == 1 &&
                    !isSupplier
                  "
                  small
                  color="#29B7B7"
                  @click="openInvoiceModal(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  style="font-size: 20px"
                >
                  mdi-receipt-text-outline
                </v-icon>
              </template>
              <span>{{ $t('labels.addInvoice') }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.PaymentType == 1 && item.Receipts.length > 0"
                  small
                  color="#29B7B7"
                  @click="openShowInvoiceModal(item)"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  style="font-size: 20px"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>{{ $t('labels.showInvoice') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.IsAdminApprovedPayment"
                  small
                  color="#29B7B7"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-1"
                  style="font-size: 20px"
                >
                  mdi-check-decagram
                </v-icon>
              </template>
              <span>{{ $t('labels.approvedByAdmin') }}</span>
            </v-tooltip>
          </div>
        </template>
        <template v-slot:footer>
          <div class="d-flex justify-center mt-8">
            <v-row align="center" justify="center">
              <v-col
                v-if="
                  $vuetify.breakpoint.md ||
                  $vuetify.breakpoint.lg ||
                  $vuetify.breakpoint.xl
                "
                md="2"
              ></v-col>
              <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                <v-pagination
                  class="mr-2"
                  @input="onPageChange"
                  :value="page"
                  :length="pageLength"
                  :total-visible="15"
                  circle
                ></v-pagination>
              </v-col>
              <v-col
                cols="11"
                sm="11"
                md="3"
                lg="3"
                xl="3"
                class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
              >
                <v-select
                  v-model="itemsPerPage"
                  :items="perPageValues"
                  :label="$t('labels.selectItemsPerPage')"
                  item-value="value"
                  item-text="name"
                  outlined
                  dense
                  @change="changeItemsPerPage()"
                  rounded
                >
                </v-select>
              </v-col>
            </v-row>
          </div>
          <div
            v-if="false"
            class="d-flex justify-center"
            style="font-size: 14px; color: grey"
          >
            {{ $t('labels.currencyWarning') }}
          </div>
        </template>

        <template v-slot:no-data>
          <div class="my-10 d-flex flex-column align-center">
            <h4>
              {{ $t('messages.noPayments') }}
            </h4>
          </div>
        </template>
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.Checkbox="{ item, select, isSelected }">
          <v-checkbox
            v-if="
              (isSupplier &&
                item.PaymentStatus != 0 &&
                item.PaymentStatus != 4 &&
                item.PaymentType == 1) ||
              (!isSupplier &&
                item.PaymentStatus != 0 &&
                item.PaymentType == 2 &&
                item.PaymentStatus != 4)
            "
            @change="updateSelectedPayments(item)"
            large
            color="green"
            :off-icon="'mdi-checkbox-blank-outline'"
            :on-icon="'mdi-checkbox-marked-outline'"
          ></v-checkbox>
        </template>

        <!-- eslint-disable-next-line  -->
        <template v-slot:item.PaymentStatus="{ item }">
          <v-chip
            v-if="item.PaymentStatus === 0"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#c5f4db"
            text-color="#2b3838"
          >
            {{ $t('labels.paymentCompleted') }}
          </v-chip>
          <v-chip
            v-else-if="item.PaymentStatus === 1"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#f4c5c5"
            text-color="#2b3838"
          >
            {{ $t('labels.paymentIncomplete') }}
          </v-chip>
          <v-chip
            v-else-if="item.PaymentStatus === 2"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#f4f4c5"
            text-color="#2b3838"
          >
            {{ $t('messages.waitingForPaymentApproval') }}
          </v-chip>
          <v-chip
            v-else-if="item.PaymentStatus === 3"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#f4c5c5"
            text-color="#2b3838"
          >
            {{ $t('messages.extraPayment') }}
          </v-chip>
          <v-chip
            v-else-if="item.PaymentStatus === 4"
            style="font-weight: 600"
            class="ma-2"
            small
            color="#f4c5c5"
            text-color="#2b3838"
          >
            {{ $t('messages.returnPayment') }}
          </v-chip>
        </template>
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.Price="{ item }">
          <span
            v-if="item.PaymentStatus != 3 && item.PaymentStatus != 4"
            class="badge-cell"
            style="white-space: nowrap"
            >{{ item.Price }}</span
          >
          <span
            v-else
            class="badge-cell"
            style="margin: 20px; white-space: nowrap"
            >{{ item.ExtraPayment }}</span
          >
        </template>
        <!-- eslint-disable-next-line  -->
        <template v-slot:item.CreatedOnUtc="{ item }">
          <span>{{ getFormattedDate(new Date(item.CreatedOnUtc)) }}</span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import formatDate from '../utils/formatDate';
import OrderService from '../services/OrderService';
import CurrencyService from '../services/CurrencyService';
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import FileService from '../services/FileService';
import ReceiptService from '../services/ReceiptService';
import DeleteModal from '../components/General/DeleteModal';
import { getCurrencyTypes } from '../utils/constants';

export default {
  data() {
    return {
      addInvoiceModal: false,
      showInvoiceModal: false,
      selectedDocument: [],
      oldDocuments: [],
      transferDate: new Date().toISOString().substr(0, 10),
      transferDateModalDialog: false,
      bankName: '',
      fullName: '',
      accountNumber: '',
      amount: '',
      selectedOrder: null,
      receiptItems: [],
      receiptId: null,
      isEdit: false,
      isPaid: false,
      uploadFileIds: null,
      deletingReceipt: null,
      receiptItemsHeaders: [
        {
          text: this.$t('labels.bankName'),
          value: 'BankName',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('labels.amount'),
          value: 'Amount',
          sortable: false,
          groupable: false,
          align: 'center',
        },
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
          groupable: false,
          align: 'center',
        },
      ],
      perPageValues: [
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      currencyOptions: null,
      selectedCurrency: null,
      spotOfferPricesHeaders: [],
      items: [],
      selectedPayments: [],
      sort: {
        by: 'Price',
        desc: false,
      },
      paymentStatuses: [],
      selectedPaymentStatus: '',

      // States
      pageLoading: false,
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      isSupplier: true,
      receiptUploadingStatus: false,
      itemPaymentStatus: null,
    };
  },
  components: {
    RefreshButton,
    FormElement,
    DeleteModal,
  },
  async mounted() {
    this.isSupplier =
      this.$store.state.Type === 'Supplier' ||
      this.$store.state.Type === 'PendingSupplier';
    this.spotOfferPricesHeaders.push({
      text: '',
      value: 'Checkbox',
      sortable: false,
      groupable: false,
      align: 'center',
    });
    if (this.isSupplier) {
      this.spotOfferPricesHeaders.push({
        text: this.$t('labels.customer'),
        value: 'Customer',
        sortable: true,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'center',
      });
    } else {
      this.spotOfferPricesHeaders.push({
        text: this.$t('labels.companyName'),
        value: 'Carrier',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'center',
      });
    }

    this.spotOfferPricesHeaders.push(
      {
        text: this.$t('titles.shipmentType'),
        value: 'ShipmentType',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell'],
        align: 'center',
      },
      {
        text: this.$t('titles.from') + '/' + this.$t('titles.to'),
        value: 'FromToWhere',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'center',
        width: '20%',
      },
      {
        text: this.$t('labels.referenceNumber'),
        value: 'RefNo',
        sortable: true,
        groupable: false,
        cellClass: ['regular-cell'],
        align: 'center',
      },
      {
        text: this.$t('labels.date', {}),
        value: 'CreatedOnUtc',
        sortable: false,
        groupable: false,
        cellClass: ['regular-cell', 'bold-cell'],
        align: 'center',
      },
      {
        text: this.$t('labels.status'),
        value: 'PaymentStatus',
        sortable: true,
        groupable: false,
        cellClass: 'regular-cell',
        align: 'center',
      },
      {
        text: this.$t('labels.price'),
        value: 'Price',
        sortable: true,
        groupable: false,
        cellClass: ['regular-cell'],
        align: 'end',
      },
      {
        text: this.$t('title.tableHeaderActions'),
        value: 'actions',
        sortable: false,
        cellClass: ['regular-cell'],
        groupable: false,
      }
    );

    this.getCurrencies();
    this.setup();
  },
  computed: {
    computedRules() {
      return this.isEdit ? '' : 'required';
    },
    formattedTransferDate() {
      return this.transferDate
        ? convertToLocal(this.transferDate)
        : new Date().toISOString().substr(0, 10);
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },

    totalAmount() {
      return this.calculatePrice(this.selectedPayments, 'PriceAsNumber');
    },
  },

  methods: {
    updateSelectedPayments(item) {
      if (!this.selectedPayments.includes(item)) {
        this.selectedPayments.push(item);
      } else {
        const index = this.selectedPayments.indexOf(item);
        if (index > -1) {
          this.selectedPayments.splice(index, 1);
        }
      }
    },
    openDeleteModal(receipt) {
      this.deletingReceipt = receipt;
      this.showInvoiceModal = false;
      this.$refs.deleteModalRef.modalStatus = true;
    },

    deleteFile(FileId) {
      this.$store.state.loadingStatus = true;

      ReceiptService.deleteFile(FileId).then((response) => {
        this.getReceiptDetail(this.receiptId);
        this.$store.state.loadingStatus = false;
      });
    },

    deleteReceipt() {
      if (!this.deletingReceipt) return;

      this.$store.state.loadingStatus = true;
      ReceiptService.delete(this.deletingReceipt.Id)
        .then((response) => {
          this.getPayments();
          this.$store.state.loadingStatus = false;
          window.showSuccess(this.$t('messages.deleteReceiptSuccess'));
        })
        .catch((error) => {
          window.showError(error);
          this.$store.state.loadingStatus = false;
        })
        .finally(() => {
          this.deletingReceipt = null;
          this.$refs.deleteModalRef.modalStatus = false;
        });
    },

    openImagePreview(url) {
      window.open(url, '_blank');
    },
    getReceiptDetail(item) {
      this.receiptId = item;
      this.$store.state.loadingStatus = true;
      ReceiptService.get(item).then((response) => {
        this.isEdit = true;
        this.uploadFileIds = response.data.Result.ReceiptFileList.map(
          (file) => file.FileId
        );
        this.receiptId = response.data.Result.Id;
        this.$store.state.loadingStatus = false;
        this.bankName = response.data.Result.BankName;
        this.fullName = response.data.Result.FullName;
        this.accountNumber = response.data.Result.AccountNumber;
        this.amount = response.data.Result.Amount;
        this.transferDate = convertToDatepicker(
          convertToLocal(response.data.Result.TransferDate)
        );
        this.oldDocuments = response.data.Result.ReceiptFileList || [];
        this.selectedCurrency = response.data.Result.CurrencyTypeId;

        this.addInvoiceModal = true;
        this.$store.state.loadingStatus = false;
      });
    },
    handleDialogInput(value) {
      if (!value) {
        this.resetInvoiceModal();
      }
    },
    async resetInvoiceModal() {
      this.bankName = '';
      this.fullName = '';
      this.accountNumber = '';
      this.amount = '';
      this.transferDate = new Date().toISOString().substr(0, 10);
      this.selectedCurrency = null;
      this.selectedDocument = [];
      this.isEdit = false;
      this.addInvoiceModal = false;
      this.$refs.observer?.reset();
    },
    openInvoiceModal(item) {
      this.isEdit = false;
      this.addInvoiceModal = true;
      this.selectedOrder = item;
    },
    openShowInvoiceModal(item) {
      item.PaymentStatus === 0 ? (this.isPaid = true) : (this.isPaid = false);
      this.showInvoiceModal = true;
      this.selectedOrder = item;

      this.itemPaymentStatus = item.PaymentStatus;
      this.receiptItems = item.Receipts;
    },
    fileUploadOperation() {
      this.$store.state.loadingStatus = true;
      if (this.isEdit) {
        if (this.selectedDocument.length > 0) {
          this.uploadFile(this.selectedDocument).then(() => {
            this.updateReceipt();
          });
        } else {
          this.updateReceipt();
        }
      } else {
        this.uploadFile(this.selectedDocument).then(() => {
          this.addReceipt();
        });
      }
    },

    async uploadFile(fileArray) {
      const document = fileArray;
      if (document.length > 0) {
        let formData = new FormData();

        for (let i = 0; i < document.length; i++) {
          formData.append('Files', document[i]);
        }
        formData.append('Type', 'Document');

        this.$store.state.loadingStatus = true;
        this.receiptUploadingStatus = true;

        try {
          const uploadFileResponse = await FileService.uploadFile(formData);
          const fileIds = uploadFileResponse.data.Result.map((file) => file.Id);
          this.uploadFileIds = fileIds;
        } catch (error) {
          console.log('Upload File Error => ', error);
          this.$store.state.loadingStatus = false;
          this.receiptUploadingStatus = false;
        }
      }
    },

    addReceipt() {
      const body = {
        OrderId: this.selectedOrder.Id,
        BankName: this.bankName,
        FullName: this.fullName,
        AccountNumber: this.accountNumber,
        Amount: this.amount,
        TransferDate: new Date(this.transferDate),
        FileIds: this.uploadFileIds,
        CurrencyTypeId: this.selectedCurrency,
      };

      this.$store.state.loadingStatus = true;
      ReceiptService.add(body)
        .then((response) => {
          this.resetInvoiceModal();
          this.addInvoiceModal = false;
          this.getPayments();
          this.$store.state.loadingStatus = false;
          window.showSuccess(this.$t('messages.addReceiptSuccess'));
        })
        .catch((error) => {
          window.showError(error);
          this.$store.state.loadingStatus = false;
          this.addInvoiceModal = false;
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.receiptUploadingStatus = false;
        });
    },

    updateReceipt() {
      const body = {
        ReceiptId: this.receiptId,
        OrderId: this.selectedOrder.Id,
        BankName: this.bankName,
        FullName: this.fullName,
        AccountNumber: this.accountNumber,
        Amount: this.amount,
        TransferDate: new Date(this.transferDate),
        FileIds: this.uploadFileIds,
        CurrencyTypeId: this.selectedCurrency,
      };

      this.$store.state.loadingStatus = true;
      ReceiptService.update(body)
        .then((response) => {
          this.resetInvoiceModal();
          this.addInvoiceModal = false;
          this.showInvoiceModal = false;
          this.getPayments();
          this.$store.state.loadingStatus = false;
          window.showSuccess(this.$t('messages.updateReceiptSuccess'));
        })
        .catch((error) => {
          console.log('Update Receipt Error => ', error);
          this.$store.state.loadingStatus = false;
          this.addInvoiceModal = false;
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    payed() {
      let orderIds = [];
      this.selectedPayments.forEach((item, index) => {
        orderIds.push(item.Id);
      });

      const body = {
        OrderIds: orderIds,
        PaymentStatus: 0,
      };

      this.$store.state.loadingStatus = true;
      OrderService.updatePaymentStatus(body)
        .then((response) => {
          this.getPayments();
          this.selectedPayments = [];
          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          window.showError(error);
          this.$store.state.loadingStatus = false;
        });
    },
    changeItemsPerPage() {
      this.page = 1;
      this.getPayments();
    },
    async getCurrencies() {
      this.currencyOptions = await getCurrencyTypes();
    },
    pay() {
      this.selectedPayments.forEach((item, index) => {
        this.selectedPayments[index].PriceAsNumber = parseInt(
          item.Price.replace(/\D/g, '')
        );
        this.selectedPayments[index].CurrencyType = item.Price.replace(
          /\d/g,
          ''
        ).trim();
      });

      const paymentIds = this.selectedPayments.map((payment) => payment.Id);

      const queryParams = [
        ...paymentIds.map((paymentId) => `ids=${paymentId}`),
      ];

      this.$store.state.loadingStatus = true;
      window.location.href = `https://${
        process.env.VUE_APP_PAYMENT_SERVICE_URL
      }/Transaction/CurrencySelection?${queryParams.join('&')}`;
    },

    async onFinally() {
      this.$store.state.loadingStatus = false;
    },

    /**
     * Format the date and returns it as a string.
     * @param {Date} date
     * @returns {string}
     */
    getFormattedDate(
      date,
      splitter = '/',
      format = [{ day: '2-digit' }, { month: '2-digit' }, { year: 'numeric' }]
    ) {
      return formatDate(date, splitter, 'tr', format);
    },

    /**
     * Trigger when page changed.
     */
    onPageChange(newPage) {
      this.$store.state.loadingStatus = true;
      this.page = newPage;
      this.getPayments();
    },

    setup() {
      this.paymentStatuses = [
        {
          name: this.$t('labels.All'),
          value: null,
        },
        {
          name: this.$t('labels.paymentCompleted'),
          value: 0,
        },
        {
          name: this.$t('labels.paymentIncomplete'),
          value: 1,
        },
        {
          name: this.$t('messages.waitingForPaymentApproval'),
          value: 2,
        },
        {
          name: this.$t('messages.extraPayment'),
          value: 3,
        },
        {
          name: this.$t('messages.returnPayment'),
          value: 4,
        },
      ];
      this.getPayments();
    },

    /**
     * Get all payments from service by filters.
     * TODO: Geliştirmeler tamamlandıktan sonra orderStatus=3 olarak filtrelenecek.
     */
    getPayments() {
      this.$store.state.loadingStatus = true;
      OrderService.getListForPayment(
        this.selectedPaymentStatus,
        null,
        null,
        this.page,
        this.itemsPerPage
      )
        .then((response) => {
          this.items = response.data.Result;
          this.totalItems = response.data.TotalItems;
        })
        .catch((error) => {
          window.showError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    /**
     * This will trigger a function on service error.
     * @param {Function} special
     * @param {*} error
     */
    onServiceError(error, special = null) {
      if (special) {
        special(error);
      } else {
        window.showError(error);
        this.pageLoading = false;
        this.$store.state.loadingStatus = false;
      }
    },

    /**
     * Calculate prices in the list.
     * @param {array} items
     */
    calculatePrice(items, key) {
      let total = 0;
      if (items) {
        items.map((item) => (total += parseFloat(item[key])));
      }
      return total;
    },
  },
};
</script>
<style>
.container {
  margin-top: 38px;
}
.offerFormSheet {
  padding: 30px;
}

.activeRadioButton {
  color: var(--pink) !important;
}

.color-pink {
  color: var(--pink) !important;
  font-weight: bold;
}

.bold-cell {
  font-weight: bold;
}

.regular-cell {
  color: #575454;
  font-size: smaller !important;
}

.filter-label {
  font-size: 14px;
  color: gray;
}
.filter-select-item {
  font-size: 12px;
}
.column-select-item {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.column-select-item:first-child {
  margin-left: 0 !important;
}
.column-select-item:last-child {
  margin-right: 0 !important;
}
.rounded-card {
  border-radius: 10px;
  background-color: #dddddd;
}

.bank-name {
  font-size: 16px;
  font-weight: bold;
  color: var(--mainColor);
}

.account-number {
  font-size: 12px;
  color: gray;
}
</style>
