import axios from '../javascript/custom-axios';

let RESOURCE_NAME = 'country-blacklist';

export default {
  async getCountries(IsBlocked, CountryName, Page, PageSize) {
    return axios.get(`${RESOURCE_NAME}/getList`, {
      params: { IsBlocked, CountryName, Page, PageSize },
    });
  },

  async update(body) {
    return axios.put(`${RESOURCE_NAME}/update`, body);
  },
};
