<template>
  <div>
    <v-card
      elevation="1"
      class="mt-0 py-lg-10"
      style="margin: 0 !important; padding-right: 4%; padding-left: 4%"
    >
      <ConfirmationModal
        :title="$t('labels.warning')"
        :message="confirmation.message"
        :confirm="confirmation.onConfirm"
        ref="confirmationModal"
        v-model="confirmation.visible"
        :maxWidth="500"
      >
        <template v-if="priceList.length > 0" v-slot:content>
          <p>
            {{ $t('messages.warningOnDeleteIntegration') }}
          </p>
          <v-data-table
            :headers="[
              {
                text: $t('labels.name'),
                value: 'Name',
                sortable: false,
                align: 'center',
              },
            ]"
            :items="priceList"
            :loading="$store.state.loadingStatus"
            :options="{
              itemsPerPage: 15,
            }"
            :footer-props="{
              itemsPerPageText: '',
            }"
            :no-data-text="$t('description.noList')"
          >
          </v-data-table>
        </template>
      </ConfirmationModal>
      <v-card-title class="popupHeadline">
        <v-row>
          <v-col class="text-lg-left text-center" lg="3" cols="12">
            <h5>{{ $t('navigationDrawerMenu.integrationManagement') }}</h5>
          </v-col>
        </v-row>
        <v-icon
          v-if="logistics.length > 0"
          @click="addIntegrationDialog = true"
          small
          class="mr-2"
          style="font-size: 32px"
        >
          mdi-plus-box-outline
        </v-icon>
        <RefreshButton :refreshFunction="getCompanyLogistics"></RefreshButton>
      </v-card-title>
      <v-divider />

      <v-data-table
        :headers="headers"
        :items="integrationData"
        :items-per-page="10"
        :options="{
          itemsPerPage: itemsPerPage,
        }"
        style="padding: 0 16px"
        hide-default-footer
        :loading="$store.state.loadingStatus"
        :no-data-text="$t('messages.noData')"
        ><!-- eslint-disable -->
        <template v-slot:item.PhotoUrl="{ item }">
          <img
            :src="item.PhotoUrl"
            :alt="item.Title"
            style="width: 50px; height: 50px; object-fit: contain"
          />
        </template>

        <template v-slot:item.LogisticName="{ item }">
          <span
            >{{ item.LogisticName }}
            {{ getLogisticName(item.FedexLogisticType) }}</span
          >
        </template>

        <template v-slot:item.StatusType="{ item }">
          <v-chip
            v-if="item.StatusType == 1"
            class="ma-2"
            small
            color="#c5f4db"
            text-color="#2b3838"
          >
            {{ $t('labels.tooltipActive') }}
          </v-chip>

          <v-chip
            v-if="item.StatusType == 2"
            class="ma-2 text--center"
            small
            color="#e3e2e3"
            text-color="#2b3838"
          >
            {{ $t('labels.tooltipPassive') }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="text-start">
            <v-tooltip top>
              <template
                v-if="item.StatusType === 2"
                v-slot:activator="{ on, attrs }"
              >
                <v-icon
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="activate(item)"
                >
                  mdi-arrow-up-bold-box-outline
                </v-icon>
              </template>
              <span>{{ $t('buttons.activateUser') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="#29B7B7"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="editIntegration(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipEdit') }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  color="var(--red)"
                  class="mr-1"
                  style="font-size: 20px"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteIntegration(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>{{ $t('labels.tooltipDelete') }}</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>

      <v-dialog
        v-model="addIntegrationDialog"
        @input="resetDialog"
        @close="resetDialog"
        max-width="600"
      >
        <v-card class="popup-card" elevation="1">
          <v-card-title
            class="popupHeadline d-flex justify-center"
            style="font-size: 16px"
          >
            {{
              isEditMode
                ? $t('labels.editIntegration')
                : $t('labels.addIntegration')
            }}
          </v-card-title>

          <v-divider></v-divider>
          <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
            <v-card-text class="mt-2">
              <form @submit.prevent="handleSubmit(addOrUpdateIntegration)">
                <FormElement
                  v-if="logistics.length > 0"
                  v-model="selectedLogistic"
                  :validationLabel="$t('labels.Integration.title')"
                  :formLabel="$t('labels.Integration.title')"
                  :disabled="isEditMode"
                  :rules="'required'"
                  :formType="{
                    name: 'multipleSelect',
                    items: {
                      data: logistics,
                      value: 'Id',
                      text: 'Name',
                    },
                  }"
                  :topLabel="true"
                  :labelShown="false"
                  :placeholder="$t('labels.Integration.title')"
                />

                <v-divider v-if="logistics.length > 0"></v-divider>

                <v-row :class="logistics.length > 0 ? 'mt-5' : 'mt-0'">
                  <FormElement
                    v-if="selectedLogistic"
                    v-for="(element, index) in formElement"
                    :key="
                      element.key.parent + '_' + element.key.child + '_' + index
                    "
                    v-model="$data[element.key.parent][element.key.child]"
                    :validationLabel="$t(element.validationLabel)"
                    :rules="element.rules"
                    :formLabel="$t(element.formLabel)"
                    :onChange="element.onChange"
                    :formType="element.formType"
                    :lg="element.lg"
                    :cols="element.cols"
                    :hidden="element.hidden"
                    :labelShown="element.labelShown"
                    :topLabel="element.topLabel"
                    :active="element.active"
                    :placeholder="$t(element.placeholder)"
                  />
                </v-row>

                <v-card-actions class="d-flex justify-center">
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: #0077d5;
                      padding: 2vh 3vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    type="submit"
                    color="primary"
                    >{{
                      isEditMode ? $t('buttons.save') : $t('buttons.add')
                    }}</v-btn
                  >
                  <v-btn
                    style="
                      color: #fff;
                      text-transform: unset !important;
                      background-color: var(--red);
                      padding: 2vh 2vh;
                      border-radius: 30px !important;
                      font-weight: bolder;
                      font-size: medium;
                    "
                    elevation="0"
                    class="darken-1"
                    @click="resetDialog"
                    >{{ $t('buttons.cancel') }}</v-btn
                  >
                </v-card-actions>
              </form>
            </v-card-text>
          </ValidationObserver>
        </v-card>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import IntegrationService from '../services/IntegrationService';
import FedexService from '../services/FedexService';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';

export default {
  data: () => ({
    logistics: [],
    integrationData: [],
    selectedLogistic: null,
    selectedIntegration: null,
    form: {
      FedexLogisticType: null,
      Name: null,
      ClientId: null,
      ClientSecretKey: null,
      ChildKey: null,
      ChildSecret: null,
      AccountNumber: null,
      GrantType: null,
    },
    isEditMode: false,
    addIntegrationDialog: false,
    headers: [],
    itemsPerPageOptions: [],
    itemsPerPage: 10,
    page: 1,
    totalItems: 0,
    FedexLogisticTypes: [
      {
        Id: 0,
        Name: 'Shipping',
      },
      {
        Id: 1,
        Name: 'Tracking',
      },
    ],
    grantTypes: [
      {
        Id: 0,
        Name: 'Client Credentials',
      },
      {
        Id: 1,
        Name: 'CSP Credentials',
      },
      {
        Id: 2,
        Name: 'Client PC Credentials',
      },
    ],
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],

    confirmation: {
      message: '',
      onConfirm: () => {},
      visible: false,
      buttonSettings: null,
    },

    priceList: [],
  }),
  mounted() {
    this.headers = [
      {
        text: this.$t('labels.name'),
        value: 'Name',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.logo'),
        value: 'PhotoUrl',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.Integration.title'),
        value: 'LogisticName',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.creator'),
        value: 'CreateBy',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.status'),
        value: 'StatusType',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.actions'),
        value: 'actions',
        sortable: false,
      },
    ];
    this.getCompanyLogistics();
    this.getLogistics();
  },
  components: {
    ConfirmationModal,
    RefreshButton,
    FormElement,
  },
  computed: {
    formElement() {
      return [
        {
          key: {
            parent: 'form',
            child: 'FedexLogisticType',
          },
          rules: 'required',
          validationLabel: 'labels.integrationType',
          topLabel: true,
          labelShown: false,
          active:
            this.selectedLogistic === '6f39f522-d69c-4931-a668-df5e89391ff0',
          hidden:
            this.selectedLogistic !== '6f39f522-d69c-4931-a668-df5e89391ff0',
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.FedexLogisticTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.integrationType',
        },
        {
          key: {
            parent: 'form',
            child: 'Name',
          },
          rules: 'required',
          validationLabel: 'labels.name',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.name',
        },
        {
          key: {
            parent: 'form',
            child: 'ClientId',
          },
          rules: 'required',
          validationLabel: 'labels.ClientId',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.ClientId',
        },
        {
          key: {
            parent: 'form',
            child: 'ClientSecretKey',
          },
          rules: 'required',
          validationLabel: 'labels.ClientSecretKey',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.ClientSecretKey',
        },
        {
          key: {
            parent: 'form',
            child: 'ChildKey',
          },
          validationLabel: 'labels.ChildKey',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.ChildKey',
        },
        {
          key: {
            parent: 'form',
            child: 'ChildSecret',
          },
          validationLabel: 'labels.ChildSecret',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.ChildSecret',
        },
        {
          key: {
            parent: 'form',
            child: 'AccountNumber',
          },
          rules: 'required',
          validationLabel: 'labels.AccountNumber',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.AccountNumber',
        },
        {
          key: {
            parent: 'form',
            child: 'GrantType',
          },
          rules: 'required',
          validationLabel: 'labels.GrantType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.grantTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.GrantType',
        },
      ];
    },
    pageLength() {
      //   return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    getLogisticName(id) {
      const logistic = this.FedexLogisticTypes.find((x) => x.Id === id);
      return logistic ? ' - ' + logistic.Name : '';
    },
    /**
     * Delete an integration account.
     * @param {string} id
     */
    confirmDelete(id) {
      this.$store.state.loadingStatus = true;
      IntegrationService.deleteCompanyLogistic(id)
        .then((response) => {
          this.getCompanyLogistics();
          this.getLogistics();
          this.$store.state.loadingStatus = false;
        })
        .catch(window.showError)
        .finally(() => (this.$store.state.loadingStatus = false));
    },
    editIntegration(item) {
      this.form = {
        FedexLogisticType: item.FedexLogisticType,
        Name: item.Name,
        ClientId: item.ClientId,
        ClientSecretKey: item.ClientSecretKey,
        ChildKey: item.ChildKey,
        ChildSecret: item.ChildSecret,
        AccountNumber: item.AccountNumber,
        GrantType: item.GrantType,
      };
      this.selectedLogistic = item.LogisticId;
      this.selectedIntegration = item;
      this.isEditMode = true;
      this.addIntegrationDialog = true;
    },
    addOrUpdateIntegration() {
      this.$refs.observer.validate().then((success) => {
        if (success) {
          if (this.isEditMode) {
            const body = {
              Id: this.selectedIntegration.Id,
              FedexLogisticType: this.form.FedexLogisticType,
              Name: this.form.Name,
              ClientId: this.form.ClientId,
              ClientSecretKey: this.form.ClientSecretKey,
              ChildKey: this.form.ChildKey,
              ChildSecret: this.form.ChildSecret,
              AccountNumber: this.form.AccountNumber,
              GrantType: this.form.GrantType,
            };

            this.$store.state.loadingStatus = true;
            IntegrationService.updateCompanyLogistic(body)
              .then((response) => {
                this.integrationData = [];
                this.getCompanyLogistics();
                this.$store.state.loadingStatus = false;
              })
              .catch((error) => {
                this.$store.state.loadingStatus = false;
              });
          } else {
            const body = {
              FedexLogisticType: this.form.FedexLogisticType,
              Name: this.form.Name,
              ClientId: this.form.ClientId,
              ClientSecretKey: this.form.ClientSecretKey,
              ChildKey: this.form.ChildKey,
              ChildSecret: this.form.ChildSecret,
              GrantType: this.form.GrantType,
              AccountNumber: this.form.AccountNumber,
              LogisticId: this.selectedLogistic,
            };

            this.$store.state.loadingStatus = true;
            IntegrationService.addCompanyLogistic(body)
              .then((response) => {
                this.getCompanyLogistics();
                this.getLogistics();
                this.$store.state.loadingStatus = false;
              })
              .catch((error) => {
                this.$store.state.loadingStatus = false;
              });
          }

          this.resetDialog();
        }
      });
    },
    activate(item) {
      this.$store.state.loadingStatus = true;
      const body = {};

      const isFedEx = item.LogisticName === 'FedEx';
      let authFunction = () => {};
      if (isFedEx) {
        authFunction = FedexService.auth;
        body.Id = item.Id;
      } else {
        authFunction = IntegrationService.authUPS;
        body.UpsId = item.Id;
      }

      authFunction(body)
        .then((response) => {
          this.getCompanyLogistics();
          if (!isFedEx) {
            window.open(response.data.Result, '_blank');
          }
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    deleteIntegration(item) {
      if (!item.Id) return;

      this.priceList = item.PriceLists || [];
      this.confirmation.onConfirm = () => this.confirmDelete(item.Id);

      if (this.priceList.length === 0) {
        this.confirmation.message = this.$t(
          'description.deleteModalDescription'
        );
      }

      this.confirmation.visible = true;
    },
    getCompanyLogistics() {
      this.$store.state.loadingStatus = true;
      IntegrationService.getCompanyLogistics()
        .then((response) => {
          this.integrationData = response.data.Result;
          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          this.$store.state.loadingStatus = false;
        });
    },
    getLogistics() {
      this.$store.state.loadingStatus = true;
      IntegrationService.getLogistics()
        .then((response) => {
          this.logistics = response.data.Result;
          console.log(this.logistics);
          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          this.$store.state.loadingStatus = false;
        });
    },
    resetDialog() {
      this.addIntegrationDialog = false;
      this.selectedLogistic = null;
      this.isEditMode = false;
      this.form = {};
      this.$refs.observer.reset();
    },
  },
};
</script>
