import { clone, property } from 'lodash';
import AddressServices from '../services/AddressService';
import CurrencyService from '../services/CurrencyService';
import EquipmentTypeService from '../services/EquipmentTypeService';
import HazmatService from '../services/HazmatService';
import store from '../store/store';
import formatDate from './formatDate';
import MarketplaceServices from '../services/MarketplaceServices';
import CompanyServices from '../services/CompanyServices';
import PortalServices from '../services/PortalService';

const colors = {
  white: '#FFF',
};

export const LADEMETER_CONSTANT = 1750;

/**
 * Get countries as an array.
 * @returns {Promise}
 */
const getCountries = async (code = '') => {
  store.state.loadingStatus = true;
  const response = await AddressServices.getCounties(code);
  store.state.loadingStatus = false;
  return response.data.Result;
};

/**
 * Get companies as an array.
 * @returns {Promise}
 */
const getCompanies = async () => {
  store.state.loadingStatus = true;
  const response = await CompanyServices.getList();
  store.state.loadingStatus = false;
  return response.data.Result;
};

/**
 * Get railways as an array.
 * @returns {Promise}
 */
const getRailways = async () => {
  store.state.loadingStatus = true;
  const response = await AddressServices.getRailways();
  store.state.loadingStatus = false;
  return response.data.Result;
};

/**
 * Get the cities as an array.
 * @returns {Promise}
 */
const getCities = async (countryId) => {
  store.state.loadingStatus = true;
  const response = await AddressServices.getCities(countryId).finally(() => {
    store.state.loadingStatus = false;
  });

  return response.data.Result;
};

/**
 * Get the seaports as an array.
 * @returns {Array}
 */
const getSeaports = async () => {
  store.state.loadingStatus = true;
  const response = await AddressServices.getSeaports();
  store.state.loadingStatus = false;
  return response.data.Result;
};

/**
 * Get the airports as an array.
 * @returns {Array}
 */
const getAirports = async (name, countryId, cityId, page, pageSize) => {
  const response = await AddressServices.getAirports(
    name,
    countryId,
    cityId,
    page,
    pageSize
  );
  return response.data.Result;
};

/**
 * Get the currency types.
 * @returns {Array}
 */
const getCurrencyTypes = async () => {
  store.state.loadingStatus = true;
  const response = await CurrencyService.getCurrencies();
  store.state.loadingStatus = false;
  return response.data.Result;
};

/**
 * Get the equipment types.
 * @param {object} params
 * @returns {Array}
 */
const getEquipmentTypes = async (params) => {
  store.state.loadingStatus = true;
  const response = await EquipmentTypeService.getEquipmentTypes(params);
  store.state.loadingStatus = false;
  return response.data.Result;
};

const ITEMS_PER_PAGE_OPTIONS = [5, 10, 15, 20, -1];

const triggerLoading = () => {
  store.state.loadingStatus = !store.state.loadingStatus;
};

/**
 * Get portal users as an array.
 * @returns {Promise}
 */
const getPortalUsers = async () => {
  store.state.loadingStatus = true;
  const response = await PortalServices.getPortalUsers();
  store.state.loadingStatus = false;
  return response.data.Result.PortalCompanies;
};

const transferTypes = [
  {
    Id: 1,
    Name: 'Kargo Taşıma',
  },
  {
    Id: 2,
    Name: 'Express Taşıma',
  },
];

const shipmentTypes = [
  {
    Id: 1,
    Name: 'Hava Yolu',
    Shipments: [1, 2],
    Icon: 'mdi-airplane',
  },
  {
    Id: 2,
    Name: 'Kara Yolu',
    Shipments: [1],
    Icon: 'mdi-truck',
  },
  {
    Id: 3,
    Name: 'Deniz Yolu',
    Shipments: [1],
    Icon: 'mdi-ferry',
  },
  {
    Id: 4,
    Name: 'Express',
    Shipments: [2],
    Icon: 'mdi-truck-delivery',
  },
  {
    Id: 5,
    Name: 'Demir Yolu',
    Shipments: [1],
    Icon: 'mdi-train',
  },
];

const loadTypes = [
  {
    Id: 3,
    Name: 'FCL Yükleme',
  },
  {
    Id: 4,
    Name: 'LCL Yükleme',
  },
  {
    Id: 1,
    Name: 'Parsiyel',
  },
  {
    Id: 5,
    Name: 'FTL Yükleme',
  },
  {
    Id: 6,
    Name: 'LTL Yükleme',
  },
  {
    Id: 2,
    Name: 'Komple Yükleme',
  },
];

const serviceTypes = [
  {
    Id: 2,
    Name: 'Limandan Limana',
  },
  {
    Id: 1,
    Name: 'Kapıdan Kapıya',
  },
  {
    Id: 3,
    Name: 'Havalimanından Havalimanına',
  },
  {
    Id: 4,
    Name: 'Adresten Adrese',
  },
  {
    Id: 5,
    Name: 'Kapıdan Limana',
  },
];

const truckTypes = [
  {
    Id: 1,
    Name: 'Tentel',
  },
  {
    Id: 2,
    Name: 'Jumbo',
  },
  {
    Id: 3,
    Name: 'Lowbed',
  },
];

const containerTypes = [
  {
    Id: 1,
    Name: '20DC',
  },
  {
    Id: 2,
    Name: '40DC',
  },
  {
    Id: 3,
    Name: '40HQ',
  },
];

const carriageTypes = [
  {
    Id: 1,
    Name: 'None',
  },
];

const getHazmatCodes = async () => {
  store.state.loadingStatus = true;

  let hazmatCodes = localStorage.getItem('hazmatCodes');
  if (!hazmatCodes) {
    const response = await HazmatService.getHazmatCodes();
    hazmatCodes = response.data.Result;
    localStorage.setItem('hazmatCodes', JSON.stringify(hazmatCodes));
    store.state.loadingStatus = false;
    return hazmatCodes;
  }

  store.state.loadingStatus = false;
  return JSON.parse(hazmatCodes);
};

/**
 * Convert Boxes To Volumes
 * @param {Array} boxes
 * @returns {Array}
 */
const convertBoxesToVolumes = (boxes = [], shipmentType = null) => {
  let newArray = [];
  let calculateParameter = 0;
  console.log('Convert Boxes to Volumes >> ', boxes, shipmentType);
  if (shipmentType) {
    switch (shipmentType) {
      case 3:
        calculateParameter = 1000;
        break;
      case 1:
        calculateParameter = 166.67;
        break;
      case 2:
        calculateParameter = 333;
        break;
    }
  }

  if (boxes.length > 0) {
    boxes.forEach((element, index) => {
      const newElement = {
        id: element.Id,
        index,
        containerCount: element.CapPieces,
        kilo: element.WeightPerCap,
        totalWeight: element.Weight,
        height: element.Height,
        length: element.Length,
        width: element.Width,
        gTypeNumber: element.GTipNo,
        productType: element.CarriageType,
        packageType: element.PackageType,
        countInCap: element.CountInCap,
      };

      const volume =
        (element.Width * element.Length * element.Height) / 1000000;
      newElement.volume = (element.CapPieces * volume).toFixed(2);

      newArray.push(newElement);
    });

    return newArray;
  }
};

/**
 * Volumes To Boxes
 * @param {Array} volumes
 * @returns {Array}
 */
const convertVolumeToBoxes = (volumes = []) => {
  let newArray = [];
  if (volumes?.length > 0) {
    volumes.forEach((element) => {
      newArray.push({
        Id: element.id,
        CapPieces: parseInt(element.containerCount),
        Weight: parseFloat(element.totalWeight),
        WeightPerCap: parseFloat(element.kilo),
        Height: parseInt(element.height),
        Length: parseInt(element.length),
        Width: parseInt(element.width),
        GTipNo: element.gTypeNumber,
        CarriageType: element.productType,
        PackageType: element.packageType,
        ChargeableWeight: element.volumeWeight || 0,
        CountInCap: element.countInCap,
      });
    });
  }

  return newArray;
};

/**
 * Convert spot shipping to offer form body.
 * @param {SpotShipping} item
 * @param {OfferFormBody} actualBody
 * @returns {OfferFormBody}
 */
const convertToFormBody = (item = {}, actualBody = {}, isSupplier = false) => {
  const fastShipping = item?.FastShippingSearch;
  if (!fastShipping) return {};

  let body = {
    transferType: fastShipping.TransportType,
    shipmentType: fastShipping.ShipmentType,
    exportImportType: fastShipping.ExportImportType,

    item: {
      ...actualBody.item,
      loadType: fastShipping.LoadingType,
      selectedService: fastShipping.ServiceType,
      containerCount: fastShipping.ContainerCount,
      containerType: fastShipping.ContainerType,

      targetPriceCurrency:
        item.TargetPriceCurrencyTypeId || item.CurrencyTypeId,
      priceCurrency: item.CurrencyTypeId,

      supplierNote: item.SupplierNote,
      customerNote: item.CustomerNote || item.Note,
      transferTime: item.TransitTime,

      isTransshipment: item.IsTransshipment,
      transshipmentCount: item.TransshipmentCount,

      expiryDate: formatDate(
        item.ExpiryDate ? new Date(item.ExpiryDate) : new Date(),
        '-',
        'en',
        [{ year: 'numeric' }, { month: '2-digit' }, { day: '2-digit' }]
      ),

      price: item.Price,
      targetPrice:
        item?.SpotShipping?.TargetPrice || item.TargetPrice
          ? item.TargetPrice +
            (isSupplier
              ? ' ' + (item.TargetPriceCurrency || item.CurrencyType)
              : '')
          : 0,
      seaport_toWhere: fastShipping.SeaUnloadingPortId,
      seaport_fromWhere: fastShipping.SeaLoadingPortId,
      airport_toWhere: fastShipping.AirUnloadingPortId,
      airport_fromWhere: fastShipping.AirloadingPortId,
      station_toWhere: fastShipping.RailwayloadingId,
      station_fromWhere: fastShipping.RailwayUnloadingId,
      city_toWhere: fastShipping.DestinationCityId,
      postalCode_toWhere: fastShipping.DestinationPostalCode,
      country_toWhere: fastShipping.DestinationCountryId,
      city_fromWhere: fastShipping.LoadingCityId,
      postalCode_fromWhere: fastShipping.LoadingPostalCode,
      country_fromWhere: fastShipping.LoadingCountryId,

      carType: fastShipping.CarriageType,
      carCount: fastShipping.CarriageCount,
      vehicleType: fastShipping.TruckType,
      vehicleCount: fastShipping.TruckCount,
      containerType: fastShipping.ContainerType,
      containerCount: fastShipping.ContainerCount,
      doorCount: fastShipping.SumCapPieces,
      kilo: fastShipping.SumWeight?.toString(),
      volume: fastShipping.SumVolume?.toString(),
      isDangerousMaterial: fastShipping.IsDangeroousSubstance || false,
      dangerousMaterialLevel: fastShipping.HazmatCode,
      willNotBeStacked: fastShipping.IsStockUp || false,
      volumes: fastShipping.FastShippingBoxes || [],

      chargeableWeight: fastShipping.ChargeableWeight,
      searchPrice: item.SearchPrice,
      taxPayer: fastShipping.TaxPayer,

      subsupplier: item.SubSupplier,
      subsupplierServiceType: item.SubSupplierService,

      expressPackageType: item.ExpressPackageType,
      expressServiceType:
        item.ExpressPackageType === 2 ? 5 : item.ExpressServiceType,
    },
  };

  delete body.item.note;

  return body;
};

/**
 * Remove null values from Object.
 * @param {Object} object
 * @returns
 */
const removeNullValues = (object) => {
  const cloned = Object.assign({}, object);
  for (var propertyName in cloned) {
    if (
      cloned[propertyName]?.toString()?.search('_NA') > -1 ||
      cloned[propertyName] === null ||
      cloned[propertyName] === undefined
    ) {
      delete cloned[propertyName];
    }

    if (Array.isArray(cloned[propertyName])) {
      if (cloned[propertyName].length === 0) {
        delete cloned[propertyName];
      }
    }
  }
  return cloned;
};

/**
 * Get marketplaces as an array.
 * @returns {Promise}
 */
const getMarketplaces = async () => {
  store.state.loadingStatus = true;
  const response = await MarketplaceServices.get();
  store.state.loadingStatus = false;
  return response.data.Result;
};

/**
 * Calculate the volume weight.
 * @param {number} volume
 * @param {string} shipmentType
 * @returns {number}
 */
const calculateVolumeWeight = (volume, shipmentType) => {
  let calculateParameter = 0;
  switch (shipmentType) {
    case 'Hava Yolu':
      calculateParameter = 166.67;
      break;
    case 'Kara Yolu':
      calculateParameter = 333;
      break;
    case 'Deniz Yolu':
      calculateParameter = 1000;
      break;
    case 'Demir Yolu':
      calculateParameter = 0;
      break;
  }
  return parseFloat(volume * calculateParameter);
};

/**
 * Convert a file to URL.
 * @param {File} file
 * @returns {String}
 */
const convertToURL = (file) => {
  return URL.createObjectURL(file);
};

/**
 * Format the phone number as (555) 666 77 88
 * @param {string} phoneNumber
 * @returns {string}
 */
const formatPhoneNumber = (phoneNumber) => {
  const rawPhoneNumber = ('' + phoneNumber).replace(/\D/g, '');
  const length = rawPhoneNumber.length;

  if (length === 10) {
    const matches = rawPhoneNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
    return `(${matches[1]}) ${matches[2]}-${matches[3]}`;
  } else if (length === 11) {
    const matches = rawPhoneNumber.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
    return `${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}`;
  } else if (length === 12) {
    const matches = rawPhoneNumber.matches(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
    return `${matches[1]} (${matches[2]}) ${matches[3]}-${matches[4]}`;
  }

  return phoneNumber;
};

/**
 * Clears special characters in a text.
 * @param {string} text
 * @returns {string}
 */
const clearText = (text) => {
  return text.replace(/\D+/g, '');
};

/**
 * Convert the string to a number.
 * @param {string} text
 * @returns {string}
 */
const convertToNumber = (text) => {
  if (typeof text === 'number') return text;
  if (!text) return null;
  return parseFloat(text.replace(/,/g, ''));
};

const logisticsTypes = [
  {
    Value: 1,
    Name: 'FedEx',
  },
  {
    Value: 2,
    Name: 'UPS',
  },
];

const orderTypes = ['Manuel', 'Etsy', 'Amazon'];

/**
 *
 * @param {*} data
 * @param {string} fileName
 * @param {string} extension
 */
const downloadFile = (data, fileName = 'sampleFile', extension = 'pdf') => {
  if (data) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${extension}`);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
};

/**
 * Convert numbers to float value with toFixed function.
 * @param {number | string} value
 * @param {number} decimal
 * @returns
 */
const getFormattedFloatValue = (value, decimal = 2) => {
  return parseFloat(value).toFixed(decimal);
};

/**
 * Convert To English Letters.
 * @param {string} text
 * @returns {string}
 */
const convertToEnglishLetters = (text) => {
  const turkishCharacters = {
    Ç: 'C',
    Ö: 'O',
    Ş: 'S',
    İ: 'I',
    Ü: 'U',
    Ğ: 'G',
    ç: 'c',
    ö: 'o',
    ş: 's',
    ı: 'i',
    ü: 'u',
    ğ: 'g',
    i: 'I',

    I: 'I',
  };

  return text.replace(/[ÇÖŞİÜĞçöşüği]/g, function (char) {
    return turkishCharacters[char] || char;
  });
};

/**
 * Split a date string by "T".
 * @param {string} date 
 * @returns {string}
 */
const extractDateFromObject = (date) => {
  if(!date || typeof date !== "string") throw new Error("Please provide a string date.")

  return date.split("T")[0];
}

export {
  colors,
  getCountries,
  getCities,
  getAirports,
  getSeaports,
  getCurrencyTypes,
  getRailways,
  getHazmatCodes,
  ITEMS_PER_PAGE_OPTIONS,
  triggerLoading,
  transferTypes,
  shipmentTypes,
  loadTypes,
  serviceTypes,
  truckTypes,
  containerTypes,
  carriageTypes,
  convertToFormBody,
  getEquipmentTypes,
  removeNullValues,
  getMarketplaces,
  convertVolumeToBoxes,
  convertBoxesToVolumes,
  calculateVolumeWeight,
  convertToURL,
  formatPhoneNumber,
  clearText,
  getCompanies,
  convertToNumber,
  logisticsTypes,
  orderTypes,
  getPortalUsers,
  downloadFile,
  getFormattedFloatValue,
  convertToEnglishLetters,
  extractDateFromObject
};
