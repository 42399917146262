import axios from "../javascript/custom-axios";
const RESOURCE_NAME = "etsy";

export default {
  getShippingProfiles(executeFor) {
    return axios.get(`${RESOURCE_NAME}/shipping/getShippingProfiles`, {
      params: { ExecuteFor: executeFor },
    });
  },
  getShops(executeFor) {
    return axios.get(`${RESOURCE_NAME}/shops`, {
      params: { ExecuteFor: executeFor },
    });
  },
  getShopSections() {
    return axios.get(`${RESOURCE_NAME}/section/getShopSections`);
  },
  upateShopSection(body) {
    return axios.post(`${RESOURCE_NAME}/section/updateShopSection`,body);
  },
  addShopSection(body) {
    return axios.post(`${RESOURCE_NAME}/section/createShopSection`,body);
  },
  deleteShopSection(body) {
    return axios.post(`${RESOURCE_NAME}/section/deleteShopSection`,body);
  },
  getShippingCarriers(executeFor, iso) {
    return axios.get(`${RESOURCE_NAME}/shipping/getShippingCarries`, {
      params: { OriginCountryISO: iso, ExecuteFor: executeFor },
    });
  },
  getPolices() {
    return axios.get(`${RESOURCE_NAME}/returnpolicy/getShopReturnPolicies`);
  },
  upateRetunPolicy(body) {
    return axios.post(`${RESOURCE_NAME}/returnpolicy/updateShopReturnPolicy`,body);
  },
  addRetunPolicy(body) {
    return axios.post(`${RESOURCE_NAME}/returnpolicy/createShopReturnPolicy`,body);
  },
  addShipProfile(body) {
    return axios.post(`${RESOURCE_NAME}/shipping/createShopShippingProfile`,body);
  },
  deleteShipProfile(body) {
    return axios.post(`${RESOURCE_NAME}/shipping/deleteShopShippingProfile`,body);
  },
  updateShipProfile(body) {
    return axios.post(`${RESOURCE_NAME}/shipping/updateShopShippingProfile`,body);
  },
  deleteRetunPolicy(body) {
    return axios.post(`${RESOURCE_NAME}/returnpolicy/deleteShopReturnPolicy`,body);
  },

  oauthEtsy(body) {
    return axios.post(`/oauth/etsy-callback`,body)
  },
  oauthAmazon(body) {
    return axios.post(`/oauth/amazon-callback`,body)
  }
};
