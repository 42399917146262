<template>
  <v-card elevation="1">
    <v-dialog
      content-class="short-content"
      v-model="actionsModal"
      max-width="400"
    >
      <v-card class="popup-card" elevation="1">
        <v-card-title class="popupHeadline" style="font-size: 24px">
          <div class="text-center popupHeadline" style="width: 100%">
            {{ $t('buttons.' + (isEditMode ? 'update' : 'add')) }}
          </div>
        </v-card-title>
        <v-divider class="mb-4" />

        <ValidationObserver ref="actionsOnFormObserver" v-slot="{ handleSubmit }">
          <form
            @submit.prevent="handleSubmit(submitForm)"
          >
            <v-card-text>
              <v-row>
                <FormElement
                    v-for="(element, index) in formItems"
                    :key="
                      element.key.parent + '_' + element.key.child + '_' + index
                    "
                    v-model="$data[element.key.parent][element.key.child]"
                    :validationLabel="$t(element.validationLabel)"
                    :rules="element.rules"
                    :formLabel="$t(element.formLabel)"
                    :onChange="element.onChange"
                    :formType="element.formType"
                    :lg="element.lg"
                    :cols="element.cols"
                    :disabled="element.disabled"
                    :hidden="element.hidden"
                    :labelShown="element.labelShown"
                    :active="element.active"
                    :topLabel="element.topLabel"
                    :placeholder="$t(element.placeholder)"
                  />
              </v-row>
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: #0077d5;

                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                type="submit"
                :loading="$store.state.loadingStatus"
                >{{ $t('buttons.save') }}</v-btn
              >
              <v-btn
                style="
                  color: #fff;
                  text-transform: unset !important;
                  background-color: var(--red);
                  padding: 1vh 1vh;
                  border-radius: 30px !important;
                  font-weight: bolder;
                  font-size: medium;
                "
                elevation="0"
                class="darken-1"
                @click="actionsModal = false"
                >{{ $t('buttons.cancel') }}</v-btn
              >
            </v-card-actions>
          </form>
        </ValidationObserver>
      </v-card>
    </v-dialog>
    <v-card-title class="d-flex flex-row" style="color: var(--darkBlue)">
      <v-row>
        <v-col class="text-lg-left text-center" lg="12" cols="12">
          <h3>{{ $t('navigationDrawerMenu.locationManagement') }}</h3>
        </v-col>
      </v-row>
      <div>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-3"
              style="font-size: 32px"
              v-bind="attrs"
              v-on="on"
              @click="actionsModal = true"
            >
              mdi-plus-box-outline
            </v-icon>
          </template>
          <span>{{ $t('buttons.add') }}</span>
        </v-tooltip>
        <RefreshButton :refreshFunction="() => getItems()"></RefreshButton>
      </div>
    </v-card-title>
    <v-divider />
    <TabScreen class="mt-4" :slots="slots" :changeIndexFunction="onTabChange"
      style="
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
        margin-bottom: 20px;
      ">
      <template v-slot:countries>
        <v-row class="my-4 px-4 d-flex justify-space-between">
          <FormElement
            key="SEARCH_COUNTRY_FILTER"
            v-model="nameFilter"
            :validationLabel="$t('labels.name')"
            :topLabel="true"
            :labelShown="false"
            :cols="6"
            :sm="4"
            :lg="3"
            :onChange="() => getItems()"
            :formType="{
              name: 'text',
              dense: true,
              textTransform: 'uppercase'
            }"
            :placeholder="$t('labels.name')"
            :hideDetails="true"
          />
          <v-col style="padding:0;" cols="6" class="d-flex justify-end">
            <v-btn
              @click="getItems(true)"
              class="button"
              :style="'color: white; background-color:var(--primary-color)'"
            >
              <v-icon>mdi-download</v-icon>
                {{ $t('buttons.excelExport') }}
              </v-btn>
          </v-col>
        </v-row>
        <v-divider />
    
        <v-data-table
          :headers="headers"
          :items="items"
          hide-default-footer
          :loading="$store.state.loadingStatus"
          :options="{
            itemsPerPage: itemsPerPage,
          }"
          :item-key="'Id'"
          :no-data-text="$t('messages.noData')"
        >
          <template v-slot:footer>
            <div class="d-flex justify-center mt-8">
              <v-row align="center" justify="center">
                <v-col
                  v-if="
                    $vuetify.breakpoint.md ||
                    $vuetify.breakpoint.lg ||
                    $vuetify.breakpoint.xl
                  "
                  md="2"
                ></v-col>
                <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                  <v-pagination
                    v-if="pageLength > 0"
                    class="my-4"
                    @input="onPageChange"
                    v-model="page"
                    :length="pageLength"
                    :total-visible="10"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col
                  cols="11"
                  sm="11"
                  md="3"
                  lg="3"
                  xl="3"
                  class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                >
                  <v-select
                    v-model="itemsPerPage"
                    :items="perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    outlined
                    dense
                    @change="changeItemsPerPage"
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="my-10 d-flex flex-column align-center">
              <h4>{{ $t('messages.noData') }}</h4>
            </div>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="getItemById(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('buttons.update') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="red"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItemById(item)"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>{{ $t('description.deleteModalTitle') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </template>
      <template v-slot:cities>
        <v-row class="my-4 px-4 d-flex justify-start">
          <FormElement
            key="CITY_COUNTRY_FILTER"
            v-model="countryId"
            :vertical="true"
            hide-details
            :validationLabel="$t('labels.country')"
            :topLabel="true"
            :labelShown="false"
            :cols="6"
            :sm="4"
            :lg="3"
            :onChange="() => getItems()"
            :formType="{
              name: 'multipleSelect',
              items: {
                data: countries,
                value: 'Id',
                text: 'Name',
              },
              dense: true,
              prepend: 'mdi-flag-variant',
            }"
            :placeholder="$t('labels.country')"
          />
          <FormElement
            key="SEARCH_CITY_FILTER"
            v-model="nameFilter"
            :vertical="true"
            hide-details
            :validationLabel="$t('labels.name')"
            :topLabel="true"
            :labelShown="false"
            :cols="6"
            :sm="4"
            :lg="3"
            :onChange="() => getItems()"
            :formType="{
              name: 'text',
              dense: true,
              textTransform: 'uppercase'
            }"
            :placeholder="$t('labels.name')"
          />
          <v-col style="padding:0;" cols="6" class="d-flex justify-end mt-lg-0 mt-6">
            <v-btn
              @click="getItems(true)"
              class="button"
              :style="'color: white; background-color:var(--primary-color)'"
            >
              <v-icon>mdi-download</v-icon>
                {{ $t('buttons.excelExport') }}
              </v-btn>
          </v-col>
        </v-row>
        <v-divider />
    
        <v-data-table
          key="CITIES_DATA_TABLE"
          :headers="headers"
          :items="items"
          hide-default-footer
          :loading="$store.state.loadingStatus"
          :options="{
            itemsPerPage: itemsPerPage,
          }"
          :item-key="'Id'"
          :no-data-text="$t('messages.noData')"
        >
          <template v-slot:footer>
            <div class="d-flex justify-center mt-8">
              <v-row align="center" justify="center">
                <v-col
                  v-if="
                    $vuetify.breakpoint.md ||
                    $vuetify.breakpoint.lg ||
                    $vuetify.breakpoint.xl
                  "
                  md="2"
                ></v-col>
                <v-col cols="9" sm="12" md="7" lg="7" xl="7">
                  <v-pagination
                    v-if="pageLength > 0"
                    class="my-4"
                    @input="onPageChange"
                    v-model="page"
                    :length="pageLength"
                    :total-visible="10"
                    circle
                  ></v-pagination>
                </v-col>
                <v-col
                  cols="11"
                  sm="11"
                  md="3"
                  lg="3"
                  xl="3"
                  class="ml-md-auto d-flex align-center mb-6 mb-md-0 pr-3 pr-md-6"
                >
                  <v-select
                    v-model="itemsPerPage"
                    :items="perPageValues"
                    :label="$t('labels.selectItemsPerPage')"
                    item-value="value"
                    item-text="name"
                    outlined
                    dense
                    @change="changeItemsPerPage"
                    rounded
                  >
                  </v-select>
                </v-col>
              </v-row>
            </div>
          </template>
          <template v-slot:no-data>
            <div class="my-10 d-flex flex-column align-center">
              <h4>{{ $t('messages.noData') }}</h4>
            </div>
          </template>
          <!-- eslint-disable -->
          <template v-slot:item.Date="{ item }">
            {{ convertToLocal(item.ValidityDate ? item.ValidityDate : item.CreatedOnUtc) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="text-start">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="getItemById(item)"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('buttons.update') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    color="red"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="deleteItemById(item)"
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>{{ $t('description.deleteModalTitle') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </template>
    </TabScreen>
    
  </v-card>
</template>
<script>
import LocationService from '../services/LocationService';
import {
  ITEMS_PER_PAGE_OPTIONS,
  downloadFile,
  getCountries,
  removeNullValues,
} from '../utils/constants';
import FormElement from '../components/FormElement.vue';
import formatDate from '../utils/formatDate';
import RefreshButton from '../components/RefreshButton.vue';
import TabScreen from '../components/TabScreen.vue';

export default {
  data() {
    return {
      countries: [],
      items: [],

      // States
      itemsPerPage: 10,
      page: 1,
      totalItems: 0,
      perPageValues: [
        {
          name: '5',
          value: 5,
        },
        {
          name: '10',
          value: 10,
        },
        {
          name: '15',
          value: 15,
        },
        {
          name: '30',
          value: 30,
        },
        {
          name: '50',
          value: 50,
        },
        {
          name: '100',
          value: 100,
        },
        {
          name: this.$t('labels.All'),
          value: 9999,
        },
      ],
      isEditMode: false,

      actionsModal: false,
      form: {},

      slots: [],
      selectedTab: 0,
      nameFilter: "",
      countryId: "",
    };
  },
  components: { FormElement, RefreshButton, TabScreen },
  mounted() {
    this.itemsPerPageOptions = ITEMS_PER_PAGE_OPTIONS;
    this.setup();
    
    this.slots = [
      {
        name: 'countries',
        title: this.$t('labels.country'),
      },
      {
        name: 'cities',
        title: this.$t('labels.city'),
      },
    ];
  },
  watch: {
    actionsModal(newValue) {
      if (!newValue) {
        this.$refs.actionsOnFormObserver.reset();
        this.form = {};
        this.isEditMode = false;
      }
    },
  },
  computed: {
    headers(){
      const tableHeaders = [
        {
          text: this.$t('labels.name'),
          value: 'Name',
          sortable: false,
        },
      ];

      if(this.selectedTab === 1){
        tableHeaders.push({
          text: this.$t('labels.country'),
          value: 'CountryName',
          sortable: false,
        });
      }

      return [
        ...tableHeaders,  
        {
          text: this.$t('title.tableHeaderActions'),
          value: 'actions',
          sortable: false,
        }
      ];
    },
    formItems(){
      if(this.selectedTab === 0){
        return [
          {
            key: {
              parent: 'form',
              child: 'SelectId',
            },
            validationLabel: 'labels.selectId',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.selectId'
          },
          {
            key: {
              parent: 'form',
              child: 'PhoneCode',
            },
            validationLabel: 'labels.phoneCode',
            rules: 'required',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.phoneCode'
          },
          {
            key: {
              parent: 'form',
              child: 'NameTr',
            },
            validationLabel: 'title.tableHeaderTrName',
            rules: 'required',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'title.tableHeaderTrName'
          },
          {
            key: {
              parent: 'form',
              child: 'NameEn',
            },
            validationLabel: 'title.tableHeaderEnName',
            rules: 'required',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'title.tableHeaderEnName'
          },
          {
            key: {
              parent: 'form',
              child: 'ISOCode2',
            },
            rules: 'required',
            validationLabel: 'labels.isoCode2',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.isoCode2'
          },
          {
            key: {
              parent: 'form',
              child: 'ISOCode3',
            },
            rules: 'required',
            validationLabel: 'labels.isoCode3',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.isoCode3'
          },
        ]
      }
      
      return[
          {
            key: {
              parent: 'form',
              child: 'SelectId',
            },
            validationLabel: 'labels.selectId',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.selectId'
          },
          {
            key: {
              parent: 'form',
              child: 'Name',
            },
            validationLabel: 'labels.name',
            rules: 'required',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.name'
          },
          {
            key: {
              parent: 'form',
              child: 'CountryId',
            },
            validationLabel: 'labels.country',
            rules: this.isEditMode ? "" : "required",
            disabled: this.isEditMode,
            formType: {
              name: 'multipleSelect',
              items: {
                data: this.countries,
                text: 'Name',
                value: 'Id',
                disableAutoSelection: true
              },
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.country'
          },
          {
            key: {
              parent: 'form',
              child: 'State',
            },
            validationLabel: 'labels.state',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.state'
          },
          {
            key: {
              parent: 'form',
              child: 'StateCode',
            },
            validationLabel: 'labels.stateCode',
            formType: {
              name: 'text'
            },
            lg: 12,
            labelShown: false,
            topLabel: true,
            cols: 12,
            placeholder: 'labels.stateCode'
          },
        ]
    },
    pageLength() {
      return Math.ceil(this.totalItems / this.itemsPerPage);
    },
  },
  methods: {
    deleteItemById(item){
      this.$store.state.loadingStatus = true;
      const isCountry = item.hasOwnProperty('ISOCode2');

      LocationService[isCountry ? 'deleteCountry' : 'deleteCity'](item.Id)
      .then(response => {
        window.showSuccess(this.$t('messages.successfulDelete'))
        this.getItems()
      })
      .catch(window.showError)
      .finally(() => {
        this.$store.state.loadingStatus = false;
      });
    },

    /**
     * Get Item By Id.
     * @param {{}} item
     */
    getItemById(item){
      this.$store.state.loadingStatus = true;
      const isCountry = item.hasOwnProperty('ISOCode2');

      LocationService[isCountry ? 'getCountryById' : 'getCityById'](item.Id)
      .then(response => {
        const data = response.data.Result;
        if(data){
          this.actionsModal = true
          this.isEditMode = true
          this.form = data
        }
      })
      .catch(window.showError)
      .finally(() => {
        this.$store.state.loadingStatus = false;
      });
     
    },

    onTabChange(index){
      this.selectedTab = index;
      this.page = 1;
      this.nameFilter = "";
      this.getItems();
    },

    changeItemsPerPage() {
      this.page = 1;
      this.getItems();
    },

    /**
     * Trigger when page changed.
     */
    onPageChange() {
      this.getItems();
    },

    async setup() {
      this.countries = await getCountries();
      
      this.getItems();
    },

    /**
     * Get search history from service.
     */
    getItems(IsExcel = false) {
        this.$store.state.loadingStatus = true;
        let functionName = "", parameters = {
            page: this.page,
            pageSize: this.itemsPerPage,
            Name: this.nameFilter,
            IsExcel
          };
        if(this.selectedTab === 0){
          functionName = 'getCountries';
        }
        else{
          functionName = 'getCitiesForAdmin';
          parameters.CountryId = this.countryId;
        }

        LocationService[functionName](
          parameters
        )
        .then((response) => {
          if(IsExcel){
            downloadFile(response.data, `report_${functionName.replace("get", "")}_${new Date().toISOString()}`, 'xlsx')
          }
          else{
            this.items = response.data.Result;
            this.totalItems = response.data.TotalItems;
          }
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },

    async submitForm(){
      this.$store.state.loadingStatus = true;
      let body = removeNullValues(this.form), requestFunction = null;

      try {
        if(!this.isEditMode){
          if(this.selectedTab === 0){
            requestFunction = LocationService.addCountry;
          }
          else{
            requestFunction = LocationService.addCity;
          }
        }
        else{
          if(this.selectedTab === 0){
            requestFunction = LocationService.updateCountry;
          }
          else{
            requestFunction = LocationService.updateCity;
          }
        }

        if(requestFunction){
          requestFunction(body)
          .then(async response => {
            window.showSuccess(this.$t(`messages.${this.isEditMode ?'successfulUpdateGeneral' : 'successfulAddGeneral'}`))
            this.actionsModal = false;
            this.getItems();

            if(this.selectedTab === 0){
              localStorage.removeItem('countries')
              this.countries = await getCountries();
            }
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          })

        }
      } catch (error) {
        window.showError(error);
      }
      finally{
        this.$store.state.loadingStatus = false;
      }
    },
  },
};
</script>
<style></style>
