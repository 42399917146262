import Vue from 'vue';
import App from './App.vue';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import VueExcelXlsx from 'vue-excel-xlsx';
// import vuetify from './plugins/vuetify';
import store from './store/store';
import router from './router';
import vuetify from './plugins/vuetify';
import './plugins';
// import VueNumberInput from '@chenfengyuan/vue-number-input';

import localization from './javascript/localization';
// import { ValidationProvider } from 'vee-validate/dist/vee-validate.full.esm';
// import { ValidationObserver } from 'vee-validate';
import CKEditor from '@ckeditor/ckeditor5-vue2';
import './javascript/vee-validate';
import Fragment from 'vue-fragment';
import * as VueGoogleMaps from 'vue2-google-maps';
import GmapCluster from 'vue2-google-maps/dist/components/cluster';
import Geocoder from '@pderas/vue2-geocoder';
import VueGtag from 'vue-gtag';

import VueMask from 'v-mask';

import Cleave from 'cleave.js';

import Calendar from 'v-calendar';
import { getFormattedFloatValue } from './utils/constants';

Vue.use(Calendar, {
  componentPrefix: 'vc',
});

function getInput(el) {
  if (el.tagName?.toLocaleUpperCase() !== 'INPUT') {
    const els = el.getElementsByTagName('input');
    if (els.length !== 1) {
      throw new Error(`v-cleave requires 1 input, found ${els.length}`);
    } else {
      el = els[0];
    }
  }
  return el;
}

Vue.directive('cleave-text-field', {
  bind: (el, binding, vnode) => {
    if (binding.value.enabled) {
      const input = el.querySelector('input');
      input.cleave = new Cleave(input, binding.value.options);
    }
  },
  update: (el, binding) => {
    if (binding.value.enabled) {
      const event = new Event('input', { bubbles: true });
      setTimeout(function () {
        const input = el.querySelector('input');
        if (
          input &&
          input.cleave?.properties?.result &&
          input.value !== input.cleave?.properties?.result
        ) {
          input.value = input.cleave?.properties?.result;
          input.dispatchEvent(event);
        }
      }, 100);
    }
  },
});

Vue.directive('cleave', {
  bind(el, binding) {
    el._cleave = new Cleave(el, binding.value);
  },
  update(el, binding) {
    el._cleave.destroy();
    el._cleave = new Cleave(el, binding.value);
  },
  unbind(el) {
    el._cleave.destroy();
  },
});

// Vue.component('ValidationProvider', ValidationProvider);
// Vue.component('ValidationObserver', ValidationObserver);

// Vue.use(VueNumberInput);
Vue.use(CKEditor);
Vue.use(VueExcelXlsx);
Vue.config.productionTip = false;
Vue.use(Fragment.Plugin);
Vue.use(Geocoder, {
  defaultCountryCode: null,
  defaultLanguage: null,
  defaultMode: 'address',
  googleMapsApiKey: 'AIzaSyDQkV6rFvJo6gYU_ifEol25CHqZ4OwfbAw',
});

const i18n = localization.setLanguageSettings(Vue);
store.state.router = router;
store.state.i18n = i18n;
// store.state.validator = ValidationObserver

Vue.component('google-map', VueGoogleMaps.Map);
Vue.component('google-map-marker', VueGoogleMaps.Marker);
Vue.component('google-map-info-window', VueGoogleMaps.InfoWindow);
Vue.component('google-map-cluster', GmapCluster);

Vue.use(VueMask);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDQkV6rFvJo6gYU_ifEol25CHqZ4OwfbAw',
    libraries: 'places',
  },
  installComponents: false,
});

Vue.use(
  VueGtag,
  {
    config: {
      id: 'G-ZRT57S6NCF',
      params: {
        send_page_view: false,
      },
    },
  },
  router
);

const transferTypes = [
  {
    Id: 1,
    Name: i18n.t('labels.cargo'),
  },
  {
    Id: 2,
    Name: i18n.t('labels.express'),
  },
];

const shipmentTypes = [
  {
    Id: 3,
    Name: i18n.t('labels.seaway'),
    Shipments: [1],
    Icon: 'mdi-sail-boat',
  },
  {
    Id: 1,
    Name: i18n.t('labels.airline'),
    Shipments: [1, 2],
    Icon: 'mdi-airplane',
  },
  {
    Id: 2,
    Name: i18n.t('labels.roadway'),
    Shipments: [1],
    Icon: 'mdi-truck',
  },
  {
    Id: 5,
    Name: i18n.t('labels.railway'),
    Shipments: [1],
    Icon: 'mdi-train',
  },
];

const loadTypes = [
  {
    Id: 3,
    Name: i18n.t('labels.FCL'),
  },
  {
    Id: 4,
    Name: i18n.t('labels.LCL'),
  },
  {
    Id: 1,
    Name: i18n.t('labels.partial'),
  },
  {
    Id: 5,
    Name: i18n.t('labels.FTL'),
  },
  {
    Id: 6,
    Name: i18n.t('labels.LTL'),
  },
  {
    Id: 2,
    Name: i18n.t('labels.complete'),
  },
];

const purposeOfShippingList = [
  {
    Name: i18n.t('labels.purposeOfShippingGift'),
    Value: 0,
  },
  {
    Name: i18n.t('labels.purposeOfShippingSample'),
    Value: 1,
  },
  {
    Name: i18n.t('labels.purposeOfShippingNotSold'),
    Value: 2,
  },
  {
    Name: i18n.t('labels.purposeOfShippingPersonalEffects'),
    Value: 3,
  },
  {
    Name: i18n.t('labels.purposeOfShippingRepairReturn'),
    Value: 4,
  },
  {
    Name: i18n.t('labels.purposeOfShippingSold'),
    Value: 5,
  },
];

const loadTypeFor = {
  seaway: [
    {
      Id: 3,
      Name: i18n.t('labels.FCL'),
    },
    {
      Id: 4,
      Name: i18n.t('labels.LCL'),
    },
  ],
  airline: [
    {
      Id: 1,
      Name: i18n.t('labels.partial'),
    },
  ],
  roadway: [
    {
      Id: 5,
      Name: i18n.t('labels.FTL'),
    },
    {
      Id: 6,
      Name: i18n.t('labels.LTL'),
    },
  ],
  railway: [
    {
      Id: 2,
      Name: i18n.t('labels.complete'),
    },
    {
      Id: 1,
      Name: i18n.t('labels.partial'),
    },
  ],
};

const serviceTypes = [
  {
    Id: 2,
    Name: i18n.t('labels.portToPort'),
  },
  {
    Id: 1,
    Name: i18n.t('labels.doorToDoor'),
  },
  {
    Id: 3,
    Name: i18n.t('labels.airportToAirport'),
  },
  {
    Id: 4,
    Name: i18n.t('labels.addressToAddress'),
  },
];

const expressServiceTypes = [
  {
    name: i18n.t('labels.ETGB'),
    value: 0,
  },
  {
    name: i18n.t('labels.ExitWithDeclaration'),
    value: 2,
  },
  {
    name: i18n.t('labels.Economic'),
    value: 3,
  },
  {
    name: i18n.t('labels.sample'),
    value: 4,
  },
  {
    name: i18n.t('labels.document'),
    value: 5,
  },
];

const packageTypes = [
  {
    name: i18n.t('labels.package'),
    value: 1,
  },
  {
    name: i18n.t('labels.envelope'),
    value: 2,
  },
];

const priceTypes = [
  i18n.t('labels.basePrice'),
  i18n.t('labels.outOfArea'),
  i18n.t('labels.circumference'),
  i18n.t('labels.ddp'),
  i18n.t('labels.handling'),
  i18n.t('labels.personalDelivery'),
  i18n.t('labels.oversize'),
  i18n.t('labels.insurance'),
  i18n.t('labels.signedDelivery'),
  i18n.t('labels.periodicPrice'),
  i18n.t('labels.eph'),
];

Vue.prototype.$getFormattedFloatValue = getFormattedFloatValue;
Vue.prototype.$expressServiceTypes = expressServiceTypes;
Vue.prototype.$expressPackageTypes = packageTypes;
Vue.prototype.$priceTypes = priceTypes;

new Vue({
  i18n,
  store,
  router,
  vuetify,
  data: {
    transferTypes,
    shipmentTypes,
    serviceTypes,
    loadTypes,
    loadTypeFor,
    purposeOfShippingList,
  },
  render: (h) => h(App),
}).$mount('#app');
