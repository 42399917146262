var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mt-0 py-lg-4",staticStyle:{"margin":"0 !important"},attrs:{"elevation":"1"}},[_c('ConfirmationModal',{attrs:{"title":_vm.$t('labels.warning'),"message":_vm.$t('messages.changeTheExcelFile'),"confirm":_vm.finishExcelFileOperation},model:{value:(_vm.confirmationModal),callback:function ($$v) {_vm.confirmationModal=$$v},expression:"confirmationModal"}}),_c('v-card-title',{staticStyle:{"color":"var(--darkBlue)"}},[_c('v-row',[_c('v-col',{staticClass:"text-lg-left text-center d-flex align-center",attrs:{"cols":"8","sm":"10","md":"10","lg":"10","xl":"10"}},[_c('v-icon',{staticStyle:{"color":"#0177d4 !important","margin-right":"5px"},attrs:{"large":"","id":"menu-activator"},on:{"click":_vm.goBack}},[_vm._v(" mdi-arrow-left ")]),_c('h4',[_vm._v(_vm._s(_vm.$t('buttons.backNavigate')))])],1)],1)],1),_c('v-divider'),_c('v-card',[_c('v-card-text',{staticStyle:{"padding":"0px","margin":"0px"}},[_c('v-row',{staticClass:"px-4 pt-4"},[_c('v-col',{staticClass:"d-flex",staticStyle:{"padding":"0px"},attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('FormElement',{key:"createdAt",attrs:{"vertical":true,"validationLabel":_vm.$t('title.tableHeaderCreateDate'),"topLabel":true,"labelShown":false,"cols":12,"lg":12,"xl":12,"disabled":true,"formType":{
              name: 'text',
            },"placeholder":_vm.$t('title.tableHeaderCreateDate')},model:{value:(_vm.item.CreatedAt),callback:function ($$v) {_vm.$set(_vm.item, "CreatedAt", $$v)},expression:"item.CreatedAt"}})],1),_c('v-col',{staticClass:"d-flex",staticStyle:{"padding":"0px"},attrs:{"cols":"12","sm":"6","md":"6","lg":"6","xl":"6"}},[_c('FormElement',{key:"logisticTypeName",attrs:{"vertical":true,"validationLabel":_vm.$t('titles.subsupplier'),"topLabel":true,"labelShown":false,"cols":12,"lg":12,"xl":12,"disabled":true,"formType":{
              name: 'text',
            },"placeholder":_vm.$t('titles.subsupplier')},model:{value:(_vm.logisticTypeName),callback:function ($$v) {_vm.logisticTypeName=$$v},expression:"logisticTypeName"}})],1)],1)],1)],1),_c('div',{staticStyle:{"position":"relative","width":"100%"}},[(
        (_vm.$vuetify.breakpoint.xl ||
          _vm.$vuetify.breakpoint.lg ||
          _vm.$vuetify.breakpoint.md) &&
        _vm.isSupplier
      )?_c('div',{staticClass:"d-flex mt-6 px-4",staticStyle:{"position":"absolute","right":"0","top":"0","z-index":"1"}},[_c('v-btn',{staticClass:"button",staticStyle:{"border-radius":"12px !important"},attrs:{"elevation":"0","color":"primary","dark":"","href":_vm.item.FileUrl,"depressed":"","download":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(_vm._s(_vm.$t('buttons.downloadFile')))],1),_c('div',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"button",staticStyle:{"border-radius":"12px !important"},attrs:{"elevation":"0","color":"primary","dark":"","depressed":""},on:{"click":_vm.onButtonClick}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload ")]),_vm._v(" "+_vm._s(_vm.$t('buttons.changeExcelFile'))+" ")],1),_c('input',{ref:"uploader",staticClass:"d-none",attrs:{"type":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"},on:{"change":_vm.onFileChanged}})],1)],1):_vm._e(),_c('TabScreen',{staticClass:"px-4 pt-4",attrs:{"slots":_vm.slots},scopedSlots:_vm._u([{key:"outOfArea",fn:function(){return [(_vm.outOfAreaData)?_c('div',{staticStyle:{"border-radius":"0","border-style":"solid","border-bottom-width":"1px","border-left-width":"2px","border-right-width":"2px","border-top-width":"0px","border-color":"lightgray"}},[_c('v-data-table',{attrs:{"items":_vm.outOfAreaData,"item-key":'CoutryName',"no-data-text":_vm.$t('messages.noData'),"hide-default-footer":"","headers":[
              {
                text: _vm.$t('labels.country'),
                value: 'CoutryName',
                sortable: true,
                groupable: false,
                align: 'center',
              },
              {
                text: _vm.$t('labels.minZipCode'),
                value: 'LowPostCode',
                sortable: true,
                groupable: false,
                align: 'center',
              },
              {
                text: _vm.$t('labels.maxZipCode'),
                value: 'HighPostCode',
                sortable: true,
                groupable: false,
                align: 'center',
              },
              {
                text: _vm.$t('labels.pricePerKg'),
                value: 'PricePerKg',
                sortable: true,
                groupable: false,
                align: 'center',
              },
              {
                text: _vm.$t('labels.minPrice'),
                value: 'MinPrice',
                sortable: true,
                groupable: false,
                align: 'center',
              },
            ],"loading":_vm.$store.state.loadingStatus,"options":{
              itemsPerPage: _vm.itemsPerPageOutOfArea,
              page: _vm.pageOutOfArea,
              sortBy: ['CoutryName', 'Price'],
              sortDesc: [_vm.sortDesc],
            }},on:{"update:sort-desc":_vm.sort},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"d-flex justify-center mt-8"},[_c('v-row',[_c('v-col',{attrs:{"cols":"0","sm":"1","md":"1","lg":"1","xl":"1"}}),_c('v-col',{staticClass:"mx-auto",attrs:{"cols":"12","sm":"8","md":"8","lg":"9","xl":"9"}},[_c('v-pagination',{staticClass:"my-4",attrs:{"value":_vm.pageOutOfArea,"length":_vm.pageLengthOutOfArea,"circle":""},on:{"input":_vm.onPageChangeOutOfArea}})],1),_c('v-col',{staticClass:"ml-md-auto d-flex align-center pr-sm-6 px-6 px-sm-0 pb-6 pb-sm-0",attrs:{"cols":"12","sm":"3","md":"3","lg":"2","xl":"2"}},[_c('v-select',{attrs:{"items":[5, 10, 15, 20, 50, 100, 1000],"label":_vm.$t('labels.selectItemsPerPage'),"item-value":"value","item-text":"name","outlined":"","dense":"","hide-details":"","rounded":""},on:{"change":_vm.changeItemsPerPageOutOfArea},model:{value:(_vm.itemsPerPageOutOfArea),callback:function ($$v) {_vm.itemsPerPageOutOfArea=$$v},expression:"itemsPerPageOutOfArea"}})],1)],1)],1)]},proxy:true}],null,false,1788315946)})],1):(_vm.isSupplier)?_c('div',{staticClass:"mt-4"},[_c('input',{ref:"outOfAreaUploader",staticClass:"d-none",attrs:{"type":"file","accept":"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"},on:{"change":_vm.onFileChangedOutOfArea}}),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"button",staticStyle:{"border-radius":"12px !important"},attrs:{"elevation":"0","color":"primary","dark":"","depressed":""},on:{"click":_vm.addNewOutOfArea}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-upload ")]),_vm._v(" "+_vm._s(_vm.$t('labels.addNewFile'))+" ")],1)],1)]):_vm._e()]},proxy:true}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }